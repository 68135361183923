import useDownloadPayment from "hooks/query/payment/use-download-payment";
import React from "react";
import { useIsMobile } from "stablr";
import { ReactComponent as DownloadIcon } from "stablr/assets/icons/download.svg";
import { Button } from "stablr/components/atoms";
import { color } from "stablr/styles";

type PaymentDownloadProps = { paymentReference: string };

export function PaymentDownload({ paymentReference }: PaymentDownloadProps) {
  const { isFetching, refetch } = useDownloadPayment(paymentReference);

  const isMobile = useIsMobile();
  return (
    <Button
      varient="line"
      iconSize={22}
      width={isMobile ? "260px" : "100%"}
      color={color.greyscale.black}
      icon={<DownloadIcon />}
      loading={isFetching}
      onClick={isFetching ? undefined : () => refetch()}
    >
      Payment Instruction
    </Button>
  );
}
