export function parseTokenToDecimals(value: number | string, decimals: number) {
  /**
   * If you want to know the decimals of a ERC20 token, use the following code (with the correct ABI/contract).
   * In this function we assume that the values are either known and not going to change, or fetched directly from the smart contract at runtime.
   */

  // const contract = new Web3Service.eth.Contract(EURR_ABI, Config.web3.contracts[token]);
  // const decimals = await contract.methods.decimals().call();

  return (typeof value === "string" ? parseInt(value) : value) * 10 ** decimals; // Calculate based on decimals (10 to the power)
}
