import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as Logomark } from 'stablr/assets/icons/logo-mark.svg'
import { Icon } from 'stablr/components/atoms'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputAppendType } from 'stablr/components/atoms/Form/InputAppendType'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { input } from 'stablr/styles'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface CurrencyInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  note?: string
  compact?: boolean
  inputType?: string
}

const CurrencyInputStyled = styled.div<{ $maxWidth?: boolean }>`
  max-width: ${({ $maxWidth }) => ($maxWidth ? '900px!important' : input.maxWidth)};
`

const InputGroupStyled = styled.div`
  display: flex;

  & > div:first-child {
    width: 80px;
  }

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: unset;
    flex: 1;
  }
`

CurrencyInput.testid = 'CurrencyInput'

export function CurrencyInput({
  input: { ...inputProps },
  label,
  note,
  inputType,
  compact = false,
  maxWidth = false,
  ...props
}: CurrencyInputProps) {
  const error = props.meta.touched && props.meta.error

  const isValueEmpty = !inputProps.value || inputProps.value.trim() === ''
  return (
    <CurrencyInputStyled
      $maxWidth={maxWidth}
      data-testid={composeTestID(CurrencyInput.testid, props.testid)}
    >
      <Label htmlFor={inputProps.name}>{label}</Label>
      <InputGroupStyled>
        <Input
          {...inputProps}
          {...props}
          textAlign="right"
          type={'text'} // Difficult to enforce denominator as number
          appendElement={<InputAppendType>{inputType ?? ''}</InputAppendType>}
          maxWidth={maxWidth}
          icon={
            <Icon size={22} color={isValueEmpty ? color.greyscale.grey4 : color.greyscale.black}>
              <Logomark />
            </Icon>
          }
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || error) && (
        <InputNote htmlFor={inputProps.name} color={error ? color.palette.red : undefined}>
          {error ? error : note}
        </InputNote>
      )}
    </CurrencyInputStyled>
  )
}
