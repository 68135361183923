import useTransaction from "hooks/query/transaction/use-transaction";
import * as React from "react";
import { useParams } from "react-router-dom";
import { getErrorStatus } from "stablr";
import { ReactComponent as TransactionsSVG } from "stablr/assets/icons/transactions.svg";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";

import PayPendingScreen from "./PayPendingScreen";
import PayProcessingScreen from "./PayProcessingScreen";
import SuccessScreen from "./SuccessScreen";

export default function TransactionScreenComponent() {
  const { id } = useParams();

  const { data: transaction, isLoading, error, refetch } = useTransaction(id ?? "");

  return isLoading ? (
    <CardLoader />
  ) : getErrorStatus(error) === 404 ? (
    <CardEmpty
      iconElement={<TransactionsSVG />}
      title="Transaction not found"
      body="We could not find this transaction"
      onRetry={refetch}
    />
  ) : error || (transaction === undefined && !isLoading) ? (
    <CardError title="Error" body="Error fetching transaction" onRetry={refetch} />
  ) : transaction.Status === "PENDING" ? (
    <PayPendingScreen transaction={transaction} />
  ) : transaction.Status === "AWAITING_PAYOUT" ? (
    <PayProcessingScreen transaction={transaction} />
  ) : transaction.Status === "CONFIRMED" ? (
    <SuccessScreen transaction={transaction} />
  ) : (
    <CardError title="Error" body={`Transaction status "${transaction.Status}" unkown`} onRetry={refetch} />
  );
}
