import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface ErrorMessageProps {
  testid?: string
  children?: string
}

const ErrorMessageStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.regular};
  color: ${color.palette.red};
  margin: ${spacing.s} 0;
  display: block;
  text-align: center;
`

ErrorMessage.testid = 'ErrorMessage'

export function ErrorMessage({ children, ...props }: ErrorMessageProps) {
  return (
    <ErrorMessageStyled data-testid={composeTestID(ErrorMessage.testid, props.testid)}>
      {children}
    </ErrorMessageStyled>
  )
}
