import { PERMISSION_ENUM } from "config/permissions";
import { useDeleteAccount } from "hooks/query/bank-account/use-delete-bank-account";
import React, { useMemo, useState } from "react";
import { ReactComponent as DeleteIcon } from "stablr/assets/icons/delete-outline.svg";
import { Button, Permission } from "stablr/components/atoms";
import { ActionModal } from "stablr/components/organisms";
import { color } from "stablr/styles";

export default function DeleteAccount({ title, id, status }: { id: string; title: string; status?: string }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateDeleteAccount, isLoading } = useDeleteAccount();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onDelete = () => {
    mutateDeleteAccount(
      { accountId: id, title },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const { varient, btnColor, backgroundColor } = useMemo(() => {
    if (status === "pending_delete") {
      return {
        varient: "primary",
        backgroundColor: color.palette.red,
        btnColor: color.greyscale.white,
      };
    }
    return {
      varient: "secondary",
      backgroundColor: color.greyscale.white,
      btnColor: color.palette.red,
    };
  }, [status]);

  return (
    <Permission permission={PERMISSION_ENUM.DELETE_BANK_ACCOUNTS}>
      <Button
        varient={varient as "primary" | "secondary"}
        color={btnColor}
        backgroundColor={backgroundColor}
        icon={<DeleteIcon />}
        onClick={openModal}
        iconSize={20}
      >
        Delete
      </Button>
      <ActionModal
        open={open}
        onClose={() => (!isLoading ? setOpen(false) : undefined)}
        title={`Delete Bank Account ${title}`}
        description={`Are you sure you want to delete ${title}?`}
        btnText="Delete"
        marginSize="small"
        btnBackgroundColor={color.palette.red}
        iconElement={<DeleteIcon />}
        btnColor={color.greyscale.white}
        onConfirm={onDelete}
        loading={isLoading}
      />
    </Permission>
  );
}
