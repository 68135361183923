import { motion } from 'framer-motion'
import * as React from 'react'
import {
  WIZARD_NAVIGATION_STEP_SVG_SIZE,
  WizardSideBarNavigationStep,
} from 'stablr/components/molecules/Wizard/WizardSideBarNavigationStep'
import colors from 'stablr/styles/color'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useIsMobile } from '../../../../hooks/use-is-mobile'

export interface StepListEntry {
  id: string
  label: string
  description: string
  accent?: boolean
}
export interface WizardSideBarNavigationProps {
  steps: StepListEntry[]
  currentLocation: number
  type: 'collapsed' | 'minimal' | 'expanded'
  iconElement: React.ReactElement
  collapsedIconElement?: React.ReactElement
  footerElement: React.ReactElement
}

const MOBILE_MARGIN = spacing.m
const DESKTOP_MARGIN = spacing.xs
const WIZARD_NAVIGATION_LARGE_LINE_SIZE = '41px'
const WIZARD_NAVIGATION_SMALL_LINE_SIZE = '20px'

const WizardSideBarNavigationStyled = styled.nav`
  z-index: 100;
  background: ${colors.gradient};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media ${media.mobile} {
    min-height: 73px;
    width: calc(100% - (${MOBILE_MARGIN} * 2));
    border-radius: ${spacing.s};
    margin: ${MOBILE_MARGIN} ${MOBILE_MARGIN} 0 ${MOBILE_MARGIN};
  }

  @media ${media.desktop} {
    height: calc(100% - (${DESKTOP_MARGIN} * 2));
    border-radius: ${spacing.m};
    margin: ${DESKTOP_MARGIN} 0 ${DESKTOP_MARGIN} ${DESKTOP_MARGIN};
  }
`

const WizardSideBarNavigationOlStyled = styled.ol`
  list-style-type: none;
  display: flex;
  flex: 1;

  & > li {
    margin: ${WIZARD_NAVIGATION_LARGE_LINE_SIZE} 0;
  }

  @media ${media.mobile} {
    flex-direction: row;
    height: 100%;
    padding: 0 ${spacing.l};
  }

  @media ${media.desktop} {
    flex-direction: column;
    height: 80%;
    padding: ${spacing.l} ${spacing.l} ${spacing.l} ${spacing.l};
  }
`

const WizardSideBarNavigationStepWrapperStyled = styled.div(
  ({ $accent }: { $accent?: boolean }) => `
  &:first-child::before {
    content: none;
  }

  &::before {
    ${$accent === true ? `flex: 1;` : ''}
    content: "";
  }

  @media ${media.mobile} {
    display: flex;
    &::before {
      width: ${WIZARD_NAVIGATION_SMALL_LINE_SIZE};
      border-top: 1px white solid;
      margin-top:calc(${WIZARD_NAVIGATION_STEP_SVG_SIZE} / 2);
    }
    ${
      $accent === true &&
      `flex: 1;
      display: flex;
      flex-direction: row;`
    }
  }

  @media ${media.desktop} {
    &::before {
      height: ${WIZARD_NAVIGATION_LARGE_LINE_SIZE};
      display: block;
      ${$accent === true ? `flex: 1;` : ''}
      border-left: 1px white solid;
      content: "";
      margin-left:calc(${WIZARD_NAVIGATION_STEP_SVG_SIZE} / 2);
    }
    ${
      $accent === true
        ? `flex: 1;
      min-height: calc(${WIZARD_NAVIGATION_LARGE_LINE_SIZE} * 3);
      display: flex;
      flex-direction: column;`
        : ''
    }
  }
`,
)

const WizardSideBarNavigationLogoContainer = styled.div`
  & img {
    height: 30px;
    object-fit: contain;
    margin: ${spacing.m} ${spacing.l} ${spacing.xs} ${spacing.l};
  }
`
const WizardSideBarCollapsedLogoContainer = styled.div`
  object-fit: contain;
  margin: ${spacing.l} auto;
  height: 100%;
  & svg {
    width: 40px;
    height: 40px;
  }
`

const WizardSideBarNavigationFooter = styled.footer`
  margin: ${spacing.m} ${spacing.l} ${spacing.m} ${spacing.l};
  text-align: center;
`

WizardSideBarNavigation.testid = 'WizardSideBarNavigation'

export function WizardSideBarNavigation({
  steps,
  type,
  iconElement,
  footerElement,
  currentLocation,
  collapsedIconElement,
}: WizardSideBarNavigationProps) {
  const isMobile = useIsMobile()

  return (
    <WizardSideBarNavigationStyled
      as={!isMobile ? motion.nav : undefined}
      animate={
        !isMobile
          ? { width: type === 'collapsed' ? '125px' : type === 'minimal' ? '100px' : '403px' }
          : undefined
      }
    >
      {type === 'collapsed' ? (
        !isMobile && (
          <WizardSideBarCollapsedLogoContainer>
            {collapsedIconElement}
          </WizardSideBarCollapsedLogoContainer>
        )
      ) : (
        <>
          {!isMobile && type === 'expanded' && (
            <WizardSideBarNavigationLogoContainer>
              {iconElement}
            </WizardSideBarNavigationLogoContainer>
          )}
          <WizardSideBarNavigationOlStyled as={motion.ol} initial="hidden" animate="show">
            {steps.map((step, index) => {
              const stepType =
                currentLocation > index
                  ? 'complete'
                  : currentLocation === index
                  ? 'active'
                  : 'incomplete'

              return (
                <WizardSideBarNavigationStepWrapperStyled
                  key={step.id}
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: stepType === 'incomplete' ? 0.5 : 1 }}
                  $accent={step.accent}
                >
                  <WizardSideBarNavigationStep
                    key={step.id}
                    type={stepType}
                    title={type === 'expanded' ? `${index + 1}. ${step.label}` : undefined}
                    description={type === 'expanded' ? step.description : undefined}
                  />
                </WizardSideBarNavigationStepWrapperStyled>
              )
            })}
          </WizardSideBarNavigationOlStyled>
          {!isMobile && type === 'expanded' && (
            <WizardSideBarNavigationFooter>{footerElement}</WizardSideBarNavigationFooter>
          )}
        </>
      )}
    </WizardSideBarNavigationStyled>
  )
}
