import copy from "copy-to-clipboard";
import { FORM_ERROR } from "final-form";
import { useCreateAPIKey } from "hooks/query/api-management/use-create-api-key";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddCircleOutline } from "stablr/assets/icons/add-circle-outline.svg";
import { Button, Spacer } from "stablr/components/atoms";
import { ButtonContainer, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import color from "stablr/styles/color";
import spacing from "stablr/styles/spacing";
import { APICreateAPIKey } from "types/API/APIManagement";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import APIKeyInfoForm from "../common/APIKeyInfoForm";
import { CopyAPIKeyModal } from "../common/CopyAPIKeyModal";
import { APIKEY_SCHEMA, APIKeySchemaValidation } from "../common/schemaValidation";

export default function AddAPIKey() {
  const navigate = useNavigate();

  const [openExitModal, setOpenExitModal] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [secretKey, setSecretKey] = useState("");
  const { mutateAsync: mutateCreateAPIKey, isLoading } = useCreateAPIKey();

  const handleOnSubmit = async (apiKey: APICreateAPIKey) => {
    try {
      const filteredWhitelistedIPs = apiKey.WhitelistedIPs && apiKey.WhitelistedIPs.filter(ip => ip !== "");
      const payloadApiKey = { ...apiKey, WhitelistedIPs: filteredWhitelistedIPs };
      const res = await mutateCreateAPIKey(payloadApiKey, { onSuccess: () => setOpen(true) });
      setSecretKey(res.Secret);
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate(-1);
    }
  };

  const onCloseModal = () => setOpen(false);

  const handleCopy = () => {
    copy(secretKey);
    onCloseModal();
    navigate(-1);
  };

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={{
          [APIKEY_SCHEMA.WHITELISTED_IPS]: [],
          [APIKEY_SCHEMA.ROLE]: "ADMIN",
        }}
        validate={validateFormValues(APIKeySchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitError, submitting, dirty }) => {
          const isDisabled = submitting || isLoading;
          return (
            <>
              <HeaderBackNav
                title="New API Key"
                onBack={event => {
                  event.preventDefault();
                  handleBack(dirty);
                }}
                testid="add-application"
              />
              <form onSubmit={handleSubmit}>
                <APIKeyInfoForm disabled={isDisabled} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <ButtonContainer justifyContent="flex-start">
                  <Button
                    type="submit"
                    varient="primary"
                    color={color.greyscale.white}
                    icon={<AddCircleOutline />}
                    iconSize={20}
                    loading={submitting}
                  >
                    Add
                  </Button>
                  <Spacer width={spacing.m} />
                  <Button
                    varient="line"
                    color={color.greyscale.black}
                    onClick={event => {
                      event.preventDefault();
                      handleBack(dirty);
                    }}
                    disabled={isDisabled}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
                <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
                <CopyAPIKeyModal secret={secretKey} open={open} onClose={onCloseModal} onConfirm={handleCopy} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}
