/* eslint-disable @typescript-eslint/no-unused-vars */
import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import { validateFormValues } from "functions/validate-schema";
import withPermission from "hocs/permission";
import { useCreateWallet } from "hooks/query/wallet/use-create-wallet";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Alert } from "stablr/components/atoms";
import { ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { getErrorMessage } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { color } from "stablr/styles";
import { APIWallet } from "types/API/Wallet";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import { WalletSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";
import WalletFields from "../common/WalletFields";

function AddWallet() {
  const [openExitModal, setOpenExitModal] = useState(false);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { mutateAsync: mutateAyncPostWallet, isLoading, isSuccess } = useCreateWallet();

  const handleOnSubmit = async (data: APIWallet) => {
    try {
      const createWalletData = {
        Name: data.Name,
        Type: data.Type,
        Address: data.Address,
        Network: data.Network,
      };
      await mutateAyncPostWallet(createWalletData, { onSuccess: () => navigate(-1) });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate("/accounts/wallet");
    }
  };

  const AlertBox = (
    <Alert
      iconColor={color.palette.yellow}
      message="Only add Wallet Addresses that support ERC-20 tokens, otherwise tokens will be lost."
    />
  );

  return (
    <>
      <Form onSubmit={handleOnSubmit} validate={validateFormValues(WalletSchemaValidation)} keepDirtyOnReinitialize>
        {({ values, submitError, handleSubmit, submitting, dirty }) => {
          const isDisabled = submitting || isLoading;
          return (
            <>
              <HeaderBackNav
                title="Add Wallet"
                onBack={event => {
                  event.preventDefault();
                  handleBack(dirty);
                }}
              />
              <form onSubmit={handleSubmit}>
                <>{isMobile && AlertBox}</>
                <WalletFields walletType={values.Type} disabled={isDisabled} values={values} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                <BottomBar
                  paddingHorizontal="0"
                  rightElement={!isMobile ? <SubmitButton buttonText="Add Wallet" loading={isDisabled} /> : undefined}
                  leftElement={isMobile ? <SubmitButton buttonText="Add Wallet" loading={isDisabled} /> : AlertBox}
                />
                <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.ADD_WALLETS)(AddWallet);
