import { APICreateAnyTransactionResponse } from "api/Transaction";
import { APITransaction, APITransactionListItem } from "types/API/Transaction";

export const MOCK_TRANSACTION_DETAIL_LIST: APITransaction[] = [
  {
    Id: "BUY_1",
    Status: "PENDING",
    DigitalAmount: {
      Amount: 10000.0,
      Currency: "EURR",
    },
    Reference: "Pending Buy Transaction",
    FiatAmount: {
      Amount: 10000.0,
      Currency: "EUR",
    },
    Type: "Buy",
    CreatedAt: "2024-01-11T08:00:07.171434Z",
    WalletInfo: {
      WalletAddress: "0x002fbc606415A4aB121899Aa45ec685b42c1a6cb",
      WalletName: "Demo wallet",
    },
    PaymentReference: "3121407311266594",
    BankAccountInfo: {
      AccountName: "test",
      IBAN: "DEMOABCD00234567890",
      Types: ["SEPA"],
    },
    ToBankAccountInfo: {
      Id: "5282987f-2896-4f69-abcd-848a4686a800",
      AccountName: "StablR Solutions DAC",
      IBAN: "DEMOABCD01234567890",
      SWIFT: "DEMO1234",
      BankName: "Demo Holdings Limited",
      BankAddress: "The Demo, 0-10 Old St.",
      BankZip: "DE01 0AB",
      BankCity: "London",
      BankCountry: "UK",
    },
    TransactionHash: null,
  },
  {
    Id: "BUY_2",
    Status: "CONFIRMED",
    DigitalAmount: {
      Amount: 10000.0,
      Currency: "EURR",
    },
    Reference: "Confirmed Buy Transaction",
    FiatAmount: {
      Amount: 10000.0,
      Currency: "EUR",
    },
    Type: "Buy",
    CreatedAt: "2024-01-11T08:00:07.171434Z",
    WalletInfo: {
      WalletAddress: "0x002fbc606415A4aB121899Aa45ec685b42c1a6cb",
      WalletName: "Demo wallet",
    },
    PaymentReference: "3121407311266594",
    BankAccountInfo: {
      AccountName: "test",
      IBAN: "DEMOABCD00234567890",
      Types: ["SEPA"],
    },
    ToBankAccountInfo: {
      Id: "5282987f-2896-4f69-abcd-848a4686a800",
      AccountName: "StablR Solutions DAC",
      IBAN: "DEMOABCD01234567890",
      SWIFT: "DEMO1234",
      BankName: "Demo Holdings Limited",
      BankAddress: "The Demo, 0-10 Old St.",
      BankZip: "DE01 0AB",
      BankCity: "London",
      BankCountry: "UK",
    },
    TransactionHash: null,
  },
  {
    Id: "SELL_1",
    Status: "PENDING",
    DigitalAmount: {
      Amount: 10000.0,
      Currency: "EURR",
    },
    Reference: "Pending Sell Transaction",
    FiatAmount: {
      Amount: 10000.0,
      Currency: "EUR",
    },
    Type: "Sell",
    CreatedAt: "2024-01-11T08:00:07.171434Z",
    WalletInfo: {
      WalletAddress: "0x002fbc606415A4aB121899Aa45ec685b42c1a6cb",
      WalletName: "Demo wallet",
    },
    PaymentReference: "3121407311266594",
    BankAccountInfo: {
      AccountName: "test",
      IBAN: "DEMOABCD00234567890",
      Types: ["SEPA"],
    },
    ToBankAccountInfo: {
      Id: "5282987f-2896-4f69-abcd-848a4686a800",
      AccountName: "StablR Solutions DAC",
      IBAN: "DEMOABCD01234567890",
      SWIFT: "DEMO1234",
      BankName: "Demo Holdings Limited",
      BankAddress: "The Demo, 0-10 Old St.",
      BankZip: "DE01 0AB",
      BankCity: "London",
      BankCountry: "UK",
    },
    TransactionHash: null,
  },
  {
    Id: "SELL_2",
    Status: "AWAITING_PAYOUT",
    DigitalAmount: {
      Amount: 10000.0,
      Currency: "EURR",
    },
    Reference: "Awaiting Payout Sell Transaction",
    FiatAmount: {
      Amount: 10000.0,
      Currency: "EUR",
    },
    Type: "Sell",
    CreatedAt: "2024-01-11T08:00:07.171434Z",
    WalletInfo: {
      WalletAddress: "0x002fbc606415A4aB121899Aa45ec685b42c1a6cb",
      WalletName: "Demo wallet",
    },
    PaymentReference: "3121407311266594",
    BankAccountInfo: {
      AccountName: "test",
      IBAN: "DEMOABCD00234567890",
      Types: ["SEPA"],
    },
    ToBankAccountInfo: {
      Id: "5282987f-2896-4f69-abcd-848a4686a800",
      AccountName: "StablR Solutions DAC",
      IBAN: "DEMOABCD01234567890",
      SWIFT: "DEMO1234",
      BankName: "Demo Holdings Limited",
      BankAddress: "The Demo, 0-10 Old St.",
      BankZip: "DE01 0AB",
      BankCity: "London",
      BankCountry: "UK",
    },
    TransactionHash: "0xe71da8c538671364531f9d0210e073d9e1ee76bc8c2ebe5e39b84389038857ca",
  },
  {
    Id: "SELL_3",
    Status: "CONFIRMED",
    DigitalAmount: {
      Amount: 10000.0,
      Currency: "EURR",
    },
    Reference: "Confirmed Sell Transaction",
    FiatAmount: {
      Amount: 10000.0,
      Currency: "EUR",
    },
    Type: "Sell",
    CreatedAt: "2024-01-11T08:00:07.171434Z",
    WalletInfo: {
      WalletAddress: "0x002fbc606415A4aB121899Aa45ec685b42c1a6cb",
      WalletName: "Demo wallet",
    },
    PaymentReference: "3121407311266594",
    BankAccountInfo: {
      AccountName: "test",
      IBAN: "DEMOABCD00234567890",
      Types: ["SEPA"],
    },
    ToBankAccountInfo: {
      Id: "5282987f-2896-4f69-abcd-848a4686a800",
      AccountName: "StablR Solutions DAC",
      IBAN: "DEMOABCD01234567890",
      SWIFT: "DEMO1234",
      BankName: "Demo Holdings Limited",
      BankAddress: "The Demo, 0-10 Old St.",
      BankZip: "DE01 0AB",
      BankCity: "London",
      BankCountry: "UK",
    },
    TransactionHash: "0xe71da8c538671364531f9d0210e073d9e1ee76bc8c2ebe5e39b84389038857ca",
  },
];

export const MOCK_CREATE_BUY_SELL_TRANSACTION_RESPONSE: APICreateAnyTransactionResponse = {
  Amount: "10000",
  BankDetails: {
    Name: "Test Company",
    Address: "This Street, 123 Lane",
    PostCode: "MK32 12ND",
  },
  ClientReference: "cb028caa-4ff1-418c-9278-1a240cb5d3e4",
  ClientReferenceInformation: "test api data",
  IsDepositOrder: true,
  IssueDate: "2024-01-11T07:43:04.7119693Z",
  MintOrderId: "64f2b1da-3216-4e00-8d01-942259b12051",
  OrderId: "6c33689d-7da1-4917-9b2b-2be09c0e55cf",
  Organization: { Id: "cb028caa-4ff1-418c-9278-1a240cb5d3e4", Metadata: "META", Name: "Test 1 Entity", City: "test" },
  PaymentInstructionId: "5192670936324651",
  PaymentReferenceNumber: "5192670936324651",
  ToBankAccount: { AccountName: "StablR Solutions DAC", IBAN: "GB24SEOU19870010089503", SWIFT: "SEOUGB21" },
  TotalAmount: "10000",
  TransactionFeeAmount: "0",
  TransactionFeeFactor: "0",
};

export const MOCK_TRANSACTIONS: APITransactionListItem[] = [
  {
    Id: "BUY_1",
    Type: "Buy",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Pending Buy Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "PENDING",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "BUY_1",
    TransactionHash: "134567",
  },
  {
    Id: "BUY_2",
    Type: "Buy",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Confirmed Buy Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "CONFIRMED",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "BUY_2",
    TransactionHash: "134567",
  },
  {
    Id: "SELL_1",
    Type: "Sell",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Pending Sell Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "PENDING",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "SELL_1",
    TransactionHash: "134567",
  },
  {
    Id: "SELL_2",
    Type: "Sell",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Awaiting Payout Sell Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "AWAITING_PAYOUT",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "SELL_2",
    TransactionHash: "134567",
  },
  {
    Id: "SELL_3",
    Type: "Sell",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Confirmed Sell Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "CONFIRMED",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "SELL_3",
    TransactionHash: "134567",
  },
  {
    Id: "SELL_4",
    Type: "Sell",
    FiatAmount: {
      Amount: 1000000,
      Currency: "EUR",
    },
    DigitalAmount: {
      Amount: 1000000,
      Currency: "EURR",
    },
    CreatedAt: new Date().toISOString(),
    Reference: "Rejected Sell Transaction",
    WalletInfo: {
      WalletAddress: "abcdefghijxyz",
      WalletName: "Demo Wallet",
    },
    Status: "REJECTED",
    OrderReference: "Order reference from user", // Entered By User
    WalletName: "Wallet A",
    BankAccountInfo: {
      AccountName: "lalala",
      IBAN: "13345",
      Types: ["SEPA"],
    },
    FiatCurrency: "EUR",
    PaymentReferenceId: "SELL_3",
    TransactionHash: "134567",
  },
];
