import * as React from 'react'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Paragraph } from '../../Paragraph'

export interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactElement | string
  id: string
  error?: boolean
}
const SIZE = '18px'
const ICON_SIZE = '12px'

const CheckBoxStyled = styled.div`
  display: flex;
  align-items: center;
`

const InputStyled = styled.input<{ $error: boolean }>`
  margin-right: ${spacing.s};
  accent-color: ${color.theme.primary};
  border: 1px solid ${({ $error }) => ($error ? color.palette.red : color.theme.primary)};
  appearance: none;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 2px;
  position: relative;
  cursor: pointer;

  &[value='true'] {
    background-color: ${color.theme.primary};

    &::after {
      content: '';
      background-image: url('/images/icons/checkbox.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: ${ICON_SIZE};
      width: ${SIZE};
      height: ${SIZE};
      position: absolute;
      left: -0.8px; // Manual Adjustment to visually center the check mark
      top: -1.4px; // Manual Adjustment to visually center the check mark
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${color.greyscale.white};
    }
  }

  min-width: ${SIZE};
`

export function CheckBox({ value, label, id, onChange, error = false }: CheckBoxProps) {
  return (
    <CheckBoxStyled>
      <InputStyled $error={error} id={id} type="checkbox" onChange={onChange} value={value} />
      <Paragraph as="label" htmlFor={id}>
        {label}
      </Paragraph>
    </CheckBoxStyled>
  )
}
