import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { usePermission } from 'stablr/hooks'
import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../atoms/Icon'

export interface DrawerLinkProps {
  testid?: string
  children?: string
  to: string
  icon: React.ReactElement
  variant?: 'primary' | 'secondary' | 'white'
  permission?: string
}

const LINK_PADDING = spacing.xs
const ICON_CIRCLE_SIZE = '40px'

const DrawerLinkContainerStyled = styled.li`
  width: 100%;
  margin: ${spacing.s} 0;
`

const DrawerLinkStyled = styled.div<{ $variant: 'primary' | 'secondary' | 'white' }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${LINK_PADDING} 0;
  text-decoration: inherit;

  &:visited,
  &:active {
    text-decoration: inherit; /* no underline */
  }

  &.active {
    &::before {
      content: '';
      width: 4px;
      display: block;
      background-color: ${color.theme.primary};
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px;
    }

    & svg > path {
      fill: url(#gradient) !important;
    }

    & span {
      color: ${color.greyscale.black};
    }
  }

  &:hover {
    &.active {
      & span {
        color: ${color.greyscale.black};
      }
    }
  }

  &:hover {
    & span {
      color: ${color.greyscale.black};
    }
  }
`

const DrawerLinkIconStyled = styled.div<{ $variant: 'primary' | 'secondary' | 'white' }>`
  ${({ $variant }) =>
    $variant === 'primary'
      ? `background-color : ${color.theme.primary};
      box-shadow: 0 0 0 4px rgba(132, 157, 255, 0.13);
      `
      : $variant === 'secondary'
      ? `background-color:  ${color.theme.secondary};
      box-shadow: 0 0 0 4px rgba(132, 157, 255, 0.13);`
      : `
  backgroundColor: ${color.greyscale.white};
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.025);
  &:hover {
    & svg > path {
      fill: url(#gradient) !important;
    }
  }
  `}
  width: ${ICON_CIRCLE_SIZE};
  min-height: ${ICON_CIRCLE_SIZE};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:visited,
  &:active {
    text-decoration: inherit; /* no underline */
  }
`

const DrawerLinkTextStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.semiBold};
  color: ${color.greyscale.grey5};
  margin: ${spacing.s} 0 0 0;
`

DrawerLink.testid = 'DrawerLink'

export function DrawerLink({
  variant = 'white',
  icon,
  to,
  children,
  permission,
  ...props
}: DrawerLinkProps) {
  const hasPermission = usePermission()

  if (permission && !hasPermission(permission)) return <></>

  return (
    <DrawerLinkContainerStyled>
      <DrawerLinkStyled
        $variant="white"
        as={NavLink}
        to={to}
        {...props}
        data-testid={composeTestID(DrawerLink.testid, props.testid)}
      >
        <DrawerLinkIconStyled $variant={variant}>
          <Icon size={18}>{icon}</Icon>
        </DrawerLinkIconStyled>
        <DrawerLinkTextStyled>{children}</DrawerLinkTextStyled>
      </DrawerLinkStyled>
    </DrawerLinkContainerStyled>
  )
}
