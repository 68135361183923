import { APICompanyInfo } from "types/API/Company";

import Connection from "./Connection";
// import { MOCK_COMPANY_INFO } from "./mocks/mock-company-information";

export const getCompanyDetails = (): Promise<APICompanyInfo> =>
  Connection.get<APICompanyInfo>(`/customers/current`, undefined, "GetCompanyInfo");

// const getCompanyDetails = (_: string): Promise<APICompanyInfo> => api.Mock.mockRequest("/comapny", MOCK_COMPANY_INFO);

export type PostCompanyDetailsType = Omit<APICompanyInfo, "Status" | "SessionId">;

export type PostCompanyDetailsResponse = {
  organizationId: string;
};

export const postCompanyDetails = (companyDetails: PostCompanyDetailsType) =>
  Connection.post<PostCompanyDetailsResponse>(`/customers`, {
    ...companyDetails,
  });
