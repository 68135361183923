import { Config } from "config";
import { FORM_ERROR } from "final-form";
import { validateTransaction } from "pages/BuySell/Sell/screens/PayPendingScreen";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Button, color, ErrorMessage, getErrorMessage, Paragraph, TextInput } from "stablr";

type TransactionHashValidationForm = {
  transactionHash: string;
};

export function ValidateTransactionHash() {
  const [transactionHash, setTransactionHash] = React.useState<false | string>(false);
  const handleValidateTransaction = async (values: TransactionHashValidationForm) => {
    setTransactionHash(false);
    try {
      await validateTransaction(values.transactionHash); // Check if it exists on the network
      setTransactionHash(values.transactionHash);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <Form onSubmit={handleValidateTransaction}>
      {({ handleSubmit, submitError, submitting }) => (
        <>
          <Field
            name="transactionHash"
            placeholder="0x4f3..."
            component={TextInput}
            label="Validate Transaction Hash Test"
          />

          <Button loading={submitting} onClick={handleSubmit}>
            Validate
          </Button>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          {transactionHash && (
            <Paragraph style={{ color: color.palette.green }}>
              Transaction {transactionHash} is on the network {Config.web3.network}
            </Paragraph>
          )}
        </>
      )}
    </Form>
  );
}
