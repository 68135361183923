import { useQuery } from "@tanstack/react-query";
import api from "api";

useCompanyInfo.queryKey = "companyInfo";

useCompanyInfo.queryKey = "companyDetails";
export default function useCompanyInfo() {
  return useQuery([useCompanyInfo.queryKey], async () => api.Company.getCompanyDetails(), {
    notifyOnChangeProps: "all",
  });
}
