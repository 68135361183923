import { Hub } from "@aws-amplify/core";
import { useQueryClient } from "@tanstack/react-query";
import { type ResourcesConfig, Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";
import UserData, { EMPTY_USER_DATA } from "context/UserData";
import * as React from "react";
import { CognitoUserData, EmptyCognitoUserData } from "types/amplify/CognitoUserData";

interface CognitoUserSessionContextProps {
  children: React.ReactElement;
}
const authConfig: ResourcesConfig["Auth"] = {
  Cognito: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID ?? "",
    userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ?? "",
  },
};
Amplify.configure({
  Auth: authConfig,
});

// Set up the token provider to use CookieStorage
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

export default function UserDataContextProvider({ children }: CognitoUserSessionContextProps) {
  const [cognitoData, setCognitoData] = React.useState<CognitoUserData | EmptyCognitoUserData>(EMPTY_USER_DATA);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    // type and cases Hub.listen
    const initialiseData = async () => {
      try {
        setCognitoData({ ...(await fetchAuthSession()).tokens?.idToken?.payload } as any);
      } catch (er) {
        setCognitoData(EMPTY_USER_DATA as EmptyCognitoUserData);
      }
    };
    initialiseData();

    const cancelListener = Hub.listen("auth", async data => {
      switch (data.payload.event) {
        case "signedIn":
          setCognitoData({
            ...(await fetchAuthSession()).tokens?.idToken?.payload,
            data: data?.payload?.data,
          } as CognitoUserData);
          break;
        case "signedOut":
          setCognitoData(EMPTY_USER_DATA);
          queryClient.removeQueries(); // Remove all queries
          break;
        case "tokenRefresh":
          setCognitoData({
            ...(await fetchAuthSession()).tokens?.idToken?.payload,
            data: data?.payload,
          } as CognitoUserData);
          break;
        case "tokenRefresh_failure":
          setCognitoData(EMPTY_USER_DATA);
          queryClient.removeQueries(); // Remove all queries
          break;
      }

      return () => {
        cancelListener();
      };
    });
  }, [queryClient]);

  return <UserData.Provider value={cognitoData}>{children}</UserData.Provider>;
}
