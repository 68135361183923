import isAfter from "date-fns/isAfter";
import subMonths from "date-fns/subMonths";
import isBIC from "validator/lib/isBIC";
import * as yup from "yup";

const MAXIMUM_FILE_SIZE_MB = 2.5;

export enum BANK_ACCOUNT_SCHEMA {
  ACCOUNT_NAME = "Name",
  BANK_NAME = "BankName",
  IBAN = "Iban",
  BANK_POSTAL_CODE = "BankPostalCode",
  SWIFT = "Swift",
  BLINC_ID = "BlincId",
  BIC = "BIC",
  COUNTRY = "Country",
  CITY = "City",
  BANK_ADDRESS = "BankAddress",
  TYPE = "Type",
  BANK_STATEMENT = "BankStatement",
  BANK_STATEMENT_FILE_ID = "BankStatementFileId",
  BANK_STATEMENT_FILE_NAME = "BankStatementFileName", // Field only visible from existing data, not create/update
}

/**
 * Validation 2.2: Create Bank Account
 */

export const BankAccountSchemaValidation = yup.object({
  [BANK_ACCOUNT_SCHEMA.BANK_NAME]: yup.string().required("Bank Name is required"),
  [BANK_ACCOUNT_SCHEMA.BANK_POSTAL_CODE]: yup
    .string()
    .required("Bank Postal Code is required")
    .matches(/^[0-9A-Z]+$/, "Postal Code can only contain numbers and letters.")
    .transform((value: string) => value.replace(/\s/g, "")),
  [BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME]: yup.string().required("Account Name is required"),
  [BANK_ACCOUNT_SCHEMA.IBAN]: yup.string().when("Type", {
    is: (value: string) => ["SWIFT", "SEPA"].includes(value),
    then: yup.string().required("IBAN/Account Number is required"),
  }),
  [BANK_ACCOUNT_SCHEMA.BIC]: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, "BIC/Swift can only contain alpha numeric characters.")
    .when("Type", {
      is: (value: string) => ["SWIFT", "SEPA"].includes(value),
      then: yup
        .string()
        .required("BIC/Swift code is required")
        .test(
          "Is Valid SWIFT/BIC",
          "Must be valid BIC/Swift code",
          value => !value || (typeof value === "string" && isBIC(value))
        )
        .transform((value: string) => value.replace(/\s/g, "")),
    }),
  [BANK_ACCOUNT_SCHEMA.BLINC_ID]: yup.string().when("Type", {
    is: "BLINC",
    then: yup.string().required("Blinc ID is required for a 'BLINC' account type."),
  }),
  [BANK_ACCOUNT_SCHEMA.BANK_ADDRESS]: yup.string().required("Bank Address is required"),
  [BANK_ACCOUNT_SCHEMA.CITY]: yup
    .string()
    .required("City is required")
    .matches(/^[a-zA-Z? ]+$/, "Not a valid city name."),
  [BANK_ACCOUNT_SCHEMA.COUNTRY]: yup
    .string()
    .required("Country is required")
    .matches(/^[a-zA-Z? ]+$/, "Not a valid country name."),
  [BANK_ACCOUNT_SCHEMA.TYPE]: yup.string().required("Type is required"),
  [BANK_ACCOUNT_SCHEMA.BANK_STATEMENT]: yup
    .mixed()
    .test("fileSize", `Maximum file size ${MAXIMUM_FILE_SIZE_MB}MB`, value => {
      if (value === undefined) {
        return false;
      }
      const oneMegaByte = 1024 * 1024;
      return value.size <= oneMegaByte * MAXIMUM_FILE_SIZE_MB;
    })
    .test("Is less than 3 months old", "File must be less than 3 months old", value => {
      if (value === undefined) {
        return false;
      } else if (value.lastModifiedDate === undefined) {
        return true; // Incase browser doesnt support this or file is missing date, skip validation
      }

      const isLessThanThreeMonths = isAfter(value.lastModifiedDate, subMonths(new Date(), 3));
      return isLessThanThreeMonths;
    })
    .test("Is .PDF, .PNG or .JPG", "File must be a .PDF, .PNG or .JPG", value => {
      // Types from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      if (value === undefined) {
        return false;
      }
      return ["application/pdf", "image/png", "image/jpg", "image/jpeg"].includes(value?.type);
    })
    .required("Bank statement is required"),
});
