import useRoles from "hooks/query/role/use-role";
import React from "react";
import { Field } from "react-final-form";
import { ReactComponent as PeopleIcon } from "stablr/assets/icons/people.svg";
import { ListBoxOptionType } from "stablr/components/atoms/Form/ListBox";
import { ListBoxInput } from "stablr/components/molecules";

import { USER_SCHEMA } from "./schemaValidation";

export const USER_ROLE_LABELS = {
  Admin: "Admin",
  TreasuryManager: "Treasury Manager",
  Trader: "Trader",
};

RoleMultiSelectInput.testid = "RoleMultiSelectInput";

export function RoleMultiSelectInput() {
  const { data: roles, isLoading } = useRoles();

  const roleOptions: ListBoxOptionType[] = React.useMemo(
    () =>
      roles
        ? (roles.map((role: keyof typeof USER_ROLE_LABELS) =>
            USER_ROLE_LABELS?.[role]
              ? { value: role, label: USER_ROLE_LABELS?.[role] }
              : { value: roleOptions, label: role }
          ) as ListBoxOptionType[])
        : ([] as ListBoxOptionType[]),
    [roles]
  );

  return (
    <Field
      placeholder="Role"
      label="Role"
      iconElement={<PeopleIcon />}
      name={USER_SCHEMA.ROLE}
      component={ListBoxInput}
      options={roleOptions}
      multiple
      loading={isLoading}
    />
  );
}
