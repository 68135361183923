import * as React from 'react'
import { color, spacing } from 'stablr/styles'
import styled from 'styled-components'

type TableBodyVarientType = 'default' | 'shaded' | 'shadow' | 'blank'

export interface TableBodyProps {
  children: React.ReactElement | React.ReactElement[]
  varient?: TableBodyVarientType
}

const TableBodyStyled = styled.tbody`
  ${({ $varient }: { $varient: TableBodyVarientType }) =>
    $varient === 'default'
      ? `& > tr {
      & > td,
      th {
        border-bottom: 1px solid ${color.greyscale.grey3};
      }
  }`
      : $varient === 'blank'
      ? ``
      : `
      
      //  No easy way to set border radius of table body

& > tr {
  & > td, & > th {
    background-color: ${color.greyscale.grey1};
  }
}

& > tr:first-child td:first-child, & > tr:first-child th:first-child {
  border-top-left-radius: ${spacing.xs};
}

& > tr:first-child td:last-child, & > tr:first-child th:last-child {
  border-top-right-radius: ${spacing.xs};
}

& > tr:last-child td:first-child, & > tr:last-child th:first-child {
  border-bottom-left-radius: ${spacing.xs};
}

& > tr:last-child td:last-child, & > tr:last-child th:last-child {
  border-bottom-right-radius: ${spacing.xs};
}`}
`

export function TableBody({ children, varient = 'default' }: TableBodyProps) {
  return <TableBodyStyled $varient={varient}>{children}</TableBodyStyled>
}
