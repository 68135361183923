import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIBankAccount } from "types/API/BankAccount";

useBankAccounts.queryKey = "bankAccounts";

export function useBankAccounts() {
  return useQuery(
    [useBankAccounts.queryKey],
    async () => api.BankAccount.getAccountList() as Promise<APIBankAccount[]>,
    {
      notifyOnChangeProps: "all",
    }
  );
}
