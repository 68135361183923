import * as React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import SellScreen from "./screens/SellScreen";
import TransactionScreen from "./screens/TransactionScreen";

const SellContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default function SellComponent() {
  return (
    <SellContainer>
      <Routes>
        <Route path="/:id" element={<TransactionScreen />} />
        <Route
          path=""
          element={<SellScreen title="1. Sell StablR" body="Please follow these steps to Sell your StablR assets" />}
        />
      </Routes>
    </SellContainer>
  );
}
