const spacing = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '20px',
  l: '32px',
  xl: '52px',
  xxl: '84px',
  xxxl: '120px'
}

export default spacing
