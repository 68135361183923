import * as React from 'react'
import { useState } from 'react'
import { ReactComponent as ChevronDownIcon } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from 'stablr/assets/icons/chevron-up.svg'
import { Icon } from 'stablr/components/atoms'
import { TableHeader, TableSpacer } from 'stablr/components/atoms/Table'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface TableInlineAccordionGroupProps {
  title?: string
  children?: React.ReactElement[] | React.ReactElement
  isDefaultOpen?: boolean
  colSpan: number // Needed to line the action up correctly
  marginBottom?: string
}

const TableInlineAccordionGroupStyled = styled.tr`
  position: relative;
  cursor: pointer;
  height: 25px;
  & > * {
    border-bottom: 0 !important; // Remove inherited bottom border
    background-color: ${color.greyscale.grey1};
    &:first-child {
      border-top-left-radius: ${spacing.xs};
      border-bottom-left-radius: ${spacing.xs};
    }
    &:last-child {
      border-top-right-radius: ${spacing.xs};
      border-bottom-right-radius: ${spacing.xs};
    }
  }
`

const ActionIconStyled = styled.td`
  padding: 0;
  width: 25px;
`

export function TableInlineAccordionGroup({
  title,
  children,
  isDefaultOpen = true,
  colSpan,
  marginBottom = spacing.xs,
}: TableInlineAccordionGroupProps) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)
  return (
    <>
      <TableInlineAccordionGroupStyled onClick={() => setIsOpen(!isOpen)}>
        <TableHeader varient="bold" paddingSize="xsmall" colSpan={colSpan}>
          {title}
        </TableHeader>
        <ActionIconStyled>
          <Icon color={color.greyscale.black} size={10}>
            {!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </Icon>
        </ActionIconStyled>
      </TableInlineAccordionGroupStyled>
      {isOpen && children}
      <TableSpacer height={marginBottom} />
    </>
  )
}
