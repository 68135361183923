import { FORM_ERROR } from "final-form";
import { useCreateWebhook } from "hooks/query/webhooks/use-create-webhook";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Button, Spacer } from "stablr/components/atoms";
import { ButtonContainer, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import color from "stablr/styles/color";
import spacing from "stablr/styles/spacing";

import ExitFormModal from "../common/ExitFormModal";
import { WebhookSchemaValidation } from "../common/schemaValidation";
import WebhookForm from "../common/WebhookForm";

interface WebhookFormValues {
  Name: string;
  Url: string;
  Secret: string;
  Status: boolean;
}

export default function AddWebhook() {
  const [openModal, setOpenModal] = useState(false);

  const { mutateAsync: mutateCreateWebhook, isLoading } = useCreateWebhook();

  const navigate = useNavigate();

  const handleOnSubmit = async (webhook: WebhookFormValues) => {
    try {
      const { Status, ...webhookWithoutStatus } = webhook;
      await mutateCreateWebhook(webhookWithoutStatus, { onSuccess: () => navigate(-1) });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleExitForm = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenModal(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Form
        initialValues={{ Status: true }}
        onSubmit={handleOnSubmit}
        validate={validateFormValues(WebhookSchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitError, submitting, dirty }) => {
          const isDisabled = submitting || isLoading;
          return (
            <>
              <HeaderBackNav title="Add Webhook" onBack={() => handleExitForm(dirty)} testid="add-webhook" />
              <form onSubmit={handleSubmit}>
                <WebhookForm />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <ButtonContainer justifyContent="flex-start">
                  <Button
                    type="submit"
                    varient="primary"
                    color={color.greyscale.white}
                    iconSize={20}
                    loading={submitting}
                  >
                    Create
                  </Button>
                  <Spacer width={spacing.m} />
                  <Button
                    varient="line"
                    color={color.greyscale.black}
                    onClick={event => {
                      event.preventDefault();
                      handleExitForm(dirty);
                    }}
                    disabled={isDisabled}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
                <ExitFormModal openModal={openModal} setOpenModal={setOpenModal} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}
