import { ReactComponent as StablRLogoMark } from "assets/svg/logo-mark-white.svg";
import { signOut } from "aws-amplify/auth";
import useUserData from "hooks/context/use-user-data";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { ReactComponent as InputSVG } from "stablr/assets/icons/input.svg";
import { InlineLink, Paragraph, Picture } from "stablr/components/atoms";
import { MenuList, MenuListItem } from "stablr/components/molecules";
import { WizardSideBarNavigation } from "stablr/components/organisms";
import { SteppedGuideTemplate } from "stablr/components/templates";
import { color } from "stablr/styles";

import CompanyVerification from "./screens/CompanyVerification";
import CreatePassword from "./screens/CreatePassword";
import EmailVerification from "./screens/EmailVerification";
import Explained from "./screens/Explained";
import SMSAuthentication from "./screens/SMSAuthentication";
import Success from "./screens/Success";
import SummaryAndSignUp from "./screens/SummaryAndSignUp";
import TOTPAuthentication from "./screens/TOTPAuthentication";
import UserInformation, { UserInformationForm } from "./screens/UserInformation";

const formatName = (name: string) => {
  if (!name) return "";
  const words = name.split(" ");
  const firstChar = words[0][0];
  const restOfName = name.slice(words[0].length).slice(0, 15);
  return `${firstChar}. ${restOfName}${restOfName.length === 15 ? "..." : ""}`;
};

export default function SignUpComponent() {
  const navigate = useNavigate();

  const user = useUserData();

  const [userInformation, setUserInformation] = React.useState<UserInformationForm | undefined>();

  const currentLocation = useCurrentLocationFromPaths([
    "/sign-up/user-information",
    "/sign-up/create-password",
    "/sign-up/email-verification",
    "/sign-up/sms-authentication",
    "/sign-up/totp-authentication",
    "/sign-up/company-verification",
    "/sign-up/summary-and-sign-up",
    "/sign-up/success",
  ]);

  const isAuthenticatedLocation = currentLocation >= 4;

  // This is normalized because totp and sms are the same location, therefore, anything above this should account for two locations before being 1
  const normalizedCurrentLocation = currentLocation < 4 ? currentLocation : currentLocation - 1;

  const handleOnLogout = async () => {
    await signOut();
    navigate("../sign-in");
  };

  React.useEffect(() => {
    if (currentLocation === 4 && user?.["custom:assignedOrganization"] !== undefined) {
      // If location is company verification (This only happens with a back button)
      navigate("/sign-up/summary-and-sign-up");
    }
  }, [currentLocation, user, navigate]);

  return (
    <SteppedGuideTemplate
      menuElement={
        isAuthenticatedLocation ? (
          <MenuList buttonText={user.name && formatName(user.name)}>
            <MenuListItem onClick={handleOnLogout} icon={<InputSVG />}>
              Log out
            </MenuListItem>
          </MenuList>
        ) : undefined
      }
      navigationElement={
        <WizardSideBarNavigation
          currentLocation={normalizedCurrentLocation}
          iconElement={
            <Picture
              supportedTypes={["webp", "png"]}
              alt="StablR Logo"
              src="/images/logos/logo-stablr-white-transparent"
            />
          }
          collapsedIconElement={<StablRLogoMark />}
          footerElement={
            <Paragraph color={color.greyscale.white}>
              <>
                Already have an account?{" "}
                <InlineLink color={color.greyscale.white} to="/sign-in">
                  Sign in
                </InlineLink>
              </>
            </Paragraph>
          }
          type={currentLocation === -1 ? "collapsed" : normalizedCurrentLocation === 5 ? "minimal" : "expanded"}
          steps={[
            {
              id: "user-information",
              label: "User Information",
              description: "Please provide us your user information",
            },
            {
              id: "create-password",
              label: "Create Password",
              description: "Choose a secure password",
            },
            {
              id: "email-verification",
              label: "Email Verification",
              description: "Verify your email address",
            },
            {
              id: "totp-authentication",
              label: "Two Factor Authentication",
              description: "Setup authentication for extra safety",
            },
            {
              id: "company-verification",
              label: "Company Information",
              description: "Please provide us your company information",
            },
            {
              id: "summary-and-sign-up",
              label: "Summary & Sign Up",
              description: "Summary of the previous steps",
              accent: true,
            },
          ]}
        />
      }
    >
      <Routes>
        <Route
          path=""
          element={<Explained onBack={handleOnLogout} onContinue={() => navigate("./user-information")} />}
        />
        <Route
          path="/user-information"
          element={
            <UserInformation
              userInformation={userInformation}
              onBack={() => navigate("../sign-up")}
              onContinue={values => {
                const modifiedValues = {
                  ...values,
                  email: values.email.toLowerCase(),
                };
                setUserInformation(modifiedValues);
                navigate("./create-password");
              }}
            />
          }
        />
        <Route
          path="/create-password"
          element={
            <CreatePassword
              userInformation={userInformation as UserInformationForm}
              onBack={() => navigate("./user-information")}
              onContinue={email => navigate(`./email-verification?email=${email}`)}
            />
          }
        />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route
          path="/sms-authentication"
          element={
            <SMSAuthentication
              onContinue={() => {
                navigate("./company-verification");
              }}
            />
          }
        />
        <Route
          path="/totp-authentication"
          element={<TOTPAuthentication onContinue={() => navigate("./company-verification")} />}
        />
        <Route
          path="/company-verification"
          element={<CompanyVerification onContinue={() => navigate("./summary-and-sign-up")} />}
        />
        <Route
          path="/summary-and-sign-up"
          element={
            <SummaryAndSignUp
              onBack={() => navigate("./company-verification")}
              onContinue={() => navigate("./success")}
            />
          }
        />
        <Route path="/success" element={<Success onContinue={handleOnLogout} />} />
      </Routes>
    </SteppedGuideTemplate>
  );
}
