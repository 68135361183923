import * as React from "react";
import { DashboardContainer } from "stablr";
import { media } from "stablr";
import styled from "styled-components";

const BuySellContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  width: 100%;

  @media ${media.mobile} {
    padding: 0;
  }
`;

export default function BuySellComponent({ children }: { children: React.ReactElement }) {
  return <BuySellContainerStyled as={DashboardContainer}>{children}</BuySellContainerStyled>;
}
