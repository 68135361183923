import { Menu } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as ChevronDownSVG } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpSVG } from 'stablr/assets/icons/chevron-up.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { useIsMobile } from 'stablr/hooks/use-is-mobile'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Avatar } from '../../atoms/Avatar'
import { Icon } from '../../atoms/Icon'

export interface UserDropDownProps {
  testid?: string
  children?: React.ReactElement[] | React.ReactElement
  name?: string
}

const MENU_LARGE_PADDING_HORIZONTAL = '20px'
const MENU_LARGE_PADDING_VERTICAL = '10px'
const MENU_SMALL_PADDING_HORIZONTAL = '10px'
const MENU_SMALL_PADDING_VERTICAL = '10px'

const UserDropDownStyled = styled.div`
  position: relative;
`
const MenuButtonStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: ${color.greyscale.white};
  color: ${color.theme.primary};
  line-height: 27px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    padding: ${MENU_SMALL_PADDING_VERTICAL} ${MENU_SMALL_PADDING_HORIZONTAL};

    &[aria-expanded='true'] {
      background-color: ${color.greyscale.grey1};
    }
  }

  @media ${media.desktop} {
    & *:first-child {
      margin-right: ${spacing.s};
    }

    padding: ${MENU_LARGE_PADDING_VERTICAL} ${MENU_LARGE_PADDING_HORIZONTAL};
  }
`
const MenuItemsStyled = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  border-radius: 3px;
  background-color: ${color.greyscale.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: ${spacing.xxs};
  min-width: 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;
  height: 14px;

  & svg {
    display: block;
  }
`

UserDropDown.testid = 'userDropDown'

export function UserDropDown({ name, children, ...props }: UserDropDownProps) {
  const isMobile = useIsMobile()
  return (
    <Menu as={UserDropDownStyled} data-testid={composeTestID(UserDropDown.testid, props.testid)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            <Avatar name={name} />
            {!isMobile ? (
              <>
                {name}
                <IconStyled color={color.theme.primary} as={Icon}>
                  {open ? <ChevronUpSVG /> : <ChevronDownSVG />}
                </IconStyled>
              </>
            ) : (
              <></>
            )}
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>{children}</Menu.Items>
        </>
      )}
    </Menu>
  )
}
