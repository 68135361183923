import { APIFileResponse } from "types/API/File";
import { APIPaymentListItem } from "types/API/Payment";

export const MOCK_PAYMENT_LIST: APIPaymentListItem[] = [
  {
    TransactionId: "demo1",
    PaymentInstructionId: "demo_payment_1236",
    Status: "CONFIRMED",
    DigitalAmount: {
      Amount: 1234,
      Currency: "EURR",
    },
    FiatAmount: {
      Amount: 1234,
      Currency: "EUR",
    },
    ClientId: "lalala",
    DateCreated: new Date().toISOString(),
    Reference: "Demo Payment 1",
    Type: "Buy",
  },
  {
    TransactionId: "demo2",
    PaymentInstructionId: "demo_payment_12349",
    Status: "CONFIRMED",
    DigitalAmount: {
      Amount: 1234,
      Currency: "EURR",
    },
    FiatAmount: {
      Amount: 1234,
      Currency: "EUR",
    },
    ClientId: "lalala",
    DateCreated: new Date().toISOString(),
    Reference: "Demo Payment 2",
    Type: "Sell",
  },
  {
    TransactionId: "demo3",
    PaymentInstructionId: "demo_payment_1230",
    Status: "PENDING",
    DigitalAmount: {
      Amount: 1234,
      Currency: "EURR",
    },
    FiatAmount: {
      Amount: 1234,
      Currency: "EUR",
    },
    ClientId: "demo12534",
    DateCreated: new Date().toISOString(),
    Reference: "Demo Payment 3",
    Type: "Buy",
  },
];

export const MOCK_PAYMENT_INSTRUCTION_FILE: APIFileResponse = {
  SignedUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  UploadedAt: "12-12-2023 12:43:12",
};
