import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { InstructionStep, InstructionStepProps } from '../../molecules/InstructionStep'

export interface InstructionStepsProps {
  testid?: string
  steps: Omit<InstructionStepProps, 'stepNumber'>[]
}

const InstructionStepsStyled = styled.ol`
  list-style-type: none;
  padding-left: 0;

  & > li {
    margin: ${spacing.xl} 0;
  }
`

InstructionSteps.testid = 'instructionSteps'

export function InstructionSteps({ testid, steps }: InstructionStepsProps) {
  return (
    <InstructionStepsStyled type="1" data-testid={composeTestID(InstructionSteps.testid, testid)}>
      {steps.map((step, index) => (
        <InstructionStep key={`${index + 1}-${step.title}`} stepNumber={index + 1} {...step} />
      ))}
    </InstructionStepsStyled>
  )
}
