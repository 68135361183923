import * as yup from "yup";

export enum VALIDATE_TRANSACTION_SCHEMA {
  TRANSACTION_HASH = "transactionHash",
}

export const TransferConfirmationSchemaValidation = yup.object({
  [VALIDATE_TRANSACTION_SCHEMA.TRANSACTION_HASH]: yup
    .string()
    .required("Transaction Hash is required")
    .test("validateTransaction", "Invalid transaction hash", (value?: string) =>
      value ? validateTxhash(value) : true
    ),
});

function validateTxhash(TxHash: string) {
  return /^0x([A-Fa-f0-9]{64})$/.test(TxHash);
}
