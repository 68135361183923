import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FileUpload, FileUploadDataType } from 'stablr/components/atoms/Form/FileUpload'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions'
import { color, input } from 'stablr/styles'
import styled from 'styled-components'

export interface FileUploadInputProps
  extends FieldRenderProps<FileUploadDataType, HTMLInputElement> {
  label?: string
  description?: string
}

const FileUploadInputStyled = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`

FileUploadInput.testid = 'FileUploadInput'

export function FileUploadInput({
  input: { ...inputProps },
  label,
  note,
  placeholder,
  description,
  compact = false,
  meta: { error, touched },
  ...props
}: FileUploadInputProps) {
  const isError = error && touched

  return (
    <FileUploadInputStyled data-testid={composeTestID(FileUploadInput.testid, props.testid)}>
      <Label readOnly={props.readOnly} htmlFor={inputProps.name} appendText={description}>
        {label}
      </Label>
      <FileUpload
        placeholder={placeholder}
        errorElement={
          (!compact || note?.trim() || isError) && (
            <InputNote htmlFor={inputProps.name} color={isError ? color.palette.red : undefined}>
              {isError ? error : note}
            </InputNote>
          )
        }
        {...inputProps}
      ></FileUpload>
    </FileUploadInputStyled>
  )
}
