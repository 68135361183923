import { motion } from 'framer-motion'
import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { getPasswordSecurityLevel } from 'stablr/functions/get-password-security-level'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import input from 'stablr/styles/input'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface PasswordIndicatorProps {
  testid?: string
  value: string
}

const BAR_HEIGHT = '4px'
const BAR_SPACE = '10px'

const BAR_SIZE = `calc((100% - (${BAR_SPACE} * 3)) / 4)`

const PasswordIndicatorStyled = styled.div`
  height: 21px;
  margin: ${spacing.xs} 0 ${spacing.s} 0;
  width: 100%;
  max-width: ${input.maxWidth};
  position: relative;
`

const SvgStyled = styled.svg`
  position: absolute;
  width: 100%;
  height: ${BAR_HEIGHT};
  top: 0;
  left: 0;
`

const PasswordLevelContainerStyled = styled.div`
  width: 100%;
  height: ${BAR_HEIGHT};
  background-color: ${color.greyscale.grey2};
  clip-path: url(#levelClip);
  overflow: hidden;
`

const PasswordLevelStyled = styled.div`
  height: ${BAR_HEIGHT};
  background-color: ${color.palette.green};
`

const PasswordLevelTextStyled = styled.span(
  ({ $securityLevel }: { $securityLevel: 0 | 1 | 2 | 3 | 4 }) => `
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${color.greyscale.grey5};
  line-height: 21px;
  font-weight: ${fontWeight.regular};
  width: 25%;
  text-align: center;
  position: absolute;
  top: 5px;
  left: ${$securityLevel === 0 ? 0 : $securityLevel === 4 ? 75 : $securityLevel * 25}%
`,
)

const securityLevelPercentMap = {
  0: `12.5%`,
  1: `36%`,
  2: `62.5%`,
  3: `87.5%`,
  4: '100%',
}

const strengthColorPercentMap = {
  0: color.palette.red,
  1: color.palette.yellow,
  2: color.palette.green,
  3: color.palette.green,
  4: color.palette.green,
}

const strengthTextMap = {
  0: 'Very Weak',
  1: 'Weak',
  2: 'Ok',
  3: 'Strong',
  4: 'Very Strong',
}

PasswordIndicator.testid = 'PasswordIndicator'

export function PasswordIndicator({ value, ...props }: PasswordIndicatorProps) {
  const securityLevel = React.useMemo(() => getPasswordSecurityLevel(value), [value])

  const strengthPercent = securityLevelPercentMap[securityLevel]
  const strengthColor = strengthColorPercentMap[securityLevel]
  const strengthText = strengthTextMap[securityLevel]

  return (
    <PasswordIndicatorStyled data-testid={composeTestID(PasswordIndicator.testid, props.testid)}>
      <SvgStyled width="0" height="0">
        <defs>
          <clipPath id="levelClip">
            <rect x={'0%'} width={BAR_SIZE} height={BAR_HEIGHT} rx={'2px'} />
            <rect
              x={`calc(((100% - (${BAR_SPACE} * 3)) / 4) * 1 + ${BAR_SPACE})`}
              width={BAR_SIZE}
              height={BAR_HEIGHT}
              rx={'2px'}
            />
            <rect
              x={`calc(((100% - (${BAR_SPACE} * 3)) / 4) * 2 + (${BAR_SPACE} * 2))`}
              width={BAR_SIZE}
              height={BAR_HEIGHT}
              rx={'2px'}
            />
            <rect
              x={`calc(((100% - (${BAR_SPACE} * 3)) / 4) * 3 + (${BAR_SPACE} * 3))`}
              width={BAR_SIZE}
              height={BAR_HEIGHT}
              rx={'2px'}
            />
          </clipPath>
        </defs>
      </SvgStyled>
      <PasswordLevelContainerStyled>
        <PasswordLevelStyled
          as={motion.div}
          style={{ backgroundColor: strengthColor }}
          initial={{ width: '0%' }}
          animate={{ width: strengthPercent }}
        />
      </PasswordLevelContainerStyled>
      <PasswordLevelTextStyled $securityLevel={securityLevel}>
        {strengthText}
      </PasswordLevelTextStyled>
    </PasswordIndicatorStyled>
  )
}
