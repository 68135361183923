import * as React from "react";
import { Switch } from "stablr/components/atoms";
import { composeTestID } from "stablr/functions/compose-test-id";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

interface SwitchOptionProps {
  testid?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  badgeElement?: React.ReactElement;
  label?: string;
}

const SwitchOptionStyled = styled.div`
  display: flex;
  margin: ${spacing.m} 0;
  max-width: 500px;
`;

const LabelStyled = styled.div`
  flex: 1;
  color: ${color.greyscale.grey5};
`;

SwitchOption.testid = "switchOption";

export default function SwitchOption({ value, onChange, label, badgeElement, ...props }: SwitchOptionProps) {
  return (
    <SwitchOptionStyled data-testid={composeTestID(SwitchOption.testid, props.testid)}>
      <LabelStyled>
        {label}
        {badgeElement}
      </LabelStyled>

      <Switch checked={value} onChange={onChange} />
    </SwitchOptionStyled>
  );
}
