import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useWebhook } from "./use-webhook";
import useWebhooks from "./use-webhooks";

export function useDeleteWebhook() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();
  return useMutation({
    mutationFn: async ({ webhookId }: { webhookId: string }) => api.Webhooks.deleteWebhook(webhookId),
    onSuccess: async (_: unknown, { webhookId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useWebhooks.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useWebhook.queryKey, webhookId] });
      successToast({ message: "Webhook has been deleted successfully." });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
