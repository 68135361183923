import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

interface TestNoteProps {
  children: string
}

const TestNoteStyled = styled.div`
  display: fixed;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${color.palette.green};
  color: ${color.greyscale.white};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.button};
  padding: ${spacing.m};
  user-select: none;
  pointer-events: none;
`

export function TestNote({ children }: TestNoteProps) {
  return <TestNoteStyled>{children}</TestNoteStyled>
}
