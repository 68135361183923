import { APICreateWallet, APIWallet, APIWalletListItem } from "types/API/Wallet";

import Connection from "./Connection";

export const getWalletList = (): Promise<Array<APIWalletListItem>> =>
  Connection.get("/wallets").then(response => (!Array.isArray(response) ? [] : response));

export const getWalletById = (walletId: string): Promise<APIWalletListItem> => Connection.get(`/wallets/${walletId}`);

export const createWallet = async (walletData: APICreateWallet) => {
  const { data: createdResponse } = await Connection.postSocketWithEvent<APIWallet>("/wallets", walletData);
  return createdResponse;
};

export const updateWallet = (walletId: string, data: Partial<APIWallet>) =>
  Connection.putSocket(`/wallets/${walletId}`, data, undefined, "UpdateWallet");

export const deleteWallet = async (walletId: string) => {
  await Connection.deleteSocket(`/wallets/${walletId}`, undefined, "DeleteWallet");
};

export const approveWallet = (WalletId: string) =>
  Connection.postSocket("/ops/confirmwallet", { WalletId }, undefined, "ApproveWallet");

export const rejectWallet = (WalletId: string) =>
  Connection.post(`/ops/rejectwallet`, { WalletId }, undefined, "RejectWallet");

export const confirmDeleteWallet = (WalletId: string) =>
  Connection.post("/ops/confirmdeletewallet", { WalletId }, undefined, "ConfirmDeleteWallet");
