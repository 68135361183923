import logError from "functions/log/log-error";
import * as React from "react";
import { ErrorBoundary as ErrorBoundaryComponent } from "react-error-boundary";
import { Spacer } from "stablr/components/atoms";
import { CardError } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import { spacing } from "stablr/styles";

interface ErrorBoundaryProps {
  children: React.ReactElement | React.ReactElement[];
}

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <ErrorBoundaryComponent
      FallbackComponent={ErrorFallback}
      onError={er => {
        logError("ErrorBoundary", er);
      }}
    >
      {children}
    </ErrorBoundaryComponent>
  );
}

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <>
      <Spacer height={spacing.l} />
      <CardError title="Oops something went wrong" body={getErrorMessage(error)} onRetry={resetErrorBoundary} />
    </>
  );
}
