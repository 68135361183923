import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APIUpdateUser } from "types/API/User";

import { useUser } from "./use-user";
import useUsers from "./use-users";

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, user }: { userId: string; user: APIUpdateUser }) => {
      const updatedUser: APIUpdateUser = {
        Name: user.Name,
        Phone: user.Phone,
        UserGroups: user.UserGroups,
        DefaultCurrency: user.DefaultCurrency,
      };
      return api.User.updateUser(userId, updatedUser);
    },
    onSuccess: async (_: unknown, { userId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useUser.queryKey, userId] });
      successToast({ message: "User has been updated successfully" });
    },
  });
}
