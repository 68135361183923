import React from "react";
import { Field } from "react-final-form";
import { PasswordInput, SwitchInput, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";

import { WEBHOOK_SCHEMA } from "./schemaValidation";

interface WebhookFormProps {
  disabled?: boolean;
  status?: string;
}

export default function WebhookForm({ disabled, status }: WebhookFormProps) {
  return (
    <>
      <FormLayout columns={1}>
        <Field
          name={WEBHOOK_SCHEMA.NAME}
          label="Display Name"
          description="(Max 25 Character)"
          placeholder="Display Name"
          component={TextInput}
          disabled={disabled}
          compact
        />
        <Field
          name={WEBHOOK_SCHEMA.URL}
          component={TextInput}
          label="URL"
          placeholder="https://webhook1a2b3c"
          disabled={disabled}
          compact
          maxWidth
        />
        <Field
          name={WEBHOOK_SCHEMA.SECRET}
          label="Secret"
          placeholder="Secret Key"
          component={PasswordInput}
          disabled={disabled}
          autoComplete="new-password"
          compact
          tooltip={`JWT signed with this secret will be sent in the header "x-webhook-secret" of the webhook request`}
          maxWidth
        />
        <Field
          name={WEBHOOK_SCHEMA.STATUS}
          label="Status"
          component={SwitchInput}
          disabled={status === undefined && true}
          compact
        />
      </FormLayout>
    </>
  );
}
