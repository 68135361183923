import useUserData from "hooks/context/use-user-data";
import { useVerifyTOTPToken } from "hooks/query/amplify/use-verify-totp-token";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Button, getErrorCode, getErrorMessage, MFAInput, Paragraph, Spacer } from "stablr";
import { composeTestID } from "stablr/functions/compose-test-id";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface TOTPSetupProps {
  testid?: string;
  totpSecret?: string;
  onSuccess: () => void;
}

const TotpFormStyled = styled.div`
  min-height: 250px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const QRTextStyled = styled.div`
  text-align: center;
  padding: ${spacing.m} 0;
  color: ${color.greyscale.grey4};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.regular};
  overflow-wrap: break-word;
`;

const ButtonContainerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

TOTPSetup.testid = "TOTPSetup";

export default function TOTPSetup({ totpSecret, onSuccess, ...props }: TOTPSetupProps) {
  const [isLinked, setIsLinked] = React.useState(false);
  const { mutateAsync: mutateAsyncVerifyTOTPToken } = useVerifyTOTPToken();
  const user = useUserData();

  const handleSubmitTotpChallenge = async (values: { code: string }) => {
    try {
      await mutateAsyncVerifyTOTPToken(values.code);
      onSuccess();
    } catch (error) {
      if (getErrorCode(error) === "CodeMismatchException") {
        return { code: "Code does not match" };
      }
      return { code: getErrorMessage(error) };
    }
  };

  return (
    <Form onSubmit={handleSubmitTotpChallenge}>
      {({ handleSubmit, submitting, submitError }) => (
        <TotpFormStyled onSubmit={handleSubmit} data-testid={composeTestID(TOTPSetup.testid, props.testid)}>
          {isLinked ? (
            <>
              <Paragraph>
                To enable please confirm your 2FA setup by entering the <b>code</b> from the <b>Authenticator App</b>
              </Paragraph>
              <Field
                name="code"
                component={MFAInput}
                onComplete={handleSubmit}
                invalid={submitError !== undefined}
                loading={submitting}
              />
            </>
          ) : (
            <>
              <Paragraph>
                <b>Open an Authenticator App</b> (i.e. Microsoft or Google) and add an account. <b>Scan the QR code</b>{" "}
                or fill in the code manually to add your StablR account to your authenticator app
              </Paragraph>
              <Spacer height={spacing.l} />
              <QRCodeSVG value={`otpauth://totp/StablR?secret=${totpSecret}&issuer=${user.email}`} />
              <Spacer height={spacing.m} />
              <QRTextStyled>{totpSecret}</QRTextStyled>

              <ButtonContainerStyled>
                <Button varient="secondary" onClick={() => setIsLinked(true)}>
                  Next
                </Button>
              </ButtonContainerStyled>
            </>
          )}
        </TotpFormStyled>
      )}
    </Form>
  );
}
