
import { EURR_ABI } from "api/web3/EURR_ABI";
import { Config } from "config";
import { parseTokenToDecimals } from "functions/web3/parse-token-to-decimals";
import React from "react";
import { Button, getErrorMessage, useToast } from "stablr";
import { BaseError, InsufficientFundsError, UserRejectedRequestError } from "viem";
import { useAccount, useContractWrite } from "wagmi";

type WalletTransferButtonProps = {
  fromWallet: string;
  toWallet: string;
  amount: number | string;
  token: "EURR";
  loading: boolean;
  onComplete: (transactionHash: string) => void;
};

const TOKEN_MAP = {
  EURR: {
    abi: EURR_ABI,
    contract:
      Config.web3.network === "mainnet" ? Config.web3.contracts.EURR.mainnet : Config.web3.contracts.EURR.sepolia,
    decimals: 6, // This is subject to change of the smart contracts 'decimals' get method.
  },
};

export function WalletTransferButton({
  token,
  toWallet,
  amount,
  onComplete,
  fromWallet,
  loading,
}: WalletTransferButtonProps) {
  const { errorToast, infoToast } = useToast();
  const { isConnected, address } = useAccount();

  // Add the ability to reduce the value for testing purposes
  const decimals = Config.features.reduceTokenAmountsInWalletConnect ? 1 : TOKEN_MAP[token]?.decimals;

  const { writeAsync, isLoading: isLoadingWriteContract } = useContractWrite({
    address: TOKEN_MAP[token]?.contract,
    abi: EURR_ABI,
    functionName: "transfer",
    args: [toWallet, parseTokenToDecimals(amount, decimals)],
    onSettled(data) {
      // Once the transaction is both sent and settled on the network
      if (data) {
        infoToast({ message: "Transaction Settled" });
        onComplete(data.hash);
      }
    },
  });

  const requestTransfer = async () => {
    try {
      await writeAsync?.();
      infoToast({ message: "Transaction Sent" });
    } catch (err) {
      const error = err as BaseError;
      // This was previously hiding other errors. For example this one: https://github.com/hirosystems/stacks.js/issues/1096#issuecomment-946350299
      if (error.walk === undefined) {
        errorToast({ message: `${getErrorMessage(err)}` });
        return;
      }
      // Custom event handling, see: https://github.com/wagmi-dev/viem/discussions/793#discussioncomment-6288655
      switch (true) {
        case error?.walk(e => e instanceof UserRejectedRequestError) instanceof UserRejectedRequestError: // Parse Rejection error handler
          errorToast({ message: `Transaction Rejected` });
          break;
        case error?.walk(e => e instanceof InsufficientFundsError) instanceof InsufficientFundsError:
          errorToast({ message: `Insufficient funds` });
          break;
        default: {
          // Best solution I could find to parse other errors to the user in a clean way: https://github.com/wagmi-dev/viem/discussions/793#discussioncomment-7006994
          const parseCleanError = error?.walk().message.split(":")[1].split("Version")[0] || error.toString();
          errorToast({ message: `${parseCleanError}` });
        }
      }
    }
  };

  const disabled = !isConnected || fromWallet.toLowerCase() !== address?.toLowerCase();

  return (
    <Button
      loading={loading || isLoadingWriteContract}
      varient={disabled ? "primary" : "gradient"}
      disabled={disabled}
      onClick={requestTransfer}
    >
      Send Transaction
    </Button>
  );
}
