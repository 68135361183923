import copy from 'copy-to-clipboard'
import React from 'react'
import { ReactComponent as CopyIcon } from 'stablr/assets/icons/content-copy.svg'
import { media } from 'stablr/styles'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useToast } from '../../../../hooks/use-toast'
import { Button } from '../../Button'
import { Spacer } from '../../Spacer'

export interface TableDataCopyableProps {
  displayValue?: string | number
  value?: string | number
  backgroundColor?: string
}

const TableDataCurrencyContainerStyled = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 ${spacing.xs};
  margin: -${spacing.xs} 0 -${spacing.xs} -${spacing.xs}; // Offset extra sizing from button
  border-radius: ${spacing.xxs};
  cursor: pointer;
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: ${color.greyscale.white};
  }

  & > button {
    padding-right: 0;
  }

  @media ${media.mobile} {
    max-width: 300px;
    word-break: break-word;
  }
`

export function TableDataCopyable({
  displayValue,
  value,
  backgroundColor,
}: TableDataCopyableProps) {
  const { infoToast } = useToast()

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    event?.preventDefault()
    copy(`${value}`)
    infoToast({ message: 'Copied to clipboard', icon: <CopyIcon /> })
  }

  return (
    <TableDataCurrencyContainerStyled backgroundColor={backgroundColor} onClick={handleClick}>
      <span>{displayValue || value}</span>
      <Spacer width="16px" />
      <Button
        layout="icon-only"
        varient="secondary"
        marginHorizontalSize="none"
        size="small"
        icon={<CopyIcon />}
        iconColor={color.greyscale.grey5}
        iconSize={20}
        backgroundColor={color.other.transparent}
        onClick={undefined}
      ></Button>
    </TableDataCurrencyContainerStyled>
  )
}
