import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import SellWizard from "pages/BuySell/components/SellWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { media, spacing } from "stablr";
import { Button, Spacer } from "stablr/components/atoms";
import { BottomBar } from "stablr/components/organisms";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import SuccessfulTransactionTable from "./components/SuccessfulTransactionTable";

interface SuccessScreenProps {
  transaction: APITransaction;
}

const SuccessScreenStyled = styled(BuySellContainer)`
  flex: 1;
  padding: 0 ${spacing.xl} ${spacing.xxl} ${spacing.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1250px;

  @media ${media.mobile} {
    margin: 0;
    padding: 0 ${spacing.m};
  }
`;

export default function SuccessScreen({ transaction }: SuccessScreenProps) {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <StepWizard
        title="Transaction Successful"
        body="Your transactions has successfully been completed"
        statusText={parseTransactionBadgeText(transaction.Status)}
        variant={getTransactionBadgeVarient(transaction.Status)}
      />
      <SellWizard />
      <Spacer height={spacing.xl} />
      <SuccessScreenStyled>
        <SuccessfulTransactionTable transaction={transaction} />
        <BottomBar
          paddingHorizontal="0px"
          rightElement={
            <Button varient="line" onClick={handleOnBack}>
              Back to Dashboard
            </Button>
          }
        />
      </SuccessScreenStyled>
    </>
  );
}
