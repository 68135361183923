import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import styled from 'styled-components'

import { Heading, HeadingElementTypes } from '../../atoms/Heading'
import { Paragraph } from '../../atoms/Paragraph'

export interface InstructionStepProps {
  testid?: string
  stepNumber: number
  title: string | React.ReactElement
  titleAs?: HeadingElementTypes
  titleVarient?: HeadingElementTypes
  body: string
}

const InstructionStepStyled = styled.li`
  list-style-type: none;
`

InstructionStep.testid = 'instructionStep'

export function InstructionStep({
  testid,
  stepNumber,
  title,
  titleAs = 'h2',
  titleVarient,
  body,
}: InstructionStepProps) {
  return (
    <InstructionStepStyled data-testid={composeTestID(InstructionStep.testid, testid)}>
      <Heading as={titleAs} varient={titleVarient}>
        {stepNumber}. {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
    </InstructionStepStyled>
  )
}
