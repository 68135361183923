import { Config } from "config";
import { WalletInput } from "pages/BuySell/components/WalletInput";
import * as React from "react";
import { color, fontSize, fontWeight, formatCurrency, Icon, Loader, media, Spacer, spacing } from "stablr";
import { ReactComponent as WalletSVG } from "stablr/assets/icons/account-balance-wallet.svg";
import { ReactComponent as Logomark } from "stablr/assets/icons/logo-mark.svg";
import { ReactComponent as SwitchAccountSVG } from "stablr/assets/icons/switch-account.svg";
import { TableDataCopyable } from "stablr/components/atoms/Table";
import styled from "styled-components";
import { APIBankAccount } from "types/API/BankAccount";
import { APIBuyTransactionTable } from "types/API/Transaction";

interface BankWalletProps {
  transaction: APIBuyTransactionTable;
  bank?: APIBankAccount;
  isLoadingBank: boolean;
  selectedNetwork: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${media.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  & span:nth-child(2) {
    font-weight: ${fontWeight.bold};
  }
`;

const BoxStyled = styled.div`
  flex: 1;
  padding: ${spacing.l} ${spacing.s} ${spacing.m} ${spacing.s};
  box-shadow: 0 1px 4px 0 #0000001a;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  font-size: ${fontSize.h4};
  background-color: ${color.greyscale.white};
  align-items: center;
  max-width: 100%;

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

const NameStyled = styled.div`
  color: ${color.theme.primary};
  font-size: ${fontSize.input};
  padding-top: ${spacing.xl};
  font-weight: ${fontWeight.semiBold};
`;

const IbanStyled = styled.div`
  text-align: center;
  font-size: ${fontSize.data};
  padding-top: ${spacing.m};

  & span {
    max-width: 300px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
`;

const BalanceStyled = styled.div`
  & span {
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.bold};
  }

  padding: 0 0 ${spacing.l} 0;
`;

const WalletAddressStyled = styled.div`
  text-align: center;
  font-size: ${fontSize.p};
  padding-top: ${spacing.m};
`;

const BottomAlignedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  align-items: center;
  padding-bottom: 65px;
`;

export default function BankWalletBoxes({ transaction, bank, isLoadingBank, selectedNetwork }: BankWalletProps) {
  const formatBalance = (value: string) =>
    value ? formatCurrency({ Amount: value, Denominator: Config.fallbackDenominator }, 2) : "";

  const transactionType = window.location.pathname.includes("buy") ? "BUY" : "SELL";
  const isBlincType = bank?.Types.includes("BLINC");

  return (
    <Container>
      <BoxStyled style={{ order: transactionType === "BUY" ? 1 : 2 }}>
        <IconContainer>
          <Icon size={20} color={color.palette.purple}>
            <SwitchAccountSVG />
          </Icon>
          <Spacer width={spacing.xs} />
          Bank Account
        </IconContainer>
        {!isLoadingBank ? (
          <>
            <IbanStyled>
              <TableDataCopyable value={isBlincType ? bank?.BlincId : bank?.Iban} />
            </IbanStyled>
            {!transaction?.wallet && <NameStyled>{bank?.Name ?? "-"}</NameStyled>}
            {transaction?.wallet && (
              <BottomAlignedContainer>
                <NameStyled>{bank?.Name ?? "-"}</NameStyled>
              </BottomAlignedContainer>
            )}
          </>
        ) : (
          <>
            <Spacer height={spacing.xl} />
            <Loader />
          </>
        )}
      </BoxStyled>
      <BoxStyled style={{ order: transactionType === "BUY" ? 2 : 1 }}>
        <IconContainer>
          <Icon size={20} color={color.palette.purple}>
            <WalletSVG />
          </Icon>
          <Spacer width={spacing.xs} />
          Wallet Balance
        </IconContainer>
        {transaction?.wallet && (
          <WalletAddressStyled>
            <TableDataCopyable value={transaction?.wallet?.Address} />
            <Spacer height={spacing.m} />
            <BalanceStyled>
              <Icon size={28}>
                <Logomark />
              </Icon>
              <span>{transaction?.currentBalance && formatBalance(transaction.currentBalance)}</span>
            </BalanceStyled>
            New Balance: <b>{transaction?.newBalance && formatBalance(transaction.newBalance)} EURR </b>
          </WalletAddressStyled>
        )}
        <WalletInput selectedNetwork={selectedNetwork} />
      </BoxStyled>
    </Container>
  );
}
