import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import { useGetAccount } from "hooks/query/bank-account/use-bank-account";
import { useUpdateAccount } from "hooks/query/bank-account/use-update-bank-account";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "stablr/components/atoms";
import { CardLoader, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { useIsMobile, useToast } from "stablr/hooks";
import spacing from "stablr/styles/spacing";
import { APIBankAccount } from "types/API/BankAccount";

import AccountFields from "../common/AccountFields";
import { BankAccountSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";

function EditBankAccount() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const { accountId = "" } = useParams();

  const { successToast } = useToast();

  const { data: account, isLoading } = useGetAccount(accountId);
  const { mutateAsync: mutateAsyncUdateAccount } = useUpdateAccount();

  const handleOnSubmit = async (data: APIBankAccount) => {
    try {
      await mutateAsyncUdateAccount({ accountId, accountDetails: data });
      successToast({ message: "Bank account has been created successfully" });
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  if (isLoading) return <Loader size="100px" />;

  return (
    <>
      <HeaderBackNav title={account?.Name ?? ""} onBack={() => navigation("/accounts/bank-accounts")} />
      {isLoading ? (
        <CardLoader />
      ) : (
        <Form
          onSubmit={handleOnSubmit}
          validate={validateFormValues(BankAccountSchemaValidation)}
          initialValues={{ ...account }}
          keepDirtyOnReinitialize
        >
          {({ submitting, handleSubmit, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <AccountFields isEditForm />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                <BottomBar
                  paddingHorizontal={isMobile ? "0" : spacing.l}
                  rightElement={
                    !isMobile ? <SubmitButton buttonText="Save Bank Account" loading={submitting} /> : undefined
                  }
                  leftElement={
                    isMobile ? <SubmitButton buttonText="Save Bank Account" loading={submitting} /> : undefined
                  }
                />
              </form>
            );
          }}
        </Form>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_BANK_ACCOUNTS)(EditBankAccount);
