import { Config } from "config";
import * as React from "react";
import { InlineLink, Table } from "stablr/components/atoms";
import { TableDataCopyable } from "stablr/components/atoms/Table";
import { TableGroup } from "stablr/components/molecules/Table";
import { formatCurrency } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

const { TableBody, TableData, TableRow, TableHeader, Table: TableStyled } = Table;

interface FromWalletAddressTableProps {
  transaction: APITransaction;
}

const TableGroupStyled = styled.div`
  margin-top: ${spacing.xl};

  & div {
    max-width: 1600px;
  }
`;

const TransactionDetailsTableStyled = styled(TableStyled)`
  width: 100%;
  padding: ${spacing.m};
  max-width: 1800px;

  & > tbody > tr:not(:last-child) > td,
  & > tbody > tr:not(:last-child) > th {
    border-bottom: 1px solid ${color.greyscale.grey3};
  }

  & > tbody > tr > td {
    width: 40%;
    padding-right: 0;
  }

  & > tbody > tr > td > div {
    justify-content: flex-end;
  }

  & > tbody > tr:nth-last-child(2) > th,
  & > tbody > tr:nth-last-child(2) > td {
    border-bottom: 1px dotted ${color.greyscale.black};
    padding-bottom: ${spacing.xxl};
  }

  & > tbody > tr:nth-last-child(1) {
    border-bottom: none;
  }

  & > tbody > tr:nth-last-child(1) > th,
  & > tbody > tr:nth-last-child(1) > td {
    border-bottom: none;
  }

  & > tbody > tr > th {
    padding: ${spacing.m} 0;
    color: ${color.greyscale.black};
  }
`;

export function FromWalletAddressTable({ transaction }: FromWalletAddressTableProps) {
  const etherscanLink =
    Config.web3.network === "mainnet"
      ? `https://etherscan.io/tx/${transaction.TransactionHash}`
      : `https://sepolia.etherscan.io/tx/${transaction.TransactionHash}`;

  return (
    <TableGroupStyled>
      <TableGroup label={"Transaction details"} varient="blank">
        <TransactionDetailsTableStyled cellSpacing="0">
          <TableBody>
            <TableRow>
              <TableHeader paddingSize="small">From Wallet address</TableHeader>
              <TableData paddingSize="small">
                <TableDataCopyable
                  displayValue={transaction.WalletInfo.WalletAddress}
                  value={transaction.WalletInfo.WalletAddress}
                ></TableDataCopyable>
              </TableData>
            </TableRow>
            <TableRow>
              <TableHeader paddingSize="small">Wallet name</TableHeader>
              <TableData paddingSize="small">{transaction?.WalletInfo.WalletName}</TableData>
            </TableRow>
            <TableRow>
              <TableHeader paddingSize="small">You will pay</TableHeader>
              <TableData paddingSize="small">
                {formatCurrency(
                  {
                    Amount: transaction.FiatAmount?.Amount,
                    Denominator: Config.fallbackDenominator,
                  },
                  2
                )}{" "}
                EURR
              </TableData>
            </TableRow>
            <TableRow>
              <TableHeader paddingSize="small">Rate</TableHeader>
              <TableData paddingSize="small">1 EURR = 1 EUR</TableData>
            </TableRow>
            <TableRow>
              <TableHeader paddingSize="small">Payment Reference</TableHeader>
              <TableData paddingSize="small">{transaction?.PaymentReference}</TableData>
            </TableRow>

            <TableRow>
              <TableHeader paddingSize="small">View Transaction</TableHeader>
              <TableData paddingSize="small">
                {transaction.TransactionHash ? (
                  <InlineLink target="_blank" external to={etherscanLink}>
                    Etherscan
                  </InlineLink>
                ) : (
                  "-"
                )}
              </TableData>
            </TableRow>
            <TableRow>
              <TableHeader paddingSize="small">You receive</TableHeader>
              <TableData paddingSize="small" bold>
                {formatCurrency(
                  {
                    Amount: transaction?.DigitalAmount?.Amount,
                    Denominator: Config.fallbackDenominator,
                  },
                  2
                )}
                {"  "}EUR
              </TableData>
            </TableRow>
          </TableBody>
        </TransactionDetailsTableStyled>
      </TableGroup>
    </TableGroupStyled>
  );
}
