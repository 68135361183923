import { APICreateUser, APIUpdateUser, APIUserListItem } from "types/API/User";

import Connection from "./Connection";

export const getUserList = () => Connection.get<APIUserListItem[]>("/customers/users", undefined, "GetUserList");

export const getUser = (userId: string) =>
  Connection.get<APIUserListItem>(`/customers/users/${userId}`, undefined, "GetUserDetail");

export const createUser = (user: APICreateUser) =>
  Connection.post("/customers/users", user, undefined, "PostCreateUser");

export const updateUser = (userId: string, user: APIUpdateUser) =>
  Connection.put(`/customers/users/${userId}`, user, undefined, "UpdateUser");

export const deleteUser = (userId: string) => Connection.put(`/customers/users/${userId}/disable`);

export const approveUser = (userId: string, data: object) => Connection.post(`/users/${userId}/approve`, data);

export const rejectUser = (userId: string, data: object) => Connection.post(`/users/${userId}/reject`, data);
