import * as React from 'react'
import colorStyle from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface CardDataProps {
  label: string
  data: string | React.ReactElement
  color?: string
}

const CardDataStyled = styled.div``

const CardDataLabelStyled = styled.span(
  ({ $color }: { $color: string }) => `
  display: block;
  color: ${$color};
  font-family: ${fontFamily.secondary};
  font-weight: ${fontWeight.light};
  font-size: ${fontSize.p};
`,
)

const CardDataDataStyled = styled.span(
  ({ $color }: { $color: string }) => `
    display: block;
    color: ${$color};
    font-family: ${fontFamily.secondary};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.h3};
  `,
)

export function CardData({ label, data, color = colorStyle.greyscale.black }: CardDataProps) {
  return (
    <CardDataStyled>
      <CardDataLabelStyled $color={color}>{label}</CardDataLabelStyled>
      <CardDataDataStyled $color={color}>{data}</CardDataDataStyled>
    </CardDataStyled>
  )
}
