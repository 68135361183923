import * as React from "react";
import { useIsMobile } from "stablr";
import { ReactComponent as ChevrownDownIcon } from "stablr/assets/icons/chevron-down.svg";
import { ReactComponent as EthereumSVG } from "stablr/assets/icons/ethereum.svg";
import { ReactComponent as SolanaSVG } from "stablr/assets/icons/solana.svg";
import { Badge, Button, Heading, Icon, Spacer } from "stablr/components/atoms";
import { color, fontSize, fontWeight, media, spacing } from "stablr/styles";
import { BadgeVarients } from "stablr/types";
import styled from "styled-components";

import { NetworkModal } from "./NetWorkModal";

interface BuyScreenProps {
  title: string;
  body: string;
  statusText?: string;
  variant?: BadgeVarients;
  onNetworkChange?: (network: string) => void;
  showNetworkModal?: boolean; 
}

interface NetworkIconsType {
  [key: string]: JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${color.palette.lightBlue};
  padding-top: ${spacing.l};

  @media ${media.mobile} {
    padding: 0 ${spacing.m};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeadingStyled = styled.div`
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    white-space: nowrap;
    font-size: ${fontSize.h5};
  }
`;

const HeaderNetworkStyled = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${fontSize.p};
  color: ${color.greyscale.white};
  cursor: pointer;

  & span {
    padding-right: ${spacing.xs};
    font-size: ${fontSize.p};
    display: flex;
    align-items: center;
  }
`;

const BodyStyled = styled.p`
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.medium};
  color: ${color.greyscale.grey7};

  @media ${media.mobile} {
    font-size: ${fontSize.h5};
  }
`;

const BadgeStyled = styled.div`
  display: flex;
  align-items: center;
`;

const networkIcons: NetworkIconsType = {
  Ethereum: <EthereumSVG />,
  Solana: <SolanaSVG />,
};

const getNetworkIcon = (network: string): JSX.Element | null => {
  return networkIcons[network] || null;
};

export default function StepWizard({
  showNetworkModal = false, 
  title,
  body,
  statusText,
  variant,
  onNetworkChange,
}: BuyScreenProps) {
  const [selectedNetwork, setSelectedNetwork] = React.useState("");
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState<boolean>(showNetworkModal);

  const openModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleNetworkChange = (value: string) => {
    value && setSelectedNetwork(value);
    if (onNetworkChange) onNetworkChange(value);
  };

  return (
    <Container>
      <HeaderContainer>
        <HeadingStyled>
          <Heading as="h2" varient={isMobile ? "h5" : "h2"} color={color.greyscale.white}>
            {title}
          </Heading>
          {statusText && (
            <BadgeStyled>
              <Badge background varient={variant} text={statusText} />
            </BadgeStyled>
          )}
        </HeadingStyled>
        <Spacer width={spacing.s} />
        {!statusText &&
          (selectedNetwork === "" ? (
            <HeaderNetworkStyled>
              <Button
                varient="secondary"
                color={color.greyscale.white}
                backgroundColor={color.palette.lightBlue}
                onClick={openModal}
              >
                <span>Select Network</span>
                <Icon color={color.greyscale.white} size={12}>
                  <ChevrownDownIcon />
                </Icon>
              </Button>
            </HeaderNetworkStyled>
          ) : (
            <HeaderNetworkStyled onClick={openModal}>
              {getNetworkIcon(selectedNetwork)}
              <Spacer width={spacing.xs} />
              {selectedNetwork} <Spacer width={spacing.s} />
              <Icon color={color.greyscale.white} size={12}>
                <ChevrownDownIcon />
              </Icon>
            </HeaderNetworkStyled>
          ))}
      </HeaderContainer>
      <BodyStyled>{body}</BodyStyled>
      <NetworkModal open={open} onClose={onCloseModal} onNetworkChange={handleNetworkChange} />
    </Container>
  );
}
