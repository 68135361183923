import { Menu } from "@headlessui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { color, fontFamily, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface UserDropDownFootLinksProps {
  links: { to: string; label: string; external?: boolean; onClick?: () => void }[];
}

const UserDropDownFootLinksStyled = styled.div`
  display: flex;
  margin: ${spacing.m} 0;
`;

const FootLinkWrapper = styled(Menu.Item)``;

const FootLinkStyled = styled.a`
  color: ${color.greyscale.black};
  font-size: ${fontSize.small};
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  text-decoration: underline;
  padding: 0 ${spacing.xs};
`;

export default function UserDropDownFootLinksComponent({ links }: UserDropDownFootLinksProps) {
  return (
    <UserDropDownFootLinksStyled>
      {links.map(({ to, label, external, onClick }) =>
        external ? (
          <FootLinkWrapper key={to}>
            <FootLinkStyled onClick={onClick} href={to} target="_blank">
              {label}
            </FootLinkStyled>
          </FootLinkWrapper>
        ) : (
          <FootLinkWrapper key={to}>
            <FootLinkStyled as={Link} onClick={onClick} key={to} to={to}>
              {label}
            </FootLinkStyled>
          </FootLinkWrapper>
        )
      )}
    </UserDropDownFootLinksStyled>
  );
}
