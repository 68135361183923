import { motion } from 'framer-motion'
import * as React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface SwitchOption {
  id: string
  label: string
}

export interface SwitchProps {
  options: SwitchOption[]
  value: string
  onChange: (newOption: string) => void
}

const HEIGHT = 56
const PADDING = 8 // Using number to avoid calc in framer motion
const ITEM_WIDTH = 150

const SwitchStyled = styled.div`
  display: inline-block;
  height: ${HEIGHT}px;
  padding: ${PADDING}px;
  box-sizing: border-box;
  background-color: ${color.greyscale.grey1};
  position: relative;
  border-radius: calc(${HEIGHT}px / 2);
`

const OptionStyled = styled.button`
  border: none;
  position: relative;
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.button};
  height: calc(${HEIGHT}px - ${PADDING}px * 2);
  color: ${({ active }: { active: boolean }) =>
    active ? color.greyscale.black : color.greyscale.grey5};
  background: transparent;
  width: ${ITEM_WIDTH}px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  z-index: 100;

  &:hover {
    color: ${color.greyscale.black};
  }
`

const LocationStyled = styled.div`
  position: absolute;
  width: ${ITEM_WIDTH}px;
  height: calc(${HEIGHT}px - ${PADDING}px * 2);
  top: ${PADDING}px;
  border-radius: calc((${HEIGHT}px - ${PADDING}px * 2) / 2);
  background-color: ${color.greyscale.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
`

export function TabSwitch({ options, value, onChange }: SwitchProps) {
  const currentLocation = React.useMemo(
    () => options.findIndex((option) => option.id === value),
    [value, options],
  )

  const leftValue =
    currentLocation === -1 || currentLocation === 0
      ? `${PADDING}px`
      : `${PADDING + currentLocation * ITEM_WIDTH}px`

  return (
    <SwitchStyled>
      <LocationStyled
        as={motion.div}
        initial={{
          left: leftValue,
        }}
        animate={{ left: leftValue }}
        transition={{ duration: 0.2 }}
      />
      {options.map((option) => (
        <OptionStyled
          active={value === option.id}
          onClick={() => {
            onChange(option.id)
          }}
          key={option.id}
        >
          {option.label}
        </OptionStyled>
      ))}
    </SwitchStyled>
  )
}
