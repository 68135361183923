import { Menu } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as CloseSVG } from 'stablr/assets/icons/close.svg'
import { ReactComponent as MenuSVG } from 'stablr/assets/icons/menu.svg'
import { Icon } from 'stablr/components/atoms/Icon'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { MOBILE_HEADER_HEIGHT } from '../../HeaderNavigation'

export interface MenuDropDownProps {
  testid?: string
  children?: React.ReactElement[] | React.ReactElement
}

const MENU_SIZE = '54px'

const MenuButtonStyled = styled.button`
  background-color: ${color.greyscale.white};
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${MENU_SIZE};
  width: ${MENU_SIZE};
  z-index: 200;
`

const MenuItemsStyled = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${color.greyscale.white};
  padding: ${spacing.xxs};
  width: 100%;
  height: 100%;
  padding-top: ${MOBILE_HEADER_HEIGHT};
  display: flex;
  flex-direction: column;
  z-index: 100;
`

const ULStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  overflow-y: auto;
  flex: 1;
`

MenuDropDown.testid = 'MenuDropDown'

export function MenuDropDown({ children, ...props }: MenuDropDownProps) {
  return (
    <Menu data-testid={composeTestID(MenuDropDown.testid, props.testid)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            <Icon
              size={24}
              gradient={open === false ? true : undefined}
              color={color.greyscale.black}
            >
              {open === true ? <CloseSVG /> : <MenuSVG />}
            </Icon>
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>
            <ULStyled>{children}</ULStyled>
          </Menu.Items>
        </>
      )}
    </Menu>
  )
}
