import useTransaction from "hooks/query/transaction/use-transaction";
import * as React from "react";
import { useParams } from "react-router-dom";
import { WizardHorizontalNavigation } from "stablr/components/organisms";

const WIZARD_STEPS = [
  { id: "/buy", label: "Buy" },
  { id: "/pay", label: "Payment" },
  { id: "/success", label: "Success", includeTime: true },
];

export default function BuyWizard() {
  const { id } = useParams();
  const { data: transaction, isLoading, error } = useTransaction(id ?? "");

  return (
    <WizardHorizontalNavigation
      currentLocation={
        isLoading || error || transaction === undefined
          ? -1
          : transaction.Status === "PENDING"
          ? 1
          : transaction.Status === "CONFIRMED"
          ? 2
          : -1
      }
      steps={WIZARD_STEPS}
    />
  );
}
