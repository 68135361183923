import * as React from 'react'
import { Button, Spacer } from 'stablr/components/atoms'
import { Heading } from 'stablr/components/atoms/Heading'
import { Icon } from 'stablr/components/atoms/Icon'
import { Paragraph } from 'stablr/components/atoms/Paragraph'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface CardEmptyProps {
  iconElement: React.ReactElement
  title: string
  body: string
  onRetry?: () => void
}

const CIRCLE_SIZE = '180px'
const CIRCLE_OUTER_SIZE = '250px'

const CardEmptyStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l};
  flex: 1;

  & h2 {
    margin-bottom: 0;
    font-weight: 600;
  }

  & p {
    margin-top: ${spacing.s};
    text-align: center;
  }
`

const IconElementStyled = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const IconContainerStyled = styled.div`
  width: ${CIRCLE_OUTER_SIZE};
  height: ${CIRCLE_OUTER_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_OUTER_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export function CardEmpty({ title, body, iconElement, onRetry }: CardEmptyProps) {
  return (
    <CardEmptyStyled>
      <IconContainerStyled>
        <Icon as={IconElementStyled} gradient size={120}>
          {iconElement}
        </Icon>
      </IconContainerStyled>
      <Heading as="h2" varient="h3" testid="card-empty">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      {onRetry && (
        <>
          <Spacer height={spacing.m} />
          <Button onClick={onRetry}>Try again</Button>
        </>
      )}
    </CardEmptyStyled>
  )
}
