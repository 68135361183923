import { PERMISSION_ENUM } from "config/permissions";
import withPermission from "hocs/permission";
import usePayments from "hooks/query/payment/use-payments";
import React from "react";
import { ReactComponent as LibraryBookIcon } from "stablr/assets/icons/library-books.svg";
import { CardEmpty, CardError, CardLoader, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import PaymentTableComponent from "./PaymentTable";

function PaymentsView() {
  const { data: payments, error, isLoading, refetch } = usePayments();
  return (
    <>
      <HeaderBackNav title={`Payment Instructions ${payments?.length ? `(${payments?.length})` : ""}`} />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Cannot load Payment Instructions" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !payments?.length ? (
        <CardEmpty
          iconElement={<LibraryBookIcon />}
          title="No Payment Instructions found"
          body="There are no Payment Instructions found at the moment"
        />
      ) : (
        <PaymentTableComponent data={payments} />
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS)(PaymentsView);
