import * as React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import BuyScreen from "./screens/BuyScreen";
import TransactionScreen from "./screens/TransactionScreen";

const BuyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default function BuyComponent() {
  return (
    <BuyContainer>
      <Routes>
        <Route path="/:id" element={<TransactionScreen />} />
        <Route
          path=""
          element={<BuyScreen title="1. Buy StablR" body="Please follow these steps to buy your StablR assets" />}
        />
      </Routes>
    </BuyContainer>
  );
}
