import { fetchAuthSession } from "aws-amplify/auth";
import { FORM_ERROR } from "final-form";
import logError from "functions/log/log-error";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import useUserData from "hooks/context/use-user-data";
import { usePostCompanyDetails } from "hooks/query/company-information/use-post-company-details";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import {
  BottomBar,
  color,
  fontWeight,
  FormLayout,
  getErrorMessage,
  spacing,
  useIsMobile,
  validateFormValues,
} from "stablr";
import { ReactComponent as BusinessIconSVG } from "stablr/assets/icons/business.svg";
import { ReactComponent as IconForwardIOS } from "stablr/assets/icons/icon-forward-ios.svg";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { Button, Heading, Icon, Paragraph } from "stablr/components/atoms";
import { Container, ErrorMessage, TextInput } from "stablr/components/molecules";
import { StepTemplate } from "stablr/components/templates";
import styled from "styled-components";
import { APICompanyInfo } from "types/API/Company";

import {
  COMPANY_INFORMATION_SCHEMA,
  CompanyInformationSchemaValidation,
} from "../common/companyInformationSchemaValidation";

interface CompanyVerificationProps {
  onContinue: () => void;
}

interface CustomLabelProps {
  children: React.ReactNode;
}

type CompanyVerificationFormType = APICompanyInfo;

const FormStyled = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const IconStyled = styled(Icon)`
  position: relative;
  padding: 22px 0 0 5px;
  margin-bottom: -2px;
  cursor: pointer;

  &:hover .tooltip {
    display: block;
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  width: 403px;
  height: 75px;
  margin-top: -80px;
  margin-left: 20px;
  box-shadow: 0 1px 4px 0 #0000001a;
  background-color: ${color.greyscale.white};
  padding: ${spacing.m};
  border-radius: 3px;
  color: ${color.greyscale.black};
  font-weight: ${fontWeight.default};
`;

const CustomLabel: React.FC<CustomLabelProps> = ({ children }) => {
  const [isTooltipVisible, setTooltipVisible] = React.useState(false);

  return (
    <>
      {children}
      <IconStyled
        size={17.5}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon />
        {isTooltipVisible && (
          <Tooltip className="tooltip">
            A text field where users can enter the name of an entity, such as a person or organization.
          </Tooltip>
        )}
      </IconStyled>
    </>
  );
};

export default function CompanyVerificationComponent({ onContinue }: CompanyVerificationProps) {
  const isMobile = useIsMobile();
  const { mutateAsync: postCompanyDetailsMutation } = usePostCompanyDetails();
  const user = useUserData();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.["custom:assignedOrganization"] !== undefined) {
      // If location is company verification (This only happens with a back button)
      navigate("/sign-up/summary-and-sign-up");
    }
  }, [user, navigate]);

  const handleOnSubmit = async (companyDetails: CompanyVerificationFormType) => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "CompanyVerification");
    const userName = user?.["cognito:username"];

    if (typeof userName !== "string") {
      return { [FORM_ERROR]: "Username not available" };
    }

    try {
      await postCompanyDetailsMutation({ companyDetails });
      logEvent(EVENT_TYPE_ENUM.FORM_SUBMIT, "CompanyVerification");
    } catch (er) {
      logError("CompanyVerification", getErrorMessage(er));
      return { [FORM_ERROR]: getErrorMessage(er) };
    }

    try {
      await fetchAuthSession({ forceRefresh: true });
    } catch (er) {
      onContinue(); // Continue even if this fails but submission is successful (We do not want the user to try and submit this twice)
      logError("CompanyVerificationReloadAuthentication", getErrorMessage(er));
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
    onContinue();
  };

  return (
    <Form onSubmit={handleOnSubmit} validate={validateFormValues(CompanyInformationSchemaValidation)}>
      {({ handleSubmit, submitting, submitError }) => {
        return (
          <FormStyled onSubmit={handleSubmit}>
            <StepTemplate
              bottomBarElement={
                <BottomBar
                  rightElement={
                    <Button
                      icon={<IconForwardIOS />}
                      varient="primary"
                      layout="icon-right"
                      type="submit"
                      loading={submitting}
                      testid="Next"
                    >
                      Submit
                    </Button>
                  }
                />
              }
            >
              <>
                <Container as="section" maxWidth={822} id="title-container" marginBottom={"0"}>
                  <header>
                    <Heading as="h1" varient="h2">
                      5. Company Information
                    </Heading>
                  </header>
                  <Paragraph>Please provide us your company information</Paragraph>
                </Container>
                <Container marginTop={"0"}>
                  <FormLayout>
                    <Field
                      name={COMPANY_INFORMATION_SCHEMA.ENTITY_NAME}
                      icon={<BusinessIconSVG />}
                      component={TextInput}
                      label={<CustomLabel>Entity Name</CustomLabel>}
                      placeholder="Entity Name"
                      compact
                    />
                  </FormLayout>

                  <FormLayout columns={isMobile ? 1 : 2}>
                    <>
                      <Field
                        name={COMPANY_INFORMATION_SCHEMA.REGISTRATION_NUMBER}
                        icon={<BusinessIconSVG />}
                        component={TextInput}
                        label="Registration Number (Chamber of Commerce)"
                        placeholder="Registration Number"
                      />
                      <Field
                        name={COMPANY_INFORMATION_SCHEMA.REGISTERED_ADDRESS}
                        icon={<BusinessIconSVG />}
                        component={TextInput}
                        label="Registered Address"
                        placeholder="Registered Address"
                      />
                      <Field
                        name={COMPANY_INFORMATION_SCHEMA.REGISTERED_POST_CODE}
                        icon={<BusinessIconSVG />}
                        component={TextInput}
                        label="Registered Postal Code"
                        placeholder="Registered Postal Code"
                      />
                      <Field
                        name={COMPANY_INFORMATION_SCHEMA.REGISTERED_CITY}
                        icon={<BusinessIconSVG />}
                        component={TextInput}
                        label="Registered City"
                        placeholder="Registered City"
                      />
                      <Field
                        name={COMPANY_INFORMATION_SCHEMA.REGISTERED_COUNTRY}
                        icon={<BusinessIconSVG />}
                        component={TextInput}
                        label="Registered Country"
                        placeholder="Registered Country"
                      />
                    </>
                  </FormLayout>
                  {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                </Container>
              </>
            </StepTemplate>
          </FormStyled>
        );
      }}
    </Form>
  );
}
