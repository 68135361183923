import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import BuyWizard from "pages/BuySell/components/BuyWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "stablr";
import { ReactComponent as InfoSVG } from "stablr/assets/icons/info.svg";
import { ReactComponent as TransactionsSVG } from "stablr/assets/icons/transactions.svg";
import { Button, Heading, Icon, Paragraph, Spacer } from "stablr/components/atoms";
import { color, media } from "stablr/styles";
import { spacing } from "stablr/styles";
import { SCREEN_SIZES } from "stablr/styles/media";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import BankWalletInfo from "../common/BuyBankWalletInfo";
import BankDetailsTable from "./components/BankDetailsTable";
import { PaymentDownload } from "./components/PaymentDownload";

interface PaymentInstructionProps {
  onBack: () => void;
  transaction: APITransaction;
}

const PaymentScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PaymentInstructionContainerStyled = styled(BuySellContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -50px;
  padding: 0 ${spacing.xxl};

  @media ${media.mobile} {
    padding: ${spacing.m};
    margin-top: -${spacing.xl};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  box-shadow: 0 1px 4px 0 #0000001a;
  background: ${color.greyscale.white};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  & span {
    padding-left: ${spacing.xs};
  }
`;

const ParagraphStyled = styled(Paragraph)`
  color: ${color.greyscale.black};
  padding: ${spacing.xs} ${spacing.xl};
  margin-top: -10px;

  @media ${media.mobile} {
    padding: ${spacing.s};
  }
`;

const BankInfoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.s};
  padding: ${spacing.l};
  box-shadow: 0 1px 4px 0 #0000001a;
  border-radius: 20px;

  @media ${media.mobile}, ${media.tablet} {
    flex-direction: column;
  }

  @media (min-width: ${SCREEN_SIZES.laptop}) {
    flex-direction: row;
  }
`;

const ButtonWrapperStyled = styled.div`
  flex: 0.7;

  & > div {
    width: 100%;
  }
  @media ${media.mobile}, ${media.tablet} {
    margin-top: ${spacing.l};
  }
`;

export default function PaymentInstructionComponent({ transaction }: PaymentInstructionProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleOnOpenTransactions = () => {
    navigate("/transactions");
  };

  return (
    <PaymentScreenStyled>
      <StepWizard
        title="2. Transfer of Payment"
        body="Please follow these steps to buy your StablR assets"
        variant={getTransactionBadgeVarient(transaction.Status)}
        statusText={parseTransactionBadgeText(transaction.Status)}
      />
      <BuyWizard />
      <PaymentInstructionContainerStyled>
        <TextWrapper>
          <Heading as="h3" varient="h3">
            <IconContainer>
              <Icon color={color.palette.brightOrange} size={20}>
                <InfoSVG />
              </Icon>
              <span> When do you receive your funds?</span>
            </IconContainer>
          </Heading>
          <ParagraphStyled>
            We got your request and one of our Operations specialist is going to check the transaction. If you made the
            payment already no further action is required from your side.
          </ParagraphStyled>
        </TextWrapper>
        <Spacer height={spacing.m} />
        <BankInfoWrapperStyled>
          <BankDetailsTable transaction={transaction} />
          <ButtonWrapperStyled>
            <PaymentDownload paymentReference={transaction.PaymentReference} />
            <Spacer height={spacing.m} />
            <Button
              width={isMobile ? "260px" : "100%"}
              varient="primary"
              backgroundColor={color.theme.primary}
              icon={<TransactionsSVG />}
              onClick={handleOnOpenTransactions}
            >
              Open Transactions
            </Button>
          </ButtonWrapperStyled>
        </BankInfoWrapperStyled>
        <Spacer height={spacing.xl} />
        <BankWalletInfo transaction={transaction} />
      </PaymentInstructionContainerStyled>
    </PaymentScreenStyled>
  );
}
