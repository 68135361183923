import * as React from "react";
import { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { ListBox } from "stablr/components/atoms";
import { DashboardContainer, Tabs } from "stablr/components/molecules";
import { useIsMobile } from "stablr/hooks";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";

import { SecuritySettings } from "./Screens/SecuritySettings";
import { UserDetails } from "./Screens/UserDetails";

const TAB_LIST = [
  {
    label: "User Details",
    value: "/settings/user-details",
  },
  {
    label: "Security Settings",
    value: "/settings/security",
  },
];

const TabPanelsStyled = styled.div`
  @media (${media.small}) {
    padding: ${spacing.s} ${spacing.l};
  }
`;

export default function SettingsComponent() {
  const navigation = useNavigate();

  const isMobile = useIsMobile();

  const handleOnTabChange = React.useCallback(
    (value: string) => {
      navigation(value);
    },
    [navigation]
  );

  const { routes } = useMemo(() => {
    return {
      permittedTabList: TAB_LIST,
      routes: (
        <TabPanelsStyled>
          <Routes>
            <Route path="*" element={<UserDetails />} />
            <Route path="/user-details" element={<UserDetails />} />
            <Route path="/security" element={<SecuritySettings />} />
          </Routes>
        </TabPanelsStyled>
      ),
    };
  }, []);

  const currentLocation = useCurrentLocationFromPaths(TAB_LIST.map(tabItem => tabItem.value));

  return (
    <DashboardContainer>
      {isMobile ? (
        <>
          <ListBox
            defaultValue={TAB_LIST[0].value}
            options={TAB_LIST}
            value={TAB_LIST[currentLocation]?.value as string}
            onChange={handleOnTabChange}
            varient="tab"
          />
          {routes}
        </>
      ) : (
        <Tabs value={TAB_LIST[currentLocation]?.value as string} tabList={TAB_LIST} onChange={handleOnTabChange}>
          {routes}
        </Tabs>
      )}
    </DashboardContainer>
  );
}
