import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Config } from "config";
import { PERMISSION_ENUM } from "config/permissions";
import * as React from "react";
import { HeaderTableIcon } from "refactor/HeaderTableIcon";
import { Table } from "stablr/components/atoms";
import { useIsMobile } from "stablr/hooks";
import { usePermission } from "stablr/hooks";
import fontWeight from "stablr/styles/fontWeight";
import styled from "styled-components";
import { APIPaymentListItem } from "types/API/Payment";

import PaymentDownload from "./PaymentDownload";

const { TableBody, TableData, TableHead, TableRow, TableDataCurrency, TableHeader } = Table;

interface PaymentTableProps {
  data: APIPaymentListItem[];
}

const PaymentTableStyled = styled.table`
  width: 100%;

  & th {
    padding-left: 0;
    font-weight: ${fontWeight.bold};
  }

  & td {
    padding-left: 0;
  }
`;

const PaymentTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    & b {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

const columnHelper = createColumnHelper<APIPaymentListItem>();

const tableColumns = [
  columnHelper.accessor("DateCreated", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Reference", {
    header: "Notes",
    cell: info => <b>{info.getValue()}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("DigitalAmount", {
    header: "Amount",
    cell: info => (
      <TableDataCurrency
        digitalAmount={{ Amount: info.getValue().Amount, Denominator: Config.fallbackDenominator as "," }}
        fiatAmount={{ Amount: info.row.original.FiatAmount.Amount, Denominator: Config.fallbackDenominator as "," }}
      />
    ),
    footer: info => info.column.id,
    sortingFn: (rowA, rowB) => {
      const amountA = Number(rowA.original.DigitalAmount?.Amount) || 0;
      const amountB = Number(rowB.original.DigitalAmount?.Amount) || 0;

      return amountA - amountB;
    },
  }),
  columnHelper.accessor("TransactionId", {
    header: "Transaction ID",
    cell: info => <span>{info.getValue()}</span>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("PaymentInstructionId", {
    header: "Payment Reference",
    cell: info => <span>{info.getValue()}</span>,
    footer: info => info.column.id,
    id: "paymenentReferenceColumn",
  }),
];

export default function PaymentTableComponent({ data }: PaymentTableProps) {
  const isMobile = useIsMobile();
  const hasPermission = usePermission();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo(() => {
    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[2]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];

    if (hasPermission(PERMISSION_ENUM.DOWNLOAD_PAYMENT_INSTRUCTIONS))
      col.push(
        // @ts-ignore
        columnHelper.accessor("PaymentInstructionId", {
          header: "",
          cell: info => {
            const isSellOrder = info.row.original.Type === "Sell";
            return !isSellOrder ? <PaymentDownload paymentReference={info.getValue()} /> : "";
          },
          footer: info => info.column.id,
          enableSorting: false,
        })
      );
    return col;
  }, [isMobile, hasPermission]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PaymentTableWrapperStyled>
      <PaymentTableStyled>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={`${headerGroup.id}_tr`}>
              {headerGroup.headers.map(header => (
                <TableHeader
                  icon={<HeaderTableIcon header={header} />}
                  key={`${header.column.columnDef.header}_th`}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </tr>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <TableRow key={`${row.id}_tablerow`}>
              {row.getVisibleCells().map(cell => {
                return (
                  <TableData key={`${cell.column.columnDef.cell}${row.id}td`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </PaymentTableStyled>
    </PaymentTableWrapperStyled>
  );
}
