import { fetchAuthSession } from "aws-amplify/auth";
import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import useUserData from "hooks/context/use-user-data";
import useRoles from "hooks/query/role/use-role";
import { useUpdateUser } from "hooks/query/user/use-update-user";
import { useUser } from "hooks/query/user/use-user";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SaveIcon } from "stablr/assets/icons/save.svg";
import { Button } from "stablr/components/atoms";
import { ButtonContainer, CardLoader, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { color } from "stablr/styles";
import { APIUpdateUser } from "types/API/User";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import DeleteUserButton from "../common/DeleteUserButton";
import { USER_SCHEMA, UserSchemaValidation } from "../common/schemaValidation";
import UserEditForm from "../common/UserEditForm";

function EditUser() {
  const navigate = useNavigate();
  const { userId = "" } = useParams();
  const userData = useUserData();
  const { isLoading: isLoadingRoles } = useRoles();
  const [openExitModal, setOpenExitModal] = useState(false);

  const { data: user, isLoading, isError } = useUser(userId);
  const { mutateAsync: mutateAsyncUpdateUser, isLoading: updatingUser } = useUpdateUser();

  const handleOnUserUpdate = async (user: APIUpdateUser) => {
    if (updatingUser) return;
    try {
      await mutateAsyncUpdateUser({ userId, user }, { onSuccess: () => navigate(-1) });
      if (isCurrentUser) await fetchAuthSession();
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const isCurrentUser = useMemo(() => userData?.["cognito:username"] === userId, [userId, userData]);

  const handleOnBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate("/accounts/user-management");
    }
  };

  if (isLoading || isLoadingRoles) return <CardLoader />;

  return (
    <>
      <Form
        onSubmit={handleOnUserUpdate}
        validate={validateFormValues(UserSchemaValidation)}
        initialValues={{ ...user, [USER_SCHEMA.ROLE]: user?.Roles ?? [] }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError, dirty }) => {
          const isDisabled = submitting || updatingUser;
          return (
            <>
              <HeaderBackNav
                title={user?.Name || ""}
                onBack={event => {
                  event.preventDefault();
                  handleOnBack(dirty);
                }}
              />
              <form onSubmit={handleSubmit}>
                <UserEditForm disabled={isDisabled || isError || user?.Disabled} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <ButtonContainer justifyContent="flex-end">
                  {!user?.Disabled && !isCurrentUser && <DeleteUserButton id={userId} name={user?.Name} />}

                  <Button
                    type="submit"
                    varient="primary"
                    color={color.greyscale.white}
                    icon={<SaveIcon />}
                    iconSize={20}
                    disabled={user?.Disabled}
                    loading={isDisabled}
                  >
                    Save Changes
                  </Button>
                </ButtonContainer>
              </form>
              <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_USERS)(EditUser);
