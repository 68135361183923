import { color, fontWeight, spacing } from 'stablr/styles'
import styled from 'styled-components'

export interface FileTypeIconProps {
  type: string
}

const FILE_TYPE_ICON_SIZE = '50px'

const FILE_TYPE_MAP = [
  {
    name: 'pdf',
    types: ['application/pdf'],
    color: color.palette.red,
  },
  { name: 'png', types: ['image/png'], color: color.palette.green },
  { name: 'jpg', types: ['image/jpg', 'image/jpeg'], color: color.palette.blue },
  { name: 'svg', types: ['image/svg+xml', 'image/svg'], color: color.theme.primary },
]

const FileTypeIconStyled = styled.div`
  width: ${FILE_TYPE_ICON_SIZE};
  height: ${FILE_TYPE_ICON_SIZE};
  border-radius: 4px;
  color: ${color.greyscale.white};
  font-size: 18px;
  text-transform: uppercase;
  font-weight: ${fontWeight.extraBold};
  line-height: ${FILE_TYPE_ICON_SIZE};
  text-align: center;
  overflow: hidden;
  margin: ${spacing.xs};
`

export function FileTypeIcon({ type }: FileTypeIconProps) {
  const typeDetails = getFileTypeDetails(type)

  return (
    <FileTypeIconStyled style={{ backgroundColor: typeDetails.color }}>
      {typeDetails.name}
    </FileTypeIconStyled>
  )
}

function getFileTypeDetails(type: string) {
  const foundType = FILE_TYPE_MAP.find((item) => item.types.includes(type))

  if (foundType === undefined) {
    return {
      name: type.split('/').length === 2 ? type.split('/')[1] : type,
      color: color.greyscale.grey5,
    }
  } else {
    return foundType
  }
}
