import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WarningSVG } from "stablr/assets/icons/warning.svg";
import { Button, Heading, Icon, Paragraph, Spacer } from "stablr/components/atoms";
import { BottomBar } from "stablr/components/organisms";
import { composeTestID } from "stablr/functions/compose-test-id";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

interface BuySellEmptyStateProps {
  testid?: string;
  text: string;
  title: string;
}

const BuySellEmptyStateStyled = styled.div`
  background-color: ${color.greyscale.white};
  border-radius: 20px;
  padding: ${spacing.m};
  border: 1px solid ${color.greyscale.grey3};
  box-shadow: 1px 2px 2px 0 ${color.greyscale.grey3};
  min-height: 100px;
  margin: ${spacing.l} auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

BuySellEmptyState.testid = "BuySellEmptyState";

const ParagraphStyled = styled(Paragraph)`
  color: ${color.greyscale.black};
  padding: ${spacing.xs} ${spacing.xxl};
  margin-top: -10px;
`;

export function BuySellEmptyState({ text, title, ...props }: BuySellEmptyStateProps) {
  const navigate = useNavigate();

  const onBankAccountsClick = () => {
    navigate("/accounts/bank-accounts");
  };

  return (
    <>
      <BuySellEmptyStateStyled data-testid={composeTestID(BuySellEmptyState.testid, props.testid)}>
        <Heading as="h3" varient="h3">
          <Icon color={color.palette.brightOrange} size={20}>
            <WarningSVG />
          </Icon>
          <span style={{ marginLeft: spacing.m }}>{title}</span>
        </Heading>

        <ParagraphStyled>{text}</ParagraphStyled>
      </BuySellEmptyStateStyled>
      <BottomBar rightElement={<Button onClick={onBankAccountsClick}>Go to Bank Accounts</Button>} />
      <Spacer height={spacing.l} />
    </>
  );
}
