import * as React from 'react'
import { Heading } from 'stablr/components/atoms/Heading'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

type VarientType = 'primary' | 'line' | 'blank'
export interface TableGroupProps {
  children?: React.ReactElement
  label: string
  varient?: VarientType
}

const TableGroupStyled = styled.div<{ $varient: VarientType }>`
  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    font-size: ${({ $varient }) => ($varient === 'blank' ? fontSize.h5 : fontSize.input)};
    font-weight: ${({ $varient }) =>
      $varient === 'blank' ? fontWeight.semiBold : fontWeight.regular};
    font-family: ${fontFamily.secondary};
    text-align: ${({ $varient }) => ($varient === 'blank' ? 'center' : 'left')};
  }

  max-width: 750px;
`

const TableGroupContainerStyled = styled.div<{ $varient: VarientType }>`
  ${({ $varient }) =>
    $varient === 'primary'
      ? `
background-color: ${color.greyscale.grey1};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  `
      : $varient === 'blank'
      ? `background-color: ${color.greyscale.white};`
      : `
  background-color: ${color.greyscale.white};
  border: 1px solid ${color.greyscale.grey3};
  border-radius: 3px;`}
  min-height: 55px;
  overflow-x: auto;
`

export function TableGroup({ label, children, varient = 'primary' }: TableGroupProps) {
  return (
    <TableGroupStyled $varient={varient}>
      <Heading as="h2" varient="h4">
        {label}
      </Heading>
      <TableGroupContainerStyled $varient={varient}>{children}</TableGroupContainerStyled>
    </TableGroupStyled>
  )
}
