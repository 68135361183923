import * as React from 'react'
import { ReactComponent as TimelapseSVG } from 'stablr/assets/icons/timelapse.svg'
import { Icon } from 'stablr/components/atoms'
import {
  WIZARD_NAVIGATION_STEP_SVG_SIZE,
  WizardHorizontalNavigationStep,
} from 'stablr/components/molecules/Wizard/WizardHorizontalNavigationStep'
import { media } from 'stablr/styles'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface StepListEntry {
  id: string
  label: string
  includeTime?: boolean
}

export interface WizardHorizontalNavigationProps {
  steps: StepListEntry[]
  currentLocation: number
  type?: 'incomplete' | 'complete' | 'current'
}

const WizardHorizontalNavigationStyled = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  max-width: 1150px;
  padding-left: 0;
`

const LINE_HEIGHT = '2px'
const LINE_MARGIN = spacing.s

const TIMEOUT_ICON_SIZE = 24

const LineIconWrapperStyled = styled.div`
  display: flex;
  flex: 1;
  margin-top: calc(${WIZARD_NAVIGATION_STEP_SVG_SIZE} / 2 - ${LINE_HEIGHT} / 2);
`

const WizardHorizontalNavigationLineStyled = styled.div`
  flex: 1;
  background-color: ${({ $active }: { $active: boolean }) =>
    $active ? color.greyscale.white : color.greyscale.grey8};
  height: 4px;
  border-radius: 2px;
  margin-left: ${LINE_MARGIN};
  margin-right: ${LINE_MARGIN};
`

const IncludeTimeIconStyled = styled.div`
  bottom: ${spacing.xs};
  position: relative;
`

const WizardWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.palette.lightBlue};
  padding: ${spacing.xxl} ${spacing.xxxl} ${spacing.xxxl} ${spacing.xxxl};

  // when width is 1080px UI bug without the margin
  margin-top: -1px;

  @media ${media.mobile} {
    padding: ${spacing.l} ${spacing.l};
  }
`

export function WizardHorizontalNavigation({
  steps,
  currentLocation,
}: WizardHorizontalNavigationProps) {
  return (
    <WizardWrapperStyled>
      <WizardHorizontalNavigationStyled>
        {steps.map((step, index) => {
          const isLastItem = index === steps.length - 1
          let stepType: 'current' | 'complete' | 'incomplete'
          if (currentLocation === -1 && index === 0) {
            stepType = 'current'
          } else {
            stepType =
              currentLocation === index && isLastItem
                ? 'complete'
                : currentLocation === index
                ? 'current'
                : currentLocation > index
                ? 'complete'
                : 'incomplete'
          }
          const previousLineActive = currentLocation >= index - 1
          const transactionCompleted = stepType === 'complete'
          return (
            <React.Fragment key={step.id}>
              {index !== 0 ? (
                step.includeTime ? (
                  <LineIconWrapperStyled>
                    <WizardHorizontalNavigationLineStyled
                      $active={currentLocation === index - 1 || stepType !== 'incomplete'}
                    />
                    {!transactionCompleted && (
                      <>
                        <Icon
                          size={TIMEOUT_ICON_SIZE}
                          as={IncludeTimeIconStyled}
                          color={previousLineActive ? color.greyscale.white : color.greyscale.grey8}
                        >
                          <TimelapseSVG />
                        </Icon>
                        <WizardHorizontalNavigationLineStyled $active={stepType !== 'incomplete'} />
                      </>
                    )}
                  </LineIconWrapperStyled>
                ) : (
                  <LineIconWrapperStyled>
                    <WizardHorizontalNavigationLineStyled $active={stepType !== 'incomplete'} />
                  </LineIconWrapperStyled>
                )
              ) : null}
              <WizardHorizontalNavigationStep
                type={stepType}
                title={`${index + 1}. ${step.label}`}
              />
            </React.Fragment>
          )
        })}
      </WizardHorizontalNavigationStyled>
    </WizardWrapperStyled>
  )
}
