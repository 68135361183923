import * as React from 'react'
import { useIsMobile } from 'stablr/hooks/use-is-mobile'
import color from 'stablr/styles/color'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface HalfHalfResponsiveProps {
  children: React.ReactElement
  logo?: React.FC
  logoMark?: React.ReactElement
}

const HalfHalfResponsiveStyled = styled.div`
  @media ${media.desktop} {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
`

const HalfHalfResponsiveLogoStyled = styled.div`
  position: absolute;
  top: ${spacing.m};
  left: ${spacing.m};
`

const HalfHalfResponsiveMainStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${media.desktop} {
    flex: 1;
    justify-content: center;
  }
`

const HalfHalfResponsiveMainContentStyled = styled.div`
  @media ${media.mobile} {
    margin: ${spacing.l};
    margin-top: 150px;
    max-width: 400px;
  }

  @media ${media.desktop} {
    max-width: 430px;
    width: 430px;
    text-align: center;
    margin: ${spacing.l};
  }
`

const HalfHalfResponsiveSecondaryStyled = styled.div`
  @media ${media.desktop} {
    background: ${color.gradient};
    margin: ${spacing.s};
    flex: 1;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      opacity: 0.2;
      padding: ${spacing.xxl};
      max-height: 80vh;
      width: 100%;
      height: 100%;
    }
  }
`

HalfHalfResponsive.testid = 'halfHalfResponsive'

export function HalfHalfResponsive({ children, logo, logoMark }: HalfHalfResponsiveProps) {
  const isMobile = useIsMobile()
  return (
    <HalfHalfResponsiveStyled data-testid={HalfHalfResponsive.testid}>
      <HalfHalfResponsiveLogoStyled as={logo} />
      <HalfHalfResponsiveMainStyled>
        <HalfHalfResponsiveMainContentStyled>{children}</HalfHalfResponsiveMainContentStyled>
      </HalfHalfResponsiveMainStyled>
      {isMobile === false && (
        <HalfHalfResponsiveSecondaryStyled>{logoMark}</HalfHalfResponsiveSecondaryStyled>
      )}
    </HalfHalfResponsiveStyled>
  )
}
