import React from "react";
import { Field } from "react-final-form";
import { FormLayout } from "stablr";
import { ReactComponent as PeopleIcon } from "stablr/assets/icons/people.svg";
import { ReactComponent as PlusIcon } from "stablr/assets/icons/plus.svg";
import { ListBoxInput, TextInput } from "stablr/components/molecules";

import { ArrayInput } from "../../../../../refactor/ArrayInput";
import { APIKEY_SCHEMA } from "./schemaValidation";

interface APIKeyFormProps {
  disabled?: boolean;
  isEditForm?: boolean;
  status?: string;
}

export default function APIKeyInfoForm({ disabled, isEditForm, status }: APIKeyFormProps) {
  return (
    <>
      <FormLayout columns={2}>
        <Field
          name={APIKEY_SCHEMA.NAME}
          label="Name"
          placeholder="Name"
          component={TextInput}
          disabled={isEditForm ? true : disabled}
          compact
        />
        <Field
          name={APIKEY_SCHEMA.ROLE}
          component={ListBoxInput}
          label="Role"
          placeholder="Role"
          options={[{ value: "ADMIN", label: "Admin" }]}
          icon={<PeopleIcon />}
          disabled={isEditForm ? true : disabled}
        />
      </FormLayout>
      <FormLayout>
        <Field
          name={APIKEY_SCHEMA.WHITELISTED_IPS}
          label={"Whitelisted IP Addresses"}
          placeholder={"Add IP Address"}
          component={ArrayInput}
          disabled={status === "REVOKED" ? true : disabled}
          icon={<PlusIcon />}
          compact
        />
      </FormLayout>
    </>
  );
}
