import * as React from 'react'
import { Loader } from 'stablr/components/atoms/Loader'
import styled from 'styled-components'

const CardLoaderStyled = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function CardLoader() {
  return (
    <CardLoaderStyled>
      <Loader size={'100px'} />
    </CardLoaderStyled>
  )
}
