import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { useIsMobile } from 'stablr/hooks/use-is-mobile'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Heading } from '../../atoms/Heading'

export interface HeaderNavigationProps {
  testid?: string
  title?: string | React.ReactElement
  children?: React.ReactElement
}

export const DESKTOP_HEADER_HEIGHT = '92px'
export const MOBILE_HEADER_HEIGHT = '67px'

const HeaderNavigationStyled = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  @media ${media.mobile} {
    height: ${MOBILE_HEADER_HEIGHT};
    padding-left: ${spacing.m};
    padding-right: ${spacing.xs};
  }
  @media ${media.desktop} {
    padding-left: calc(${spacing.xl} + ${spacing.m});
    padding-right: ${spacing.m};
    min-height: ${DESKTOP_HEADER_HEIGHT};
    display: flex;
    align-items: center;
  }
`

const HeadingContainerStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

HeaderNavigation.testid = 'headerNavigation'

export function HeaderNavigation({ children, title, ...props }: HeaderNavigationProps) {
  const isMobile = useIsMobile()
  return (
    <HeaderNavigationStyled data-testid={composeTestID(HeaderNavigation.testid, props.testid)}>
      <HeadingContainerStyled>
        {!isMobile ? (
          <Heading as="h1" varient="h2">
            {title}
          </Heading>
        ) : (
          title
        )}
      </HeadingContainerStyled>
      {children}
    </HeaderNavigationStyled>
  )
}
