import { Menu } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as ChevronDownSVG } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpSVG } from 'stablr/assets/icons/chevron-up.svg'
import { Icon } from 'stablr/components/atoms/Icon'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface MenuListProps {
  testid?: string
  children?: React.ReactElement[] | React.ReactElement
  buttonText?: string
}

const MENU_PADDING_HORIZONTAL = '30px'
const MENU_PADDING_VERTICAL = '10px'

const MenuListStyled = styled.div`
  position: relative;
`
const MenuButtonStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: ${color.greyscale.white};
  color: ${color.greyscale.black};
  line-height: 27px;
  padding: ${MENU_PADDING_VERTICAL} ${MENU_PADDING_HORIZONTAL};
  border: 1px solid ${color.greyscale.grey5};
  cursor: pointer;
  display: flex;
  align-items: center;
`

const MenuItemsStyled = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  border-radius: 3px;
  background-color: ${color.theme.primary};
  padding: ${spacing.xxs};
`

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;
  height: 14px;

  & svg {
    display: block;
  }

  & path {
    fill: ${color.greyscale.black};
  }
`

MenuList.testid = 'menuList'

export function MenuList({ buttonText, children, ...props }: MenuListProps) {
  return (
    <Menu as={MenuListStyled} data-testid={composeTestID(MenuList.testid, props.testid)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            {buttonText}
            <IconStyled as={Icon}>{open ? <ChevronUpSVG /> : <ChevronDownSVG />}</IconStyled>
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>{children}</Menu.Items>
        </>
      )}
    </Menu>
  )
}
