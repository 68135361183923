import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIAPIKey } from "types/API/APIManagement";

useAPIKeys.queryKey = "apiKeys";

export default function useAPIKeys() {
  return useQuery([useAPIKeys.queryKey], async () => api.APIManagement.getAPIKeyList() as Promise<APIAPIKey[]>, {
    notifyOnChangeProps: "all",
  });
}
