import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTableIcon } from "refactor/HeaderTableIcon";
import { Badge, Table } from "stablr/components/atoms";
import { TableDataCopyable } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import fontWeight from "stablr/styles/fontWeight";
import styled from "styled-components";
import { APIAPIKey } from "types/API/APIManagement";

import { getAPIBadgeVarient } from "../functions/get-api-badge-varient";
import { parseAPIBadgeText } from "../functions/parse-api-badge-text";

const { TableBody, TableData, TableHead, TableHeader, TableRow } = Table;

interface APITableProps {
  data: APIAPIKey[];
}

const APITableStyled = styled.table`
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const APITableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    & b {
      font-weight: ${fontWeight.semiBold};
    }

    & > td:last-child {
      text-align: right;
    }
  }
`;

const NameStyled = styled.div`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const columnHelper = createColumnHelper<APIAPIKey>();

export const tableColumns = [
  columnHelper.accessor("CreatedOn", {
    header: "Date",
    cell: info => format(new Date(info.getValue()), "dd/MM/yyyy HH:mm"),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Name", {
    header: "Name",
    cell: info => <NameStyled>{info.getValue()}</NameStyled>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Role", {
    header: "Role",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Status", {
    header: "Status",
    cell: info => (
      <Badge noMargin varient={getAPIBadgeVarient(info?.getValue())} text={parseAPIBadgeText(info.getValue())} />
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("ApplicationId", {
    header: "Client ID",
    cell: info => <TableDataCopyable value={info.getValue()} />,
    footer: info => info.column.id,
  }),
];

export function APIManagementTable({ data }: APITableProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo(() => {
    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const onRowClick = (row: Row<APIAPIKey>) => {
    navigate(`/accounts/api-management/${row.original.Id}/edit`);
  };

  return (
    <APITableWrapperStyled>
      <APITableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    icon={<HeaderTableIcon header={header} />}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} onRowClick={() => onRowClick(row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </APITableStyled>
    </APITableWrapperStyled>
  );
}
