import { autoSignIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import { Heading, InlineLink, Loader, Paragraph } from "stablr/components/atoms";
import { Container, MFAInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import { getErrorMessage } from "stablr/functions";
import { color } from "stablr/styles";

EmailVerificationComponent.testid = "EmailVerification";

const RESEND_DELAY = 5000;

export default function EmailVerificationComponent() {
  const [resendingError, setResendingError] = React.useState<undefined | string>();
  const [resentCode, setResentCode] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [searchParams] = useSearchParams();
  const email = (searchParams.get("email") ?? "")?.replace(/[ ]/g, "+");
  const location = useLocation();
  const navigate = useNavigate();

  const handleConfirmSignup = async (values: { code: string }) => {
    try {
      await confirmSignUp({ username: email, confirmationCode: values.code });

      const signInOutput = await autoSignIn();

      if (signInOutput.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
        navigate(
          `/sign-up/sms-authentication?destination=${signInOutput?.nextStep?.codeDeliveryDetails?.destination ?? ""}`
        );
      }
      // @ts-ignore
      else if (signInOutput.nextStep.signInStep === "DONE") {
        navigate("/sign-up/totp-authentication");
      }
    } catch (error) {
      return { code: getErrorMessage(error) };
    }
  };

  const handleResendCode = async () => {
    try {
      setResendingError(undefined);
      setResending(true);
      const currentSearchParams = window.location.search;
      await resendSignUpCode({ username: email });
      setResentCode(true);
      navigate(`${window.location.pathname}${currentSearchParams}`, { replace: true });
    } catch (error) {
      setResendingError(getErrorMessage(error));
    }
    setResending(false);
    setTimeout(() => setResentCode(false), RESEND_DELAY);
  };

  return (
    <Form onSubmit={handleConfirmSignup}>
      {({ handleSubmit, submitError, submitting }) => (
        <StepTemplate>
          <Container as="section" maxWidth={1000} id="title-container">
            <header>
              <Heading as="h1" varient="h2">
                3. Email verification
              </Heading>
            </header>
            <Paragraph>Verify your email address</Paragraph>
            <br />
            <Paragraph>
              Please fill in the 6-digit code sent to <b>{email}</b> to verify this email belongs to you
            </Paragraph>
            <FormLayout>
              <>
                <Field
                  name="code"
                  invalid={submitError !== undefined}
                  component={MFAInput}
                  ariaLabel="MFA"
                  onComplete={handleSubmit}
                  loading={submitting}
                />
              </>
            </FormLayout>

            <Paragraph>
              It may take a minute to receive your code.
              <br />
              Haven’t received it?{" "}
              <InlineLink
                to={`${location.search}`}
                onClick={resentCode ? undefined : handleResendCode}
                testid="resend-link"
              >
                Resend a new code
              </InlineLink>
              .
            </Paragraph>
            {resending ? <Loader /> : <></>}
            {resentCode ? <Paragraph color={color.theme.primary}>New code sent to {email}</Paragraph> : <></>}
            {resendingError ? <Paragraph color={color.palette.red}>{resendingError}</Paragraph> : <></>}
          </Container>
        </StepTemplate>
      )}
    </Form>
  );
}
