import useUserData from "hooks/context/use-user-data";
import { useMFAPreference } from "hooks/query/amplify/use-mfa-preference";
import { useSetMFAPreference } from "hooks/query/amplify/use-set-mfa-preference";
import { useTOTPSecret } from "hooks/query/amplify/use-totp-secret";
import { useVerifyTOTPToken } from "hooks/query/amplify/use-verify-totp-token";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as ArrowBackSVG } from "stablr/assets/icons/arrow-back.svg";
import { Button, Heading, Paragraph, Spacer } from "stablr/components/atoms";
import { CardLoader, Container, MFAInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import { getErrorMessage } from "stablr/functions";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface TOTPAuthenticationProps {
  onContinue: () => void;
}

TOTPAuthentication.testid = "TOTPAuthentication";

const QRContainerStyled = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const QRTextStyled = styled.div`
  text-align: center;
  padding: ${spacing.m} 0;
  color: ${color.greyscale.grey4};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.regular};
  overflow-wrap: break-word;
  max-width: 300px;
`;
const TOTPContainerStyled = styled.div`
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default function TOTPAuthentication({ onContinue }: TOTPAuthenticationProps) {
  const user = useUserData();

  const [isLinked, setIsLinked] = React.useState(false);

  const { isLoading: isLoadingMFAPreference, data: mfaPreference } = useMFAPreference();
  const { data: totpSecret, isLoading: isLoadingTOTPSecret } = useTOTPSecret(true);
  const { mutateAsync: mutateAsyncVerifyTOTPToken } = useVerifyTOTPToken();
  const { mutateAsync: mutateAsyncSetMFAPreference } = useSetMFAPreference();

  const skipSMSAuth = mfaPreference?.preferred === "TOTP";

  // Redirect if User TOTP is set up. This is used for a user navigating back
  if (skipSMSAuth) {
    onContinue();
  }

  const handleSubmitTotpChallenge = async (values: { code: string }) => {
    try {
      await mutateAsyncVerifyTOTPToken(values.code);
      await mutateAsyncSetMFAPreference("TOTP");
      onContinue();
    } catch (error) {
      if (["CodeMismatchException", "Code mismatch"].includes(getErrorMessage(error))) {
        return { code: "Code does not match" };
      }
      return { code: getErrorMessage(error) };
    }
  };

  return (
    <StepTemplate>
      <Container as="section" maxWidth={1000} id="title-container">
        <header>
          <Heading as="h1" varient="h2">
            4. Two Factor Authentication
          </Heading>
        </header>
        <Paragraph>Setup two factor authentication for extra safety</Paragraph>
        {isLoadingMFAPreference || isLoadingTOTPSecret ? (
          <CardLoader />
        ) : isLinked === false ? (
          <TOTPContainerStyled>
            <br />
            <Paragraph>
              <b>Open an Authenticator App</b> (i.e. Microsoft or Google) and add an account. <b>Scan the QR code</b> or
              fill in the code manually to add your StablR account to your authenticator app
            </Paragraph>
            <Spacer height={spacing.l} />
            <QRContainerStyled>
              <QRCodeSVG value={`otpauth://totp/StablR?secret=${totpSecret?.sharedSecret}&issuer=${user.email}`} />
              <Spacer height={spacing.m} />
              <QRTextStyled>{totpSecret?.sharedSecret}</QRTextStyled>
              <Spacer height={spacing.m} />
              <Button varient="secondary" onClick={() => setIsLinked(true)}>
                Next
              </Button>
            </QRContainerStyled>
          </TOTPContainerStyled>
        ) : (
          <>
            <br />
            <Paragraph>
              To enable please confirm your 2FA setup by entering the <b>code</b> from the <b>Authenticator App</b>
            </Paragraph>
            <Spacer height={spacing.m} />
            <Form onSubmit={handleSubmitTotpChallenge}>
              {({ submitError, handleSubmit, submitting }) => (
                <FormLayout>
                  <>
                    <Field
                      name="code"
                      component={MFAInput}
                      onComplete={handleSubmit}
                      invalid={submitError !== undefined}
                      loading={submitting}
                    />
                  </>
                </FormLayout>
              )}
            </Form>
            <Spacer height={spacing.m} />
            <Button icon={<ArrowBackSVG />} varient="secondary" onClick={() => setIsLinked(false)}>
              Back to QR
            </Button>
          </>
        )}
      </Container>
    </StepTemplate>
  );
}
