import { useQuery } from "@tanstack/react-query";
import { fetchMFAPreference } from "aws-amplify/auth";

useMFAPreference.queryKey = "mfaPreference";

export function useMFAPreference() {
  return useQuery(
    [useMFAPreference.queryKey],
    async () => {
      const data = await fetchMFAPreference();
      return data;
    },
    {
      notifyOnChangeProps: "all",
    }
  );
}
