import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APICreateWebhook } from "types/API/Webhooks";

import { useWebhook } from "./use-webhook";
import useWebhooks from "./use-webhooks";

export function useUpdateWebhook() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ webhookId, webhookUpdated }: { webhookId: string; webhookUpdated: APICreateWebhook }) => {
      const updatedWebhook: APICreateWebhook = {
        Name: webhookUpdated.Name,
        Url: webhookUpdated.Url,
        Secret: webhookUpdated.Secret,
        Status: webhookUpdated.Status,
      };
      return api.Webhooks.updateWebhook(webhookId, updatedWebhook);
    },
    onSuccess: async (_: unknown, { webhookId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useWebhooks.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useWebhook.queryKey, webhookId] });
      successToast({ message: "Webhook has been updated successfully" });
    },
  });
}
