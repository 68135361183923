import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { CheckBox } from 'stablr/components/atoms/Form/CheckBox'

export interface CheckBoxProps extends FieldRenderProps<string, HTMLInputElement> {
  label?: string | React.ReactElement
}

export function CheckBoxInput({
  input: { ...inputProps },
  label = '',
  meta: { error, touched },
}: CheckBoxProps) {
  const isError = error && touched
  return (
    <React.Fragment>
      <CheckBox
        error={isError}
        value={inputProps.value}
        onChange={inputProps.onChange}
        label={label}
        id={inputProps.name}
      />
    </React.Fragment>
  )
}
