import { APICreateWebhook, APIWebhook } from "types/API/Webhooks";

import Connection from "./Connection";

export const getWebhooksList = () => Connection.get<APIWebhook[]>("/notifications/webhooks");

export const getWebhookById = (webhookId: string) => Connection.get<APIWebhook>(`/notifications/webhooks/${webhookId}`);

export const updateWebhook = (id: string, data: Partial<APIWebhook>) =>
  Connection.put(`/notifications/webhooks/${id}`, data);

export const deleteWebhook = (webhookId: string) => {
  return Connection.delete(`/notifications/webhooks/${webhookId}`);
};

export type APIPostCreateWebhookResponse = {
  Id: string;
};

export const createWebhook = (data: APICreateWebhook) => {
  return Connection.post<APIPostCreateWebhookResponse>("/notifications/webhooks", data);
};
