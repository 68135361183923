import { Config } from "config";
import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-calculate";
import withPermission from "hocs/permission";
import { useBankAccounts } from "hooks/query/bank-account/use-bank-accounts";
import { useCreateTransaction } from "hooks/query/transaction/use-create-transaction";
import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import { BuySellEmptyState } from "pages/BuySell/components/BuySellEmptyState";
import SellWizard from "pages/BuySell/components/SellWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import * as React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Button } from "stablr/components/atoms";
import { ErrorMessage } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { formatCurrency, getErrorMessage, validateFormValues } from "stablr/functions";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APIWalletListItem } from "types/API/Wallet";

import { SellOrderSchemaValidation } from "../common/schemaValidation";
import SellOrderForm from "../common/SellOrderForm";
import SellTransactionTable from "./components/SellTransactionTable";

interface SellScreenProps {
  title: string;
  body: string;
}

interface SellFormValues {
  wallet?: APIWalletListItem;
  currentBalance?: string;
  sell?: number;
  reference?: string;
}

const SellScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormContainer = styled(BuySellContainer)`
  flex: 1;
  padding: 0 ${spacing.xxl};
  margin-top: -50px;

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

// Create Decorator
const decorator = createDecorator(
  {
    field: "wallet",
    updates: {
      currentBalance: wallet => wallet.Balance.Amount,
      newBalance: (wallet, values?: SellFormValues) => wallet?.Balance?.Amount - (values?.sell ?? 0),
    },
  },
  {
    field: "sell",
    updates: {
      newBalance: (sell, values?: SellFormValues) =>
        (values?.wallet !== undefined ? parseFloat(values?.wallet?.Balance?.Amount.toString()) : 0) -
        parseFloat(`${sell ?? 0}`),

      receive: sell =>
        sell ? formatCurrency({ Amount: sell, Denominator: Config.fallbackDenominator }, 2) : undefined,
    },
  }
);
function SellScreenComponent({ title, body }: SellScreenProps) {
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>("Ethereum");
  const [showTransactionTable, setShowTransactionTable] = React.useState<boolean>(false);

  const { mutateAsync: mutateAsyncCreateTransaction } = useCreateTransaction();
  const { data: banks, isLoading: isLoadingBank } = useBankAccounts();
  const navigate = useNavigate();

  const approvedBankAccounts = banks?.filter(bank => bank.Status === "APPROVED");

  const handleShowTransactionTable = (values: SellFormValues) => {
    if (values?.wallet && values?.sell) {
      setShowTransactionTable(true);
    }
  };

  const handleOnSubmit = async (values: SellFormValues) => {
    if (values?.wallet?.Address === undefined || values?.sell === undefined) return;
    try {
      const response = await mutateAsyncCreateTransaction({
        type: "sell",
        WalletAddress: values?.wallet?.Address,
        DigitalAmount: values?.sell?.toString(),
        ClientReference: values?.reference ?? "",
      });
      const transactionId = response?.OrderId;
      navigate(`/sell/${transactionId}`);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <SellScreenStyled>
      <StepWizard title={title} body={body} onNetworkChange={setSelectedNetwork} showNetworkModal />
      <SellWizard />
      <Form<SellFormValues>
        decorators={[decorator]}
        onSubmit={handleOnSubmit}
        validate={validateFormValues(SellOrderSchemaValidation)}
      >
        {({ handleSubmit, submitting, values, submitError }) => {
          handleShowTransactionTable(values);

          return (
            <>
              <FormContainer as={"form"}>
                {isLoadingBank || (approvedBankAccounts !== undefined && approvedBankAccounts.length > 0) ? (
                  <>
                    <SellOrderForm
                      transaction={values}
                      bank={banks ? banks[0] : undefined}
                      isLoadingBank={isLoadingBank}
                      selectedNetwork={selectedNetwork}
                    />
                    {showTransactionTable && <SellTransactionTable transaction={values} />}
                    <BottomBar
                      paddingHorizontal="0"
                      rightElement={
                        <Button onClick={handleSubmit} loading={submitting}>
                          Continue
                        </Button>
                      }
                    />
                  </>
                ) : (
                  <BuySellEmptyState
                    title={"No approved bank account(s)"}
                    text={"You must have at least one approved bank account before you can make a buy or sell order."}
                  />
                )}
              </FormContainer>
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </>
          );
        }}
      </Form>
    </SellScreenStyled>
  );
}

export default withPermission(PERMISSION_ENUM.SELL_EURR)(SellScreenComponent);
