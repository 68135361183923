
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { ListBox, ListBoxOptionType } from 'stablr/components/atoms/Form/ListBox'
import color from 'stablr/styles/color'
import input from 'stablr/styles/input'
import styled from 'styled-components'

export interface ListBoxInputProps extends FieldRenderProps<string, any> {
  options: ListBoxOptionType[]
  label?: string
  compact?: boolean
}

const HeadlessComboBoxContainer = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
`

ListBoxInput.testid = 'ListBoxInput'

export function ListBoxInput({
  input: { ...inputProps },
  options = [],
  name,
  label,
  meta: { error, touched },
  compact = false,
  ...props
}: ListBoxInputProps) {
  const isError = error && touched
  return (
    <HeadlessComboBoxContainer data-testid={ListBoxInput.testid}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <ListBox defaultValue="" {...inputProps} {...props} options={options} name={name} />
      {(!compact || isError) && (
        <InputNote htmlFor={name} color={isError ? color.palette.red : undefined}>
          {error}
        </InputNote>
      )}
    </HeadlessComboBoxContainer>
  )
}
