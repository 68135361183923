import * as React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../Icon'

export interface TableHeaderProps {
  icon?: React.ReactElement | null
  paddingSize?: 'xsmall' | 'small' | 'large'
  children?: React.ReactElement | string | Element | React.ReactNode
  textAlign?: 'left' | 'center' | 'right'
  iconLayout?: 'left' | 'right'
  onClick?: React.MouseEventHandler
  width?: string
  colSpan?: number
  varient?: 'default' | 'bold'
}

const LARGE_PADDING = spacing.m
const SMALL_PADDING = spacing.s
const XSMALL_PADDING = spacing.xs

const TableHeaderStyled = styled.th(
  ({
    $paddingSize,
    $textAlign,
    $varient,
    onClick,
  }: {
    $paddingSize: 'xsmall' | 'small' | 'large'
    $textAlign: 'left' | 'center' | 'right'
    onClick?: React.MouseEventHandler
    $varient?: 'default' | 'bold'
  }) => `
  text-align: ${$textAlign};
  padding: ${
    $paddingSize === 'small'
      ? SMALL_PADDING
      : $paddingSize === 'xsmall'
      ? XSMALL_PADDING
      : LARGE_PADDING
  } ${
    $paddingSize === 'small'
      ? SMALL_PADDING
      : $paddingSize === 'xsmall'
      ? XSMALL_PADDING
      : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${$varient === 'bold' ? fontWeight.semiBold : fontWeight.medium};
  font-size: ${$varient === 'bold' ? fontSize.p : fontSize.p};
  color: ${$varient === 'bold' ? color.greyscale.black : color.greyscale.grey6};
  ${onClick ? 'cursor: pointer;' : ''}
`,
)

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableHeaderFloat = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`

const IconStyled = styled.div`
  ${({ $iconLayout }: { $iconLayout: 'left' | 'right' }) =>
    $iconLayout === 'right' ? `margin-left: ${spacing.s};` : `margin-right: ${spacing.s};`}
`

const IconPlaceholder = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
`

TableHeader.testid = 'tableHeader'

export function TableHeader({
  icon,
  children,
  paddingSize = 'large',
  textAlign = 'left',
  iconLayout = 'right',
  onClick,
  width,
  varient = 'default',
  ...props
}: TableHeaderProps) {
  return (
    <TableHeaderStyled
      $paddingSize={paddingSize}
      $textAlign={textAlign}
      $varient={varient}
      onClick={onClick}
      style={{ width: width }}
      {...props}
    >
      <TableHeaderFloat>
        <>
          {iconLayout === 'left' && (
            <IconStyled $iconLayout={iconLayout} size={18} as={Icon}>
              {icon || <IconPlaceholder />}
            </IconStyled>
          )}
          {children}
          {iconLayout === 'right' && (
            <IconStyled size={18} as={Icon}>
              {icon || <IconPlaceholder />}
            </IconStyled>
          )}
        </>
      </TableHeaderFloat>
    </TableHeaderStyled>
  )
}
