import { Config } from "config";
import * as React from "react";
import { Table } from "stablr/components/atoms";
import { formatCurrency } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

const { TableBody, TableData, TableHeader, TableRow, TableDataCopyable } = Table;
interface StablrWalletDetails {
  WalletAddress: string;
  WalletIssuer: string;
}

interface WalletDetailsTableProps {
  destinationWallet: StablrWalletDetails;
  transaction: APITransaction;
}

const WalletDetailsTableStyled = styled.table`
  width: 100%;
  padding: ${spacing.xs};
  background-color: ${color.greyscale.white};
  border: 1px solid ${color.greyscale.grey3};

  & > div {
    max-width: 1200px;
  }

  & > tbody > tr > th {
    background-color: ${color.greyscale.white};
    color: ${color.greyscale.black};
  }

  & > tbody > tr > td {
    width: 100%;
    background-color: ${color.greyscale.white};
  }
`;

export default function WalletDetailsTable({ destinationWallet, transaction }: WalletDetailsTableProps) {
  return (
    <WalletDetailsTableStyled cellSpacing="0">
      <TableBody>
        <TableRow noBorder>
          <TableHeader paddingSize="small">Wallet Owner:</TableHeader>
          <TableData paddingSize="small">{destinationWallet.WalletIssuer}</TableData>
        </TableRow>
        <TableRow noBorder>
          <TableHeader paddingSize="small">Wallet Address:</TableHeader>
          <TableData paddingSize="small">
            <TableDataCopyable value={destinationWallet.WalletAddress} />
          </TableData>
        </TableRow>
        <TableRow noBorder>
          <TableHeader paddingSize="small">Amount:</TableHeader>
          <TableData paddingSize="small">
            <TableDataCopyable
              value={formatCurrency(
                {
                  Amount: transaction.DigitalAmount.Amount,
                  Denominator: Config.fallbackDenominator,
                },
                2
              )}
            />
          </TableData>
        </TableRow>
      </TableBody>
    </WalletDetailsTableStyled>
  );
}
