import React from "react";
import { Route, Routes } from "react-router-dom";

import AddWebhook from "./AddWebhook";
import EditWebhook from "./EditWebhook";
import WebhooksOverview from "./WebhooksOverview";

export default function Webhooks() {
  return (
    <Routes>
      <Route path="*" element={<WebhooksOverview />} />
      <Route path="/add" element={<AddWebhook />} />
      <Route path="/:webhookId/edit" element={<EditWebhook />} />
    </Routes>
  );
}
