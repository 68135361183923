import React, { memo } from "react";
import { Button } from "stablr/components/atoms";

const SubmitButton = ({ buttonText, loading }: { buttonText: string; loading: boolean }) => {
  return (
    <Button type="submit" loading={loading}>
      {buttonText}
    </Button>
  );
};

export default memo(SubmitButton);
