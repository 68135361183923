import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import { useCreateUser } from "hooks/query/user/use-create-user";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddCircleOutline } from "stablr/assets/icons/add-circle-outline.svg";
import { Button } from "stablr/components/atoms";
import { ButtonContainer, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import color from "stablr/styles/color";
import { APICreateUser } from "types/API/User";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import { UserSchemaValidation } from "../common/schemaValidation";
import UserAddForm from "../common/UserAddForm";

function AddUser() {
  const [openExitModal, setOpenExitModal] = useState(false);

  const navigate = useNavigate();

  const { mutateAsync: mutateCreateUser, isLoading } = useCreateUser();

  const handleOnSubmit = async (user: APICreateUser) => {
    if (isLoading) return;
    try {
      await mutateCreateUser(user, { onSuccess: () => navigate(-1) });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate("/accounts/user-management");
    }
  };

  return (
    <>
      <Form onSubmit={handleOnSubmit} validate={validateFormValues(UserSchemaValidation)} keepDirtyOnReinitialize>
        {({ handleSubmit, submitError, submitting, dirty }) => {
          const isDisabled = submitting || isLoading;
          return (
            <>
              <HeaderBackNav
                title="Add User"
                onBack={(event: React.MouseEvent) => {
                  event.preventDefault();
                  handleBack(dirty);
                }}
                testid="add-user"
              />
              <form onSubmit={handleSubmit}>
                <UserAddForm />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <ButtonContainer justifyContent="flex-end">
                  <Button
                    varient="secondary"
                    color={color.greyscale.black}
                    onClick={event => {
                      event.preventDefault();
                      handleBack(dirty);
                    }}
                    disabled={isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    varient="primary"
                    color={color.greyscale.white}
                    icon={<AddCircleOutline />}
                    iconSize={20}
                    loading={isDisabled}
                  >
                    Add User
                  </Button>
                </ButtonContainer>
                <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.ADD_USERS)(AddUser);
