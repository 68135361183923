import * as yup from "yup";

export enum COMPANY_INFORMATION_SCHEMA {
  ENTITY_NAME = "Name",
  REGISTRATION_NUMBER = "RegistrationNumber",
  REGISTERED_ADDRESS = "Address",
  REGISTERED_POST_CODE = "PostalCode",
  REGISTERED_CITY = "City",
  REGISTERED_COUNTRY = "Country",
}

/**
 * Validation 1.2: Create Sell Order
 */

export const CompanyInformationSchemaValidation = yup.object({
  [COMPANY_INFORMATION_SCHEMA.ENTITY_NAME]: yup.string().required("Required"),
  [COMPANY_INFORMATION_SCHEMA.REGISTRATION_NUMBER]: yup.string().required("Required"),
  [COMPANY_INFORMATION_SCHEMA.REGISTERED_ADDRESS]: yup.string().required("Required"),
  [COMPANY_INFORMATION_SCHEMA.REGISTERED_POST_CODE]: yup.string().required("Required"),
  [COMPANY_INFORMATION_SCHEMA.REGISTERED_CITY]: yup.string().required("Required"),
  [COMPANY_INFORMATION_SCHEMA.REGISTERED_COUNTRY]: yup.string().required("Required"),
});
