import { APIApplicationStatus } from "types/API/APIManagement";

export function parseAPIBadgeText(text: APIApplicationStatus): string {
  switch (text) {
    case "ACTIVE":
      return "Active";
    case "REVOKED":
      return "Revoked";
    default:
      return text;
  }
}
