import { Config } from "config";
import * as React from "react";
import { media, spacing } from "stablr";
import {
  Table,
  TableBody,
  TableData,
  TableDataCopyable,
  TableHeader,
  TableRow,
  TableRowTooltip,
} from "stablr/components/atoms/Table";
import { TableGroup } from "stablr/components/molecules/Table";
import { formatCurrency } from "stablr/functions";
import { SCREEN_SIZES } from "stablr/styles/media";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

type BankDetailsTableProps = {
  transaction: APITransaction;
};

const TableGroupStyled = styled.div`
  @media (min-width: ${SCREEN_SIZES.laptop}) {
    & > div {
      & > div {
        overflow: visible;
      }
    }
  }
`;

const BankDetailsTableStyled = styled(Table)`
  width: 100%;
`;

const TableDataStyled = styled(TableData)`
  @media ${media.laptop} {
    padding-left: ${spacing.xxl};
  }
`;

export default function BankDetailsTable({ transaction }: BankDetailsTableProps) {
  return (
    <TableGroupStyled>
      <TableGroup label={"Our bank details"} varient="blank">
        <BankDetailsTableStyled>
          <TableBody varient="blank">
            {transaction.ToBankAccountInfo?.IBAN ? (
              <TableRow>
                <TableHeader varient="bold" paddingSize="small">
                  Bank account:
                </TableHeader>
                <TableDataStyled paddingSize="small">
                  <TableDataCopyable value={transaction.ToBankAccountInfo?.IBAN} />
                </TableDataStyled>
              </TableRow>
            ) : (
              <></>
            )}
            {transaction.ToBankAccountInfo?.BlincId ? (
              <TableRow>
                <TableHeader varient="bold" paddingSize="small">
                  Blinc ID:
                </TableHeader>
                <TableDataStyled paddingSize="small">
                  <TableDataCopyable value={transaction.ToBankAccountInfo?.BlincId} />
                </TableDataStyled>
              </TableRow>
            ) : (
              <></>
            )}
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Bank account Name:
              </TableHeader>
              <TableDataStyled paddingSize="small">
                <TableDataCopyable value={transaction.ToBankAccountInfo?.AccountName} />
              </TableDataStyled>
            </TableRow>
            <TableRow
              tooltip={
                <TableRowTooltip
                  varient="warning"
                  text="Use the exact payment reference in your wire transfer. If you reference deviates, your payment will be refunded."
                />
              }
            >
              <TableHeader varient="bold" paddingSize="small">
                Payment reference:
              </TableHeader>
              <TableDataStyled paddingSize="small" bold>
                <TableDataCopyable value={transaction.PaymentReference} />
              </TableDataStyled>
            </TableRow>
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Amount:
              </TableHeader>
              <TableDataStyled paddingSize="small">
                <TableDataCopyable
                  value={formatCurrency(
                    {
                      Amount: transaction.FiatAmount.Amount,
                      Denominator: Config.fallbackDenominator,
                    },
                    2
                  )}
                />
              </TableDataStyled>
            </TableRow>
            {transaction.ToBankAccountInfo?.SWIFT ? (
              <TableRow>
                <TableHeader varient="bold" paddingSize="small">
                  BIC/SWIFT:
                </TableHeader>
                <TableDataStyled paddingSize="small">
                  <TableDataCopyable value={transaction?.ToBankAccountInfo?.SWIFT} />
                </TableDataStyled>
              </TableRow>
            ) : (
              <></>
            )}
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Bank Name:
              </TableHeader>
              <TableDataStyled paddingSize="small">{transaction.ToBankAccountInfo?.BankName}</TableDataStyled>
            </TableRow>
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Bank Address:
              </TableHeader>
              <TableDataStyled paddingSize="small">{transaction.ToBankAccountInfo?.BankAddress}</TableDataStyled>
            </TableRow>
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Postal Code:
              </TableHeader>
              <TableDataStyled paddingSize="small">{transaction.ToBankAccountInfo?.BankZip}</TableDataStyled>
            </TableRow>
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                City:
              </TableHeader>
              <TableDataStyled paddingSize="small">{transaction.ToBankAccountInfo?.BankCity}</TableDataStyled>
            </TableRow>
            <TableRow>
              <TableHeader varient="bold" paddingSize="small">
                Country:
              </TableHeader>
              <TableDataStyled paddingSize="small">{transaction.ToBankAccountInfo?.BankCountry}</TableDataStyled>
            </TableRow>
          </TableBody>
        </BankDetailsTableStyled>
      </TableGroup>
    </TableGroupStyled>
  );
}
