import { APITransaction, APITransactionListItem } from "types/API/Transaction";

import Connection from "./Connection";

export const getTransactions = (): Promise<APITransactionListItem[]> =>
  Connection.get<APITransactionListItem[]>(`/organizations/transactions`);

export const getTransaction = (transactionId: string) =>
  Connection.get<APITransaction>(`/organizations/transactions/${transactionId}`);

/**
 * Create Transaction
 */

// This data is different to the transaction item data structure
export interface APICreateAnyTransactionResponse {
  Amount: string;
  BurnOrderId?: string;
  ClientReference: string;
  ClientReferenceInformation: string;
  IsDepositOrder: boolean;
  IssueDate: string;
  OrderId: string;
  PaymentReferenceNumber: string;
  PaymentInstructionId: string;
  MintOrderId?: string;
  BankDetails: { Name: string; Address: string; PostCode: string };
  ToBankAccount: { AccountName: string; IBAN?: string; SWIFT?: string | null; BlincId?: string };
  Organization: { Id: string; Metadata: string; Name: string; City: string };
  TransactionFeeAmount: string;
  TransactionFeeFactor: string;
  TotalAmount: string;
}

interface APICreateTransactionBasicPayload {
  WalletAddress: string;
  ClientReference: string;
}
interface APICreateSellTransactionPayload extends APICreateTransactionBasicPayload {
  DigitalAmount: string;
}

export const createSellTransaction = (sellPayload: APICreateSellTransactionPayload) =>
  Connection.postSocket<APICreateAnyTransactionResponse>(`/organizations/sellstablr`, sellPayload);

export interface APICreateBuyTransactionPayload extends APICreateTransactionBasicPayload {
  FiatAmount: string;
}

export const createBuyTransaction = (buyPayload: APICreateBuyTransactionPayload) =>
  Connection.postSocket<APICreateAnyTransactionResponse>("/organizations/buystablr", {
    ...buyPayload,
    FiatAmount: parseFloat(buyPayload.FiatAmount),
  });

/**
 * Confirm Sell Transaction
 */

export const confirmTransaction = async (transactionId: string, transactionHash: string) => {
  const response = await Connection.post<{
    success: true;
  }>(`/ops/confirmwallettransfer`, {
    transactionHash,
    entityId: transactionId,
  });

  await timeout(5000); // Timeout for completion of sell order transition

  return response;
};

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
