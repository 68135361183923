import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APICreateUser } from "types/API/User";

import useUsers from "./use-users";

export function useCreateUser() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async (user: APICreateUser) => {
      user.DefaultCurrency = "EUR";
      return api.User.createUser(user);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      successToast({ message: "User has been created successfully" });
    },
  });
}
