import * as React from 'react'
import { ReactComponent as WizardStepActive } from 'stablr/assets/svg/WizardStepActive.svg'
import { ReactComponent as WizardStepComplete } from 'stablr/assets/svg/WizardStepComplete.svg'
import { ReactComponent as WizardStepIncomplete } from 'stablr/assets/svg/WizardStepIncomplete1.svg'
import { Heading } from 'stablr/components/atoms/Heading'
import { Paragraph } from 'stablr/components/atoms/Paragraph'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useIsMobile } from '../../../../hooks/use-is-mobile'

export interface WizardSideBarNavigationStepProps {
  testid?: string
  title?: string
  description?: string
  type?: 'incomplete' | 'active' | 'complete'
}

export const WIZARD_NAVIGATION_STEP_SVG_SIZE = '38px'
export const WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT = spacing.xs

const WizardSideBarNavigationStepStyled = styled.li`
  display: flex;
  align-items: center;
  position: relative;

  & h1,
  p {
    margin: ${({ theme: { spacing } }) => `${spacing.xxs} 0 ${spacing.xxs} ${spacing.m}`};
  }

  & svg {
    width: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
    height: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  }

  @media ${({ theme: { media } }) => media.desktop} {
    height: calc(
      ${WIZARD_NAVIGATION_STEP_SVG_SIZE} + (${WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT} * 2)
    );
  }
`

const WizardStepTextWrapper = styled.div`
  flex: 1;
`

WizardSideBarNavigationStep.testid = 'WizardSideBarNavigationStep'

export function WizardSideBarNavigationStep({
  testid,
  title,
  description,
  type = 'complete',
}: WizardSideBarNavigationStepProps) {
  const isMobile = useIsMobile()

  return (
    <WizardSideBarNavigationStepStyled
      data-testid={composeTestID(WizardSideBarNavigationStep.testid, testid)}
    >
      {type === 'complete' ? (
        <WizardStepComplete />
      ) : type === 'active' ? (
        <WizardStepActive />
      ) : (
        <WizardStepIncomplete />
      )}
      {!isMobile && (title !== undefined || description !== undefined) && (
        <WizardStepTextWrapper>
          <Heading as="h1" varient="h4" color={color.greyscale.white}>
            {title}
          </Heading>
          <Paragraph color={color.greyscale.white}>{description}</Paragraph>
        </WizardStepTextWrapper>
      )}
    </WizardSideBarNavigationStepStyled>
  )
}
