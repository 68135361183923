import * as React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

const LARGE_PADDING = spacing.m
const SMALL_PADDING = spacing.s
const XSMALL_PADDING = spacing.xs

export const TABLEDATA_VARIENT_MAP = {
  default: {},
  red: {
    color: color.palette.red,
  },
}

const TableDataStyled = styled.td(
  ({ $paddingSize, $bold }: { $paddingSize: 'xsmall' | 'small' | 'large'; $bold?: boolean }) => `
  text-align: left;
  padding: ${
    $paddingSize === 'small'
      ? SMALL_PADDING
      : $paddingSize === 'xsmall'
      ? XSMALL_PADDING
      : LARGE_PADDING
  } ${
    $paddingSize === 'small'
      ? SMALL_PADDING
      : $paddingSize === 'xsmall'
      ? XSMALL_PADDING
      : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${$bold ? fontWeight.semiBold : fontWeight.regular};
  font-size: ${fontSize.p};
  color: ${color.greyscale.black};

`,
)

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableHeaderFloat = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

export interface TableDataProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  paddingSize?: 'xsmall' | 'small' | 'large'
  varient?: 'default' | 'red'
  bold?: boolean
}

TableData.testid = 'TableData'

export function TableData({
  children,
  paddingSize = 'large',
  varient = 'default',
  bold = false,
  ...props
}: TableDataProps) {
  return (
    <TableDataStyled
      $paddingSize={paddingSize}
      style={TABLEDATA_VARIENT_MAP[varient]}
      $bold={bold}
      {...props}
    >
      <TableHeaderFloat>{children}</TableHeaderFloat>
    </TableDataStyled>
  )
}
