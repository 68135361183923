import { getPasswordSecurityLevel } from "stablr";
import * as yup from "yup";

export enum PASSWORD_SCHEMA {
  CURRENT_PASSWORD = "currentPassword",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
}

export const PasswordSchemaValidation = yup.object({
  [PASSWORD_SCHEMA.CURRENT_PASSWORD]: yup.string().required("Current Password is required"),
  [PASSWORD_SCHEMA.PASSWORD]: yup
    .string()
    .test("passwordSecurityLevel", "Password must be more secure", function (value?: string) {
      const passwordSecurtyLevel = getPasswordSecurityLevel(value);
      return passwordSecurtyLevel >= 4;
    })
    .required("Password is required"),
  [PASSWORD_SCHEMA.CONFIRM_PASSWORD]: yup
    .string()
    .oneOf([yup.ref(PASSWORD_SCHEMA.PASSWORD), null], "Passwords do not match")
    .required("Confirm Password is required"),
});
