import { useQuery } from "@tanstack/react-query";
import api from "api";

useIssuers.queryKey = "issuers";

export default function useIssuers() {
  return useQuery([useIssuers.queryKey], async () => api.Issuer.getIssuers(), {
    notifyOnChangeProps: "all",
  });
}
