import React, { ReactElement } from 'react'
import { ReactComponent as BackIcon } from 'stablr/assets/icons/arrow-back.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { useIsMobile } from 'stablr/hooks/use-is-mobile'
import color from 'stablr/styles/color'
import styled from 'styled-components'

import { Button } from '../../atoms/Button'
import { Heading } from '../../atoms/Heading'

export interface HeaderBackNavProps {
  title: string
  size?: 'large' | 'small'
  iconSize?: number
  backIcon?: ReactElement
  onBack?: (event: React.MouseEvent) => void
  children?: ReactElement | ReactElement[]
  testid?: string
}
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

HeaderBackNav.testid = 'headerBackNav'

export function HeaderBackNav({
  title,
  onBack,
  backIcon,
  size,
  iconSize,
  children,
  testid,
  ...props
}: HeaderBackNavProps) {
  const isMobile = useIsMobile()

  return (
    <HeadingContainer data-testid={composeTestID(HeaderBackNav.testid, testid)} {...props}>
      {!!onBack && (
        <Button
          icon={backIcon ? backIcon : <BackIcon />}
          color={color.palette.purple}
          varient="secondary"
          rounded
          iconSize={iconSize ?? 20}
          size={size}
          layout="icon-only"
          onClick={onBack}
        />
      )}
      <Heading as="h3" testid={testid} varient={isMobile ? 'h4' : 'h3'}>
        {title}
      </Heading>
      {children}
    </HeadingContainer>
  )
}
