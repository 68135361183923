import { APITransactionStatus } from "types/API/Transaction";

export function getTransactionBadgeVarient(value: APITransactionStatus) {
  switch (value) {
    case "PENDING":
      return "info";
    case "CONFIRMED":
      return "success";
    case "REJECTED":
      return "error";
    case "AWAITING_PAYOUT":
      return "action";
    default:
      return undefined; // Refurn blank style
  }
}
