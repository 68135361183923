import { APICompanyInfo } from "types/API/Company";

import { PostCompanyDetailsResponse, PostCompanyDetailsType } from "./Company";
import { MOCK_COMPANY_INFO } from "./Company.mock.data";
import * as Mock from "./Connection.mock";

export const getCompanyDetails = (): Promise<APICompanyInfo> => Mock.get<APICompanyInfo>(`/current`, MOCK_COMPANY_INFO);

export const postCompanyDetails = (_companyDetails: PostCompanyDetailsType) =>
  Mock.postSocket<PostCompanyDetailsResponse>(`/customers`);
