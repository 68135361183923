import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { APICreateBankAccount } from "types/API/BankAccount";

import { useBankAccounts } from "./use-bank-accounts";

export function useCreateAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (accountDetails: APICreateBankAccount) => api.BankAccount.createAccount(accountDetails),
    onSuccess: async () => {
      await queryClient.invalidateQueries([useBankAccounts.queryKey]);
    },
  });
}
