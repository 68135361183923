import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Loader } from 'stablr/components/atoms/Loader'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useIsMobile } from '../../../../hooks/use-is-mobile'

export interface MFAInputProps extends FieldRenderProps<string, HTMLInputElement> {
  note?: string
  length?: number
  ariaLabel: string
  onComplete: () => void
  invalid?: boolean
  loading?: boolean
  autoFocus?: boolean
}

const LARGE_INPUT_WIDTH = '55px'
const SMALL_INPUT_WIDTH = '40px'

const ACCEPTED_REGEX = /[0-9]{1}/

const MFAInputStyled = styled.div`
  display: flex;
  align-items: center;
`

const MFAInputContainerStyled = styled.div``

const InputStyled = styled.input(
  ({ $error }: { $error: boolean }) => `
  border: solid ${$error === true ? color.palette.red : color.greyscale.grey5} 1px;
  border-radius: 3px;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.greyscale.black};
  padding: ${spacing.m};
  text-align: center;
  width: ${LARGE_INPUT_WIDTH};
  box-sizing: border-box;
  margin-right: ${spacing.s};
  ::placeholder {
    color: ${color.greyscale.grey5};
  }
  @media ${media.mobile} {
    // Reduce mobile size due to screen width limitations (avoid horizontal scroll)
    width: ${SMALL_INPUT_WIDTH};
    padding: ${spacing.m} ${spacing.xs};
  }
  @media ${media.desktop} {
  }
`,
)

/**
 * Based on react-auth-code-input: https://github.com/drac94/react-auth-code-input/blob/master/src/index.tsx
 */

export const MFAInput = React.forwardRef(
  (
    {
      input: { type = 'text', ...inputProps },
      onComplete,
      note,
      length = 6,
      ariaLabel,
      loading,
      autoFocus,
      ...props
    }: MFAInputProps,
    ref,
  ) => {
    const inputsRef = React.useRef<Array<HTMLInputElement>>([])
    const isMobile = useIsMobile()

    const errorMessage =
      props.meta.touched && props.meta.error ? props.meta.error : props.meta.submitError

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus()
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = ''
          }
          inputsRef.current[0].focus()
        }
        sendResult()
      },
    }))

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join('')
      inputProps.onChange && inputProps.onChange(res)
      if (res.length === 6) onComplete && onComplete()
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = e
      if (value.length > 1) {
        e.target.value = value.charAt(0)
        if (nextElementSibling !== null) {
          ;(nextElementSibling as HTMLInputElement).focus()
        }
      } else {
        if (value.match(ACCEPTED_REGEX)) {
          if (nextElementSibling !== null) {
            ;(nextElementSibling as HTMLInputElement).focus()
          }
        } else {
          e.target.value = ''
        }
      }
      sendResult()
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      const target = e.target as HTMLInputElement
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement
            t.value = ''
            t.focus()
            e.preventDefault()
          }
        } else {
          target.value = ''
        }
        sendResult()
      }
    }

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select()
    }

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('Text')

      let currentInput = 0

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i)
        const currentValue = inputsRef.current[currentInput].value
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter
            if (inputsRef.current[currentInput].nextElementSibling !== null) {
              ;(inputsRef.current[currentInput].nextElementSibling as HTMLInputElement).focus()
              currentInput++
            }
          }
        }
      }
      sendResult()

      e.preventDefault()
    }

    return (
      <MFAInputContainerStyled>
        <MFAInputStyled>
          {[...new Array(length)].fill('').map((item, index) => (
            <InputStyled
              key={`input_${index}`}
              {...props}
              ref={(el: HTMLInputElement) => {
                inputsRef.current[index] = el
              }}
              autoFocus={index === 0 && autoFocus ? true : undefined}
              data-testid={`${inputProps.name}-${index}`}
              $error={typeof errorMessage === 'string'}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onFocus={handleOnFocus}
              onPaste={handleOnPaste}
              autoComplete={index === 0 ? 'one-time-code' : 'off'}
              maxLength={1}
              type={type}
              aria-label={
                ariaLabel ? `${ariaLabel}. Character ${index + 1}.` : `Character ${index + 1}.`
              }
            ></InputStyled>
          ))}
          {loading && <Loader size={isMobile ? '32px' : '50px'} varient="default" />}
        </MFAInputStyled>

        <InputNote htmlFor={inputProps.name} color={!errorMessage ? undefined : color.palette.red}>
          {!errorMessage ? note : errorMessage}
        </InputNote>
      </MFAInputContainerStyled>
    )
  },
)

MFAInput.displayName = 'MFAInput'
