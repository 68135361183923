import * as React from 'react'
import { Link } from 'react-router-dom'
import colorStyle from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface InputLinkProps {
  to: string
  children: string
}

const InputLinkStyled = styled(Link)`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${colorStyle.greyscale.greyDark1};
  line-height: 21px;
  min-height: 21px;
  font-weight: ${fontWeight.regular};
  margin: 0;
  text-align: right;
  text-decoration: underline;
  flex: 1;
`

InputLink.testid = 'InputLink'

export function InputLink({ to, children }: InputLinkProps) {
  return <InputLinkStyled to={to}>{children}</InputLinkStyled>
}
