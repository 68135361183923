import React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import { BadgeVarients } from 'stablr/types/badge-variant'
import styled from 'styled-components'

export interface BadgeProps {
  text: string
  varient?: BadgeVarients
  noMargin?: boolean
  background?: boolean
}

const BadgeStyled = styled.span`
  padding: ${spacing.xxs} ${spacing.xs};
  border-radius: 20px;
  color: ${color.greyscale.grey5};
  background-color: ${color.greyscale.grey2};
  text-align: center;
  font-size: 12px;
  font-weight: ${fontWeight.semiBold};
  margin: 0 ${({ $noMargin }: { $noMargin?: boolean }) => ($noMargin ? '0' : spacing.xs)};
  font-family: ${fontFamily.primary};
  white-space: nowrap;

  &.success {
    color: ${color.palette.green};
    background-color: ${color.palette.lightGreen};
  }

  &.error {
    color: ${color.palette.red};
    background-color: ${color.palette.lightRed};
  }

  &.info {
    color: ${color.palette.orange};
    background-color: ${color.palette.lightOrange};
  }

  &.action {
    color: ${color.palette.purple};
    background-color: ${color.palette.lightPurple};
  }

  &.background-success {
    color: ${color.greyscale.white};
    background-color: ${color.palette.green};
  }

  &.background-info {
    color: ${color.greyscale.white};
    background-color: ${color.palette.orange};
  }

  &.background-action {
    color: ${color.greyscale.white};
    background-color: ${color.palette.blue};
  }
`

Badge.testid = 'Badge'

export function Badge({ text, varient, noMargin, background }: BadgeProps) {
  const className = background ? `background-${varient}` : varient;

  return (
    <BadgeStyled data-testid={Badge.testid} className={className} $noMargin={noMargin}>
      {text}
    </BadgeStyled>
  )
}
