import React from "react";
import { Field, Form } from "react-final-form";
import { useToast } from "stablr";
import { ReactComponent as CopyIcon } from "stablr/assets/icons/content-copy.svg";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { ReactComponent as WarningIcon } from "stablr/assets/icons/warning.svg";
import { Button, Icon, Spacer } from "stablr/components/atoms";
import { CopyInputText, ErrorMessage, Modal } from "stablr/components/molecules";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

import { APIKEY_SCHEMA } from "./schemaValidation";

const TextStyled = styled.div`
  font-size: ${fontSize.h5};
  font-weight: ${fontWeight.default};
  max-width: 371px;
  padding-left: 25px;
`;

const InfoMessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoMessageStyled = styled.span`
  max-width: 318px;
  padding-left: 15px;
`;

export function CopyAPIKeyModal({
  open,
  onClose,
  onConfirm,
  secret,
}: {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;

  loading?: boolean;
  errorMessage?: string;
  secret: string;
}) {
  const { infoToast } = useToast();
  const [copiedSecret, setCopiedSecret] = React.useState(secret);
  const [closeCount, setCloseCount] = React.useState(0);

  React.useEffect(() => {
    setCopiedSecret(secret);
  }, [secret]);

  const handleSubmit = () => {
    //
  };

  const onCloseModal = () => {
    setCloseCount(prevCount => prevCount + 1);
    if (closeCount !== 0) {
      onConfirm();
      infoToast({ title: "", message: "Copied to your clipboard", icon: <CopyIcon /> });
    }
  };

  return (
    <Form initialValues={{ [APIKEY_SCHEMA.SECRET]: copiedSecret }} onSubmit={handleSubmit}>
      {({ submitError }) => (
        <Modal
          open={open}
          onClose={onClose}
          icon={<WarningIcon />}
          iconSize={24}
          iconColor={color.palette.yellow}
          title="Attention: Save your API Secret"
          modalBody={
            <>
              <TextStyled>Quick reminder: Don&apos;t forget to save your API Secret!</TextStyled>
              <Field name={APIKEY_SCHEMA.SECRET} readOnly component={CopyInputText} placeholder="API Key" />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </>
          }
          modalFooter={
            <>
              <Spacer width={spacing.m} />
              <InfoMessageWrapper>
                {closeCount === 1 && (
                  <>
                    <Icon color={color.palette.blue} size={24}>
                      <InfoIcon />
                    </Icon>
                    <InfoMessageStyled>
                      You can not go back to this page, click close again to leave this page
                    </InfoMessageStyled>
                  </>
                )}
                <Button varient="secondary" onClick={onCloseModal}>
                  Close
                </Button>
              </InfoMessageWrapper>
            </>
          }
        />
      )}
    </Form>
  );
}
