import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as InfoIcon } from 'stablr/assets/icons/info.svg'
import { Icon, Tooltip } from 'stablr/components/atoms'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { spacing } from 'stablr/styles'
import color from 'stablr/styles/color'
import styled from 'styled-components'

const IconStyled = styled(Icon)`
  position: relative;
  padding: 0 0 0 5px;
  margin-bottom: -4px;
  cursor: pointer;
`

const TooltipStyled = styled.span`
  position: relative;
  padding: 0 ${spacing.xs} 0 0;
  width: 50px;

  #tooltip {
    min-width: 350px;
    position: absolute;
    bottom: 100%;
    left: 0%;
    margin-left: 0;
    opacity: 0;
  }
  &:hover {
    #tooltip {
      opacity: 1;
    }
  }
`

export interface TextInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  note?: string
  compact?: boolean
  maxWidth?: boolean
}

TextInput.testid = 'TextInput'

export function TextInput({
  input: { type = 'text', ...inputProps },
  label,
  note,
  tooltip,
  maxWidth = false,
  compact = false,
  meta: { error, touched },
  ...props
}: TextInputProps) {
  const isError = error && touched

  return (
    <div data-testid={composeTestID(TextInput.testid, props.testid)}>
      <Label readOnly={props.readOnly} htmlFor={inputProps.name}>
        {label}
        {tooltip !== undefined && (
          <TooltipStyled>
            <IconStyled color={color.greyscale.grey4} size={17.5}>
              <InfoIcon />
            </IconStyled>
            <Tooltip text={tooltip}></Tooltip>
          </TooltipStyled>
        )}
      </Label>
      <Input maxWidth={maxWidth} {...inputProps} {...props} type={type}></Input>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.palette.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </div>
  )
}
