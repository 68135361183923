import phone from 'phone'
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as PhoneSVG } from 'stablr/assets/icons/phone.svg'
import { ComboBox } from 'stablr/components/atoms/Form/ComboBox'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import input from 'stablr/styles/input'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import countryCodesJSON from './country-codes.json'

export interface PhoneNumberInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  note?: string
  compact?: boolean
}

const BORDER_MARGIN = spacing.xs

const COUNTRY_CODE_OPTIONS = countryCodesJSON.data

const PhoneNumberInputStyled = styled.div`
  max-width: ${input.maxWidth};
`

const InputGroupStyled = styled.div`
  display: flex;

  & > div:first-child {
    width: 135px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    &::after {
      content: '';
      height: calc(100% - ${BORDER_MARGIN} * 2);
      box-sizing: border-box;
      margin: ${BORDER_MARGIN} 0;
      width: 0;
      border-left: solid ${color.greyscale.grey5} 1px;
    }
  }

  & > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: unset;
    flex: 1;
  }

  &.disabled {
    & > div:first-child {
      width: 110px;
    }
  }
`

PhoneNumberInput.testid = 'phoneNumberInput'

export function PhoneNumberInput({
  input: { type = 'tel', ...inputProps },
  note,
  compact,
  meta: { error, touched },
  disabled,
  ...props
}: PhoneNumberInputProps) {
  const [extension, setExtension] = React.useState(
    inputProps.value ? `${phone(inputProps.value).countryCode}` : '',
  )

  const [selectedCountryCode, setSelectedCountryCode] = React.useState(extension)
  const InputRef = React.useRef(null)

  const handleOnChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement
    const newValue = `${selectedCountryCode}${target.value}`
    inputProps.onChange(newValue)
  }

  const [extensionValue, numberValue] = React.useMemo(() => {
    const number =
      typeof inputProps.value === 'string' ? inputProps.value.replace(extension, '') : ''
    return [extension, number]
  }, [inputProps.value])

  const isError = touched && error

  React.useEffect(() => {
    if (extensionValue) setExtension(extensionValue)
  }, [extensionValue])

  const handleOnExtensionChange = (value: string) => {
    setExtension(value)
    setSelectedCountryCode(value)
    if (COUNTRY_CODE_OPTIONS.includes(value)) {
      if (InputRef.current !== null) {
        inputProps.onChange(`${selectedCountryCode}${numberValue}`)
      }
    }
  }

  return (
    <PhoneNumberInputStyled data-testid={composeTestID(PhoneNumberInput.testid, props.testid)}>
      <Label htmlFor={inputProps.name}>{props.label}</Label>
      <InputGroupStyled className={disabled ? 'disabled' : ''}>
        <ComboBox
          value={selectedCountryCode}
          minimumMatchedResults={2}
          icon={<PhoneSVG />}
          placeholder={'+00'}
          name={`${inputProps.name}-extension`}
          onSelectionChange={handleOnExtensionChange}
          options={COUNTRY_CODE_OPTIONS}
          disabled={disabled}
        />
        <Input
          ref={InputRef}
          {...inputProps}
          {...props}
          disabled={disabled}
          type={type}
          onChange={handleOnChange}
          value={numberValue}
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.palette.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </PhoneNumberInputStyled>
  )
}
