import { PERMISSION_ENUM } from "config/permissions";
import withPermission from "hocs/permission";
import useCompanyInfo from "hooks/query/company-information/use-company";
import React, { memo } from "react";
import { Form } from "react-final-form";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { Alert, Spacer } from "stablr/components/atoms";
import { CardError, CardLoader, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import color from "stablr/styles/color";
import spacing from "stablr/styles/spacing";

import CompanyFields from "./CompanyFields";

function CompanyDetails() {
  const { data, isLoading, error, refetch } = useCompanyInfo();

  const handleOnSubmit = () => {
    // do nothing;
  };

  return (
    <>
      <HeaderBackNav title="Company Information" />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching company information" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : (
        <Form onSubmit={handleOnSubmit} initialValues={data}>
          {() => (
            <>
              <Spacer height={spacing.m} />
              <Alert
                message="The Company Details cannot be edited"
                iconElement={<InfoIcon />}
                iconColor={color.palette.blue}
                iconSize={20}
              />
              <Spacer height={spacing.m} />
              <CompanyFields isDisabled />
            </>
          )}
        </Form>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_ACCOUNT)(memo(CompanyDetails));
