import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { InputNote, Label } from 'stablr/components/atoms'
import { Switch } from 'stablr/components/atoms/Switch'
import { composeTestID } from 'stablr/functions'
import { color, fontSize, spacing } from 'stablr/styles'
import styled from 'styled-components'

export interface SwitchInputProps extends FieldRenderProps<boolean | string, HTMLInputElement> {
  testid?: string
  label?: string
  compact?: boolean
}

const SpanStyled = styled.span`
  color: ${color.greyscale.greyDark1};
  font-size: ${fontSize.p};
  margin-left: ${spacing.s};
`
SwitchInput.testid = 'SwitchInput'

export function SwitchInput({
  input: { type = 'text', ...inputProps },
  label,
  note,
  compact = false,
  meta: { error, touched },
  tooltip,
  description,
  ...props
}: SwitchInputProps) {
  const isError = touched && error

  return (
    <div data-testid={composeTestID(SwitchInput.testid, props.testid)}>
      {label && <Label>{label}</Label>}
      <Switch {...inputProps} {...props} checked={inputProps.value} />
      <SpanStyled>{inputProps.value === true ? 'Enabled' : 'Disabled'}</SpanStyled>
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.palette.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </div>
  )
}
