import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIPaymentListItem } from "types/API/Payment";

usePayments.queryKey = "payments";

export default function usePayments() {
  return useQuery([usePayments.queryKey], async () => api.Payment.getPaymentList() as Promise<APIPaymentListItem[]>, {
    notifyOnChangeProps: "all",
  });
}
