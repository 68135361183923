import { PERMISSION_ENUM } from "config/permissions";
import withPermission from "hocs/permission";
import { useBankAccounts } from "hooks/query/bank-account/use-bank-accounts";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SwitchAccount } from "stablr/assets/icons/switch-account.svg";
import { ActionHeader, CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { SummaryList } from "stablr/components/organisms";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import BankAccountDetail from "./AccountDetail";

function AccountListComponent() {
  const navigation = useNavigate();
  const { infoToast } = useToast();

  const { data: accountList, isLoading, error, refetch } = useBankAccounts();

  const pendingOrApprovedBanks = accountList?.filter(item => item.Status === "PENDING" || item.Status === "APPROVED");

  const handleAddAccount = () => {
    if (pendingOrApprovedBanks && pendingOrApprovedBanks?.length > 0)
      infoToast({ message: "You can only have 1 bank accounts connected to your account at the moment" });
    else navigation("/accounts/bank-accounts/add");
  };

  return (
    <>
      <ActionHeader
        title="Bank Account"
        btnText="Add Bank Account"
        onBtnClick={handleAddAccount}
        permission={PERMISSION_ENUM.ADD_BANK_ACCOUNTS}
      />
      {error || (!isLoading && accountList === undefined) ? (
        <CardError body={getErrorMessage(error)} title="Cannot load Bank Account(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !accountList?.length ? (
        <CardEmpty
          iconElement={<SwitchAccount />}
          title="No Bank Accounts found"
          body="There is no Bank Account connected to your account at the moment"
        />
      ) : (
        <SummaryList>
          {accountList.map(({ Iban, ...account }) => (
            <BankAccountDetail key={Iban} Iban={Iban} {...account} onRefresh={refetch} />
          ))}
        </SummaryList>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_BANK_ACCOUNTS)(AccountListComponent);
