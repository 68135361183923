import { Config } from "config";
import { Web3Service } from "services/web3";

import { EURR_ABI } from "./EURR_ABI";

const assumedDecimals = 6;

async function getTotalSupply(token: keyof typeof Config.web3.contracts) {
  const chains =
    Config.web3.network === "mainnet" ? Config.web3.contracts[token].mainnet : Config.web3.contracts[token].sepolia;
  const contract = new Web3Service.eth.Contract(EURR_ABI, chains);

  const result = await contract.methods.totalSupply().call();

  return result / 10 ** assumedDecimals;
}

export default { getTotalSupply };
