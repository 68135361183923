import { APICreateUser, APIUpdateUser, APIUserListItem } from "types/API/User";

import * as Mock from "./Connection.mock";
import { MOCK_USERS } from "./User.mock.data";

export const getUserList = () => Mock.get<APIUserListItem[]>("customers/users", MOCK_USERS);

export const getUser = (userId: string) => {
  const mockUser = MOCK_USERS.find(user => user.Username === userId);
  if (mockUser !== undefined) {
    return Mock.get<APIUserListItem>(`customers/users/${userId}`, mockUser);
  } else {
    return Mock.throwError<APIUserListItem>(404);
  }
};

export const createUser = (_: APICreateUser) => Mock.postSocket("customers/users");

export const updateUser = (userId: string, _: APIUpdateUser) => Mock.put(`customers/users/${userId}`, true);

export const deleteUser = (userId: string) => Mock.put(`customers/users/${userId}/disable`);

export const approveUser = (userId: string, _: object) => Mock.post(`customers/users/${userId}/approve`, true);

export const rejectUser = (userId: string, _: object) => Mock.post(`customers/users/${userId}/reject`, true);
