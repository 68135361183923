export async function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      const result = fr.result;
      if (typeof result === "string" && result.split(",")?.length > 1) {
        resolve(result.split(",")?.[1] as string);
      }
      resolve(result);
    };
    fr.onerror = reject;
  });
}
