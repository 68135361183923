import { confirmSignIn } from "aws-amplify/auth";
import useUserData from "hooks/context/use-user-data";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Link, useSearchParams } from "react-router-dom";
import { Heading, Paragraph, Spacer } from "stablr/components/atoms";
import { Container, MFAInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import { getErrorMessage } from "stablr/functions";
import { spacing } from "stablr/styles";

interface SMSAuthenticationProps {
  onContinue: () => void;
  mobile?: string;
}

SMSAuthenticationComponent.testid = "SMSAuthentication";

const ALERT_DELAY_SECONDS = 15;

export default function SMSAuthenticationComponent({ onContinue }: SMSAuthenticationProps) {
  const user = useUserData();
  const [hasBeenAWhile, setHasBeenAWhile] = React.useState(false);
  const [searchParams] = useSearchParams();
  const destination = searchParams.get("destination");

  const skipSMSAuth = user?.["cognito:username"] !== undefined;

  // Redirect if User signed in (has username in user data), this is for when a user navigates back using the browser
  if (skipSMSAuth) {
    onContinue();
  }

  React.useEffect(() => {
    setTimeout(() => {
      setHasBeenAWhile(true);
    }, ALERT_DELAY_SECONDS * 1000);
  }, []);

  const handleOnSubmit = async (values: { code: string }) => {
    try {
      await confirmSignIn({ challengeResponse: values.code });
      onContinue();
    } catch (error) {
      if (
        ["CodeMismatchException", "Code mismatch", "Invalid code or auth state for the user."].includes(
          getErrorMessage(error)
        )
      ) {
        return { code: "Code does not match" };
      }
      return { code: getErrorMessage(error) };
    }
  };

  return (
    <StepTemplate>
      <Container as="section" maxWidth={1000} id="title-container">
        <header>
          <Heading as="h1" varient="h2">
            4. SMS Authentication
          </Heading>
        </header>
        <Paragraph>Setup two factor authentication for extra safety</Paragraph>
        <br />
        {
          <>
            <Paragraph>
              Please fill in the 6-digit code sent to <b>{destination}</b> to complete your authentication setup
            </Paragraph>
            <Form onSubmit={handleOnSubmit}>
              {({ submitError, handleSubmit, submitting }) => (
                <FormLayout>
                  <>
                    <Field
                      invalid={submitError !== undefined}
                      onComplete={handleSubmit}
                      name="code"
                      component={MFAInput}
                      ariaLabel="MFA"
                      loading={submitting}
                    />
                  </>
                </FormLayout>
              )}
            </Form>
            {hasBeenAWhile && (
              <Paragraph>
                If you are having trouble receiving your SMS code after 5 minutes. Sign in with the credentials you
                created to try again.
                <Spacer height={spacing.m} />
                <Link to="/sign-in">Sign In Again</Link>
              </Paragraph>
            )}
          </>
        }
      </Container>
    </StepTemplate>
  );
}
