import React from 'react'
import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Heading } from '../../atoms/Heading'
import { Icon } from '../../atoms/Icon'

export interface NotFoundProps {
  image: React.ReactElement
  message: string
  description: string
}

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.xxl} ${spacing.m};
  text-align: center;

  & h3 {
    margin-bottom: ${spacing.xs};
  }
`

const DescriptionStyled = styled.p`
  font-weight: 400;
  color: ${color.greyscale.grey5};
  font-size: ${fontSize.p};
  margin: 0;
`

export function NotFound({ image, message, description }: NotFoundProps) {
  return (
    <NotFoundContainer>
      <Icon size={300}>{image}</Icon>
      <Heading as="h3" varient="h3">
        {message}
      </Heading>
      <DescriptionStyled>{description}</DescriptionStyled>
    </NotFoundContainer>
  )
}
