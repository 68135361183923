import copy from 'copy-to-clipboard'
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as CopyIcon } from 'stablr/assets/icons/content-copy.svg'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputButtonIcon } from 'stablr/components/atoms/Form/InputButtonIcon'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { useToast } from 'stablr/hooks'
import color from 'stablr/styles/color'
import input from 'stablr/styles/input'
import styled from 'styled-components'

export interface CopyInputTextProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  maxWidth?: boolean
}

const CopyInputTextStyled = styled.div<{ $maxWidth: boolean }>`
  width: 100%;
  max-width: ${({ $maxWidth }) => (!$maxWidth ? input.maxWidth : 'none')};

  & > div {
    max-width: ${({ $maxWidth }) => (!$maxWidth ? input.maxWidth : 'none')};
  }
`

CopyInputText.testid = 'CopyInputText'

export function CopyInputText({
  input: { ...inputProps },
  label,
  maxWidth = false,
  meta: { error, touched },
  ...props
}: CopyInputTextProps) {
  const { infoToast } = useToast()
  const [copied, setCopied] = React.useState(false)

  const copyText = () => {
    copy(inputProps.value)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <CopyInputTextStyled
      data-testid={composeTestID(CopyInputText.testid, props.testid)}
      $maxWidth={maxWidth}
    >
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input
        maxWidth={maxWidth}
        {...inputProps}
        {...props}
        appendElement={
          inputProps.value ? (
            <InputButtonIcon onClick={copyText}>
              <CopyIcon />
            </InputButtonIcon>
          ) : undefined
        }
      ></Input>
      {copied && infoToast({ title: '', message: 'Copied to your clipboard', icon: <CopyIcon /> })}
      {touched && error && (
        <InputNote htmlFor={inputProps.name} color={error ? color.palette.red : undefined}>
          {error}
        </InputNote>
      )}
    </CopyInputTextStyled>
  )
}
