import { APIAPIKey, APICreateAPIKey } from "types/API/APIManagement";

import { MOCK_API_MANAGEMENT_KEY, MOCK_API_MANAGEMENT_KEY_LIST } from "./APIKeyManagement.mock.data";
import * as Mock from "./Connection.mock";

export const getAPIKeyList = () => Mock.get<APIAPIKey[]>("/oauth/clients", MOCK_API_MANAGEMENT_KEY_LIST);

export const getAPIKeyById = (keyId: string) => Mock.get<APIAPIKey>(`/oauth/clients/${keyId}`, MOCK_API_MANAGEMENT_KEY);

export const updateAPIKey = (id: string, data: Partial<APICreateAPIKey>) => Mock.put(`/oauth/clients/${id}`, data);

export const revokeAPIKey = (apiKeyId: string) => {
  return Mock.post(`/oauth/clients/${apiKeyId}/revoke`);
};

export const createAPIKey = (_data: APICreateAPIKey) => {
  return Mock.post<APIAPIKey>("/oauth/clients", {
    Id: "9b0a8616-0014-4de5-a022-cf500fcb8e77",
    Name: "apikey2",
    Role: "ADMIN",
    Status: "ACTIVE",
    ApplicationId: "ut8mf6ksnepmjv207b64h",
    Secret: "1kpnf41gmlod76tv0rk8544lshv6109mk4jk5gerf9l4jcd8711b",
    WhitelistedIPs: ["192.68.1.1"],
    CreatedOn: "2024-08-06T22:00:06.2374788Z",
  });
};
