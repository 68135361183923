import { Config } from "config";
import PageRoutes from "pages";
import React from "react";
import { TestNote, Toast } from "stablr/components/atoms";
import { ThemeProvider } from "styled-components";
import theme from "styles";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Toast />
      <PageRoutes />
      {Config.mocked && <TestNote>Demo Mode</TestNote>}
    </ThemeProvider>
  );
}

export default App;
