import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'
export interface FormLayoutProps {
  testid?: string
  children: React.ReactElement | React.ReactElement[]
  columns?: 1 | 2 | 3
  mobileColumns?: 1 | 2 | 3
}

const DESKTOP_SPACING = spacing.xl

const MOBILE_FORM_MAX_WIDTH = '800px'
const DESKTOP_FORM_MAX_WIDTH = '1000px'

const FormLayoutStyled = styled.div(
  ({ $columns, $mobileColumns }: { $columns?: 1 | 2 | 3; $mobileColumns?: 1 | 2 | 3 }) => `
  & > * {
    margin: ${spacing.xs} 0;
  }
  @media ${media.mobile} {
    position:relative;
    max-width: min(100%, ${MOBILE_FORM_MAX_WIDTH}); // Prevent x-overflow on dashboard
    ${
      ($mobileColumns ?? $columns) === 2
        ? `
    & > * {
      width: calc(50% - (${DESKTOP_SPACING} / 2));
      display: inline-block;
      vertical-align: top;
      &:nth-child(odd) {
        margin-right: ${DESKTOP_SPACING};
      }
    }`
        : ($mobileColumns ?? $columns) === 3
        ? `
    & > * {
      width: calc(33.3% - ((${DESKTOP_SPACING} * 2) / 3));
      display: inline-block;
      vertical-align: top;
      &:not(:nth-child(3)) {
        margin-right: ${DESKTOP_SPACING};
      }
    }`
        : ''
    }
    & > *:last-child {
      margin-bottom: ${spacing.xl};
    }
  }
  @media ${media.desktop} {
    position:relative;
    max-width: min(100%, ${DESKTOP_FORM_MAX_WIDTH}); // Prevent x-overflow on dashboard
    ${
      $columns === 2
        ? `
    & > * {
      width: calc(50% - (${DESKTOP_SPACING} / 2));
      display: inline-block;
      vertical-align: top;
      &:nth-child(odd) {
        margin-right: ${DESKTOP_SPACING};
      }
    }`
        : $columns === 3
        ? `
    & > * {
      width: calc(33.3% - ((${DESKTOP_SPACING} * 2) / 3));
      display: inline-block;
      vertical-align: top;
      &:not(:nth-child(3)) {
        margin-right: ${DESKTOP_SPACING};
      }
    }`
        : `
      `
    }
    & > *:last-child {
      margin-bottom: ${spacing.xl};
    }
  }
`,
)

FormLayout.testid = 'formLayout'

export function FormLayout({ columns, mobileColumns, ...props }: FormLayoutProps) {
  return (
    <FormLayoutStyled
      data-testid={composeTestID(FormLayout.testid, props.testid)}
      $columns={columns}
      $mobileColumns={mobileColumns}
    >
      {props.children}
    </FormLayoutStyled>
  )
}
