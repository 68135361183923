import color from "stablr/styles/color";
import fontFamily from "stablr/styles/fontFamily";
import fontSize from "stablr/styles/fontSize";
import fontWeight from "stablr/styles/fontWeight";
import input from "stablr/styles/input";
import media from "stablr/styles/media";
import spacing from "stablr/styles/spacing";
import { DefaultTheme } from "styled-components";

type KeyValue = { [x: string]: string | KeyValue };
type KeyValueNum = { [x: string]: number };

declare module "styled-components" {
  export interface DefaultTheme {
    spacing: KeyValue;
    fontFamily: KeyValue;
    fontSize: KeyValue;
    fontWeight: KeyValueNum;
    color: KeyValue;
    media: KeyValue;
    input: KeyValue;
  }
}

const defaultTheme: DefaultTheme = {
  media,
  spacing,
  fontFamily,
  fontSize,
  fontWeight,
  color,
  input,
};

export default defaultTheme;
