import { Config } from "config";
import BankWalletBoxes from "pages/BuySell/components/BankWalletBoxes";
import * as React from "react";
import { Field } from "react-final-form";
import { CurrencyInput, FormLayout, media, parseCurrency, Spacer, spacing, TextInput, useIsMobile } from "stablr";
import styled from "styled-components";
import { APIBankAccount } from "types/API/BankAccount";
import { APISellTransactionTable } from "types/API/Transaction";

import { SELL_ORDER_SCHEMA } from "./schemaValidation";

interface SellOrderFormProps {
  transaction: APISellTransactionTable;
  bank?: APIBankAccount;
  isLoadingBank: boolean;
  selectedNetwork: string;
}

const Container = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const SellFormWrapperStyled = styled.div`
  padding: 0 ${spacing.xl};
  box-shadow: 0 1px 4px 0 #0000001a;
  flex: 1;
  margin-top: ${spacing.m};
  border-radius: 20px;

  & > * {
    max-width: none;
  }

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

export default function SellOrderForm({ transaction, bank, isLoadingBank, selectedNetwork }: SellOrderFormProps) {
  const isMobile = useIsMobile();

  const parseBalanceInput = (value: string) => {
    return parseCurrency(value, Config.fallbackDenominator);
  };

  return (
    <>
      <Container>
        <BankWalletBoxes
          transaction={transaction}
          bank={bank}
          isLoadingBank={isLoadingBank}
          selectedNetwork={selectedNetwork}
        />
      </Container>
      <Spacer height={spacing.l} />
      <SellFormWrapperStyled>
        <FormLayout columns={isMobile ? 1 : 2}>
          <Field
            name={SELL_ORDER_SCHEMA.SELL}
            compact
            label="You Sell"
            inputType="EURR"
            component={CurrencyInput}
            parse={parseBalanceInput}
            maxWidth
          />
          <Field
            name={SELL_ORDER_SCHEMA.REFERENCE}
            compact
            tooltip={"Here you can add a note to the transaction for example a reference number 123AB"}
            label="Notes (Optional)"
            placeholder="Notes"
            component={TextInput}
            maxWidth
          />
        </FormLayout>
      </SellFormWrapperStyled>
      <Spacer height={spacing.l} />
    </>
  );
}
