import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIAPIKey } from "types/API/APIManagement";

useAPIKey.queryKey = "apiKey";

export function useAPIKey(apiKeyId: string) {
  return useQuery(
    [useAPIKey.queryKey, apiKeyId],
    async () => api.APIManagement.getAPIKeyById(apiKeyId) as Promise<APIAPIKey>
  );
}
