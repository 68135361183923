import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useAPIKey } from "./use-api-key";
import useAPIKeys from "./use-api-keys";

export function useRevokeAPIKey() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();
  return useMutation({
    mutationFn: async ({ apiKeyId }: { apiKeyId: string }) => api.APIManagement.revokeAPIKey(apiKeyId),
    onSuccess: async (_: unknown, { apiKeyId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useAPIKeys.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useAPIKey.queryKey, apiKeyId] });
      successToast({ message: `API Key has been revoked successfully.` });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
