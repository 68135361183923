import { APIWebookStatus } from "types/API/Webhooks";

export function getAPIWebhookVarient(value: APIWebookStatus) {
  switch (value) {
    case "Enabled":
      return "success";
    case "Disabled":
      return "error";
    default:
      return "default";
  }
}
