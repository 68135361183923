import React, { useState } from 'react'
import { ReactComponent as AddSVG } from 'stablr/assets/icons/add.svg'
import { color, fontWeight, input, spacing } from 'stablr/styles'
import styled from 'styled-components'

import { Icon, Spacer } from '../..'
import { FileTypeIcon } from '../FileTypeIcon'

const FILE_UPLOAD_HEIGHT = '175px'

const FileUploadWrapperStyled = styled.div`
  width: 100%;
  text-align: right;
  max-width: ${input.maxWidth};
`

const FileUploadContainerStyled = styled.div`
  border-radius: 3px;
  border: 0.5px solid ${input.borderColor};
  padding: 20px;
  cursor: pointer;
  width: 100%;
  height: ${FILE_UPLOAD_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlaceholderStyled = styled.div`
  color: ${color.greyscale.grey5};
  display: flex;
  text-align: center;
  align-items: center;
  p {
    text-decoration: underline;
  }
`

const SelectedFileStyled = styled.div`
  display: flex;
`

const SelectedFileTextStyled = styled.div`
  margin-left: ${spacing.xs};
  text-align: left;
  margin: ${spacing.xs} ${spacing.xs};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
  span {
    font-weight: ${fontWeight.semiBold};
    display: block;
    margin-bottom: ${spacing.xxs};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    color: ${color.greyscale.grey5};
    margin: 0;
    display: block;
  }
`

const RemoveButtonStyled = styled.button`
  color: ${color.palette.red};
  text-decoration: underline;
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
  padding: ${spacing.xs} 0;
`

const FileUploadBottomBarStyled = styled.div`
  display: flex;
  align-items: flex-start;
`

export type FileUploadDataType = { name: string; type: string; size: number }

export interface FileUploadProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  placeholder?: string
  value?: FileUploadDataType
  onChange: (input?: FileUploadDataType) => void
  errorElement?: React.ReactElement
}

export function FileUpload({
  placeholder,
  value,
  onChange,
  errorElement,
  ...rest
}: FileUploadProps) {
  const [isDragOver, setIsDragOver] = useState(false)

  const fileExists = value !== undefined && typeof value === 'object'

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = (e: any) => {
    e.preventDefault()
    setIsDragOver(false)
  }

  const handleDrop = (e: any) => {
    e.preventDefault()
    setIsDragOver(false)
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      const file = files[0]
      onChange && onChange(file)
    }
    rest.onFocus && rest.onFocus(e)
  }

  const handleFileChange = (e: any) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const file = files[0]
      onChange && onChange(file)
    }
    rest.onFocus && rest.onFocus(e)
  }

  const handleClick = (e: any) => {
    const fileInput = document.getElementById('fileInput')
    if (fileInput) {
      fileInput.click()
    }
    rest.onBlur && rest.onBlur(e)
  }

  const handleRemove = (e: any) => {
    onChange && onChange(undefined)
    rest.onBlur && rest.onBlur(e)
  }

  return (
    <FileUploadWrapperStyled>
      <FileUploadContainerStyled
        onDragOver={handleDragEnter}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={!fileExists ? handleClick : undefined}
      >
        <input
          id="fileInput"
          {...rest}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {fileExists ? (
          <SelectedFileStyled>
            <FileTypeIcon type={value.type} />
            <SelectedFileTextStyled>
              <span>{value.name}</span>
              <p>You uploaded just now</p>
            </SelectedFileTextStyled>
          </SelectedFileStyled>
        ) : isDragOver ? (
          <p>Drop the file here</p>
        ) : (
          <PlaceholderStyled>
            <Icon color={color.greyscale.grey5}>
              <AddSVG />
            </Icon>
            <Spacer width={spacing.s} />
            <p>{placeholder ?? 'Click or drag and drop a file to upload'}</p>
          </PlaceholderStyled>
        )}
      </FileUploadContainerStyled>
      <FileUploadBottomBarStyled>
        {errorElement}
        {fileExists ? (
          <RemoveButtonStyled onClick={handleRemove}>Remove</RemoveButtonStyled>
        ) : (
          <></>
        )}
      </FileUploadBottomBarStyled>
    </FileUploadWrapperStyled>
  )
}
