import React from 'react'

export interface PermissionInterface {
  userRoles: string[]
  permissions?: { [key: string]: string[] }
}

export const EMPTY_PERMISSION_DATA = {
  userRoles: [],
  permissions: {},
}

const PermissionData = React.createContext<PermissionInterface>(EMPTY_PERMISSION_DATA)

export default PermissionData
