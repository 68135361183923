// import { APICreateBuyTransactionPayload, APICreateSellTransactionResponse } from "api/Transaction"; // Having troube getting or types to comply
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { asyncSleep } from "refactor/async-sleep";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import useTransactions from "./use-transactions";

type CreateTransactionOptions = {
  type: "buy" | "sell";
  WalletAddress: string;
  ClientReference: string;
  // ClientOfferId: string;
  FiatAmount?: string;
  DigitalAmount?: string;
};

export function useCreateTransaction() {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToast();

  return useMutation({
    mutationFn: async ({ type, WalletAddress, FiatAmount, DigitalAmount, ClientReference }: CreateTransactionOptions) =>
      type === "buy"
        ? api.Transaction.createBuyTransaction({
            WalletAddress,
            FiatAmount: FiatAmount ?? "0",
            ClientReference,
          })
        : type === "sell"
        ? api.Transaction.createSellTransaction({
            WalletAddress,
            DigitalAmount: DigitalAmount ?? "0",
            ClientReference,
          })
        : undefined,
    onSuccess: async () => {
      await asyncSleep(5000);
      await queryClient?.invalidateQueries({ queryKey: [useTransactions.queryKey] });
      successToast({ message: "Transaction has been created" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
