import useDownloadPayment from "hooks/query/payment/use-download-payment";
import React from "react";
import { ReactComponent as DownloadIcon } from "stablr/assets/icons/download.svg";
import { Button } from "stablr/components/atoms";
import { useIsMobile } from "stablr/hooks";
import color from "stablr/styles/color";

export default function PaymentDownload({ paymentReference }: { paymentReference: string }) {
  const { isFetching, refetch } = useDownloadPayment(paymentReference);

  const isMobile = useIsMobile();

  return (
    <Button
      icon={<DownloadIcon />}
      iconSize={20}
      varient={isMobile ? "gradient" : "line"}
      borderColor={isMobile ? color.other.transparent : color.greyscale.grey4}
      backgroundColor={isMobile ? color.palette.purple : color.greyscale.white}
      iconColor={isMobile ? color.greyscale.white : color.palette.purple}
      layout={isMobile ? "icon-only" : undefined}
      marginHorizontalSize="small"
      size="small"
      disabled={isFetching}
      onClick={() => refetch()}
      loading={isFetching}
    >
      Download
    </Button>
  );
}
