import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { color } from 'stablr/styles'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  testid?: string
  readOnly?: boolean
  children?: React.ReactNode
  appendText?: string
}

const LabelStyled = styled.label<{ readOnly?: boolean }>`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.semiBold};
  padding: ${spacing.s} 0;
  display: block;
  text-align: left;
  opacity: ${(props) => (props.readOnly ? '0.5' : '1')};
`

const AppendTextStyled = styled.span`
  margin-left: ${spacing.xs};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.semiBold};
  color: ${color.greyscale.grey5};
`

Label.testid = 'label'

export function Label({ testid, appendText, children, ...props }: LabelProps) {
  return (
    <LabelStyled {...props} data-testid={composeTestID(Label.testid, testid)}>
      {children}
      {appendText ? <AppendTextStyled>{appendText}</AppendTextStyled> : <></>}
    </LabelStyled>
  )
}
