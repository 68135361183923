import { ReactComponent as StablRLogoMark } from "assets/svg/logo-mark-white.svg";
import { ReactComponent as StablRLogo } from "assets/svg/logo-stablr.svg";
import { resetPassword } from "aws-amplify/auth";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as EmailIcon } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as LockIconSVG } from "stablr/assets/icons/lock.svg";
import { Button, Heading, Paragraph } from "stablr/components/atoms";
import { ErrorMessage, FeatureIcon, TextInput } from "stablr/components/molecules";
import { HalfHalfResponsive } from "stablr/components/templates";
import { composeTestID, getErrorCode, getErrorMessage } from "stablr/functions";
import { color } from "stablr/styles";
import styled from "styled-components";

interface ResetPasswordProps {
  testid?: string;
}

interface ResetPasswordFormValues {
  email: string;
  password: string;
}

const ResetPasswordStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

ResetPasswordComponent.testid = "ResetPassword";

export default function ResetPasswordComponent({ ...props }: ResetPasswordProps) {
  const [sendSuccess, setSendSuccess] = React.useState(false);

  const handleOnSubmit = async (values: ResetPasswordFormValues) => {
    setSendSuccess(false);
    try {
      await resetPassword({ username: values.email });
      setSendSuccess(true);
    } catch (err) {
      if (getErrorCode(err) === "UserNotFoundException") {
        return;
      }
      return { [FORM_ERROR]: getErrorMessage(err) };
    }
  };

  return (
    <ResetPasswordStyled data-testid={composeTestID(ResetPasswordComponent.testid, props.testid)}>
      <HalfHalfResponsive logo={StablRLogo} logoMark={<StablRLogoMark />}>
        <Form onSubmit={handleOnSubmit}>
          {({ handleSubmit, submitError, values }) => (
            <>
              <FeatureIcon icon={<LockIconSVG />} />
              <Heading as="h1" varient="h2">
                Reset Password
              </Heading>
              <Paragraph>
                Enter the email associated with your account and we’ll send an email with instructions to reset your
                password.
              </Paragraph>
              <Field
                name="email"
                label="Email Address"
                parse={(value: string) => value.toLowerCase()}
                icon={<EmailIcon />}
                component={TextInput}
                placeholder="email@stablr.com"
              ></Field>
              <Button width="100%" onClick={handleSubmit}>
                Send Instructions
              </Button>
              {submitError ? <ErrorMessage>{submitError}</ErrorMessage> : <></>}
              {sendSuccess ? (
                <Paragraph color={color.theme.primary}>
                  Reset link sent to <b>{values.email}</b>.
                </Paragraph>
              ) : (
                <></>
              )}
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </ResetPasswordStyled>
  );
}
