import { useQuery } from "@tanstack/react-query";
import { setUpTOTP } from "aws-amplify/auth";

useTOTPSecret.queryKey = "useTOTPSecret";

export function useTOTPSecret(enabled?: boolean) {
  return useQuery(
    [useTOTPSecret.queryKey],
    async () => {
      const secretCode = await setUpTOTP();
      return secretCode;
    },
    { enabled: enabled ?? false, retry: false }
  );
}
