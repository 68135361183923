import * as React from 'react'
import styled from 'styled-components'

export interface TableSpacerProps {
  height: string
}

const TableSpacerStyled = styled.tbody`
  width: 100%;
  & > tr {
    width: 100%;
  }
`

export function TableSpacer({ height }: TableSpacerProps) {
  return (
    <TableSpacerStyled>
      <tr style={{ height }}></tr>
    </TableSpacerStyled>
  )
}
