import { Tab } from '@headlessui/react'
import React from 'react'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

const TabPanelsStyled = styled(Tab.Panels)`
  @media (${media.mobile}) {
    padding: ${spacing.s} ${spacing.l};
  }
`

TabPannels.testid = 'tabPannels'

export function TabPannels({ panels }: { panels: React.ReactElement[] }) {
  return (
    <Tab.Panels as={TabPanelsStyled} data-testid={TabPannels.testid}>
      {panels.map((panel, id) => (
        <Tab.Panel key={id}>{panel}</Tab.Panel>
      ))}
    </Tab.Panels>
  )
}
