import * as React from 'react'
import { useState } from 'react'
import { ReactComponent as ChevronDownIcon } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from 'stablr/assets/icons/chevron-up.svg'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface TableRowExpandableProps {
  children?: React.ReactElement[] | React.ReactElement
  expandableContent?: React.ReactElement[] | React.ReactElement
}

const TableRowExpandableStyled = styled.tr`
  position: relative;

  &:not(:last-child) {
    & > td {
      border-bottom: 1px solid ${color.greyscale.grey3};
    }
  }
`

const ActionIconStyled = styled.td`
  padding: 0 ${spacing.m};
`

const ExpandableContentStyled = styled.tr`
  background-color: ${color.greyscale.grey1};
  & > td {
    padding: ${spacing.s} ${spacing.s};
  }
  height: 86px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  width: 100%;
`

export function TableRowExpandable({ children, expandableContent }: TableRowExpandableProps) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <TableRowExpandableStyled>
        {children}
        <ActionIconStyled onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </ActionIconStyled>
      </TableRowExpandableStyled>
      {isOpen && (
        <ExpandableContentStyled>
          <td colSpan={10000}>{expandableContent}</td>
        </ExpandableContentStyled>
      )}
    </>
  )
}
