import { useWeb3Modal } from "@web3modal/react";
import React from "react";
import { Button } from "stablr";
import { ReactComponent as AddCircleSVG } from "stablr/assets/icons/add-circle-outline.svg";
import { useAccount, useDisconnect } from "wagmi";

import { transformWalletAddress } from "../../../functions/transform-wallet-address";

export function WalletConnectButton() {
  const { open } = useWeb3Modal();
  const { disconnectAsync, isLoading: isLoadingDisconnect } = useDisconnect();
  const { isConnected, address } = useAccount();

  const connectWallet = async () => {
    open();
  };

  const disconnectWallet = async () => {
    await disconnectAsync();
  };

  return (
    <Button
      varient={!isConnected ? "line" : "line"}
      loading={isLoadingDisconnect}
      icon={isConnected ? undefined : <AddCircleSVG />}
      onClick={!isConnected ? connectWallet : disconnectWallet}
    >
      {!isConnected ? "Connect Wallet" : `Disconnect (${transformWalletAddress(address)})`}
    </Button>
  );
}
