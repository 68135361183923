import { PublicKey } from "@solana/web3.js";
import Web3 from "web3";
import * as yup from "yup";

export enum WALLET_SCHEMA {
  WALLET_TYPE = "Type",
  WALLET_NAME = "Name",
  WALLET_ADDRESS = "Address",
  WALLET_NETWORK = "Network",
}

/**
 * Validation 2.1: Create Wallet
 */
const validateSolanaAddress = (address?: string) => {
  try {
    if (!address) return true;
    new PublicKey(address);
    return true;
  } catch (error) {
    return false;
  }
};

export const WalletSchemaValidation = yup.object({
  [WALLET_SCHEMA.WALLET_TYPE]: yup.string().required("Wallet Type is required"),
  [WALLET_SCHEMA.WALLET_ADDRESS]: yup
    .string()
    .required("Wallet Address is required")
    .test("validateWallet", function (value) {
      const { Network } = this.parent;

      if (!value) return false;

      const errorMessage =
        Network === "Ethereum" ? "Invalid ERC20 Wallet Address" : "Invalid Wallet Address";

      if (Network === "Solana") {
        return validateSolanaAddress(value) || this.createError({ message: errorMessage });
      } else if (Network === "Ethereum") {
        return Web3.utils.isAddress(value) || this.createError({ message: errorMessage });
      }

      return this.createError({ message: errorMessage });
    }),
  [WALLET_SCHEMA.WALLET_NAME]: yup
    .string()
    .required("Wallet Name is required")
    .test("maxLength", "Wallet name cannot be more than 50 characters", (value?: string) => (value?.length ?? 0) <= 50),
});
