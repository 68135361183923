import { useQuery } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";
import { APIFileResponse, APIFileType } from "types/API/File";

useDownloadFile.queryKey = "download-file";

export default function useDownloadFile(fileId: string, fileType: APIFileType) {
  const { errorToast, successToast } = useToast();

  return useQuery([useDownloadFile.queryKey, fileType, fileId], async () => api.Files.downloadFile(fileId, fileType), {
    enabled: false,
    retry: false,
    onSuccess: (response: APIFileResponse) => {
      window.open(response.SignedUrl);
      successToast({ message: "Successfully downloaded the file", title: "File Download" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error), title: "File Download Failed" });
    },
  });
}
