import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APICreateAPIKey } from "types/API/APIManagement";

import useAPIKeys from "./use-api-keys";

export function useCreateAPIKey() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(
    (apiKey: APICreateAPIKey) => {
      return api.APIManagement.createAPIKey(apiKey);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [useAPIKeys.queryKey] });
        successToast({ message: "API Key has been created successfully" });
      },
    }
  );
}
