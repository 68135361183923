import { useWallets } from "hooks/query/wallet/use-wallets";
import React from "react";
import { Field } from "react-final-form";
import { ReactComponent as PeopleIcon } from "stablr/assets/icons/people.svg";

import { BUY_ORDER_SCHEMA } from "../Buy/common/schemaValidation";
import { ListBoxInputNoLabel } from "./ListBoxInputNoLabel";

WalletInput.testid = "WalletInput";

export function WalletInput({ selectedNetwork }: { selectedNetwork: string }) {
  const { data: wallets, isLoading } = useWallets();

  const walletOptions = React.useMemo(() => {
    return wallets?.length
      ? wallets
          .filter(wallet => wallet.Status === "APPROVED" && wallet.Network === selectedNetwork)
          .map(wallet => ({
            id: wallet.Id,
            label: wallet.Name,
            value: wallet,
          }))
      : [];
  }, [wallets, selectedNetwork]);

  return (
    <Field
      placeholder="Select Wallet"
      label="Select Wallet"
      iconElement={<PeopleIcon />}
      name={BUY_ORDER_SCHEMA.WALLET}
      component={ListBoxInputNoLabel}
      options={walletOptions}
      loading={isLoading}
    />
  );
}
