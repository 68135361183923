// import { ReactComponent as EditIcon } from "stablr/assets/icons/edit.svg";
// import Button from "components/atoms/Button/Button";
// import RenderWithPermissions from "components/atoms/Permission";
import { Config } from "config";
import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { ButtonContainer, CopyInputText, TextInput } from "stablr/components/molecules";
import { Accordion } from "stablr/components/organisms";
import { formatCurrency } from "stablr/functions";
import { useIsDesktop } from "stablr/hooks";
import { BadgeVarients } from "stablr/types";
// import { useNavigate } from "react-router-dom";
// import { color } from "stablr/styles";;
import { APIWallet, APIWalletListItem } from "types/API/Wallet";

// import ApproveWallet from "../common/ApproveWallet";
import DeleteWallet from "../common/DeleteWallet";
// import RejectWallet from "../common/RejectWallet";
import { WALLET_SCHEMA } from "../common/schemaValidation";

const STATUSES = {
  PENDING: { varient: "info", label: "Pending" },
  REJECTED: { varient: "error", label: "Rejected" },
  APPROVED: { varient: "success", label: "Approved" },
  DELETED: { varient: "error", label: "Deleted" },
};

interface WalletDetailProps {
  varient?: string;
  data: APIWalletListItem;
}

export default function WalletDetail({ data }: WalletDetailProps) {
  // const navigation = useNavigate();
  const { varient: statusVarient, label: statusLabel } = useMemo(
    () => STATUSES[data.Status] ?? { varient: undefined, label: data.Status ?? "No Status" },
    [data.Status]
  );
  const isDesktop = useIsDesktop();

  const handleSubmit = (data: APIWallet) => data;

  // const onEdit = (event: React.MouseEvent<HTMLElement>) => {
  //   event?.preventDefault();
  //   navigation(`./${walletId}/edit`);
  // };

  // const editWallet = (
  //   <RenderWithPermissions permission={PERMISSION_ENUM.UPDATE_WALLETS}>
  //     <Button
  //       varient="secondary"
  //       color={color.palette.blue}
  //       icon={<EditIcon />}
  //       iconSize={20}
  //       marginHorizontalSize="small"
  //       onClick={onEdit}
  //     >
  //       Edit
  //     </Button>
  //   </RenderWithPermissions>
  // );

  return (
    <Accordion
      title={data.Name}
      status={{ text: statusLabel, varient: statusVarient as BadgeVarients }}
      description={`${formatCurrency(
        {
          Amount: data.Balance.Amount,
          Denominator: Config.fallbackDenominator,
        },
        2
      )} EURR`}
      actions={
        <>
          {isDesktop && (
            <>
              {/* {editWallet} */}
              <DeleteWallet title={data.Name} id={data.Id} />
            </>
          )}
        </>
      }
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...data,
        }}
      >
        {() => {
          return (
            <>
              <Field
                name={WALLET_SCHEMA.WALLET_ADDRESS}
                label="Wallet Address"
                placeholder="Wallet Address"
                component={CopyInputText}
                readOnly
              />
              <Field
                name={WALLET_SCHEMA.WALLET_TYPE}
                label="Wallet Type"
                placeholder="Wallet Type"
                component={TextInput}
                readOnly
                compact
              />
              <ButtonContainer>
                <>
                  {((!isDesktop && data.Status === "APPROVED") || (!isDesktop && data.Status === "PENDING")) && (
                    <DeleteWallet title={data.Name} id={data.Id} isPending={data.Status === "PENDING"} />
                  )}
                  {/* {!isDesktop && status == 1 && editWallet} */}
                  {/* {data.Status == "1" && <ApproveWallet title={data.name} id={data.walletId} />} */}
                  {/* {[1, 2].includes(Number(data.Status)) && <RejectWallet walletId={data.walletId} title={data.name} />} */}
                </>
              </ButtonContainer>
            </>
          );
        }}
      </Form>
    </Accordion>
  );
}
