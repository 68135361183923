import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import colorStyle from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface InputNoteProps extends React.HTMLAttributes<HTMLParagraphElement> {
  testid?: string
  color?: string
  htmlFor: string
}

const InputNoteStyled = styled.p(
  ({ color }: Pick<InputNoteProps, 'color'>) => `
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.p};
    color: ${color ?? colorStyle.greyscale.greyDark1};
    line-height: 21px;
    min-height: 21px;
    font-weight: ${fontWeight.regular};
    margin: ${spacing.xs} 0 ${spacing.s} 0;
    text-align: left;
    display: flex;
    width: 100%;
  `,
)

InputNote.testid = 'InputNote'

export function InputNote({ ...props }: InputNoteProps) {
  return (
    <InputNoteStyled
      data-testid={composeTestID(InputNote.testid, props.testid)}
      {...props}
    ></InputNoteStyled>
  )
}
