import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APIWalletListItem } from "types/API/Wallet";

useWallets.queryKey = "wallets";

export function useWallets() {
  return useQuery([useWallets.queryKey], async () => api.Wallet.getWalletList() as Promise<APIWalletListItem[]>, {
    notifyOnChangeProps: "all",
  });
}
