const MOCK_REQUEST_TIMEOUT_MS = 500;

const mockRequest =
  (type: string) =>
  async <T>(url: string, responseObject?: T): Promise<T> => {
    console.log(`Requesting Mock (${type}): `, url); // eslint-disable-line no-console
    await timeout(MOCK_REQUEST_TIMEOUT_MS);
    return responseObject as T;
  };

const mockSocketRequest =
  (type: string) =>
  async <T>(url: string, responseObject?: T, _message?: string, _failed?: boolean): Promise<T> => {
    console.log(`Requesting Mock (${type}_SOCKET): `, url); // eslint-disable-line no-console
    await timeout(MOCK_REQUEST_TIMEOUT_MS);
    return responseObject as T;
  };

export const get = mockRequest("GET");
export const post = mockRequest("POST");
export const put = mockRequest("PUT");
export const patch = mockRequest("PATCH");
export const del = mockRequest("DELETE");

export const getSocket = mockSocketRequest("GET");
export const postSocket = mockSocketRequest("POST");
export const putSocket = mockSocketRequest("PUT");
export const patchSocket = mockSocketRequest("PATCH");
export const deleteSocket = mockSocketRequest("DELETE");

export const throwError = <T>(code?: number, message?: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    const errorMessage = message ?? "This is a mocked error";
    // Simulate an error response
    const errorResponse = {
      status: code ?? 500,
      statusText: "Not Found",
      data: { message: errorMessage },
    };

    setTimeout(() => {
      // Reject the promise with the simulated error response
      reject({ response: errorResponse, message: errorMessage });
    }, MOCK_REQUEST_TIMEOUT_MS);
  });
};

export const error = async (_?: unknown, __?: unknown) => {
  await timeout(MOCK_REQUEST_TIMEOUT_MS);
  throw new Error("This is a mocked error");
};

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
