import { useQuery } from "@tanstack/react-query";
import api from "api";

useWebhooks.queryKey = "webhooks";

export default function useWebhooks() {
  return useQuery([useWebhooks.queryKey], async () => api.Webhooks.getWebhooksList(), {
    notifyOnChangeProps: "all",
  });
}
