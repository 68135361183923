export type APIAmplifyError = {
  response: {
    status: number
  }
  code: string
}

export function getErrorStatus(error: unknown) {
  // Realy just had to use 'any' here, no other alternative I could find
  return (error as APIAmplifyError)?.response?.status
}
