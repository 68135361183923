import { Tab } from '@headlessui/react'
import React from 'react'
import { ReactComponent as ChevronDownSVG } from 'stablr/assets/icons/chevron-down.svg'
import { Icon } from 'stablr/components/atoms'
import { TabListItem } from 'stablr/components/molecules/Tabs'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

const TAB_SUB_MENU_WIDTH = '220px;'

const TabStyled = styled.button<{ $isSelected: boolean }>`
  font-size: 18px;
  background-color: ${color.other.transparent};
  padding: ${spacing.m} calc(${spacing.m} + ${spacing.xs});
  border: 0;
  display: inline-block;
  position: relative;
  overflow: visible;
  cursor: pointer;

  :focus-visible {
    outline: none;
  }

  &:first-child {
    padding-left: ${spacing.m};
  }

  &:last-child {
    padding-right: ${spacing.m};
  }

  &[aria-selected='true'],
  &.--child-selected {
    background-color: ${color.palette.lightPurple};
    color: ${color.palette.purple};
    border-bottom: 2px solid ${color.palette.purple};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${color.palette.purple}; // Prevent container width change
  }

  &:hover {
    background-color: ${color.palette.lightPurple};
  }
`

const TabWithOptionsStyled = styled(TabStyled)`
  text-align: left;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`

const ChildTabStyled = styled(TabStyled)`
  border-bottom: 1px solid ${color.greyscale.grey2};
  text-align: left;
  padding-left: ${spacing.m};
  padding-right: ${spacing.m};
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: ${color.greyscale.black}; // Prevent container width change

  &[aria-selected='true'] {
    background-color: ${color.palette.lightPurple};
    color: ${color.palette.purple};
    border: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${color.palette.purple}; // Prevent container width change
  }

  &:hover {
    background-color: ${color.palette.lightPurple};
  }
`

const TabOptionsMenuStyled = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  width: ${TAB_SUB_MENU_WIDTH};
  min-height: 10px;
  z-index: 1;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${color.greyscale.white};
  display: flex;
  flex-direction: column;
  border-radius: 7px;

  & > * {
    margin: 0;
  }
`

const TabButtonTextStyled = styled.div`
  display: inline-block;
`

const IconWrapper = styled.span`
  margin-left: ${spacing.s};
`

export const TabButton = ({
  title,
  tabKey,
  testid,
  options,
  selected,
}: {
  title: string
  tabKey: string
  testid?: string
  options?: Omit<TabListItem, 'options'>[]
  selected?: string
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  if (options !== undefined) {
    const isChildSelected = options.find((option) => option.value === selected) !== undefined

    return (
      <TabWithOptionsStyled
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
        className={isChildSelected ? '--child-selected' : undefined}
        $isSelected={isChildSelected}
      >
        <TabButtonTextStyled>
          {title}
          <IconWrapper>
            <Icon color={color.theme.primary}>
              <ChevronDownSVG />
            </Icon>
          </IconWrapper>
        </TabButtonTextStyled>
        <TabOptionsMenuStyled $isOpen={isOpen}>
          {options.map((option) => (
            <Tab
              key={option.value}
              as={ChildTabStyled}
              data-testid={composeTestID(TabButton.testid, testid)}
              value={option.value}
              data-headlessui-state={selected === option.value ? 'selected' : undefined}
            >
              {option.label}
            </Tab>
          ))}
        </TabOptionsMenuStyled>
      </TabWithOptionsStyled>
    )
  }

  return (
    <Tab
      as={TabStyled}
      data-testid={composeTestID(TabButton.testid, testid)}
      value={tabKey}
      data-headlessui-state={selected === tabKey ? 'selected' : undefined}
    >
      {title}
    </Tab>
  )
}

TabButton.testid = 'tabButton'
