import { spacing } from 'stablr/styles'
import styled from 'styled-components'

export interface TableExpandableContentProps {
  children: React.ReactElement | React.ReactElement[]
  buttonElements?: React.ReactElement | React.ReactElement[]
}

const TableExpandableContentStyled = styled.div`
  display: flex;
  position: relative;
`

const TableExpandableChildrenStyled = styled.div`
  flex: 1;
`

const TableExpandableButtonContainerStyled = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  padding: 0 ${spacing.s};
`

export function TableExpandableContent({ children, buttonElements }: TableExpandableContentProps) {
  return (
    <TableExpandableContentStyled>
      <TableExpandableChildrenStyled>{children}</TableExpandableChildrenStyled>
      {buttonElements && (
        <TableExpandableButtonContainerStyled>
          {buttonElements}
        </TableExpandableButtonContainerStyled>
      )}
    </TableExpandableContentStyled>
  )
}
