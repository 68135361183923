import BankWalletInfo from "pages/BuySell/Buy/common/SellBankWalletInfo";
import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import SellWizard from "pages/BuySell/components/SellWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BottomBar } from "stablr";
import { ReactComponent as InfoSVG } from "stablr/assets/icons/info.svg";
import { Button, Heading, Icon, Paragraph, Spacer } from "stablr/components/atoms";
import { color, media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import { FromWalletAddressTable } from "./components/FromWalletAddressTable";

interface PayProcessingScreenProps {
  transaction: APITransaction;
}

const PayProcessingScreenStyled = styled(BuySellContainer)`
  flex: 1;
  margin-top: -50px;
  padding: 0 ${spacing.xxl};
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  box-shadow: 0 1px 4px 0 #0000001a;
  background: ${color.greyscale.white};
`;

const ParagraphStyled = styled(Paragraph)`
  color: ${color.greyscale.black};
  padding: ${spacing.xs} ${spacing.xxl};
  margin-top: -10px;

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

const TextStyled = styled.span`
  padding-left: ${spacing.xs};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    padding: 0 ${spacing.m};
  }
`;

export default function PayProcessingScreen({ transaction }: PayProcessingScreenProps) {
  const navigate = useNavigate();

  const handleOnOpenTransactions = () => {
    navigate("/transactions");
  };

  return (
    <>
      <StepWizard
        title="3. Funds Pay Out"
        body="Your funds are awaiting to be paid out"
        variant={getTransactionBadgeVarient(transaction.Status)}
        statusText={parseTransactionBadgeText(transaction.Status)}
      />
      <SellWizard />
      <PayProcessingScreenStyled>
        <TextWrapper>
          <Heading as="h3" varient="h4">
            <IconContainer>
              <Icon color={color.palette.brightOrange} size={20}>
                <InfoSVG />
              </Icon>
              <TextStyled> When do you receive your funds?</TextStyled>
            </IconContainer>
          </Heading>
          <ParagraphStyled>
            Your funds are currently undergoing the redemption process, and you won&apos;t be able to take any actions
            until it&apos;s completed. Please be patient and you will see the funds in your bank account once the
            process is finished.
          </ParagraphStyled>
        </TextWrapper>
        <Spacer height={spacing.s} />
        <FromWalletAddressTable transaction={transaction} />
        <Spacer height={spacing.xl} />
        <BankWalletInfo transaction={transaction} />
        <Spacer height={spacing.s} />
        <BottomBar
          paddingHorizontal="0"
          rightElement={
            <>
              <Button varient="primary" onClick={handleOnOpenTransactions}>
                Open Transactions
              </Button>
            </>
          }
        ></BottomBar>
      </PayProcessingScreenStyled>
    </>
  );
}
