import { Config } from "config";
import CognitoGroup from "types/amplify/CognitoGroup";

export enum PERMISSION_ENUM {
  MENU_ACTION_CTA = "MENU_ACTION_CTA",
  BUY_EURR = "BUY_EURR",
  SELL_EURR = "SELL_EURR",
  VIEW_DASHBOARD = "VIEW_DASHBOARD",
  VIEW_TRANSACTIONS = "VIEW_TRANSACTIONS",
  VIEW_ACCOUNT = "VIEW_ACCOUNT",
  VIEW_USERS = "VIEW_USERS",
  ADD_USERS = "ADD_USERS",
  UPDATE_USERS = "UPDATE_USERS",
  DELETE_USERS = "DELETE_USERS",
  APPROVE_USERS = "APPROVE_USERS",
  REJECT_USERS = "REJECT_USERS",
  VIEW_WALLETS = "VIEW_WALLETS",
  ADD_WALLETS = "ADD_WALLETS",
  UPDATE_WALLETS = "UPDATE_WALLETS",
  DELETE_WALLETS = "DELETE_WALLETS",
  APPROVE_WALLETS = "APPROVE_WALLETS",
  REJECT_WALLETS = "REJECT_WALLETS",
  VIEW_BANK_ACCOUNTS = "VIEW_BANK_ACCOUNTS",
  ADD_BANK_ACCOUNTS = "ADD_BANK_ACCOUNTS",
  UPDATE_BANK_ACCOUNTS = "UPDATE_BANK_ACCOUNTS",
  DELETE_BANK_ACCOUNTS = "DELETE_BANK_ACCOUNTS",
  APPROVE_BANK_ACCOUNTS = "APPROVE_BANK_ACCOUNTS",
  REJECT_BANK_ACCOUNTS = "REJECT_BANK_ACCOUNTS",
  VIEW_PAYMENT_INSTRUCTIONS = "VIEW_PAYMENT_INSTRUCTIONS",
  DOWNLOAD_PAYMENT_INSTRUCTIONS = "DOWNLOAD_PAYMENT_INSTRUCTIONS",
  ADD_API_KEY = "ADD_API_KEY",
  REVOKE_API_KEY = "REVOKE_API_KEY",
  UPDATE_API_KEY = "UPDATE_API_KEY",
  VIEW_API_KEY = "VIEW_API_KEY",
  VIEW_WEBHOOKS = "VIEW_WEBHOOKS",
  DELETE_WEBHOOKS = "DELETE_WEBHOOKS",
  UPDATE_WEBHOOKS = "UPDATE_WEBHOOKS",
  ADD_WEBHOOKS = "ADD_WEBHOOKS",
}

const ADMIN = "Admin";
const TM = "TreasuryManager";
const TRADER = "Trader";

const DEFAULT_PERMISSIONS: { [x: string]: CognitoGroup[] } = {
  [PERMISSION_ENUM.BUY_EURR]: [TM, TRADER],
  [PERMISSION_ENUM.SELL_EURR]: [TM, TRADER],
  [PERMISSION_ENUM.VIEW_DASHBOARD]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_TRANSACTIONS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_ACCOUNT]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.VIEW_USERS]: [ADMIN],
  [PERMISSION_ENUM.UPDATE_USERS]: [ADMIN],
  [PERMISSION_ENUM.DELETE_USERS]: [ADMIN],
  [PERMISSION_ENUM.ADD_USERS]: [ADMIN],
  [PERMISSION_ENUM.VIEW_WALLETS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.ADD_WALLETS]: [TM],
  [PERMISSION_ENUM.DELETE_WALLETS]: [TM],
  [PERMISSION_ENUM.UPDATE_WALLETS]: [TM],
  [PERMISSION_ENUM.APPROVE_WALLETS]: [TM],
  [PERMISSION_ENUM.REJECT_WALLETS]: [TM],
  [PERMISSION_ENUM.VIEW_BANK_ACCOUNTS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.ADD_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.DELETE_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.UPDATE_BANK_ACCOUNTS]: [TM],
  [PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS]: [TM, TRADER, ADMIN],
  [PERMISSION_ENUM.DOWNLOAD_PAYMENT_INSTRUCTIONS]: [TM, TRADER],
  [PERMISSION_ENUM.ADD_API_KEY]: [ADMIN],
  [PERMISSION_ENUM.UPDATE_API_KEY]: [ADMIN],
  [PERMISSION_ENUM.REVOKE_API_KEY]: [ADMIN],
  [PERMISSION_ENUM.VIEW_API_KEY]: [ADMIN, TM, TRADER],
  [PERMISSION_ENUM.VIEW_WEBHOOKS]: [ADMIN],
  [PERMISSION_ENUM.UPDATE_WEBHOOKS]: [ADMIN],
  [PERMISSION_ENUM.ADD_WEBHOOKS]: [ADMIN],
  [PERMISSION_ENUM.DELETE_WEBHOOKS]: [ADMIN],
};

const DISABLE_API_MANAGEMENT: { [x: string]: CognitoGroup[] } = {
  [PERMISSION_ENUM.ADD_API_KEY]: [],
  [PERMISSION_ENUM.UPDATE_API_KEY]: [],
  [PERMISSION_ENUM.REVOKE_API_KEY]: [],
};

export const PERMISSIONS = {
  ...DEFAULT_PERMISSIONS,
  ...(Config.features.disableAPIManagement ? DISABLE_API_MANAGEMENT : {}),
};
