import { PERMISSION_ENUM } from "config/permissions";
import { useRevokeAPIKey } from "hooks/query/api-management/use-revoke-api-key";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Paragraph, Permission, Spacer } from "stablr/components/atoms";
import { Modal } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import { color, spacing } from "stablr/styles";

export default function RevokeAPIKeyButton({ apikeyName, id }: { apikeyName: string, id: string }) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { mutateAsync: mutateRevokeAPIKey, isLoading } = useRevokeAPIKey();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onRevoke = async () => {
    try {
      await mutateRevokeAPIKey({ apiKeyId: id });
      onCloseModal();
      navigate("/accounts/api-management");
    } catch (error) {
      return getErrorMessage(error);
    }
  };

  return (
    <Permission permission={PERMISSION_ENUM.REVOKE_API_KEY}>
      <Button
        varient={"line"}
        iconSize={20}
        borderColor={color.palette.red}
        color={color.palette.red}
        backgroundColor={color.greyscale.white}
        onClick={openModal}
      >
        Revoke
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        title={`Revoke API KEY ${apikeyName}`}
        modalBody={<Paragraph>{`Are you sure you want to revoke API Key ${apikeyName}?`}</Paragraph>}
        modalFooter={
          <>
            <Button
              loading={isLoading}
              color={color.greyscale.white}
              backgroundColor={color.palette.red}
              onClick={onRevoke}
              iconSize={20}
            >
              Revoke
            </Button>
            <Spacer width={spacing.m} />
            <Button
              onClick={onCloseModal}
              color={color.greyscale.black}
              backgroundColor={color.greyscale.white}
              marginHorizontalSize="small"
            >
              Cancel
            </Button>
          </>
        }
      />
    </Permission>
  );
}
