import { Config } from "config";
import useDownloadFile from "hooks/query/file/use-download-file";
import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { DownloadInput, TextInput } from "stablr/components/molecules";
import { Accordion, FormLayout } from "stablr/components/organisms";
import { useIsDesktop } from "stablr/hooks";
import { media, spacing } from "stablr/styles";
import { BadgeVarients } from "stablr/types/badge-variant";
import styled from "styled-components";
import { APIBankAccountListItem } from "types/API/BankAccount";
import { APIWalletListItem } from "types/API/Wallet";

import DeleteAccount from "../common/DeleteAccount";
import { BANK_ACCOUNT_SCHEMA } from "../common/schemaValidation";

interface AccountDetailProps extends APIBankAccountListItem {
  varient?: string;
  onRefresh: () => void;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.m} 0;

  @media (${media.desktop}) {
    justify-content: flex-start;

    & button:first-child {
      margin-right: ${spacing.xs};
      margin-bottom: 0;
    }
  }
`;

interface AccountDetail {
  name: string;
  label: string;
  placeholder: string;
  type?: Array<"BLINC" | "SEPA" | "SWIFT">;
}

const AccountFields: AccountDetail[] = [
  {
    name: BANK_ACCOUNT_SCHEMA.TYPE,
    label: "Type",
    placeholder: "Type",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME,
    label: "Bank Account Holder",
    placeholder: "Bank Account Holder",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.IBAN,
    label: "IBAN",
    placeholder: "IBAN",
    type: ["SWIFT", "SEPA"],
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BIC,
    label: "BIC/SWIFT",
    placeholder: "BIC/SWIFT",
    type: ["SWIFT", "SEPA"],
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BLINC_ID,
    label: "Blinc Id",
    placeholder: "Blinc Id",
    type: ["BLINC"],
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BANK_NAME,
    label: "Bank Name",
    placeholder: "Bank Name",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BANK_ADDRESS,
    label: "Bank Address",
    placeholder: "Bank Address",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.BANK_POSTAL_CODE,
    label: "Bank Postal Code",
    placeholder: "Bank Postal Code",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.CITY,
    label: "Bank City",
    placeholder: "City",
  },
  {
    name: BANK_ACCOUNT_SCHEMA.COUNTRY,
    label: "Bank Country",
    placeholder: "Country",
  },
];

const STATUSES = {
  PENDING: { varient: "info", label: "Pending" },
  REJECTED: { varient: "error", label: "Rejected" },
  APPROVED: { varient: "success", label: "Approved" },
  DELETED: { varient: "error", label: "Deleted" }, // Available Soon
};

const BANK_TYPE = {
  BLINC: "BLINC",
  SWIFT: "SWIFT",
  SEPA: "SEPA", // Backend reads as 1 on create and sends back as SEPA. This needs to be discussed for consistency
};

const disableBankAccountDelete = Config.features.disableBankAccountDelete;

export default function BankAccountDetail({ onRefresh, Id, Name, ...accountDetails }: AccountDetailProps) {
  const { refetch } = useDownloadFile(accountDetails.BankStatementFileId, "bank-statements");
  const isDesktop = useIsDesktop();

  const { varient: statusVarient, label: statusLabel } = useMemo(
    () => STATUSES[accountDetails.Status] ?? { varient: undefined, label: accountDetails.Status ?? "No Status" },
    [accountDetails.Status]
  );

  const handleSubmit = (data: APIWalletListItem) => data;

  return (
    <Accordion
      title={Name}
      status={{ text: statusLabel, varient: statusVarient as BadgeVarients }}
      actions={
        <>
          {isDesktop &&
            !disableBankAccountDelete &&
            (accountDetails.Status === "APPROVED" || accountDetails.Status === "PENDING") && (
              <DeleteAccount title={Name} id={Id} />
            )}
        </>
      } // Not Available yet
    >
      <>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            ...accountDetails,
            Id,
            Name,
            [BANK_ACCOUNT_SCHEMA.TYPE]: BANK_TYPE[accountDetails.Types[0]],
          }}
        >
          {() => {
            return (
              <FormLayout columns={2}>
                <>
                  {AccountFields.filter(
                    item => item?.type === undefined || item.type.includes(accountDetails.Types[0])
                  ).map(({ name, label, placeholder }) => (
                    <Field
                      key={name}
                      name={name}
                      label={label}
                      placeholder={placeholder}
                      component={TextInput}
                      readOnly
                      compact
                    />
                  ))}
                  <Field
                    label="Bank Account Statement"
                    readOnly
                    inputType="Download"
                    name={BANK_ACCOUNT_SCHEMA.BANK_STATEMENT_FILE_NAME}
                    value={BANK_ACCOUNT_SCHEMA.BANK_STATEMENT_FILE_ID}
                    component={DownloadInput}
                    onDownload={refetch}
                  />
                </>
              </FormLayout>
            );
          }}
        </Form>
        <ButtonContainer>
          {!isDesktop &&
            !disableBankAccountDelete &&
            (accountDetails.Status === "APPROVED" || accountDetails.Status === "PENDING") && (
              <DeleteAccount title={Name} id={Id} />
            )}
          {/* {Status === "pending_add" && <ApproveBankAccount accountId={Id} title={Name} />}
          {Status.toLowerCase() !== "verified" && <RejectBankAccount accountId={Id} />} */}
        </ButtonContainer>
      </>
    </Accordion>
  );
}
