import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { ReactComponent as CheckSVG } from 'stablr/assets/icons/check.svg'
import { ReactComponent as ChevronDownIcon } from 'stablr/assets/icons/chevron-down.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { fontFamily, fontSize, fontWeight } from 'stablr/styles'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../Icon'
import { Loader } from '../../Loader'

export type ListBoxOptionType = {
  label: string
  value: string
  testid?: string
  icon?: React.ReactElement
}

export interface ListBoxProps<T extends string | string[]>
  extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  options: ListBoxOptionType[]
  value: T
  defaultValue: T
  onChange: (value: T) => void
  name?: string
  varient?: ListBoxVarientType
  placeholder?: string
  multiple?: boolean
  loading?: boolean
  icon?: React.ReactElement
}

type ListBoxVarientType = 'form' | 'tab'

const INPUT_ICON_SIZE = 24

const HEIGHT = '60px'

const ListBoxStyled = styled(Listbox)`
  width: 100%;

  &.disabled {
    & button {
      background-color: ${color.greyscale.grey1};
      cursor: default;
    }
  }

  &.loading {
    & button {
      cursor: default;
    }
  }
`

const ListBoxContentStyled = styled.div`
  width: 100%;
  position: relative;
`

const ListButtonStyled = styled(Listbox.Button)`
  width: 100%;
  height: ${HEIGHT};
  padding: ${spacing.m};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${color.greyscale.black};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  text-align: left;

  &.varient-form {
    background-color: ${color.greyscale.white};
    border: solid ${color.greyscale.grey5} 1px;
    border-radius: 3px;
  }

  &.varient-tab {
    background-color: ${color.palette.lightPurple};
    border-bottom: 1px solid ${color.palette.purple};
  }

  &.placeholder {
    color: ${color.greyscale.grey5};
  }
`

const PlaceholderContainerStyled = styled.div`
  display: flex;
  align-items: center;
`
const IconStyled = styled(Icon)`
  padding-right: ${spacing.m};
`

const ListOptionsStyled = styled(Listbox.Options)`
  width: 100%;
  z-index: 1;
  background-color: ${color.greyscale.white};
  overflow-y: auto;
  position: absolute;
  max-height: min(450px, 60vh);
  color: ${color.greyscale.black};
  font-weight: ${fontWeight.default};

  &.varient-form {
    padding: 0;
    margin-top: ${spacing.xs};
    background-color: ${color.greyscale.white};
    border: solid ${color.greyscale.grey5} 1px;
    border-radius: 3px;
  }

  &.varient-tab {
    padding: 0 ${spacing.xs};
    margin-top: 0;
    border: none;
    box-shadow: ${spacing.xxs} ${spacing.xxs} ${spacing.s} 0 ${color.greyscale.grey2};
    border-radius: 0 0 ${spacing.s} ${spacing.s};
  }
`

const ListOptionStyled = styled(Listbox.Option)`
  list-style: none;
  cursor: pointer;

  &.varient-form {
    padding: ${spacing.m} ${spacing.m};

    & > span > div > svg > path {
      fill: ${color.greyscale
        .black}; // Control without using the active prop (List.Option) because it is unstablr for some reason
    }
    &:hover {
      background-color: ${color.palette.purple};
      color: ${color.greyscale.white};
      & > span > div > svg > path {
        fill: ${color.greyscale.white};
      }
    }
  }

  &.varient-tab {
    padding: ${spacing.m} ${spacing.m};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${color.greyscale.grey2};
  }
`

const ListOptionTextStyled = styled.span<{ $hasIcon: boolean }>`
  width: 100%;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  display: flex;
  align-items: center;
  padding-left: ${({ $hasIcon }) => ($hasIcon ? spacing.l : '0')};

  & > *:first-child {
    position: absolute;
    left: ${spacing.m};
  }

  &.multiple {
    padding-left: ${spacing.l};
  }
`

const LoaderWrapperStyled = styled.div`
  width: 14px;
  height: 14px;
  & > * {
    margin: -13px -13px;
  }
`

const SelectedValueContainerStyled = styled.div`
  display: flex;
  align-items: center;
`

const OptionIconStyled = styled.span`
  margin-right: ${spacing.m};
`

ListBox.testid = 'ListBox'
export function ListBox<T extends string | string[]>({
  varient = 'form',
  options,
  onChange,
  defaultValue,
  value,
  name,
  placeholder,
  multiple,
  disabled,
  loading = false,
  icon,
}: ListBoxProps<T>) {
  const selectedValue = React.useMemo(() => {
    if (multiple) {
      return typeof value === 'string'
        ? []
        : value.map((item) => options.find((option) => option.value === item))
    } else {
      return (
        options.find((option) => option.value === value) ??
        options.find((option) => option.value === defaultValue)
      )
    }
  }, [value])

  const displayValue = React.useMemo(() => {
    if (multiple) {
      return typeof value === 'string'
        ? []
        : value.map((item) => options.find((option) => option.value === item)?.label)
    } else {
      const selectedOption = options.find((option) => option.value === value)
      return selectedOption ? (
        <SelectedValueContainerStyled>
          {selectedOption.icon && <OptionIconStyled>{selectedOption.icon}</OptionIconStyled>}
          {selectedOption.label}
        </SelectedValueContainerStyled>
      ) : (
        <>
          <PlaceholderContainerStyled>
            {icon && <IconStyled size={INPUT_ICON_SIZE}>{icon}</IconStyled>}
            {placeholder}
          </PlaceholderContainerStyled>
        </>
      )
    }
  }, [value, options, icon, placeholder])

  const handleOnChange = (value: any | any[]) => {
    if (multiple) {
      const selectedValues: string[] = value.map((item: any) =>
        typeof item === 'string' ? item : item.value,
      ) as string[]
      onChange && onChange(selectedValues as T)
    } else {
      value && onChange(value.value)
    }
  }

  const showPlaceholder =
    (multiple && Array.isArray(selectedValue) && selectedValue.length === 0) ||
    (!multiple && !Array.isArray(selectedValue) && selectedValue === undefined)

  return (
    <ListBoxStyled
      value={selectedValue}
      name={name}
      onChange={handleOnChange}
      data-testid={ListBox.testid}
      multiple={multiple}
      disabled={disabled || loading}
      className={`${disabled ? 'disabled' : ''} ${loading ? 'loading' : ''}`}
    >
      <ListBoxContentStyled>
        <ListButtonStyled className={`varient-${varient} ${showPlaceholder ? 'placeholder' : ''}`}>
          <span>
            {Array.isArray(selectedValue) && Array.isArray(displayValue) ? (
              selectedValue.length ? (
                displayValue.join(', ')
              ) : (
                placeholder
              )
            ) : !Array.isArray(selectedValue) ? (
              displayValue
            ) : (
              <></> // This should never happen. This is a type issue for displayValue(array)
            )}
          </span>
          {loading ? (
            <LoaderWrapperStyled>
              <Loader />
            </LoaderWrapperStyled>
          ) : (
            <Icon color={color.theme.primary}>
              <ChevronDownIcon />
            </Icon>
          )}
        </ListButtonStyled>
        <Transition as={Fragment}>
          <ListOptionsStyled className={`varient-${varient}`}>
            {options.map((option, idx) => (
              <ListOptionStyled
                key={`${value}-${idx}`}
                data-testid={composeTestID(`${ListBox.testid}Option`, option.value)}
                value={option}
                className={`varient-${varient}`}
              >
                {({ selected }) => {
                  const key = option.value
                  const label = option.label
                  const optionIcon = option.icon
                  return (
                    <ListOptionTextStyled
                      key={key}
                      className={multiple ? 'multiple' : ''}
                      $hasIcon={!!optionIcon}
                    >
                      {multiple && <Icon>{selected && <CheckSVG />}</Icon>}
                      {optionIcon && <OptionIconStyled>{optionIcon}</OptionIconStyled>}
                      {label}
                    </ListOptionTextStyled>
                  )
                }}
              </ListOptionStyled>
            ))}
          </ListOptionsStyled>
        </Transition>
      </ListBoxContentStyled>
    </ListBoxStyled>
  )
}