import * as React from 'react'
import { ReactComponent as WizardStepComplete } from 'stablr/assets/icons/step-complete.svg'
import { ReactComponent as WizardStepCurrent } from 'stablr/assets/icons/step-current.svg'
import { ReactComponent as WizardStepIncomplete } from 'stablr/assets/icons/step-incomplete.svg'
import { Icon } from 'stablr/components/atoms/Icon'
import { media } from 'stablr/styles'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface WizardHorizontalNavigationStepProps {
  title?: string
  type?: 'incomplete' | 'complete' | 'current'
}

export const WIZARD_NAVIGATION_STEP_SVG_SIZE = '38px'
export const WIZARD_NAVIGATION_STEP_OVERFLOW_HEIGHT = spacing.xs
const HEIGHT = '80px'

const WizardHorizontalNavigationStepStyled = styled.li`
  position: relative;
  list-style: none;
  align-items: center;
  height: ${HEIGHT};

  & svg {
    width: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
    height: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  }

  &:not(:first-child, :last-child) {
    .text-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:first-child {
    .text-wrapper {
      left: 0;
    }
  }

  &:last-child {
    .text-wrapper {
      right: 0;
    }
  }
`

const WizardStepTextWrapper = styled.div`
  position: absolute;
  top: ${WIZARD_NAVIGATION_STEP_SVG_SIZE};
  white-space: nowrap;
`

const SpanStyled = styled.span`
  color: ${color.greyscale.grey8};
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.button};
  padding: ${spacing.s} 0;
  display: block;

  &[data-active='true'] {
    color: ${color.greyscale.black};
    color: ${color.greyscale.white};
  }
  @media ${media.mobile} {
   font-size: ${fontSize.xs};
  }
`

WizardHorizontalNavigationStep.testid = 'WizardHorizontalNavigationStep'

export function WizardHorizontalNavigationStep({
  title,
  type = 'complete',
}: WizardHorizontalNavigationStepProps) {
  let StepComponent

  switch (type) {
    case 'incomplete':
      StepComponent = WizardStepIncomplete
      break
    case 'current':
      StepComponent = WizardStepCurrent
      break
    case 'complete':
    default:
      StepComponent = WizardStepComplete
      break
  }

  return (
    <WizardHorizontalNavigationStepStyled data-active={type === 'complete'}>
      <Icon color={type === 'complete' ? color.theme.primary : color.greyscale.white}>
        <StepComponent />
      </Icon>
      {title !== undefined && (
        <WizardStepTextWrapper className="text-wrapper">
          <SpanStyled data-active={type !== 'incomplete'}>{title}</SpanStyled>
        </WizardStepTextWrapper>
      )}
    </WizardHorizontalNavigationStepStyled>
  )
}
