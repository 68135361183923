import { FORM_ERROR } from "final-form";
import useIssuers from "hooks/query/issuer/use-issuer";
import useConfirmTransaction from "hooks/query/transaction/use-confirm-transaction";
import BankWalletInfo from "pages/BuySell/Buy/common/SellBankWalletInfo";
import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import SellWizard from "pages/BuySell/components/SellWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import { transformWalletAddress } from "pages/BuySell/functions/transform-wallet-address";
import { WalletConnectButton } from "pages/BuySell/Sell/screens/components/WalletConnectButton";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Web3Service } from "services/web3";
import { Alert, Button, Heading, Loader, Paragraph, Spacer } from "stablr/components/atoms";
import { CardError, ErrorMessage, ExpandableListItem, Modal, TableGroup, TextInput } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { color, fontSize, media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";
import { useAccount } from "wagmi";

import { TransferConfirmationSchemaValidation } from "../common/validateTransactionSchemaValidation";
import OurWalletDetailsTable from "./components/OurWalletDetailsTable";
import { WalletTransferButton } from "./components/WalletTransferButton";

type PayPendingScreenProps = {
  transaction: APITransaction;
  currentBalance?: string;
  buy?: number;
  newBalance?: number;
};

type PayPendingValues = {
  transactionHash: string;
};

const PayPendingScreenStyled = styled(BuySellContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.xxl};
  margin-top: -80px;

  @media ${media.mobile} {
    padding: ${spacing.xxl};
  }
`;

const ParagraphStyled = styled.p`
  color: ${color.greyscale.black};
  font-size: ${fontSize.p};
  padding-bottom: ${spacing.l};
`;

const TableEmptyContainerStyled = styled.div`
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WalletButtonsContainerStyled = styled.div`
  width: 100%;
  display: flex;

  @media ${media.mobile} {
    flex-direction: column;
    gap: 15px;
  }
`;

const BoxWrapperStyled = styled.div`
  box-shadow: 0 1px 4px 0 #0000001a;
  border-radius: 20px;
  padding: ${spacing.m} ${spacing.l} ${spacing.xxl} ${spacing.l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${color.greyscale.white};
`;

const ExpandableListItemStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;

  & > li {
    & > button {
      padding-left: 0;
      display: inline;

      & > div {
        & > svg {
          margin-left: ${spacing.l};
          
          & > path {
            fill: ${color.palette.darkPurple};
          }
        }
      }
    }
  }
`;

const AlertStyled = styled.div`
  padding-bottom: ${spacing.xl};

  & > * {
    max-width: none;
    box-shadow: none;
    border: 1px solid ${color.palette.blue};
    color: ${color.palette.blue};
  }
`;

export async function validateTransaction(transactionHash: string) {
  const transactionReceipt = await Web3Service.eth.getTransactionReceipt(transactionHash);
  if (transactionReceipt?.blockNumber === undefined) throw Error("Transaction not found");
}

export default function PayPendingScreen({ transaction }: PayPendingScreenProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isWalletConnectTransaction, setIsWalletConnectTransaction] = React.useState(false);
  const [walletConnectError, setWalletConnectError] = React.useState(false); // Manual Error identifier for wallet connect submittion handling

  const { mutateAsync: mutateConfirmTransactionAsync, isLoading: isLoadingTransferSubmit } = useConfirmTransaction();
  const { data: issuersData, isLoading: isLoadingIssuers, error: issuersError, refetch: issuersRefetch } = useIssuers();

  const { isConnected, address } = useAccount();

  const handleTransactionSubmit = async ({
    transactionHash,
  }: PayPendingValues): Promise<{ [FORM_ERROR]: string } | undefined> => {
    setIsWalletConnectTransaction(false);
    try {
      await validateTransaction(transactionHash); // Check
      await mutateConfirmTransactionAsync({ id: transaction.Id, transactionHash });
      setIsModalOpen(false);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  const handleWalletConnectTransactionSubmit = async (transactionHash: string) => {
    setIsWalletConnectTransaction(true);
    setWalletConnectError(false);
    try {
      await mutateConfirmTransactionAsync({ id: transaction.Id, transactionHash });
    } catch (er) {
      setWalletConnectError(getErrorMessage(er));
    }
  };

  const DEPOSIT_WALLET = React.useMemo(() => {
    if (issuersData !== undefined && issuersData.length > 0) {
      const issuerWithBurnWallet = issuersData.find(issuer => issuer.IsBurnAllowed);
      if (issuerWithBurnWallet) {
        return {
          issuerName: issuerWithBurnWallet.Name,
          wallet: issuerWithBurnWallet.Address,
        };
      }
    }
    return undefined;
  }, [issuersData]);

  const isConnectedWalletFromTransaction =
    address?.toLowerCase() === transaction.WalletInfo.WalletAddress?.toLowerCase();

  return (
    <>
      <StepWizard
        title="2. EURR Transfer"
        body="Please follow these steps to sell your StablR assets."
        variant={getTransactionBadgeVarient(transaction.Status)}
        statusText={parseTransactionBadgeText(transaction.Status)}
      />
      <SellWizard />
      <Spacer height={spacing.l} />
      <PayPendingScreenStyled>
        <BoxWrapperStyled>
          <Heading as="h3">How would you like to transfer your EURR?</Heading>
          <ExpandableListItemStyled>
            <ExpandableListItem varient="basic" title="Transfer EURR with WalletConnect">
              <ParagraphStyled>
                By connecting your wallet, WalletConnect ensures a secure connection, where you are able to sign the
                Sell Transaction to the StablR burn address.
              </ParagraphStyled>
              <AlertStyled>
                <Alert
                  iconColor={color.palette.blue}
                  message="Always verify and connect only the designated wallet that has been whitelisted for your account. If you encounter any issues or have questions regarding the whitelisting process, feel free to reach out to our support team for assistance"
                />
              </AlertStyled>
              <WalletButtonsContainerStyled>
                <WalletTransferButton
                  loading={isLoadingTransferSubmit && isWalletConnectTransaction}
                  fromWallet={transaction.WalletInfo.WalletAddress as string}
                  token="EURR"
                  amount={transaction.FiatAmount.Amount}
                  toWallet={DEPOSIT_WALLET?.wallet as string}
                  onComplete={handleWalletConnectTransactionSubmit}
                />
                <Spacer width={spacing.m} />
                <WalletConnectButton />
              </WalletButtonsContainerStyled>
              <div>
                {walletConnectError ||
                  (isConnected && !isConnectedWalletFromTransaction && (
                    <>
                      <Spacer height={spacing.m} />
                      <ErrorMessage>
                        {walletConnectError
                          ? walletConnectError
                          : `You are not connected to the registered wallet for this transaction. To send a transaction, connect with "${
                              transaction.WalletInfo.WalletName
                            }" (${transformWalletAddress(transaction.WalletInfo.WalletAddress)})`}
                      </ErrorMessage>
                    </>
                  ))}
              </div>
            </ExpandableListItem>
            <ExpandableListItem varient="basic" title="Transfer EURR manually">
              <ParagraphStyled>
                For manually transferring the EURR to our Stablr burn address, please use the wallet details underneath.
                Upon completion of the transfer, confirm the transfer of EURR with via the button by providing the
                transaction hash.
              </ParagraphStyled>
              <TableGroup label="Our Wallet Details">
                {isLoadingIssuers ? (
                  <TableEmptyContainerStyled>
                    <Loader />
                  </TableEmptyContainerStyled>
                ) : issuersData !== undefined && !issuersError && DEPOSIT_WALLET !== undefined ? (
                  <OurWalletDetailsTable
                    transaction={transaction}
                    destinationWallet={{
                      WalletAddress: DEPOSIT_WALLET?.wallet,
                      WalletIssuer: DEPOSIT_WALLET?.issuerName,
                    }}
                  />
                ) : (
                  <TableEmptyContainerStyled>
                    <CardError title="Error" body={"Can't find wallet details"} onRetry={issuersRefetch} size="small" />
                  </TableEmptyContainerStyled>
                )}
              </TableGroup>
              <Spacer height={spacing.l} />
              <AlertStyled>
                <Alert
                  iconColor={color.palette.blue}
                  message="Please use the exact wallet address provided above for EURR transfers to avoid token loss and expedite redemption. Remember to return to the platform and confirm your transfer with the button below."
                />
              </AlertStyled>
              <BottomBar
                paddingHorizontal="0"
                leftElement={
                  <>
                    <Button
                      varient="primary"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      EURR Transferred
                    </Button>
                  </>
                }
              ></BottomBar>
            </ExpandableListItem>
          </ExpandableListItemStyled>
        </BoxWrapperStyled>
        <Spacer height={spacing.xxl} />

        <Form onSubmit={handleTransactionSubmit} validate={validateFormValues(TransferConfirmationSchemaValidation)}>
          {({ handleSubmit, submitting, submitError }) => (
            <>
              <BankWalletInfo transaction={transaction} />
              <Modal
                open={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                title="Transfer confirmation"
                modalBody={
                  <>
                    <Paragraph>Please enter the transaction hash of the successful EURR transfer</Paragraph>
                    <Field name="transactionHash" component={TextInput} placeholder="0xb5c8bd94..." />
                    {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                      varient="primary"
                      color={color.greyscale.black}
                      backgroundColor={color.greyscale.white}
                    >
                      Cancel
                    </Button>
                    <Spacer width={spacing.s} />
                    <Button type="submit" loading={submitting} onClick={handleSubmit} varient="primary">
                      Confirm
                    </Button>
                  </>
                }
              />
            </>
          )}
        </Form>
      </PayPendingScreenStyled>
    </>
  );
}
