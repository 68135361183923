import { getPasswordSecurityLevel } from "stablr";
import * as yup from "yup";

export enum NEW_PASSWORD_SCHEMA {
  NEW_PASSWORD = "newPassword",
  CONFIRM_NEW_PASSWORD = "confirmNewPassword",
}

export const NewPasswordSchemaValidation = yup.object({
  [NEW_PASSWORD_SCHEMA.NEW_PASSWORD]: yup
    .string()
    .required("Password is required")
    .test("passwordSecurityLevel", "Password must be more secure", function (value?: string) {
      const passwordSecurtyLevel = getPasswordSecurityLevel(value);
      return passwordSecurtyLevel >= 4;
    }),
  [NEW_PASSWORD_SCHEMA.CONFIRM_NEW_PASSWORD]: yup
    .string()
    .oneOf([yup.ref(NEW_PASSWORD_SCHEMA.NEW_PASSWORD), null], "Passwords do not match")
    .required("Confirm Password is required"),
});
