import { APIApplicationStatus } from "types/API/APIManagement";

export function getAPIBadgeVarient(value: APIApplicationStatus) {
  switch (value) {
    case "ACTIVE":
      return "success";
    case "REVOKED":
      return "error";
    default:
      return "default"
  }
}
