import { PERMISSION_ENUM } from "config/permissions";
import { useDeleteWallet } from "hooks/query/wallet/use-delete-wallet";
import React, { memo, useState } from "react";
import { ReactComponent as DeleteIcon } from "stablr/assets/icons/delete-outline.svg";
import { Button, Permission } from "stablr/components/atoms";
import { ActionModal } from "stablr/components/organisms";
import color from "stablr/styles/color";

function DeleteWallet({ title, id, isPending }: { id: string; title: string; isPending?: boolean }) {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: mutateDeleteWallet, isLoading } = useDeleteWallet();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onDelete = () =>
    mutateDeleteWallet(
      { walletId: id, title },
      {
        onSuccess: () => {
          onCloseModal();
        },
      }
    );

  return (
    <Permission permission={PERMISSION_ENUM.DELETE_WALLETS}>
      <Button
        varient={isPending ? "primary" : "secondary"}
        icon={<DeleteIcon />}
        iconSize={20}
        onClick={openModal}
        color={isPending ? color.greyscale.white : color.palette.red}
        backgroundColor={isPending ? color.palette.red : undefined}
      >
        Delete
      </Button>
      <ActionModal
        open={open}
        onClose={onCloseModal}
        title={`Delete Wallet ${title}`}
        btnText="Delete"
        description={`Are you sure you want to delete ${title} from Whitelist?`}
        onConfirm={onDelete}
        iconElement={<DeleteIcon />}
        btnColor={color.greyscale.white}
        btnBackgroundColor={color.palette.red}
        loading={isLoading}
      />
    </Permission>
  );
}

export default memo(DeleteWallet);
