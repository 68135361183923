import { Disclosure } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as WizardStepComplete } from 'stablr/assets/icons/check-circle.svg'
import { ReactComponent as ChevronDownSVG } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpSVG } from 'stablr/assets/icons/chevron-up.svg'
import { Heading } from 'stablr/components/atoms/Heading'
import { Icon } from 'stablr/components/atoms/Icon'
import { fontWeight } from 'stablr/styles'
import color from 'stablr/styles/color'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface SummaryListItemProps {
  title: string | React.ReactElement
  children?: React.ReactElement
  defaultOpen?: boolean
}

const SummaryListItemStyled = styled.button<{ varient: string; $hasChildren: boolean }>`
  position: relative;
  list-style-type: none;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 ${spacing.s};
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: solid ${color.greyscale.grey3} 1px;

  h2 {
    margin-left: ${spacing.m};
    flex: 1;
    text-align: left;
  }

  &[aria-expanded='true'] {
    ${({ $hasChildren }) => ($hasChildren ? 'border: none;' : '')}
  }
`

const SummaryListItemChildrenStyled = styled(Disclosure.Panel)`
  border-bottom: solid ${color.greyscale.grey3} 1px;

  @media ${media.mobile} {
    padding: ${spacing.m} 0;
  }

  @media ${media.desktop} {
    padding: ${spacing.xs} ${spacing.xl} ${spacing.m} ${spacing.xl};
  }
`
const IconStyled = styled.div`
  margin: 0 ${spacing.m};
  display: flex;
  align-items: center;
  color: ${color.palette.purple};
  font-weight: ${fontWeight.bold};

  svg {
    margin-left: ${spacing.s};
  }
`

SummaryListItem.testid = 'summaryListItem'

export function SummaryListItem({ title, children, defaultOpen = false }: SummaryListItemProps) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button as={SummaryListItemStyled} $hasChildren={!!children}>
            <Icon size={28} color={color.palette.purple}>
              <WizardStepComplete />
            </Icon>
            <Heading as="h2" varient="h4">
              {title}
            </Heading>
            {!!children && (
              <IconStyled color={color.palette.purple} as={Icon}>
                {!open ? (
                  <>
                    Show more
                    <ChevronDownSVG />
                  </>
                ) : (
                  <>
                    Show less
                    <ChevronUpSVG />
                  </>
                )}
              </IconStyled>
            )}
          </Disclosure.Button>
          {!!children && <SummaryListItemChildrenStyled>{children}</SummaryListItemChildrenStyled>}
        </>
      )}
    </Disclosure>
  )
}
