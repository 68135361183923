import * as React from "react";
import { spacing } from "stablr";
import { ReactComponent as CheckSVG } from "stablr/assets/icons/check.svg";
import { ReactComponent as IconBackIOS } from "stablr/assets/icons/icon-back-ios.svg";
import { Button, Spacer } from "stablr/components/atoms";
import { CardInfo, Container } from "stablr/components/molecules";
import { StepTemplate } from "stablr/components/templates";
import styled from "styled-components";

interface SuccessProps {
  onContinue: () => void;
}

const ContainerStyled = styled(Container)`
  height: calc(100% - ${spacing.xxl});
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function SuccessComponent({ onContinue }: SuccessProps) {
  return (
    <StepTemplate>
      <ContainerStyled>
        <CardInfo
          icon={<CheckSVG />}
          title="Thank you for signing up with StablR!"
          body="Sign up process is completed. You can now continue."
          button={
            <Button varient="secondary" onClick={onContinue} layout="icon-left" icon={<IconBackIOS />}>
              Back to Sign In
            </Button>
          }
        ></CardInfo>
        <Spacer height={spacing.xxl} />
      </ContainerStyled>
    </StepTemplate>
  );
}
