import { APIBankAccount, APIBankAccountListItem, APICreateBankAccount } from "types/API/BankAccount";

import { MOCK_ACCOUNT_LIST } from "./BankAccount.mock.data";
import * as Mock from "./Connection.mock";

export const getAccountList = () =>
  Mock.get<APIBankAccountListItem[]>(`/organizations/bankaccounts`, MOCK_ACCOUNT_LIST);

export const getAccountById = (id: string) =>
  Mock.get<APIBankAccountListItem>(`/organizations/bankaccounts/${id}`, MOCK_ACCOUNT_LIST[0]);

export const createAccount = (_data: APICreateBankAccount) => Mock.postSocket("/organizations/bankaccounts");

export const updateAccount = (Iban: string, _data: Partial<APIBankAccount>) =>
  Mock.put(`/organizations/bankaccounts/${Iban}`);

export const deleteAccount = (accountId: string) => Mock.deleteSocket(`/organizations/bankaccounts/${accountId}`);
