import { useCallback } from 'react'
import { usePermissionContext } from 'stablr/context/use-permission'

export function usePermission() {
  const { permissions, userRoles } = usePermissionContext()

  const hasPermission = useCallback(
    (permission: string): boolean => {
      if (
        (permissions?.[permission] as unknown as string[])?.find((perm: string) =>
          userRoles.find((role) => role === perm),
        )
      )
        return true

      return false
    },
    [userRoles],
  )

  return hasPermission
}
