import React from "react";
import { Route, Routes } from "react-router-dom";

import AddAPIKey from "./AddAPIKey";
import { APIKeyManagementOverview } from "./APIManagement";
import EditAPIKey from "./EditAPIKey";

export default function APIManagement() {
  return (
    <Routes>
      <Route path="*" element={<APIKeyManagementOverview />} />
      <Route path="/add" element={<AddAPIKey />} />
      <Route path="/:apiKeyId/edit" element={<EditAPIKey />} />
    </Routes>
  );
}
