import { Config } from "config";

import * as APIManagement from "./APIKeyManagement";
import * as MockAPIManagement from "./APIKeyManagement.mock";
import * as BankAccount from "./BankAccount";
import * as MockBankAccount from "./BankAccount.mock";
import * as Company from "./Company";
import * as MockCompany from "./Company.mock";
import * as Files from "./Files";
import * as MockFiles from "./Files.mock";
import * as Issuer from "./Issuer";
import * as MockIssuer from "./Issuer.mock";
import * as Payment from "./Payment";
import * as MockPayment from "./Payment.mock";
import * as Role from "./Role";
import * as MockRole from "./Role.mock";
import * as Transaction from "./Transaction";
import * as MockTransaction from "./Transaction.mock";
import * as User from "./User";
import * as MockUser from "./User.mock";
import * as Wallet from "./Wallet";
import * as MockWallet from "./Wallet.mock";
import Web3 from "./web3";
import * as Webhooks from "./Webhooks";
import * as MockWebhooks from "./Webhooks.mock";

type APIInterface = {
  // These additional types inforce consistency with mocked responses
  User: typeof User;
  Role: typeof Role;
  Company: typeof Company;
  Wallet: typeof Wallet;
  BankAccount: typeof BankAccount;
  Payment: typeof Payment;
  Transaction: typeof Transaction;
  Issuer: typeof Issuer;
  Files: typeof Files;
  APIManagement: typeof APIManagement;
  Webhooks: typeof Webhooks;
  Web3: typeof Web3;
};

const API: APIInterface = {
  User,
  Role,
  Company,
  Wallet,
  BankAccount,
  Payment,
  Transaction,
  Issuer,
  Files,
  APIManagement,
  Webhooks,
  Web3,
};

const APIMocked: APIInterface = {
  User: MockUser,
  Role: MockRole,
  Company: MockCompany,
  Wallet: MockWallet,
  BankAccount: MockBankAccount,
  Payment: MockPayment,
  Transaction: MockTransaction,
  Issuer: MockIssuer,
  Files: MockFiles,
  APIManagement: MockAPIManagement,
  Webhooks: MockWebhooks,
  Web3,
};

export default Config.mocked ? APIMocked : API;
