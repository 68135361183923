import { APICreateWebhook, APIWebhook } from "types/API/Webhooks";

import * as Mock from "./Connection.mock";
import { APIPostCreateWebhookResponse } from "./Webhooks";
import { MOCK_WEBHOOK, MOCK_WEBHOOKS_LIST } from "./Webhooks.mock.data";

export const getWebhooksList = () => Mock.get<APIWebhook[]>("/notifications/webhooks", MOCK_WEBHOOKS_LIST);

export const getWebhookById = (hookId: string) =>
  Mock.get<APIWebhook>(`/notifications/webhooks/${hookId}`, MOCK_WEBHOOK);

export const updateWebhook = (id: string, data: Partial<APIWebhook>) => Mock.put(`/notifications/webhooks/${id}`, data);

export const deleteWebhook = (hookId: string) => {
  return Mock.post(`/notifications/webhooks/${hookId}`);
};

export const createWebhook = (_data: APICreateWebhook) => {
  return Mock.post<APIPostCreateWebhookResponse>("/notifications/webhooks", { Id: "exampleId" });
};
