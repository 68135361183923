import * as amplitude from "@amplitude/analytics-browser";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Config } from "config";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import React from "react";
import { BrowserRouter, matchPath, matchRoutes, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FullScreenLoader } from "stablr";
import { anonymiseId } from "stablr/functions";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";

import Accounts from "./Account";
import BuySell from "./BuySell";
import Buy from "./BuySell/Buy";
import Sell from "./BuySell/Sell";
import { ErrorBoundary } from "./components/ErrorBoundary";
import Dashboard from "./Dashboard";
import DashboardMenu from "./DashboardMenu";
import Home from "./Home";
import SettingsComponent from "./Settings";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Test from "./Test";
import Transactions from "./Transaction";
import UserDataContextProvider from "./UserDataContextProvider";

export const AUTHENTICATED_PATHS = [
  { path: "/dashboard/*" },
  { path: "/test/*" },
  { path: "/transactions/*" },
  { path: "/accounts/*" },
  { path: "/buy/*" },
  { path: "/sell/*" },
  { path: "/settings/*" },
];

export const TEST_ENVIRONMENTS = ["development", "test"];

const LOGGABLE_ROUTES = [
  { path: "/dashboard" },
  { path: "/buy", exact: true },
  { path: "/buy/:id" },
  { path: "/sell", exact: true },
  { path: "/sell/:id" },
  { path: "/transactions", exact: true },
  { path: "/accounts", exact: true },
  { path: "/accounts/company-information", exact: true },
  { path: "/accounts/wallet", exact: true },
  { path: "/accounts/wallet/add", exact: true },
  { path: "/accounts/wallet/:id/edit", exact: true },
  { path: "/accounts/bank-accounts", exact: true },
  { path: "/accounts/bank-accounts/add", exact: true },
  { path: "/accounts/bank-accounts/:id/edit", exact: true },
  { path: "/accounts/payment-instructions", exact: true },
  { path: "/accounts/user-management", exact: true },
  { path: "/accounts/user-management/add", exact: true },
  { path: "/accounts/user-management/:id", exact: true },
  { path: "/sign-in", exact: true },
  { path: "/sign-in/sms-authentication", exact: true },
  { path: "/sign-in/reset-password", exact: true },
  { path: "/sign-in/new-password", exact: true },
  { path: "/sign-up/user-information", exact: true },
  { path: "/sign-up/create-password", exact: true },
  { path: "/sign-up/email-verification", exact: true },
  { path: "/sign-up/sms-authentication", exact: true },
  { path: "/sign-up/company-verification", exact: true },
  { path: "/sign-up/summary-and-sign-up", exact: true },
  { path: "/sign-up/success", exact: true },
  { path: "/settings", exact: true },
  { path: "/settings/user-details", exact: true },
  { path: "/settings/security", exact: true },
];

export const UNAUTHENTICATED_PATHS = [{ path: "/sign-up/*" }, { path: "/sign-in/*" }, { path: "/" }];

export const NO_REDIRECT_PATHS = [{ path: "/sign-up/*" }];

/**
 * Wagmi and Wallet connect setup
 */
const chains = Config.web3.network === "mainnet" ? [mainnet] : [sepolia];

const chainConfigurations = chains.map(chain => ({
  id: chain.id,
  network: chain.network,
  name: chain.name,
  nativeCurrency: chain.nativeCurrency,
  rpcUrls: chain.rpcUrls,
  blockExplorers: chain.blockExplorers,
  contracts: chain.contracts,
}));

const projectId = Config.walletConnect.projectId;
const { publicClient } = configureChains(chainConfigurations, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

/**
 * Wagmi and Wallet connect setup
 */

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <UserDataContextProvider>
        <ActiveSessionWrapper>
          {isAuthenticatedPath =>
            isAuthenticatedPath ? (
              <>
                <WagmiConfig config={wagmiConfig}>
                  <DashboardMenu>
                    <RoutesComponent />
                  </DashboardMenu>
                </WagmiConfig>
                <Web3Modal
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                  tokenContracts={{
                    1:
                      Config.web3.network === "mainnet"
                        ? Config.web3.contracts.EURR.mainnet
                        : Config.web3.contracts.EURR.sepolia,
                  }}
                />
              </>
            ) : (
              <RoutesComponent />
            )
          }
        </ActiveSessionWrapper>
      </UserDataContextProvider>
    </BrowserRouter>
  );
}

function RoutesComponent() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(LOGGABLE_ROUTES, location);

  React.useEffect(() => {
    if (matchedRoutes) logEvent(EVENT_TYPE_ENUM.NAVIGATE_SCREEN, matchedRoutes[0].route.path);
  }, [matchedRoutes]);

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-up/*" element={<SignUp />} />
        <Route path="/sign-in/*" element={<SignIn />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/accounts/*" element={<Accounts />} />
        <Route path="/settings/*" element={<SettingsComponent />} />
        {TEST_ENVIRONMENTS.includes(Config.environment ?? "") && <Route path="/test/*" element={<Test />} />}
        <Route path="/transactions/*" element={<Transactions />} />
        <Route
          path={"/buy/*"}
          element={
            <BuySell>
              <Buy />
            </BuySell>
          }
        ></Route>
        <Route
          path={"/sell/*"}
          element={
            <BuySell>
              <Sell />
            </BuySell>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
}
function ActiveSessionWrapper({ children }: { children: (isAuthenticatedPath: boolean) => React.ReactElement }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAuthenticatedPath = React.useMemo(() => !!matchRoutes(AUTHENTICATED_PATHS, pathname), [pathname]);
  const isKnownPath = React.useMemo(
    () => !!matchRoutes([...AUTHENTICATED_PATHS, ...UNAUTHENTICATED_PATHS], pathname),
    [pathname]
  );
  const isNoRedirectPath = React.useMemo(() => !!matchRoutes([...NO_REDIRECT_PATHS], pathname), [pathname]);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getCurrentSession = async () => {
      try {
        const payload = await fetchUserAttributes();

        const userSub = payload?.sub ?? "";
        const isKYBSubmitted =
          typeof payload?.["custom:kybStatus"] === "string" && payload?.["custom:kybStatus"] === "SUBMITTED";
        const isKYBVerified =
          typeof payload?.["custom:kybStatus"] === "string" && payload?.["custom:kybStatus"] === "VALIDATED";

        if (
          isKYBVerified === false &&
          isKYBSubmitted === false &&
          !matchPath(pathname, "/sign-up/company-verification")
        ) {
          navigate("../sign-up/company-verification");
        } else if (isKYBSubmitted === true && !matchPath(pathname, "/sign-up/summary-and-sign-up")) {
          navigate("../sign-up/summary-and-sign-up");
        } else if (!isAuthenticatedPath && isKnownPath && isKYBVerified === true && !isNoRedirectPath) {
          navigate("../dashboard");
        }

        /** Add user association to logging */
        if (Config.logging.amplitudeApiKey) amplitude.init(Config.logging.amplitudeApiKey, anonymiseId(userSub));

        setIsLoading(false);
      } catch (error: unknown) {
        /** Remove user association from logging */
        if (Config.logging.amplitudeApiKey) amplitude.init(Config.logging.amplitudeApiKey);

        if (!isAuthenticatedPath) {
          return;
        }
        // If there is no active user session
        if (error === "No current user") {
          navigate("../sign-in");
        } // If there is a different error with the session
        else {
          navigate("../sign-in");
        }
      }
    };
    getCurrentSession();
  }, [isAuthenticatedPath]); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect eslint rule disabled to avoid overloading auth checks

  return isAuthenticatedPath && isLoading ? <FullScreenLoader /> : children(isAuthenticatedPath);
}
