import React from "react";
import { FieldRenderProps } from "react-final-form";
import { ReactComponent as RemoveIcon } from "stablr/assets/icons/remove-circle-outline.svg";
import { Input, InputButtonIcon, InputNote, Label } from "stablr/components/atoms";
import { composeTestID } from "stablr/functions";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

import { usePrevious } from "./hooks/usePrevious";

export interface ArrayInputTextProps extends FieldRenderProps<string[], HTMLInputElement> {
  testid?: string;
}

const ArrayInputStyled = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: ${spacing.m};

  & > div:first-child {
    max-width: none;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RemoveAllButton = styled.div`
  color: ${color.palette.red};
  cursor: pointer;
  padding-top: 10px;
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.bold};
`;

ArrayInput.testid = "ArrayInput";

export function ArrayInput({
  input: { ...inputProps },
  meta: { error, touched },
  label,
  disabled,
  ...props
}: ArrayInputTextProps) {
  const inputLength = (inputProps.value ?? []).length;
  const hasReachedMaxLimit = inputLength >= 10;
  const previousLength = usePrevious(inputProps.value.length) ?? 0;
  const currentLength = inputProps.value.length;
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (currentLength > previousLength) {
      focusLastInput();
    }
  }, [currentLength, previousLength]);

  const focusLastInput = () => {
    if (wrapperRef?.current?.children === undefined) return;
    const mappedInputs = wrapperRef.current.children; // Couldn't find the type to support child input elements
    const lastChildIndex = mappedInputs.length - 1;
    (mappedInputs?.[lastChildIndex]?.children?.[0]?.children?.[0] as HTMLElement).focus();
  };

  const handleRemoveAll = () => {
    inputProps.onChange([]);
  };

  const handleItemChange = (index: number, newValue: string) => {
    if (newValue === "") {
      handleDeleteItem(index);
    } else {
      const updatedValue = [...inputProps.value];
      updatedValue[index] = newValue;
      inputProps.onChange(updatedValue);
    }
  };

  const handleDeleteItem = (index: number) => {
    const updatedValue = [...inputProps.value].filter((_: string, i: number) => i !== index);
    inputProps.onChange(updatedValue);
  };

  const handleAddItem = (value: string) => {
    inputProps.onChange([...inputProps.value, value]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleAddItem(value);
  };

  return (
    <ArrayInputStyled data-testid={composeTestID(ArrayInput.testid, props.testid)}>
      <LabelWrapper>
        <Label htmlFor={inputProps.name}>{label}</Label>
        {inputLength > 0 && !disabled && (
          <RemoveAllButton onClick={handleRemoveAll}>Remove all saved IP addresses</RemoveAllButton>
        )}
      </LabelWrapper>
      <div ref={wrapperRef}>
        {inputProps.value.map((item: string, index: number) => (
          <InputWrapper key={`ArrayInput_${index}`}>
            <Input
              disabled={disabled}
              {...inputProps}
              tabIndex={index}
              appendElement={
                !disabled ? (
                  <InputButtonIcon color={color.palette.red} size={24} onClick={() => handleDeleteItem(index)}>
                    <RemoveIcon />
                  </InputButtonIcon>
                ) : (
                  <></>
                )
              }
              value={item}
              onChange={e => {
                handleItemChange(index, e.target.value);
              }}
              type={"text"}
            />
            {touched && error?.[index] && (
              <InputNote htmlFor={inputProps.name} color={error?.[index] ? color.palette.red : undefined}>
                {error?.[index]}
              </InputNote>
            )}
          </InputWrapper>
        ))}
      </div>
      {!hasReachedMaxLimit && !disabled && (
        <InputWrapper>
          <Input
            {...inputProps}
            {...props}
            tabIndex={inputLength + 1}
            value={""}
            onChange={handleInputChange}
            type={"text"}
          />
        </InputWrapper>
      )}
    </ArrayInputStyled>
  );
}
