export function parseCurrency(value: string, denominator: '.' | ',') {
  if (value === undefined || value === '') {
    return ''
  }
  const restrictedValue = value
    .replace(/[^0-9.,]/g, '')
    .replace(denominator === ',' ? '.' : ',', denominator)

  let resultValue = restrictedValue

  // If there is a dot, restrict the value to at most two digits after the dot
  const dotIndex = resultValue.indexOf(denominator)
  if (dotIndex !== -1) {
    const integerPart = resultValue.slice(0, dotIndex)
    const decimalPart = resultValue.slice(dotIndex + 1, dotIndex + 3)
    resultValue = `${integerPart}${denominator}${decimalPart}`
  }

  return resultValue
}
