import { useDeleteWebhook } from "hooks/query/webhooks/use-delete-webhook";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "stablr/components/atoms";
import { ActionModal } from "stablr/components/organisms";
import { color } from "stablr/styles";

export default function DeleteWebhook({ name, webhookId }: { webhookId: string; name: string }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const { mutateAsync: mutateAsyncDeleteWebhook, isLoading } = useDeleteWebhook();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const deleteWebhook = async () => {
    await mutateAsyncDeleteWebhook({ webhookId }, { onSuccess: () => navigate(-1) });
    onCloseModal();
  };

  return (
    <>
      <Button
        onClick={openModal}
        varient="line"
        color={color.palette.red}
        borderColor={color.palette.red}
        backgroundColor={color.greyscale.white}
      >
        Delete
      </Button>
      <ActionModal
        open={open}
        onClose={onCloseModal}
        title={`Delete: ${name}`}
        description={"Are you sure you want to delete this webhook?"}
        btnText="Delete"
        marginSize="small"
        btnBackgroundColor={color.palette.red}
        btnColor={color.greyscale.white}
        onConfirm={deleteWebhook}
        loading={isLoading}
      />
    </>
  );
}
