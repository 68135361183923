import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as InfoIcon } from 'stablr/assets/icons/info.svg'
import { ReactComponent as VisibilityOffSVG } from 'stablr/assets/icons/visibility-off.svg'
import { ReactComponent as VisibilityOnSVG } from 'stablr/assets/icons/visibility-on.svg'
import { Icon } from 'stablr/components/atoms'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputButtonIcon } from 'stablr/components/atoms/Form/InputButtonIcon'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { PasswordIndicator } from 'stablr/components/atoms/Form/PasswordIndicator'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { fontWeight, spacing } from 'stablr/styles'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface TextInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  note?: string
  hasIndicator?: boolean
  appendElement?: React.ReactElement
  tooltip?: React.ReactNode
  maxWidth?: boolean
}

const IconStyled = styled(Icon)`
  position: relative;
  padding: 0 0 0 5px;
  margin-bottom: -2px;
  cursor: pointer;

  &:hover .tooltip {
    display: block;
  }
`

const Tooltip = styled.div`
  display: none;
  position: absolute;
  width: 403px;
  height: 75px;
  margin-top: -80px;
  margin-left: 20px;
  box-shadow: 0 1px 4px 0 #0000001a;
  background-color: ${color.greyscale.white};
  padding: ${spacing.m};
  border-radius: 3px;
  color: ${color.greyscale.black};
  font-weight: ${fontWeight.default};
`

PasswordInput.testid = 'passwordInput'

export function PasswordInput({
  input: { ...inputProps },
  label,
  note,
  hasIndicator,
  appendElement,
  tooltip,
  maxWidth = false,
  ...props
}: TextInputProps) {
  const [isShown, setIsShown] = React.useState(false)
  const [isTooltipVisible, setTooltipVisible] = React.useState(false)

  const showIndicator =
    typeof inputProps.value === 'string' && inputProps.value.length > 0 && hasIndicator === true
  const errorMessage = props.meta.touched && props.meta.error

  return (
    <div data-testid={composeTestID(PasswordInput.testid, props.testid)}>
      <Label readOnly={props.readOnly} htmlFor={inputProps.name}>
        {label}
        {tooltip !== undefined && (
          <IconStyled
            size={17.5}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            <InfoIcon />
            {isTooltipVisible && <Tooltip className="tooltip">{tooltip}</Tooltip>}
          </IconStyled>
        )}
      </Label>
      <Input
        maxWidth={maxWidth}
        {...inputProps}
        {...props}
        appendElement={
          <InputButtonIcon onClick={() => setIsShown(!isShown)} color={color.greyscale.black}>
            {isShown ? <VisibilityOnSVG /> : <VisibilityOffSVG />}
          </InputButtonIcon>
        }
        type={isShown ? 'text' : 'password'}
      ></Input>
      {showIndicator && !errorMessage ? (
        <PasswordIndicator value={inputProps.value} />
      ) : (
        <InputNote htmlFor={inputProps.name} color={!errorMessage ? undefined : color.palette.red}>
          {!errorMessage ? note : errorMessage}
          {appendElement}
        </InputNote>
      )}
    </div>
  )
}
