import { FORM_ERROR } from "final-form";
import React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { getErrorMessage, validateFormValues } from "stablr";
import { ReactComponent as EthereumSVG } from "stablr/assets/icons/ethereum.svg";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { ReactComponent as SolanaSVG } from "stablr/assets/icons/solana.svg";
import { Icon, Spacer } from "stablr/components/atoms";
import { ListBoxInput } from "stablr/components/molecules";
import { ActionModal } from "stablr/components/organisms";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

import { NetworkModalSchemaValidation } from "../components/common/schemaValidation";

const TextStyled = styled.div`
  font-size: ${fontSize.input};
  font-weight: ${fontWeight.default};
  max-width: 471px;
  color: ${color.greyscale.black};
`;

const InfoMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const InfoMessageStyled = styled.span`
  max-width: 318px;
  padding-left: 15px;
`;

export function NetworkModal({
  open,
  onClose,
  onNetworkChange,
}: {
  open: boolean;
  onClose: () => void;
  onNetworkChange: (value: string) => void;
}) {
  const [closeCount, setCloseCount] = React.useState(0);

  const navigate = useNavigate();

  const handleSubmit = (values: { Network: string }) => {
    try {
      onNetworkChange(values.Network);
      if (values.Network) onClose();
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  const networkOptions = [
    { value: "Ethereum", label: "Ethereum", icon: <EthereumSVG /> },
    { value: "Solana", label: "Solana", icon: <SolanaSVG /> },
  ];

  const onCloseModal = () => {
    setCloseCount(prevCount => prevCount + 1);
    if (closeCount !== 0) {
      navigate(-1);
    }
  };

  return (
    <Form onSubmit={handleSubmit} validate={validateFormValues(NetworkModalSchemaValidation)} keepDirtyOnReinitialize>
      {({ handleSubmit }) => (
        <ActionModal
          btnText="Confirm"
          onConfirm={handleSubmit}
          open={open}
          onClose={onCloseModal}
          title="Select Network"
          description={
            <>
              <TextStyled>Please select the network that you want to use for the transaction.</TextStyled>
              <Spacer height={spacing.m} />
              <Field name="Network" component={ListBoxInput} placeholder="Select Network" options={networkOptions} />
              <InfoMessageWrapper>
                {closeCount === 1 && (
                  <>
                    <Icon color={color.palette.blue} size={24}>
                      <InfoIcon />
                    </Icon>
                    <InfoMessageStyled>If you cancel, you&apos;ll be redirected from this page.</InfoMessageStyled>
                  </>
                )}
              </InfoMessageWrapper>
            </>
          }
        />
      )}
    </Form>
  );
}
