import * as React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Avatar } from '../../atoms/Avatar'

export interface UserDropDownInfoProps {
  name?: string
  email?: string
}

const UserDropDownInfoStyled = styled.span`
  text-align: center;
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.data};
  color: ${color.greyscale.black};
  margin: ${spacing.xs};
  display: block;
  font-weight: ${fontWeight.regular};
  line-height: 24px;
  max-width: 200px;
  word-break: break-word;
`

const DataContainerStyled = styled.div`
  padding: ${spacing.xs} 0;
`

const UserDropDownInfoContainerStyled = styled.div`
  padding: ${spacing.m} 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export function UserDropDownInfo({ name, email }: UserDropDownInfoProps) {
  return (
    <UserDropDownInfoContainerStyled>
      <Avatar name={name} size="large" />
      <DataContainerStyled>
        <UserDropDownInfoStyled>{name}</UserDropDownInfoStyled>
        <UserDropDownInfoStyled>{email}</UserDropDownInfoStyled>
      </DataContainerStyled>
    </UserDropDownInfoContainerStyled>
  )
}
