import * as React from 'react'
import { ReactComponent as WarningIcon } from 'stablr/assets/icons/warning.svg'
import { Button } from 'stablr/components/atoms/Button'
import { Heading } from 'stablr/components/atoms/Heading'
import { Icon } from 'stablr/components/atoms/Icon'
import { Paragraph } from 'stablr/components/atoms/Paragraph'
import { Spacer } from 'stablr/components/atoms/Spacer'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface CardErrorProps {
  title: string
  body: string
  size?: 'large' | 'small'
  onRetry: () => void
}

const CIRCLE_SIZE = '180px'
const CIRCLE_OUTER_SIZE = '250px'

const CardErrorStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l} ${spacing.l};
  flex: 1;

  & h2 {
    margin-bottom: 0;
    font-weight: 600;
  }

  & p {
    margin-top: ${spacing.s};
    text-align: center;
  }
`

const IconElementStyled = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const IconContainerStyled = styled.div`
  width: ${CIRCLE_OUTER_SIZE};
  height: ${CIRCLE_OUTER_SIZE};
  background-color: rgba(132, 157, 255, 0.05);
  border-radius: calc(${CIRCLE_OUTER_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export function CardError({ title, body, onRetry, size = 'large' }: CardErrorProps) {
  return (
    <CardErrorStyled>
      {size === 'large' && (
        <IconContainerStyled>
          <Icon as={IconElementStyled} gradient size={120}>
            <WarningIcon />
          </Icon>
        </IconContainerStyled>
      )}
      <Heading as="h2" varient="h3" testid="card-error">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      <Spacer height={spacing.m} />
      <Button onClick={onRetry}>Try again</Button>
    </CardErrorStyled>
  )
}
