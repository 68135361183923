import * as React from 'react'
import { composeTestID, getInitials } from 'stablr/functions'
import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface AvatarProps {
  testid?: string
  name?: string
  size?: 'large' | 'small'
}

const AvatarStyled = styled.div(
  ({ $size }: { $size: 'large' | 'small' }) => `
  background: ${color.gradient};
  width: ${$size === 'large' ? SIZE_LARGE : SIZE_SMALL};
  height: ${$size === 'large' ? SIZE_LARGE : SIZE_SMALL};
  font-size: ${$size === 'large' ? FONT_SIZE_LARGE : FONT_SIZE_SMALL};
  border-radius: 100%;
  color: ${color.greyscale.white};
  font-weight: ${fontWeight.semiBold};
  display:flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`,
)

const SIZE_LARGE = '64px'
const SIZE_SMALL = '34px'
const FONT_SIZE_LARGE = fontSize.h3
const FONT_SIZE_SMALL = fontSize.p

Avatar.testid = 'Avatar'

export function Avatar({ name, size = 'small', ...props }: AvatarProps) {
  const initials = name && getInitials(name)
  return (
    <AvatarStyled $size={size} data-testid={composeTestID(Avatar.testid, props.testid)}>
      {initials}
    </AvatarStyled>
  )
}
