import { Menu } from "@headlessui/react";
import * as React from "react";
import { Button } from "stablr/components/atoms";
import color from "stablr/styles/color";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";

interface BorderlessButtonProps {
  children: string;
  onClick?: () => void;
}

const ButtonContainerStyled = styled(Menu.Item)`
  padding: ${spacing.m} 0;
  width: 100%;
`;

export function BorderlessButton({ children, ...props }: BorderlessButtonProps) {
  return (
    <ButtonContainerStyled>
      <Button
        onClick={props.onClick}
        size="small"
        varient="primary"
        color={color.greyscale.black}
        backgroundColor={color.greyscale.white}
      >
        {children}
      </Button>
    </ButtonContainerStyled>
  );
}
