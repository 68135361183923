export function getPasswordSecurityLevel(password?: string) {
  if (typeof password !== 'string') return 0
  const eightOrMore = password.length >= 8 ? 1 : 0
  const lettersAndNumbers = /[0-9]{1,}.*[a-zA-Z]{1,}|[a-zA-Z]{1,}.*[0-9]{1,}/.test(password) ? 1 : 0
  const lowerAndUpper = /[a-z]{1,}.*[A-Z]{1,}|[A-Z]{1,}.*[a-z]{1,}/.test(password) ? 1 : 0
  const containsSpecialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{1,}/.test(password) ? 1 : 0

  return (eightOrMore + lettersAndNumbers + lowerAndUpper + containsSpecialCharacters) as
    | 0
    | 1
    | 2
    | 3
    | 4
}
