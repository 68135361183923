import * as React from 'react'
import styled from 'styled-components'

type ListWrapperProps = {
  children: React.ReactElement | React.ReactElement[]
}

const ListWrapperStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
`

export function ListWrapper({ children }: ListWrapperProps) {
  return <ListWrapperStyled>{children}</ListWrapperStyled>
}
