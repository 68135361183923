import * as yup from "yup";

export enum BUY_ORDER_SCHEMA {
  WALLET = "wallet",
  CURRENT_BALANCE = "currentBalance",
  NEW_BALANCE = "newBalance",
  BUY = "buy",
  PAY = "pay",
  REFERENCE = "reference",
}

/**
 * Validation 3.1: Create Buy Order
 */

export const BuyOrderSchemaValidation = yup.object({
  [BUY_ORDER_SCHEMA.WALLET]: yup.object().required("Wallet address is required"),
  [BUY_ORDER_SCHEMA.BUY]: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Incorrect number format")
    .required("Buy amount is required")
    .min(10, "Must be more than 10")
    .max(1000000000, "Must be less than 1,000,000,000"),
  [BUY_ORDER_SCHEMA.REFERENCE]: yup.string().max(12, "Notes can not exceed 12 characters"),
});
