import { APIWebhook } from "types/API/Webhooks";

export const MOCK_WEBHOOKS_LIST: APIWebhook[] = [
  {
    Id: "bb7d428a-cda7-4a18-8005-dfd39e3460d5",
    Name: "Webhook 1",
    Url: "https://www.webhooks.com/receiver",
    Secret: "123efd4",
    Status: "Enabled",
    CreatedAt: "2024-01-01T00:00:00.000Z",
  },
  {
    Id: "bb7d428a-cda7-4a18-8005-dfd39e3460d5",
    Name: "Webhook 1",
    Url: "https://www.webhooks.com/receiver",
    Secret: "23r4f4",
    Status: "Disabled",
    CreatedAt: "2024-01-01T00:00:00.000Z",
  },
];

export const MOCK_WEBHOOK: APIWebhook = {
  Id: "bb7d428a-cda7-4a18-8005-dfd39e3460d5",
  Name: "Webhook 1",
  Url: "https://www.webhooks.com/receiver",
  Secret: "34r534At",
  Status: "Enabled",
  CreatedAt: "2024-01-01T00:00:00.000Z",
};
