import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { Icon, Spacer } from 'stablr/components/atoms'
import color from 'stablr/styles/color'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface ModalProps {
  open: boolean
  onClose?: () => void
  title: string
  modalBody?: React.ReactElement
  modalFooter?: React.ReactElement
  icon?: React.ReactElement
  iconColor?: string
  iconSize?: number
}

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
`

const ModalBody = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: ${color.greyscale.white};
  padding: ${spacing.l};
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  font-weight: 100;
  pointer-events: auto;
  min-width: 300px;

  @media (${media.desktop}) {
    min-width: 500px;
    padding: ${spacing.l};
  }
`

const ModalContent = styled.div`
  padding: ${spacing.s};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${spacing.l};
  flex-flow: wrap;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export function Modal({
  open,
  onClose,
  title,
  modalBody,
  modalFooter,
  icon,
  iconColor,
  iconSize,
}: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as={ModalStyled} onClose={() => onClose?.()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div />
        </Transition.Child>

        <ModalBody className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel as="div">
              <TitleWrapper>
                {icon && (
                  <Icon id="icon" color={iconColor} size={iconSize}>
                    {icon}
                  </Icon>
                )}
                <Spacer width="10px" />
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
              </TitleWrapper>
              <ModalContent>{modalBody}</ModalContent>
              <ModalFooter className="mt-4">{modalFooter}</ModalFooter>
            </Dialog.Panel>
          </Transition.Child>
        </ModalBody>
      </Dialog>
    </Transition>
  )
}
