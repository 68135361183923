import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import styled from 'styled-components'

import { TableData } from '../../atoms/Table/TableData'
import { TableHeader } from '../../atoms/Table/TableHeader'

export interface FormSummaryTableDataItem {
  label: string
  icon?: React.ReactElement
  value?: string | React.ReactElement
}

export interface FormSummaryTableProps {
  testid?: string
  data: FormSummaryTableDataItem[]
}

const FormSummaryTableStyled = styled.table`
  border-radius: 3px;
  border: 1px solid ${color.greyscale.grey3};
  box-shadow: 0 0 8px ${color.other.borderGradient};
  background-color: ${color.greyscale.white};
  width: 100%;
  box-sizing: border-box;
  table-layout: auto;
`

const FormSummaryTableRowStyled = styled.tr`
  td {
    width: 100%;
  }
`

FormSummaryTable.testid = 'formSummaryTable'

export function FormSummaryTable({ data, ...props }: FormSummaryTableProps) {
  return (
    <FormSummaryTableStyled
      cellSpacing="0"
      data-testid={composeTestID(FormSummaryTable.testid, props.testid)}
    >
      {data.map((item) => (
        <FormSummaryTableRowStyled key={item.label}>
          <TableHeader paddingSize="small" icon={item.icon} iconLayout="left">
            {item.label}
          </TableHeader>
          <TableData paddingSize="small">{item.value}</TableData>
        </FormSummaryTableRowStyled>
      ))}
    </FormSummaryTableStyled>
  )
}
