import React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface TooltipProps {
  text: string
  testid?: string
}
const TooltipStyled = styled.div`
  position: absolute;
  max-width: 403px;
  max-height: 200px;
  margin-top: -100px;
  margin-left: 100px;
  box-shadow: 0 1px 4px 0 #0000001a;
  background-color: ${color.greyscale.white};
  padding: ${spacing.m};
  border-radius: 3px;
  color: ${color.greyscale.black};
  font-weight: ${fontWeight.default};
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid ${color.greyscale.grey3};
`

Tooltip.testid = 'Tooltip'

export function Tooltip({ text, testid }: TooltipProps) {
  return (
    <TooltipStyled id="tooltip" data-testid={composeTestID(Tooltip.testid, testid)}>
      {text}
    </TooltipStyled>
  )
}
