import * as React from 'react'
import { input } from 'stablr/styles'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface InputTypeProps {
  children: string
}

const BORDER_MARGIN = spacing.xs

const InputTypeStyled = styled.div`
  min-width: 100px;
  border: solid ${color.greyscale.grey5} 1px;
  border-radius: 3px;
  min-height: ${input.height};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.greyscale.black};
  padding: ${spacing.m} ${spacing.m} ${spacing.m} ${spacing.m};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  position: relative;

  &::after {
    content: '';
    height: calc(100% - ${BORDER_MARGIN} * 2);
    box-sizing: border-box;
    margin: ${BORDER_MARGIN} 0;
    width: 0;
    border-left: solid ${color.greyscale.grey5} 1px;
    position: absolute;
    right: 0;
    top: 0;
  }
`

InputType.testid = 'InputType'

export function InputType({ children }: InputTypeProps) {
  return <InputTypeStyled>{children}</InputTypeStyled>
}
