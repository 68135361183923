import * as React from 'react'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface MenuDropDownFeatureListProps {
  children: React.ReactElement | React.ReactElement[]
}

const MenuDropDownFeatureListStyled = styled.div`
  display: flex;
  padding: ${spacing.s} 0;
`

export function MenuDropDownFeatureList({ children }: MenuDropDownFeatureListProps) {
  return <MenuDropDownFeatureListStyled>{children}</MenuDropDownFeatureListStyled>
}
