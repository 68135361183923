import { signUp } from "aws-amplify/auth";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as IconBackIOS } from "stablr/assets/icons/icon-back-ios.svg";
import { ReactComponent as IconForwardIOS } from "stablr/assets/icons/icon-forward-ios.svg";
import { ReactComponent as LockSVG } from "stablr/assets/icons/lock.svg";
import { Button, Heading, Paragraph } from "stablr/components/atoms";
import { Container, ErrorMessage, PasswordInput } from "stablr/components/molecules";
import { BottomBar, FormLayout } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import { getErrorMessage, validateFormValues } from "stablr/functions";

import { PASSWORD_SCHEMA, PasswordSchemaValidation } from "../common/passwordSchemaValidation";
import { UserInformationForm } from "./UserInformation";

interface CreatePaswordFormProps {
  password: string;
  confirmPassword: string;
}

interface CreatePasswordProps {
  onBack: () => void;
  onContinue: (email: string) => void;
  userInformation: UserInformationForm;
}

CreatePasswordComponent.testid = "createPassword";

export default function CreatePasswordComponent({ onBack, onContinue, userInformation }: CreatePasswordProps) {
  const handleOnSubmit = async (values: CreatePaswordFormProps) => {
    try {
      await signUp({
        username: userInformation.email,
        password: values.password,
        options: {
          userAttributes: {
            email: userInformation.email,
            phone_number: userInformation.mobile,
            name: userInformation.fullName,
          },
          autoSignIn: true,
        },
      });
      onContinue(userInformation.email);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <Form
      initialValues={{ password: "", confirmPassword: "" }}
      validate={validateFormValues(PasswordSchemaValidation)}
      onSubmit={handleOnSubmit}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <StepTemplate
            bottomBarElement={
              <BottomBar
                rowReversed
                rightElement={
                  <Button
                    varient="secondary"
                    tabIndex={3}
                    onClick={onBack}
                    layout="icon-left"
                    mobileLayout="icon-only"
                    icon={<IconBackIOS />}
                  >
                    Previous Step
                  </Button>
                }
                leftElement={
                  <Button
                    type="submit"
                    tabIndex={2}
                    icon={<IconForwardIOS />}
                    varient="primary"
                    layout="icon-right"
                    onClick={handleSubmit}
                    loading={submitting}
                    testid="Next"
                  >
                    Next
                  </Button>
                }
              />
            }
          >
            <Container as="section" maxWidth={1000} id="title-container">
              <header>
                <Heading as="h1" varient="h2">
                  <> 2. Create Password</>
                </Heading>
              </header>
              <Paragraph>
                Your password must be classified as at least strong. A good password consist of:
                <li>8 or more characters</li>
                <li>Mixture of letters and numbers</li>
                <li>Mixture of upper and lowercase</li>
                <li>Special characters</li>
              </Paragraph>

              <FormLayout>
                <>
                  <Field
                    label="Password"
                    autoComplete="new-password"
                    name={PASSWORD_SCHEMA.PASSWORD}
                    placeholder="Create password"
                    note="Create a strong password"
                    component={PasswordInput}
                    hasIndicator
                    tabIndex={0}
                    icon={<LockSVG />}
                  />
                  <Field
                    label="Confirm Password"
                    autoComplete="new-password"
                    icon={<LockSVG />}
                    name={PASSWORD_SCHEMA.CONFIRM_PASSWORD}
                    placeholder="Repeat Password"
                    component={PasswordInput}
                    tabIndex={1}
                  />
                  <ErrorMessage>{submitError}</ErrorMessage>
                </>
              </FormLayout>
            </Container>
          </StepTemplate>
        </form>
      )}
    </Form>
  );
}
