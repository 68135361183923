import * as React from 'react'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Button } from '../../atoms/Button'

export interface UserDropDownActionsProps {
  onLogout: () => void
  version: string
}

const UserDropDownActionsStyled = styled.div`
  margin: ${spacing.m} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VersionStyled = styled.span`
  text-decoration: underline;
  margin: ${spacing.m} 0 0 0;
  font-size: ${fontSize.small};
  color: ${color.greyscale.grey5};
  font-family: ${fontFamily.primary};
`

export function UserDropDownActions({ onLogout, version }: UserDropDownActionsProps) {
  return (
    <UserDropDownActionsStyled>
      <Button size="small" varient="line" onClick={onLogout} rounded>
        Log Out
      </Button>
      <VersionStyled>Version: {version}</VersionStyled>
    </UserDropDownActionsStyled>
  )
}
