import { APIAPIKey, APICreateAPIKey } from "types/API/APIManagement";

import Connection from "./Connection";

export const getAPIKeyList = () => Connection.get<APIAPIKey[]>("/oauth/clients");

export const getAPIKeyById = (keyId: string) => Connection.get<APIAPIKey>(`/oauth/clients/${keyId}`);

export const updateAPIKey = (id: string, data: Partial<APICreateAPIKey>) =>
  Connection.put(`/oauth/clients/${id}`, data);

export const revokeAPIKey = (apiKeyId: string) => {
  return Connection.put(`/oauth/clients/${apiKeyId}/revoke`);
};

export const createAPIKey = (data: APICreateAPIKey) => {
  return Connection.post<APIAPIKey>("/oauth/clients", data);
};
