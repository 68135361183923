import styled from 'styled-components'

export interface IconProps {
  color?: string
  size?: number
  gradient?: boolean
}

export const Icon = styled.div(
  ({ color, gradient = false, size = 14 }: IconProps) => `
  display: inline-block;

  & > svg {
    width: ${size}px;
    height: ${size}px;
    display: block;
    & > path {
      fill: ${gradient === true ? `url(#gradient)` : color} !important;
    }
  }
`,
)
