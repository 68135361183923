import React from "react";
import { Field } from "react-final-form";
import { ReactComponent as EmailIcon } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as PeopleIcon } from "stablr/assets/icons/people.svg";
import { ReactComponent as UserIcon } from "stablr/assets/icons/person.svg";
import { PhoneNumberInput, TextInput } from "stablr/components/molecules";

import { RoleMultiSelectInput } from "./RoleMultiSelectInput";
import { USER_SCHEMA } from "./schemaValidation";

interface UserFormProps {
  disabled?: boolean;
}

export default function UserInfoForm({ disabled = false }: UserFormProps) {
  return (
    <>
      <Field
        name={USER_SCHEMA.NAME}
        label="Name"
        placeholder="Name"
        component={TextInput}
        disabled={disabled}
        icon={<UserIcon />}
        compact
      />
      <Field
        name={USER_SCHEMA.PHONE}
        disabled={disabled}
        label="Mobile Phone Number"
        component={PhoneNumberInput}
        compact
      />
      <Field
        name={USER_SCHEMA.COMPANY_EMAIL}
        label="Company Email Address"
        disabled
        placeholder="email@stablr.com"
        component={TextInput}
        icon={<EmailIcon />}
        compact
      />
      <Field
        placeholder="Role"
        label="Role"
        iconElement={<PeopleIcon />}
        name={USER_SCHEMA.ROLE}
        component={RoleMultiSelectInput}
        disabled={disabled}
      />
    </>
  );
}
