export default function parseBadgeVarient(value?: string) {
  if (value === undefined) return undefined;

  switch (value.toUpperCase()) {
    case "INITIALIZED": {
      return "info";
    }
    case "SUBMITTED": {
      return "info";
    }
    case "REJECTED": {
      return "error";
    }
    case "VALIDATED": {
      return "success";
    }
    default:
      return undefined;
  }
}
