import * as React from 'react'
import { Link } from 'react-router-dom'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface UserDropDownFootLinksProps {
  links: { to: string; label: string; external?: boolean }[]
}

const UserDropDownFootLinksStyled = styled.div`
  display: flex;
  margin: ${spacing.m} 0;
`

const FootLinkStyled = styled.a`
  color: ${color.greyscale.black};
  font-size: ${fontSize.small};
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  text-decoration: underline;
  padding: 0 ${spacing.xs};
`

export function UserDropDownFootLinks({ links }: UserDropDownFootLinksProps) {
  return (
    <UserDropDownFootLinksStyled>
      {links.map(({ to, label, external }) =>
        external === true ? (
          <FootLinkStyled key={to} href={to} target="_blank">
            {label}
          </FootLinkStyled>
        ) : (
          <FootLinkStyled key={to} as={Link} to={to}>
            {label}
          </FootLinkStyled>
        ),
      )}
    </UserDropDownFootLinksStyled>
  )
}
