import * as React from 'react'
import media from 'stablr/styles/media'
import styled from 'styled-components'

export interface DashboardTemplateProps {
  navigationBarElement: React.ReactElement
  headerNavigationElement: React.ReactElement
  children: React.ReactElement
}

const DashboardTemplateStyled = styled.div`
  @media ${media.mobile} {
    min-height: 100%;
  }
  @media ${media.desktop} {
    width: 100%;
    height: 100%;
    display: flex;
  }
`

const DashboardTemplateMainStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media ${media.mobile} {
    flex-grow: 1;
  }
  @media ${media.desktop} {
    flex: 1;
  }
`

const DashboardTemplateChildrenStyled = styled.main`
  background-color: #fbfbfb;
  overflow-y: auto;
  @media ${media.mobile} {
    flex-grow: 1;
  }
  @media ${media.desktop} {
    flex: 1;
  }
`

DashboardTemplate.testid = 'dashboardTemplate'

export function DashboardTemplate({
  navigationBarElement,
  headerNavigationElement,
  children,
}: DashboardTemplateProps) {
  return (
    <DashboardTemplateStyled>
      {navigationBarElement}
      <DashboardTemplateMainStyled>
        {headerNavigationElement}
        <DashboardTemplateChildrenStyled>{children}</DashboardTemplateChildrenStyled>
      </DashboardTemplateMainStyled>
    </DashboardTemplateStyled>
  )
}
