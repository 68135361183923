import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionModal } from "stablr/components/organisms";
import { color } from "stablr/styles";

export interface ExitFormModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}
export default function ExitFormModal({ openModal, setOpenModal }: ExitFormModalProps) {
  const navigate = useNavigate();

  const onCloseModal = () => setOpenModal(false);

  const navigateBack = async () => {
    navigate(-1);
  };

  return (
    <>
      <ActionModal
        open={openModal}
        onClose={onCloseModal}
        title="Are you sure you want to exit?"
        description="Your changes will not be saved"
        btnText="Yes exit"
        marginSize="small"
        btnBackgroundColor={color.greyscale.black}
        btnColor={color.greyscale.white}
        onConfirm={navigateBack}
      />
    </>
  );
}
