import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useUser } from "./use-user";
import useUsers from "./use-users";

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToast();
  return useMutation({
    mutationFn: (userId: string) => api.User.deleteUser(userId),
    onSuccess: async (result, userId: string) => {
      await queryClient?.invalidateQueries({ queryKey: [useUsers.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useUser.queryKey, userId] });
      successToast({ message: "User deleted succesfully" });
    },
    onError: (error: Error) => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
