import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import styled from 'styled-components'

export type SupportedImageType = 'webp' | 'png' | 'jpg'

export interface PictureProps {
  testid?: string
  src: string
  supportedTypes: SupportedImageType[]
  alt: string
  className?: string
  children?: React.ReactElement
}

const PictureStyled = styled.picture``

Picture.testid = 'picture'

export function Picture({
  testid,
  src,
  supportedTypes,
  alt,
  className,
  children,
  title,
}: PictureProps) {
  return (
    <PictureStyled className={className} data-testid={composeTestID(Picture.testid, testid)}>
      {supportedTypes.includes('webp') && <source srcSet={`${src}.webp`} type="image/webp" />}
      {supportedTypes.includes('jpg') && <source srcSet={`${src}.jpg`} type="image/jpg" />}
      {supportedTypes.includes('png') && <source srcSet={`${src}.png`} type="image/png" />}
      <img src={`${src}.${supportedTypes[0]}`} alt={alt} />
      {children}
    </PictureStyled>
  )
}
