import * as React from 'react'
import { Link, LinkProps as LinkComponentProps } from 'react-router-dom'
import { composeTestID } from 'stablr/functions/compose-test-id'
import colorStyle from 'stablr/styles/color'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface LinkProps extends LinkComponentProps {
  testid?: string
  color?: string
  external?: boolean
}

const LinkStyled = styled.a(
  ({ color }: Pick<LinkProps, 'color'>) => `
  color: ${color ?? colorStyle.theme.primary};
  font-weight: ${fontWeight.bold};
  text-decoration: none;
`,
)

InlineLink.testid = 'link'

export function InlineLink({ external = false, ...props }: LinkProps) {
  return external === true ? (
    <LinkStyled
      data-testid={composeTestID(InlineLink.testid, props.testid)}
      href={props.to as string}
      {...props}
    />
  ) : (
    <LinkStyled
      as={Link}
      data-testid={composeTestID(InlineLink.testid, props.testid)}
      {...props}
    ></LinkStyled>
  )
}
