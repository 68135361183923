import { useMutation } from "@tanstack/react-query";
import { updateMFAPreference } from "aws-amplify/auth";
import { useToast } from "stablr/hooks";

export function useSetMFAPreference() {
  const { successToast } = useToast();
  return useMutation(
    async (preference: "SMS" | "TOTP") =>
      updateMFAPreference({
        sms: preference === "SMS" ? "ENABLED" : "DISABLED",
        totp: preference === "TOTP" ? "ENABLED" : "DISABLED",
      }),
    {
      onSuccess: async (_: void, preference: "SMS" | "TOTP") => {
        successToast({ message: `MFA has been set to ${preference}` });
      },
    }
  );
}
