import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-calculate";
import withPermission from "hocs/permission";
import { useBankAccounts } from "hooks/query/bank-account/use-bank-accounts";
import { useCreateTransaction } from "hooks/query/transaction/use-create-transaction";
import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import { BuySellEmptyState } from "pages/BuySell/components/BuySellEmptyState";
import BuyWizard from "pages/BuySell/components/BuyWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import * as React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Button, Spacer } from "stablr/components/atoms";
import { ErrorMessage } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APIWalletListItem } from "types/API/Wallet";

import BuyOrderForm from "../common/BuyOrderForm";
import { BuyOrderSchemaValidation } from "../common/schemaValidation";
import BuyTransactionTable from "./components/BuyTransactionTable";

interface BuyFormValues {
  wallet?: APIWalletListItem;
  currentBalance?: string;
  buy?: number;
  reference?: string;
  newBalance?: string;
  pay?: string;
}

interface BuyScreenProps {
  title: string;
  body: string;
}

const BuyScreenStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormContainer = styled(BuySellContainer)`
  flex: 1;
  padding: 0 ${spacing.xxl};
  margin-top: -50px;

  @media ${media.mobile} {
    padding: ${spacing.m};
    margin-top: -${spacing.xl};
  }
`;

const PAY_RATE = 1;

// Create Decorator
const decorator = createDecorator(
  // Calculations:
  {
    field: "wallet", // when the value of foo changes...
    updates: {
      currentBalance: wallet => wallet.Balance.Amount,
      newBalance: (wallet, values?: BuyFormValues) =>
        parseFloat(wallet.Balance.Amount) + (values?.buy === undefined ? 0 : parseFloat(values.buy.toString())),
    },
  },
  {
    field: "buy", // when the value of foo changes...
    updates: {
      newBalance: (buy, values?: BuyFormValues) =>
        (values?.wallet !== undefined ? parseFloat(values?.wallet.Balance.Amount.toString()) : 0) +
        (buy === undefined ? 0 : parseFloat(buy.toString())),
      pay: buy => (buy === undefined ? undefined : (parseFloat(buy.toString()) * PAY_RATE).toFixed(2)),
    },
  }
);

function BuyScreenComponent({ title, body }: BuyScreenProps) {
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>("Ethereum");
  const [showTransactionTable, setShowTransactionTable] = React.useState<boolean>(false);

  const { mutateAsync: mutateAsyncCreateTransaction } = useCreateTransaction();
  const { data: banks, isLoading: isLoadingBank } = useBankAccounts();
  const navigate = useNavigate();

  const approvedBankAccounts = banks?.filter(bank => bank.Status === "APPROVED");

  const handleShowTransactionTable = (values: BuyFormValues) => {
    if (values?.wallet && values?.buy) {
      setShowTransactionTable(true);
    }
  };

  const handleOnSubmit = async (values: BuyFormValues) => {
    if (values?.wallet?.Address === undefined || values?.buy === undefined) return;

    try {
      const response = await mutateAsyncCreateTransaction({
        type: "buy",
        WalletAddress: values?.wallet?.Address,
        FiatAmount: values?.buy?.toString(),
        ClientReference: values?.reference ?? "",
      });
      const transactionId = response?.OrderId;
      navigate(`/buy/${transactionId}`);
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <BuyScreenStyled>
      <StepWizard title={title} body={body} onNetworkChange={setSelectedNetwork} showNetworkModal />
      <BuyWizard />
      <Form<BuyFormValues>
        decorators={[decorator]}
        onSubmit={handleOnSubmit}
        validate={validateFormValues(BuyOrderSchemaValidation)}
      >
        {({ handleSubmit, submitting, values, submitError }) => {
          handleShowTransactionTable(values);

          return (
            <>
              <FormContainer as={"form"}>
                {isLoadingBank || (approvedBankAccounts !== undefined && approvedBankAccounts.length > 0) ? (
                  <>
                    <BuyOrderForm
                      transaction={values}
                      bank={approvedBankAccounts ? approvedBankAccounts[0] : undefined}
                      isLoadingBank={isLoadingBank}
                      selectedNetwork={selectedNetwork}
                    />
                    <Spacer height={spacing.m} />
                    {showTransactionTable && <BuyTransactionTable transaction={values} />}
                    {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                    <BottomBar
                      paddingHorizontal="0"
                      rightElement={
                        <Button onClick={handleSubmit} loading={submitting}>
                          Continue
                        </Button>
                      }
                    />
                  </>
                ) : (
                  <BuySellEmptyState
                    title={"No approved bank account(s)"}
                    text={"You must have at least one approved bank account before you can make a buy or sell order."}
                  />
                )}
              </FormContainer>
            </>
          );
        }}
      </Form>
    </BuyScreenStyled>
  );
}

export default withPermission(PERMISSION_ENUM.BUY_EURR)(BuyScreenComponent);
