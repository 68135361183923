import { Menu as HeadlessMenu } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as MenuIcon } from 'stablr/assets/icons/more-horiz.svg'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Button } from '../../atoms/Button'
import { Icon } from '../../atoms/Icon'

export interface MenuItem {
  label: string
  icon?: React.ReactElement
  onClick?: () => void
  iconBgColor?: string
  iconColor?: string
}

export interface MenuProps {
  options: MenuItem[]
  disabled?: boolean
}

const HeadlessMenuStyled = styled.div`
  display: flex;
  position: relative;

  &.disabled {
    background-color: ${color.greyscale.grey1};

    & button {
      background-color: ${color.greyscale.grey1};
      cursor: default;
    }
  }
`

const HeadlessMenuItemsStyled = styled.div`
  width: 100%;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  position: absolute;
  min-height: 10px;
  background-color: ${color.greyscale.white};
  list-style-type: none;
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
  z-index: 100;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 12px ${color.greyscale.grey3};
  border-radius: 4px;
  padding: ${spacing.xs};

  & button {
    border-bottom: 1px solid ${color.greyscale.grey2};

    &:last-child {
      border-bottom: 0;
    }
  }
`

const HeadlessMenuItemStyled = styled.button`
  width: 100%;
  background-color: ${color.greyscale.white};
  padding: ${spacing.s} ${spacing.m} ${spacing.s} ${spacing.l};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${color.greyscale.black};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  text-align: left;

  & > *:first-child {
    position: absolute;
    left: ${spacing.s};
  }
`

const IconStyled = styled.div<{ iconBgColor?: string }>`
  border-radius: 50%;
  margin-right: ${spacing.s};
  background-color: ${({ iconBgColor }) => iconBgColor || color.palette.purple};
`

HeadlessMenuList.testid = 'HeadlessMenuList'

export function HeadlessMenuList({ options }: MenuProps) {
  return (
    <HeadlessMenuStyled>
      <HeadlessMenu>
        <HeadlessMenu.Button
          as={Button}
          icon={<MenuIcon />}
          layout="icon-only"
          backgroundColor={color.other.transparent}
          iconColor={color.greyscale.grey5}
          iconSize={20}
        ></HeadlessMenu.Button>

        <HeadlessMenu.Items as={HeadlessMenuItemsStyled}>
          {options?.map(
            ({ icon, label, iconBgColor, iconColor, onClick }: MenuItem, id: number) => {
              return (
                <HeadlessMenu.Item as={React.Fragment} key={`${label}-${id}`}>
                  {() => {
                    return (
                      <HeadlessMenuItemStyled onClick={onClick}>
                        {icon && (
                          <Icon
                            as={IconStyled}
                            size={18}
                            color={iconColor}
                            iconBgColor={iconBgColor}
                          >
                            {icon}
                          </Icon>
                        )}
                        {label}
                      </HeadlessMenuItemStyled>
                    )
                  }}
                </HeadlessMenu.Item>
              )
            },
          )}
        </HeadlessMenu.Items>
      </HeadlessMenu>
    </HeadlessMenuStyled>
  )
}
