import * as amplitude from "@amplitude/analytics-browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { Config } from "config";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/globalStyles";

/** Configure amplify AWS users*/
Amplify.configure(Config.awsEnv);
const existingConfig = Amplify.getConfig();

Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      "stablr-api": {
        endpoint: process.env.REACT_APP_BASE_URL ?? "",
        region: process.env.REACT_APP_AWS_COGNITO_REGION, // Optional
      },
    },
  },
});

/** Initialise Amplitude (Logging) */
if (Config.logging.amplitudeApiKey) amplitude.init(Config.logging.amplitudeApiKey);

/** Configure reactquery */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
