import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import { useUpdateWebhook } from "hooks/query/webhooks/use-update-webhook";
import { useWebhook } from "hooks/query/webhooks/use-webhook";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spacer } from "stablr/components/atoms";
import { ButtonContainer, CardError, CardLoader, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { color } from "stablr/styles";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";
import { APIWebookStatus } from "types/API/Webhooks";

import DeleteWebhook from "../common/DeleteWebhook";
import ExitFormModal from "../common/ExitFormModal";
import { WebhookSchemaValidation } from "../common/schemaValidation";
import WebhookForm from "../common/WebhookForm";

export interface WebhookFormValues {
  Name: string;
  Url: string;
  Secret: string;
  Status: APIWebookStatus;
}
const HeaderContainerStyled = styled.div`
  align-items: center;
  display: flex;
`;

function EditWebhook() {
  const navigate = useNavigate();
  const { webhookId = "" } = useParams();
  const [openExitModal, setOpenExitModal] = useState(false);
  const { data: webhook, isLoading, isError, error, refetch } = useWebhook(webhookId);
  const { mutateAsync: mutateAsyncUpdateWebhook, isLoading: updatingWebhook } = useUpdateWebhook();

  const handleOnWebhookUpdate = async (webhook: WebhookFormValues) => {
    if (updatingWebhook) return;
    try {
      await mutateAsyncUpdateWebhook(
        {
          webhookId,
          webhookUpdated: { ...webhook, Status: webhook.Status ? "Enabled" : "Disabled" },
        },
        { onSuccess: () => navigate(-1) }
      );
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleOnBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate(-1);
    }
  };

  if (isLoading) return <CardLoader />;

  if (isError) return <CardError title="Error" body={getErrorMessage(error)} onRetry={refetch} />;

  return (
    <>
      <Form
        initialValues={{ ...webhook, Status: webhook?.Status === "Enabled" }}
        onSubmit={handleOnWebhookUpdate}
        validate={validateFormValues(WebhookSchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError, values, dirty }) => {
          const isDisabled = submitting;
          return (
            <>
              <HeaderContainerStyled>
                <HeaderBackNav
                  title={webhook?.Name || ""}
                  onBack={(event: React.MouseEvent) => {
                    event.preventDefault();
                    handleOnBack(dirty);
                  }}
                  testid="edit-webhook"
                ></HeaderBackNav>
              </HeaderContainerStyled>
              <form onSubmit={handleSubmit}>
                <WebhookForm disabled={isDisabled || isError} status={values.Status} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

                <ButtonContainer justifyContent="flex-start">
                  <Button type="submit" varient="primary" color={color.greyscale.white} loading={isDisabled}>
                    Save
                  </Button>
                  <Spacer width={spacing.l} />
                  {webhook && <DeleteWebhook name={webhook.Name} webhookId={webhook.Id} />}
                </ButtonContainer>
                <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_WEBHOOKS)(EditWebhook);
