import * as React from 'react'
import color from 'stablr/styles/color'
import input from 'stablr/styles/input'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface FieldSetProps {
  children: React.ReactElement | React.ReactElement[]
}

const PADDING = spacing.m
const MAX_WIDTH = input.maxWidth

const FieldSetStyled = styled.fieldset`
  padding: ${PADDING};
  border: none;
  width: calc(100% + ${PADDING} * 2);
  max-width: min(${MAX_WIDTH}, 100%);
  background-color: ${color.theme.primaryLight};
  margin: 0 -${PADDING};
  border-radius: 26px;
  box-sizing: content-box;
`

export function FieldSet({ children }: FieldSetProps) {
  return <FieldSetStyled>{children}</FieldSetStyled>
}
