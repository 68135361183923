import React from 'react'
import { ReactComponent as WarningIcon } from 'stablr/assets/icons/warning.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../Icon'

export interface AlertProps {
  message: string | React.ReactElement
  iconElement?: React.ReactElement
  testid?: string
  iconSize?: number
  iconColor?: string
}

const AlertStyled = styled.div`
  padding: ${spacing.s} ${spacing.m};
  display: flex;
  align-items: flex-start;
  max-width: 500px;
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.default};
  line-height: 21px;
  border-radius: 3px;
  box-shadow: 5px 2px 10px ${color.greyscale.grey3};

  & > div > svg {
    margin-right: ${spacing.m};
  }
`

Alert.testid = 'Alert'

export function Alert({ message, iconElement, testid, iconSize, iconColor }: AlertProps) {
  return (
    <AlertStyled data-testid={composeTestID(Alert.testid, testid)}>
      <Icon color={iconColor} size={iconSize ?? 18}>
        {iconElement || <WarningIcon />}
      </Icon>
      {message}
    </AlertStyled>
  )
}
