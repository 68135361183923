import { Config, Constants } from "config";
import { TestCookies } from "cookies/Test";
import * as React from "react";
import { color, Container } from "stablr";
import { Alert, Button, ComboBox, Label, Paragraph, Spacer } from "stablr/components/atoms";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";

import { ValidateTransactionHash } from "./components/ValidateTransactionHash";

const TestStyled = styled(Container)`
  background-color: white;
  padding: ${spacing.m};
`;

// Values that can override the config in test environments
const BLANK_TEST_CONFIG = Constants;

const DropdownContainerStyled = styled.div`
  width: 300px;
`;

const testConfig = TestCookies.get("testConfig");

export default function TestPage() {
  const featuresConfig = Config.features;
  const web3Config = Config.web3;

  const handleOnToggleFeature = (key: keyof typeof Constants.features) => {
    const newConfig = {
      features: {
        ...(testConfig?.features || {}), // Provide a default empty object if testConfig is null or undefined
        [key]: testConfig?.features?.[key] !== undefined ? !testConfig?.features?.[key] : !Constants.features[key],
      },
      web3: { ...(testConfig?.web3 || {}) },
    };
    TestCookies.set("testConfig", newConfig);
    window.location.reload();
  };

  const handleOnSwapNetwork = (newNetwork: "sepolia" | "mainnet" | string) => {
    // Triggered in the life cycle. Test page so low priority to look into
    if (newNetwork === "" || newNetwork === web3Config.network) {
      return;
    }
    const testConfig = TestCookies.get("testConfig");
    const newConfig = { ...BLANK_TEST_CONFIG, ...(testConfig || {}), web3: { network: newNetwork } };

    TestCookies.set("testConfig", newConfig);

    window.location.reload();
  };

  const handleOnToggleMockMode = () => {
    if (Config.mocked === true) {
      TestCookies.remove("mocked");
    } else {
      TestCookies.set("mocked", "1");
    }
    window.location.reload();
  };

  const resetConfig = () => {
    TestCookies.set("testConfig", {});
    window.location.reload();
  };

  return (
    <TestStyled>
      <>
        Mock Data: {Config.mocked === true ? "On" : "Off"}
        <Button onClick={() => handleOnToggleMockMode()}>
          {`Turn Mock Mode ${Config.mocked === true ? "Off" : "On"}`}
        </Button>
        <Spacer height={spacing.m} />
        {Object.entries(Constants.features).map((item: [string, boolean]) => (
          <React.Fragment key={item[0]}>
            Default value: {item[1] === true ? "On" : "Off"}
            <Button onClick={() => handleOnToggleFeature(item[0] as keyof typeof Constants.features)}>
              {`${item[0]} (${featuresConfig?.[item[0] as keyof typeof featuresConfig] === true ? "On" : "Off"})`}
            </Button>
            <Spacer height={spacing.m} />
          </React.Fragment>
        ))}
        <Spacer height={spacing.m} />
        <DropdownContainerStyled>
          <Label>{`Network (Default: ${Constants.web3.network}, Active: ${web3Config.network})`}</Label>
          <ComboBox
            name="Network"
            value={web3Config.network}
            options={[
              {
                id: "mainnet",
                value: "mainnet",
                label: "Mainnet",
              },
              {
                id: "sepolia",
                value: "sepolia",
                label: "Sepolia (Test Net)",
              },
            ]}
            onSelectionChange={handleOnSwapNetwork}
          />
          <Paragraph>
            This will update the network for all <b>non-backend (web3)</b> interactions and <b>Wallet Connect</b>{" "}
            integrations.
          </Paragraph>
          <Spacer height={spacing.l} />
          <ValidateTransactionHash />
        </DropdownContainerStyled>
        <Spacer height={spacing.xl} />
        <Button backgroundColor={color.palette.red} onClick={resetConfig}>
          Remove All Test Settings
        </Button>
        <Spacer height={spacing.xl} />
        <Alert message={"Refresh required to apply changes"}></Alert>
      </>
    </TestStyled>
  );
}
