import * as React from 'react'
import styled from 'styled-components'

export interface SpacerProps {
  height?: string
  width?: string
}

const SpacerStyled = styled.div``

export function Spacer({ height, width }: SpacerProps) {
  return <SpacerStyled style={{ width: width ?? '100%', height: height ?? '100%' }}></SpacerStyled>
}
