import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APIAPIKey, APICreateAPIKey } from "types/API/APIManagement";

import { useAPIKey } from "./use-api-key";
import useAPIKeys from "./use-api-keys";

export function useUpdateAPIKey() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ apiKeyId, apiKeyUpdated }: { apiKeyId: string; apiKeyUpdated: APIAPIKey }) => {
      const updatedAPIKey: APICreateAPIKey = {
        Name: apiKeyUpdated.Name,
        WhitelistedIPs: apiKeyUpdated.WhitelistedIPs,
        // Role: application.Role,
      };
      return api.APIManagement.updateAPIKey(apiKeyId, updatedAPIKey);
    },
    onSuccess: async (_: unknown, { apiKeyId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useAPIKeys.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useAPIKey.queryKey, apiKeyId] });
      successToast({ message: "API Key has been updated successfully" });
    },
  });
}
