import { PERMISSION_ENUM } from "config/permissions";
import withPermission from "hocs/permission";
import React from "react";
import { Route, Routes } from "react-router-dom";

import TransactionView from "./TransactionView";

function Transactions() {
  return (
    <Routes>
      <Route path="*" element={<TransactionView />} />
    </Routes>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_TRANSACTIONS)(Transactions);
