import useTransactions from "hooks/query/transaction/use-transactions";
import * as React from "react";
import { ReactComponent as ClearAllSVG } from "stablr/assets/icons/clear-all.svg";
import { CardEmpty, CardError, CardLoader, CardTitle, CardWrapper } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import { color, media, spacing } from "stablr/styles";
import styled from "styled-components";

import TransactionsTable from "./TransactionsTable";

const LARGE_DEFAULT_HEIGHT = "650px";
const SMALL_DEFAULT_HEIGHT = "650px";
interface RecentActivityCardProps {
  title: string;
  buttonElement?: React.ReactElement;
}

const RecentActivityCardStyled = styled(CardWrapper)`
  background: ${color.greyscale.white};
  margin-top: ${spacing.l};
  padding-bottom: ${spacing.l};
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${media.mobile} {
    min-height: ${SMALL_DEFAULT_HEIGHT};
  }
  @media ${media.desktop} {
    min-height: ${LARGE_DEFAULT_HEIGHT};
  }
`;

export default function RecentActivityCardComponent({ title, buttonElement }: RecentActivityCardProps) {
  const { data, isLoading, error, refetch } = useTransactions();

  return (
    <RecentActivityCardStyled>
      <CardTitle title={title} buttonElement={buttonElement} />
      {isLoading ? (
        <CardLoader />
      ) : error || data === undefined ? (
        <CardError title="Cannot load Transaction(s)" body={getErrorMessage(error)} onRetry={refetch} />
      ) : data.length === 0 ? (
        <CardEmpty
          title="No transactions found"
          body="There are no transactions found at the moment"
          iconElement={<ClearAllSVG />}
        />
      ) : (
        <TransactionsTable data={data.slice(0,5)} />
      )}
    </RecentActivityCardStyled>
  );
}
