import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Config } from "config";
import differenceInMinutes from "date-fns/differenceInMinutes";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Table } from "stablr/components/atoms";
import { capitalize } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { spacing } from "stablr/styles";
import colors from "stablr/styles/color";
import styled from "styled-components";
import { APITransactionListItem } from "types/API/Transaction";

const {
  TableBody,
  TableData,
  TableHeader,
  TableRow,
  TableHead,
  TableRowBadge,
  TableDataCurrency,
  Table: TableComponent,
} = Table;
interface TransactionsTableProps {
  data: APITransactionListItem[];
}

const TransactionsTableStyled = styled(TableComponent)`
  width: 100%;

  & .currency {
    & > p {
      margin-top: ${spacing.xxs};
      color: rgba(0, 0, 0, 0.4);
    }
  }

  & tbody > tr {
    border-bottom: 1px solid ${colors.greyscale.grey3};
  }
`;

const TransactionsTableWrapperStyled = styled.div`
  width: 100%;
  padding: 0 ${spacing.m};
  overflow-x: auto;
`;

const columnHelper = createColumnHelper<APITransactionListItem>();

const columns = [
  columnHelper.accessor("Type", {
    header: "Type",
    cell: info => <b>{capitalize(info.getValue())}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("DigitalAmount", {
    header: "Amount",
    cell: info => (
      <TableDataCurrency
        digitalAmount={{ Amount: info.getValue().Amount, Denominator: Config.fallbackDenominator as "," }}
        fiatAmount={{ Amount: info.row.original.FiatAmount.Amount, Denominator: Config.fallbackDenominator as "," }}
      />
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("CreatedAt", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Reference", {
    header: "Notes",
    cell: info => info.getValue() ?? "-",
    footer: info => info.column.id,
  }),
  columnHelper.accessor("WalletInfo.WalletName", {
    header: "Wallet",
    cell: info => (info.getValue() && info.getValue() !== "" ? info.getValue() : "-"),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("PaymentReferenceId", {
    header: "Payment Reference",
    cell: info => info.getValue() ?? "-",
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Status", {
    header: "Status",
    cell: info =>
      info.getValue()?.trim() ? (
        <Badge
          varient={getTransactionBadgeVarient(info?.getValue())}
          text={parseTransactionBadgeText(info.getValue())}
        ></Badge>
      ) : undefined,
    footer: info => info.column.id,
  }),
];

const mobileColumns = [columns[0], columns[1], columns[2]];

export default function TransactionsTableComponent({ data }: TransactionsTableProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = React.useCallback(
    (data: APITransactionListItem) => {
      logEvent(EVENT_TYPE_ENUM.CLICK, "TransactionItem-TransactionOverview", {
        Type: data.Type,
        Status: data.Status,
        AgeInMinutes: differenceInMinutes(new Date(), new Date(data.CreatedAt)),
      });
      if (data.Type === "Buy") {
        navigate(`/buy/${data.Id}`);
      } else {
        navigate(`/sell/${data.Id}`);
      }
    },
    [navigate]
  );

  return (
    <TransactionsTableWrapperStyled>
      <TransactionsTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    paddingSize="small"
                    key={header.id}
                    textAlign={header.column.columnDef.header === "Amount" ? "right" : "left"}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => {
              const isRejected = row.original.Status === "REJECTED";
              return (
                <React.Fragment key={row.id}>
                  {isMobile && (
                    <TableRowBadge
                      colSpan={4}
                      onRowClick={() => onRowClick(row.original)}
                      badgeElement={
                        <Badge
                          varient={getTransactionBadgeVarient(row.original?.Status)}
                          text={parseTransactionBadgeText(row.original?.Status)}
                        />
                      }
                    ></TableRowBadge>
                  )}
                  <TableRow key={row.id} onRowClick={!isRejected ? () => onRowClick(row.original) : undefined}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableData paddingSize="small" key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableData>
                      );
                    })}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </>
        </TableBody>
      </TransactionsTableStyled>
    </TransactionsTableWrapperStyled>
  );
}
