import React, { memo, useMemo } from "react";
import { Field, useFormState } from "react-final-form";
import { FileUploadInput, FormInformationList, ListBoxInput, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import styled from "styled-components";

import { BANK_ACCOUNT_SCHEMA } from "./schemaValidation";

const FormLayoutStyled = styled.div`
  & > div :last-child {
    margin-bottom: 0;
  }
`;

interface AccountDetail {
  name: string;
  label: string;
  placeholder: string;
  isEditable: boolean;
  parse?: (value: string) => string;
  type?: string[];
}

function AccountFieldComponent({
  isEditForm,
  disabled,
}: {
  isEditForm?: boolean;
  disabled?: boolean;
  accountType?: string;
}) {
  const {
    values: { Type },
  } = useFormState();
  const normaliseIBAN = (value: string) => {
    return value.replace(/ /g, "");
  };

  const normalisePostalCode = (value: string) => {
    return value.toUpperCase();
  };

  const AccountFields: AccountDetail[] = useMemo(
    () => [
      {
        name: BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME,
        label: "Bank Account Holder",
        placeholder: "Bank Account Holder",
        isEditable: true,
      },
      {
        name: BANK_ACCOUNT_SCHEMA.IBAN,
        label: "IBAN / Account Number",
        parse: normaliseIBAN,
        placeholder: "NL00RABO0123456789",
        isEditable: isEditForm ? false : true,
        type: ["SEPA", "SWIFT"],
      },
      {
        name: BANK_ACCOUNT_SCHEMA.BIC,
        label: "BIC/SWIFT",
        placeholder: "BIC/SWIFT",
        isEditable: isEditForm ? false : true,
        type: ["SEPA", "SWIFT"],
      },
      {
        name: BANK_ACCOUNT_SCHEMA.BLINC_ID,
        label: "Blinc Id",
        placeholder: "Blinc Id",
        isEditable: isEditForm ? false : true,
        type: ["BLINC"],
      },
      {
        name: BANK_ACCOUNT_SCHEMA.BANK_NAME,
        label: "Bank Name",
        placeholder: "Bank Name",
        isEditable: isEditForm ? false : true,
      },
      {
        name: BANK_ACCOUNT_SCHEMA.BANK_ADDRESS,
        label: "Bank Address",
        placeholder: "Bank Address",
        isEditable: isEditForm ? false : true,
      },
      {
        name: BANK_ACCOUNT_SCHEMA.BANK_POSTAL_CODE,
        label: "Bank Postal Code",
        placeholder: "Bank Postal Code",
        parse: normalisePostalCode,
        isEditable: isEditForm ? false : true,
      },
      {
        name: BANK_ACCOUNT_SCHEMA.CITY,
        label: "City",
        placeholder: "City",
        isEditable: true,
      },
      {
        name: BANK_ACCOUNT_SCHEMA.COUNTRY,
        label: "Country",
        placeholder: "Country",
        isEditable: true,
      },
    ],
    [isEditForm]
  );

  return (
    <>
      <FormLayoutStyled>
        <FormLayout columns={1}>
          <Field
            name={BANK_ACCOUNT_SCHEMA.TYPE}
            component={ListBoxInput}
            label="Account Type"
            placeholder="Account Type"
            options={[
              { value: "SEPA", label: "SEPA" },
              { value: "BLINC", label: "BLINC" },
            ]}
          />
        </FormLayout>
      </FormLayoutStyled>
      <FormLayout columns={2}>
        <>
          {AccountFields.filter(item => item?.type === undefined || item.type.includes(Type)).map(
            ({ name, label, placeholder, isEditable, type, ...rest }) => (
              <Field
                key={name}
                name={name}
                label={label}
                placeholder={placeholder}
                component={TextInput}
                compact
                disabled={!isEditable || disabled}
                {...rest}
              />
            )
          )}
        </>
      </FormLayout>
      {!isEditForm ? (
        <FormLayout columns={2}>
          <Field
            name={BANK_ACCOUNT_SCHEMA.BANK_STATEMENT}
            component={FileUploadInput}
            label="Bank Statement"
            placeholder="Add Bank Statement"
            description="(.PDF, .PNG, .JPG)"
          />
          <FormInformationList
            title="Bank Statement should:"
            bulletPoints={[
              "Not be older then 3 Months.",
              "Contain the Account Number, Account Holder and Ownership details clear and readable.",
            ]}
          />
        </FormLayout>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(AccountFieldComponent);
