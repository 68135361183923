import { PERMISSION_ENUM } from "config/permissions";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "stablr/components/atoms";
import { Modal } from "stablr/components/molecules";
import { usePermission } from "stablr/hooks";
import { color } from "stablr/styles";

const withPermission = (requiredPermission: PERMISSION_ENUM) => (WrappedComponent: React.ElementType) => {
  //eslint-disable-next-line
  const EnhancedComponent = (props: any) => {
    const hasPermission = usePermission();
    const navigate = useNavigate();

    if (!hasPermission(requiredPermission)) {
      return (
        <Modal
          title="Permission Required"
          modalBody={<p>You do not have permission to perform this action</p>}
          open={true}
          modalFooter={
            <Button varient="primary" backgroundColor={color.greyscale.black} onClick={() => navigate("/dashboard")}>
              Go Back to Dashboard
            </Button>
          }
        />
      );
    }
    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};

export default withPermission;
