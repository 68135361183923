import { APIWallet, APIWalletListItem } from "types/API/Wallet";

export const MOCK_WALLET_LIST: APIWalletListItem[] = [
  {
    Network: "Ethereum",
    OrganizationId: "q234",
    Id: "WALLET_1",
    Name: "Demo 1 Wallet",
    Status: "APPROVED",
    Address: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69d0",
    Balance: {
      Amount: 180000,
      Currency: "EURR",
    },
    FireblocksWalletId: "3487628yg4873y4r",
    Created: "12-12-2023 12:43:12",
    Type: "MetaMask",
  },
  {
    Network: "Ethereum",
    OrganizationId: "q234",
    Id: "WALLET_2",
    Name: "Demo 2 Wallet",
    Status: "APPROVED",
    Address: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69c9",
    Balance: {
      Amount: 130000,
      Currency: "EURR",
    },
    FireblocksWalletId: "3487628yg4873y4r",
    Created: "12-12-2023 12:43:12",
    Type: "MetaMask",
  },
  {
    Network: "Ethereum",
    OrganizationId: "q234",
    Id: "WALLET_3",
    Name: "Demo 3 Wallet",
    Status: "APPROVED",
    Address: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a6942",
    Balance: {
      Amount: 80000,
      Currency: "EURR",
    },
    FireblocksWalletId: "3487628yg4873y4r",
    Created: "12-12-2023 12:43:12",
    Type: "MetaMask",
  },
  {
    Network: "Solana",
    OrganizationId: "q234",
    Id: "WALLET_3",
    Name: "Demo 4 Wallet",
    Status: "APPROVED",
    Address: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a6942",
    Balance: {
      Amount: 80000,
      Currency: "EURR",
    },
    FireblocksWalletId: "3487628yg4873y4r",
    Created: "12-12-2023 12:43:12",
    Type: "MetaMask",
  },
];

export const MOCK_WALLET: APIWallet = {
  Network: "Ethereum",
  OrganizationId: "q234",
  Id: "1",
  Name: "ABollen",
  Status: "APPROVED",
  Address: "6146ccf6a66d994f7c363db875e31ca35581450a4bf6d3be6cc9ac79233a69d0",
  Balance: {
    Amount: 12445,
    Currency: "EURR",
  },
  FireblocksWalletId: "3487628yg4873y4r",
  Created: "12-12-2023 12:43:12",
  Type: "MetaMask",
};
