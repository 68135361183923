import { APIUserListItem } from "types/API/User";

export const MOCK_USERS: APIUserListItem[] = [
  {
    Id: "dd8b7828-723c-4010-b64c-87df991893c1",
    CreatedAt: "2023-09-11T06:31:19.367Z",
    Name: "Demo Admin",
    Roles: ["Admin"],
    Disabled: false,
    Username: "admin01",
    Phone: "\u002B31650123456",
    Email: "admin@demo.com",
  },
  {
    Id: "dd8b7828-723c-4011-b64c-87df991893c1",
    CreatedAt: "2023-09-12T06:31:19.367Z",
    Name: "Demo Treasury Manager",
    Roles: ["TreasuryManager"],
    Disabled: false,
    Username: "TM01",
    Phone: "\u002B31650123456",
    Email: "tm@demo.com",
  },
  {
    CreatedAt: "2022-09-11T06:31:19.367Z",
    Id: "dd8b7828-723c-4310-b64c-87df991893c1",
    Name: "Demo Trader",
    Roles: ["Trader"],
    Disabled: false,
    Username: "trader01",
    Phone: "\u002B31650123456",
    Email: "trader@demo.com",
  },
  {
    CreatedAt: "2023-05-01T06:31:19.367Z",
    Id: "dd8b7828-713c-4010-b64c-87df991893c1",
    Name: "Demo Unauthorised",
    Roles: ["Admin", "TreasuryManager", "Trader"],
    Disabled: true,
    Phone: "\u002B31650123456",
    Username: "Unauth01",
    Email: "unauth@demo.com",
  },
];
