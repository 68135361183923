import React from "react";
import { matchPath, matchRoutes, useLocation } from "react-router-dom";

export function useCurrentLocationFromPaths(slugsOrPaths: Array<string | { path: string; end?: boolean }[]>) {
  const { pathname } = useLocation();

  return React.useMemo(() => {
    let slugLocation = -1;
    slugsOrPaths.forEach((slugOrPath, index) => {
      if (typeof slugOrPath === "string") {
        if (matchPath(pathname, slugOrPath) !== null) slugLocation = index;
      } else if (matchRoutes(slugOrPath, pathname)) slugLocation = index;
    });
    return slugLocation;
  }, [pathname, slugsOrPaths]);
}
