import { Menu } from "@headlessui/react";
import * as React from "react";
import { ReactComponent as ChevronDownSVG } from "stablr/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpSVG } from "stablr/assets/icons/chevron-up.svg";
import { Avatar, Icon } from "stablr/components/atoms";
import { composeTestID } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { color, fontFamily, fontSize, fontWeight, media, spacing } from "stablr/styles";
import styled from "styled-components";

interface UserDropDownProps {
  testid?: string;
  children?: React.ReactElement[] | React.ReactElement;
  name?: string;
}

const MENU_LARGE_PADDING_HORIZONTAL = "20px";
const MENU_LARGE_PADDING_VERTICAL = "10px";
const MENU_SMALL_PADDING_HORIZONTAL = "10px";
const MENU_SMALL_PADDING_VERTICAL = "10px";

const UserDropDownStyled = styled.div`
  position: relative;
`;
const MenuButtonStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: ${color.greyscale.white};
  color: ${color.theme.primary};
  line-height: 27px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    padding: ${MENU_SMALL_PADDING_VERTICAL} ${MENU_SMALL_PADDING_HORIZONTAL};

    &[aria-expanded="true"] {
      background-color: ${color.greyscale.grey1};
    }
  }

  @media ${media.desktop} {
    & *:first-child {
      margin-right: ${spacing.s};
    }

    padding: ${MENU_LARGE_PADDING_VERTICAL} ${MENU_LARGE_PADDING_HORIZONTAL};
  }

  & span {
    max-width: 200px;
    word-break: break-all;
  }
`;
const MenuItemsStyled = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.xxs});
  right: 0;
  border-radius: 3px;
  background-color: ${color.greyscale.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: ${spacing.xxs};
  min-width: 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;
  height: 14px;

  & svg {
    display: block;
  }
`;

UserDropDownComponent.testid = "userDropDown";

export default function UserDropDownComponent({ name, children, ...props }: UserDropDownProps) {
  const isMobile = useIsMobile();

  const formatName = (name: string) => {
    if (!name) return "";
    const words = name.split(" ");
    const firstChar = words[0][0];
    const restOfName = name.slice(words[0].length).slice(0, 15);
    return `${firstChar}. ${restOfName}${restOfName.length === 15 ? "..." : ""}`;
  };

  return (
    <Menu as={UserDropDownStyled} data-testid={composeTestID(UserDropDownComponent.testid, props.testid)}>
      {({ open }) => (
        <>
          <Menu.Button as={MenuButtonStyled}>
            <Avatar name={name} />
            {!isMobile ? (
              <>
                <span>{name && formatName(name)}</span>
                <IconStyled color={color.theme.primary} as={Icon}>
                  {open ? <ChevronUpSVG /> : <ChevronDownSVG />}
                </IconStyled>
              </>
            ) : (
              <></>
            )}
          </Menu.Button>
          <Menu.Items as={MenuItemsStyled}>{children}</Menu.Items>
        </>
      )}
    </Menu>
  );
}
