import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APICreateWebhook } from "types/API/Webhooks";

import useWebhooks from "./use-webhooks";

export function useCreateWebhook() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(
    (webhook: APICreateWebhook) => {
      return api.Webhooks.createWebhook(webhook);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [useWebhooks.queryKey] });
        successToast({ message: "Webhook has been created successfully" });
      },
    }
  );
}
