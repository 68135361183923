import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Heading } from '../../atoms/Heading'
import { Paragraph } from '../../atoms/Paragraph'
import { Spacer } from '../../atoms/Spacer'

export interface TextWithButtonProps {
  testid?: string
  title: string
  body: string
  buttonElement?: React.ReactElement
}

const TextWithButtonStyled = styled.div``

TextWithButton.testid = 'textWithButton'

export function TextWithButton({ title, body, buttonElement, ...props }: TextWithButtonProps) {
  return (
    <TextWithButtonStyled data-testid={composeTestID(TextWithButton.testid, props.testid)}>
      <Heading as="h1" varient="h2">
        {title}
      </Heading>
      <Paragraph color={color.greyscale.black}>{body}</Paragraph>
      <Spacer height={spacing.m} />
      {buttonElement}
    </TextWithButtonStyled>
  )
}
