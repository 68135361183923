import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { input } from 'stablr/styles'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../Icon'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  appendElement?: React.ReactElement
  onIconClick?: (event: unknown) => void
  textAlign?: 'left' | 'right'
  maxWidth?: boolean
}

const INPUT_ICON_SIZE = 20

const InputContainerStyled = styled.div<{ $maxWidth: boolean }>`
  border: solid ${color.greyscale.grey5} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: ${input.height};
  box-sizing: content-box; // Because this includes the border
  max-width: ${({ $maxWidth }) => ($maxWidth ? '900px' : input.maxWidth)};

  &.disabled {
    background-color: ${color.greyscale.grey1};
  }
`

const InputStyled = styled.input<{ $textAlign: 'left' | 'right' }>`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.greyscale.black};
  padding: ${spacing.m};
  border: none;
  width: 100%;
  flex: 1;
  text-align: ${({ $textAlign }: { $textAlign: 'left' | 'right' }) => $textAlign};

  ::placeholder {
    color: ${color.greyscale.grey5};
  }
`

const IconStyled = styled.div`
  padding: ${spacing.m} 0 ${spacing.m} ${spacing.m};
  flex: 0 !important;
`

const AppendElementStyled = styled.div`
  display: flex;
  align-items: center;
`

export const InputTestID = 'Input'

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { icon, appendElement, onIconClick, disabled, textAlign = 'left', maxWidth = false, ...props },
    ref,
  ) => {
    return (
      <InputContainerStyled $maxWidth={maxWidth} className={disabled ? 'disabled' : ''}>
        {icon && (
          <IconStyled size={INPUT_ICON_SIZE} as={Icon} onClick={onIconClick}>
            {icon}
          </IconStyled>
        )}
        <InputStyled
          onWheel={props.type === 'number' ? preventNumberScroll : undefined}
          $textAlign={textAlign}
          disabled={disabled}
          data-testid={composeTestID(InputTestID, props.testid)}
          ref={ref}
          {...props}
        ></InputStyled>
        {appendElement && <AppendElementStyled>{appendElement}</AppendElementStyled>}
      </InputContainerStyled>
    )
  },
)

const preventNumberScroll = (e: any) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}

Input.displayName = 'Input'
