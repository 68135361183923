import * as React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as ExternalSVG } from "stablr/assets/icons/external.svg";
import { Button, Spacer } from "stablr/components/atoms";
import { color, fontFamily, fontSize, spacing } from "stablr/styles";
import styled from "styled-components";

import { BorderlessButton } from "./BorderlessButton";

interface UserDropDownActionsProps {
  onLogout: () => void;
  version: string;
}

const UserDropDownActionsStyled = styled.div`
  margin: ${spacing.m} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize.button};
  width: 100%;
`;

const VersionStyled = styled.span`
  text-decoration: underline;
  margin: ${spacing.m} 0 0 0;
  font-size: ${fontSize.small};
  color: ${color.greyscale.grey5};
  font-family: ${fontFamily.primary};
`;

// const ButtonWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

export default function UserDropDownActionsComponent({ onLogout, version }: UserDropDownActionsProps) {
  const navigation = useNavigate();

  const onSettings = () => navigation("/settings");

  // const onStatusOpen = () => window.open("https://status.stablr.com", "_blank");

  // const onSupport = () => window.open("https://stablr.com/support", "_blank");

  return (
    <UserDropDownActionsStyled>
      {/* <ButtonWrapper>
        <BorderlessButton onClick={onStatusOpen}>Status Page</BorderlessButton>
        <Spacer width={spacing.xs} />
        <Icon size={13} color={color.theme.primary}>
          <ExternalSVG />
        </Icon>
      </ButtonWrapper> */}
      <BorderlessButton onClick={onSettings}>Settings</BorderlessButton>
      {/* <BorderlessButton onClick={onSupport}>Support</BorderlessButton> */}
      <Spacer height={spacing.m} />
      <Button size="small" varient="line" onClick={onLogout}>
        Log Out
      </Button>
      <VersionStyled>Version: {version}</VersionStyled>
    </UserDropDownActionsStyled>
  );
}
