import { ReactComponent as StablRLogoMark } from "assets/svg/logo-mark-white.svg";
import { ReactComponent as StablRLogo } from "assets/svg/logo-stablr.svg";
import { confirmSignIn, fetchUserAttributes, signIn } from "aws-amplify/auth";
import { Config } from "config";
import { FORM_ERROR } from "final-form";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import { validateFormValues } from "functions/validate-schema";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmailIcon } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as PasswordIcon } from "stablr/assets/icons/lock.svg";
import { Button, Heading, InlineLink, InputLink, Paragraph } from "stablr/components/atoms";
import { ErrorMessage, PasswordInput, TextInput } from "stablr/components/molecules";
import { HalfHalfResponsive } from "stablr/components/templates";
import { composeTestID, getErrorMessage } from "stablr/functions";
import styled from "styled-components";
import * as yup from "yup";
interface SignInProps {
  testid?: string;
}

interface SignInFormValues {
  email: string;
  password: string;
}

const SignInStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

SignInComponent.testid = "signIn";

export const LoginSchemaValidation = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function SignInComponent({ ...props }: SignInProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnSubmit = async ({ email, password }: SignInFormValues) => {
    setIsLoading(true);

    try {
      const parsedEmail = email.toLowerCase();
      const user = await signIn({ username: parsedEmail, password });
      logEvent(EVENT_TYPE_ENUM.AUTHENTICATION, "LoginSubmit");

      if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        navigate("./update-password");
        return;
      } else if (user.nextStep.signInStep === "RESET_PASSWORD") {
        return { [FORM_ERROR]: user.nextStep.signInStep };
      } else if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
        navigate(`./sms-authentication?destination=${user?.nextStep?.codeDeliveryDetails?.destination ?? ""}`);
        return;
      } else if (user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        navigate("./totp-authentication");
        return;
      } else if (
        user.nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION" &&
        user.nextStep.allowedMFATypes?.includes("TOTP")
      ) {
        await confirmSignIn({
          challengeResponse: "TOTP",
        });
        navigate("./totp-authentication");
        return;
      }

      const attributes = await fetchUserAttributes();

      const hasOrganisation = typeof attributes?.["custom:assignedOrganization"] === "string";
      const hasConfiguredMFA = attributes.preferredMFA === "NOMFA";

      if (hasOrganisation === false) {
        if (hasConfiguredMFA) {
          navigate("../sign-up/totp-authentication");
        } else {
          navigate("../sign-up/company-verification");
        }
      } else {
        navigate("../dashboard"); // Should only need this route for users with disabled MFA
      }
    } catch (error) {
      setIsLoading(false);

      return { [FORM_ERROR]: getErrorMessage(error) };
    }
    setIsLoading(false);
  };

  return (
    <SignInStyled data-testid={composeTestID(SignInComponent.testid, props.testid)}>
      <HalfHalfResponsive logo={StablRLogo} logoMark={<StablRLogoMark />}>
        <Form onSubmit={handleOnSubmit} validate={validateFormValues(LoginSchemaValidation)}>
          {({ handleSubmit, submitError }) => (
            <form onSubmit={handleSubmit}>
              <Heading as="h1" varient="h2">
                Hi, Welcome Back!
              </Heading>
              <Paragraph>Easy access to fully backed digital assets</Paragraph>
              <Field
                name="email"
                label="Email Address"
                autoComplete="email"
                icon={<EmailIcon />}
                component={TextInput}
                placeholder="email@stablr.com"
              ></Field>
              <Field
                name="password"
                label="Password"
                autoComplete="current-password"
                icon={<PasswordIcon />}
                component={PasswordInput}
                placeholder="Password"
                appendElement={<InputLink to="/sign-in/reset-password">Forgot password?</InputLink>}
              ></Field>
              <ErrorMessage>{submitError}</ErrorMessage>
              <Button type="submit" loading={isLoading} width="100%">
                Login
              </Button>
              {!Config.features.disableSignUp && (
                <Paragraph>
                  Not registered yet? <InlineLink to="/sign-up">Create an account</InlineLink>
                </Paragraph>
              )}
            </form>
          )}
        </Form>
      </HalfHalfResponsive>
    </SignInStyled>
  );
}
