import { Config } from "config/index";
import { PERMISSION_ENUM } from "config/permissions";
import logError from "functions/log/log-error";
import withPermission from "hocs/permission";
import { useWallets } from "hooks/query/wallet/use-wallets";
import React from "react";
import { useNavigate } from "react-router-dom";
import { media } from "stablr";
import { ReactComponent as AccountBalanceWallet } from "stablr/assets/icons/account-balance-wallet.svg";
import { Button } from "stablr/components/atoms";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { ActionHeader } from "stablr/components/molecules";
import { SummaryList } from "stablr/components/organisms";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";
import styled from "styled-components";

import WalletDetail from "./WalletDetail";

const ButtonStyled = styled.div`
  @media (${media.mobile}) {
    & > button {
      white-space: normal;
    }
  }
`;

function WalletOverview() {
  const navigation = useNavigate();

  const { data, isLoading, error, refetch } = useWallets();

  const { successToast } = useToast();

  async function addTokenToMetamask() {
    const tokenAddress =
      Config.web3.network === "mainnet" ? Config.web3.contracts.EURR.mainnet : Config.web3.contracts.EURR.sepolia;
    const tokenSymbol = "EURR";
    const tokenDecimals = 6;
    const tokenImage = "https://etherscan.io/token/images/stablr_32.png";

    if (window.ethereum === undefined || window.ethereum.request === undefined) return;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        successToast({ title: "Success", message: "EURR was added to your Wallet!" });
      }
    } catch (error) {
      logError("ADD_TO_METAMASK");
    }
  }

  return (
    <>
      <ActionHeader
        title="Wallet Overview"
        btnText="Add Wallet"
        onBtnClick={() => navigation("/accounts/wallet/add")}
        permission={PERMISSION_ENUM.ADD_WALLETS}
        actionRight={
          window.ethereum !== undefined && window.ethereum.request !== undefined ? (
            <ButtonStyled>
              <Button onClick={() => addTokenToMetamask()} varient="secondary" underlined marginHorizontalSize="none">
                Add Token to Metamask
              </Button>
            </ButtonStyled>
          ) : undefined
        }
      />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching wallet(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data?.length ? (
        <CardEmpty
          iconElement={<AccountBalanceWallet />}
          title="No Wallets found"
          body="There is no Wallet connected to your account at the moment"
        />
      ) : (
        <SummaryList>
          {data.map(wallet => (
            <WalletDetail key={wallet.Id} data={wallet} />
          ))}
        </SummaryList>
      )}
    </>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_WALLETS)(WalletOverview);
