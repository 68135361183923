import { useMutation } from "@tanstack/react-query";
import api from "api";
import { PostCompanyDetailsType } from "api/Company";

export function usePostCompanyDetails() {
  return useMutation({
    mutationFn: async ({ companyDetails }: { companyDetails: PostCompanyDetailsType }) => {
      return api.Company.postCompanyDetails(companyDetails);
    },
  });
}
