import { TestCookies } from "cookies/Test";

const apiName = "stablr-api";

const TestConfig = TestCookies.get("testConfig");
const Mocked = TestCookies.get("mocked");

const testContracts = {
  test: "0xc9d0d69f076935f8a65abaefa75bc7c48f3226a7" as `0x${number}`,
  development: "0xd00465B43d3C13aFc2d96619A82b90120ace61bF" as `0x${number}`,
  acceptance: "0xdF2D914BA84B5128e2f653B649A5767215C2B728" as `0x${number}`,
};

const environment = process.env.REACT_APP_ENV as "cypress" | "development" | "test" | "acceptance" | "production";

export const Constants = {
  apiName,
  environment: environment,
  fallbackDenominator: "." as "." | ",",
  testEnvironments: ["development", "test"],
  mocked:
    (!!(process.env.REACT_APP_MOCKED === "1") || (Mocked || "").toString() === "1") && environment !== "production",
  links: {
    privacyPolicy: "https://www.stablr.com/privacy-policy",
    termsOfUse: "https://www.stablr.com/terms-and-conditions",
  },
  walletConnect: {
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
  },
  web3: {
    contracts: {
      EURR: {
        sepolia: testContracts[environment as "development" | "test" | "acceptance"],
        mainnet: "0xdaC306D72f48dbaD805a11CBf7A512A277C084C9" as `0x${number}`,
      },
    },
    network: process.env.REACT_APP_WEB3_NETWORK as "sepolia" | "mainnet",
  },
  logging: {
    amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  },
  features: {
    disableBankAccountDelete: !!(process.env.REACT_APP_DISABLE_BANK_ACCOUNT_DELETE === "1"),
    disableWalletBank: !!(process.env.REACT_APP_DISABLE_WALLET_BANK === "1"),
    disableSignUp: !!(process.env.REACT_APP_DISABLE_SIGNUP === "1"),
    disableAPIManagement: !!(process.env.REACT_APP_DISABLE_API_MANAGEMENT === "1"),
    disableWebhooks: process.env.REACT_APP_DISABLE_WEBHOOKS === "1",
    reduceTokenAmountsInWalletConnect: false, // Not configured by env file
  },
  awsEnv: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: process.env.REACT_APP_BASE_URL,
          custom_headers: () => ({
            "Content-Type": "application/json",
          }),
        },
      ],
    },
  },
};

export const Config =
  Constants.testEnvironments.includes(Constants.environment ?? "") && TestConfig
    ? ({
        ...Constants,
        features: { ...Constants.features, ...TestConfig.features },
        web3: { ...Constants.web3, network: TestConfig?.web3?.network || Constants.web3.network },
      } as typeof Constants)
    : Constants;
