import { BuySellContainer } from "pages/BuySell/components/BuySellContainer";
import BuyWizard from "pages/BuySell/components/BuyWizard";
import StepWizard from "pages/BuySell/components/StepWizard";
import { getTransactionBadgeVarient } from "pages/Transaction/functions/get-transaction-badge-varient";
import { parseTransactionBadgeText } from "pages/Transaction/functions/parse-transaction-badge-text";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spacer } from "stablr/components/atoms";
import { BottomBar } from "stablr/components/organisms";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import SuccessfulTransactionTable from "./components/SuccessfulTransactionTable";

interface SuccessScreenProps {
  title: string;
  body: string;
  transaction: APITransaction;
}

const SuccessScreenStyled = styled(BuySellContainer)`
  flex: 1;
  padding: 0 ${spacing.xl} ${spacing.xxl} ${spacing.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1250px;

  @media ${media.mobile} {
    margin: 0;
    padding: 0 ${spacing.m};
  }
`;

export default function SuccessScreenComponent({ title, body, transaction }: SuccessScreenProps) {
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <StepWizard
        title={title}
        body={body}
        variant={getTransactionBadgeVarient(transaction?.Status)}
        statusText={parseTransactionBadgeText(transaction?.Status)}
      />
      <BuyWizard />
      <Spacer height={spacing.xl} />
      <SuccessScreenStyled>
        <SuccessfulTransactionTable transaction={transaction} />
        <Spacer height={spacing.xl} />
        <BottomBar
          paddingHorizontal="0px"
          rightElement={
            <Button varient="line" onClick={handleOnBack}>
              Back to Dashboard
            </Button>
          }
        ></BottomBar>
      </SuccessScreenStyled>
    </>
  );
}
