import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { input } from 'stablr/styles'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Loader } from '../../Loader'

export type InputAppendTypeProps = {
  children?: string
  color?: string
  testid?: string
  onClick?: () => void
  loading?: boolean
}

const InputAppendTypeStyled = styled.div`
  min-width: 60px;
  text-align: left;
  border-radius: 3px;
  height: ${input.height};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${({ $color }: { $color?: string; onClick?: () => void; $loading: boolean }) =>
    $color ?? color.greyscale.grey4};
  box-sizing: border-box;

  padding: ${({ $loading }: { $color?: string; onClick?: () => void; $loading: boolean }) =>
    $loading ? `0 ${spacing.xs} 0 0` : `${spacing.m} ${spacing.m} ${spacing.m} 0`};
  cursor: ${({ onClick }: { onClick?: () => void }) =>
    onClick !== undefined ? 'pointer' : 'default'};
`

const LoaderWrapperStyled = styled.div`
  height: ${input.height};
  width: 40px;
  display: flex;
  align-items: center;
`

InputAppendType.testid = 'InputAppendType'

export function InputAppendType({
  children,
  color,
  onClick,
  testid,
  loading = false,
}: InputAppendTypeProps) {
  return (
    <InputAppendTypeStyled
      data-testid={composeTestID(InputAppendType.testid, testid)}
      $color={color}
      $loading={loading}
      onClick={onClick}
    >
      {loading ? (
        <LoaderWrapperStyled>
          <Loader />
        </LoaderWrapperStyled>
      ) : (
        children
      )}
    </InputAppendTypeStyled>
  )
}
