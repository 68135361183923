import * as React from "react";
import { Route, Routes } from "react-router-dom";

import NewPassword from "./screens/NewPassword";
import ResetPassword from "./screens/ResetPassword";
import SignIn from "./screens/SignIn";
import SMSAuthentication from "./screens/SMSAuthentication";
import TOTPAuthentication from "./screens/TOTPAuthentication";
import UpdatePassword from "./screens/UpdatePassword";

export default function SignUpComponent() {
  return (
    <Routes>
      <Route path="" element={<SignIn />} />
      <Route path="/sms-authentication" element={<SMSAuthentication />} />
      <Route path="/totp-authentication" element={<TOTPAuthentication />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/update-password" element={<UpdatePassword />} />
    </Routes>
  );
}
