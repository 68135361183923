import * as React from 'react'
import styled from 'styled-components'

export interface TableHeadProps {
  children: React.ReactElement | React.ReactElement[]
}

const TableHeadStyled = styled.thead``

export function TableHead({ children }: TableHeadProps) {
  return <TableHeadStyled>{children}</TableHeadStyled>
}
