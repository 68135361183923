import * as React from 'react'
import { useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as WarningIcon } from 'stablr/assets/icons/warning.svg'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputAppendType } from 'stablr/components/atoms/Form/InputAppendType'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface DownloadInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  note?: string
  compact?: boolean
  buttonText?: string
  onDownload?: () => Promise<void>
}
const MAX_INPUT_WIDTH = '500px'

export const DownloadInputStyled = styled.div`
  width: 100%;
  max-width: ${MAX_INPUT_WIDTH};
`

DownloadInput.testid = 'DownloadInput'

export function DownloadInput({
  input: { ...inputProps },
  label,
  note,
  buttonText = 'Download',
  compact = false,
  onDownload,
  ...props
}: DownloadInputProps) {
  const [isDownloading, setIsDownloading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const error = props.meta.touched && props.meta.error

  const handleOnDownload = async () => {
    try {
      setIsDownloading(true)
      onDownload && (await onDownload())
    } catch (er) {
      setHasError(true)
      setTimeout(() => {
        setHasError(false)
      }, 5000)
    }
    setIsDownloading(false)
  }

  return (
    <DownloadInputStyled data-testid={composeTestID(DownloadInput.testid, props.testid)}>
      <Label htmlFor={inputProps.name} readOnly={props.readOnly}>
        {label}
      </Label>
      <Input
        {...inputProps}
        {...props}
        icon={hasError ? <WarningIcon /> : undefined}
        color={hasError ? color.palette.red : undefined}
        appendElement={
          <InputAppendType
            onClick={isDownloading ? undefined : handleOnDownload}
            color={isDownloading ? color.greyscale.grey2 : color.palette.purple}
            loading={isDownloading}
          >
            {buttonText ?? ''}
          </InputAppendType>
        }
      ></Input>
      {(!compact || note?.trim() || error) && (
        <InputNote htmlFor={inputProps.name} color={error ? color.palette.red : undefined}>
          {error ? error : note}
        </InputNote>
      )}
    </DownloadInputStyled>
  )
}
