import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { ReactComponent as ChevronDownIcon } from 'stablr/assets/icons/chevron-down.svg'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../Icon'

const ListboxStyled = styled.div`
  width: 100%;
  position: relative;
`

const ListButtonStyled = styled(Listbox.Button)`
  width: 100%;
  padding: ${spacing.m};
  border: 0;
  background-color: ${color.palette.lightPurple};
  border-bottom: 1px solid ${color.palette.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const ListOptionsStyled = styled(Listbox.Options)`
  width: 100%;
  border: none;
  box-shadow: ${spacing.xxs} ${spacing.xxs} ${spacing.s} 0 ${color.greyscale.grey2};
  border-radius: 0 0 ${spacing.s} ${spacing.s};
  margin-top: 0;
  z-index: 1;
  background-color: ${color.greyscale.white};
  padding: 0 ${spacing.m};
  overflow-y: auto;
  position: absolute;
  max-height: min(450px, 60vh);
`

const ListOptionStyled = styled(Listbox.Option)`
  padding: ${spacing.l} ${spacing.xs};
  list-style: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${color.greyscale.grey2};
  }

  cursor: pointer;
`
export interface OptionInterface {
  label: string
  value: string
}

export type DropDownProps = {
  options: OptionInterface[]
  value: string
  defaultValue: string
  onChange: (value: string) => void
}

DropDown.testid = 'DropDown'

export function DropDown({ options, onChange, defaultValue, value }: DropDownProps) {
  const selectedValue = React.useMemo(
    () =>
      options.find((option) => option.value === value) ??
      options.find((option) => option.value === defaultValue),
    [value],
  )

  const handleOnChange = (option?: OptionInterface) => {
    option && onChange(option.value)
  }

  return (
    <Listbox
      value={selectedValue}
      by="value"
      onChange={handleOnChange}
      data-testid={DropDown.testid}
    >
      <ListboxStyled>
        <Listbox.Button as={ListButtonStyled}>
          <span>{selectedValue?.label}</span>
          <Icon>
            <ChevronDownIcon />
          </Icon>
        </Listbox.Button>
        <Transition as={Fragment}>
          <Listbox.Options as={ListOptionsStyled}>
            {options.map(({ label, value }, idx) => (
              <Listbox.Option
                key={`${value}-${idx}`}
                as={ListOptionStyled}
                value={{ label, value }}
              >
                <span>{label}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </ListboxStyled>
    </Listbox>
  )
}
