import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APITransaction } from "types/API/Transaction";

useTransaction.queryKey = "transaction";

export default function useTransaction(transactionId: string) {
  return useQuery(
    [useTransaction.queryKey, transactionId],
    async () => api.Transaction.getTransaction(transactionId) as Promise<APITransaction>
  );
}
