import { Config } from "config";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as AlternativeEmailSVG } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as IconBackIOS } from "stablr/assets/icons/icon-back-ios.svg";
import { ReactComponent as IconForwardIOS } from "stablr/assets/icons/icon-forward-ios.svg";
import { ReactComponent as PersonSVG } from "stablr/assets/icons/person.svg";
import { Button, Heading, InlineLink, Paragraph, Spacer } from "stablr/components/atoms";
import { CheckBoxInput, Container, PhoneNumberInput, TextInput } from "stablr/components/molecules";
import { BottomBar, FormLayout } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import { validateFormValues } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { spacing } from "stablr/styles";
import styled from "styled-components";

import { USER_INFORMATION_SCHEMA, UserInformationSchemaValidation } from "../common/userInformationSchemaValidation";

export interface UserInformationForm {
  email: string;
  fullName: string;
  mobile: string;
  accepted: boolean;
}

const ButtonContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

interface UserInformationProps {
  onBack: () => void;
  onContinue: (values: UserInformationForm) => void;
  userInformation?: UserInformationForm;
}

export default function UserInformationComponent({ onBack, onContinue, userInformation }: UserInformationProps) {
  const isMobile = useIsMobile();

  return (
    <Form
      onSubmit={onContinue}
      validate={validateFormValues(UserInformationSchemaValidation)} // Unfixed react-final-form error regarding validation but appears to have no functional impact https://github.com/final-form/react-final-form/issues/850
      initialValues={userInformation}
    >
      {({ handleSubmit }) => (
        <StepTemplate
          bottomBarElement={
            <BottomBar
              leftElement={
                <Button
                  varient="secondary"
                  onClick={onBack}
                  layout="icon-left"
                  mobileLayout="icon-only"
                  icon={<IconBackIOS />}
                >
                  Previous Step
                </Button>
              }
              rightElement={
                !isMobile ? (
                  <ButtonContainerStyled>
                    <Field
                      name={USER_INFORMATION_SCHEMA.ACCEPTED}
                      component={CheckBoxInput}
                      label={
                        <Paragraph as="span">
                          As authorized user I hereby accept the{" "}
                          <InlineLink
                            external
                            target="_blank"
                            to={Config.links.termsOfUse}
                            onClick={() => {
                              logEvent(EVENT_TYPE_ENUM.LINK, "TermsAndConditions-SignUp");
                            }}
                          >
                            Terms & Conditions
                          </InlineLink>{" "}
                          and{" "}
                          <InlineLink
                            external
                            to={Config.links.privacyPolicy}
                            target="_blank"
                            onClick={() => {
                              logEvent(EVENT_TYPE_ENUM.LINK, "PrivacyPolicy-SignUp");
                            }}
                          >
                            Privacy Policy.
                          </InlineLink>
                        </Paragraph>
                      }
                    />
                    <Spacer width={spacing.s} />
                    <Button
                      icon={<IconForwardIOS />}
                      varient="primary"
                      layout="icon-right"
                      onClick={handleSubmit}
                      testid="Next"
                    >
                      Next
                    </Button>
                  </ButtonContainerStyled>
                ) : (
                  <Button
                    icon={<IconForwardIOS />}
                    varient="primary"
                    layout="icon-right"
                    onClick={handleSubmit}
                    testid="Next"
                  >
                    Next
                  </Button>
                )
              }
            />
          }
        >
          <Container as="section" maxWidth={500} id="title-container">
            <header>
              <Heading as="h1" varient="h2">
                1. User Information
              </Heading>
            </header>
            <Paragraph>Please provide us your user information</Paragraph>
            <FormLayout>
              <>
                <Field
                  label="Company Email Address"
                  type="email"
                  name={USER_INFORMATION_SCHEMA.EMAIL}
                  placeholder="email@stablr.com"
                  note="In the next step you will verify this email address"
                  component={TextInput}
                  icon={<AlternativeEmailSVG />}
                />
                <Field
                  label="Full Name"
                  icon={<PersonSVG />}
                  type=""
                  name={USER_INFORMATION_SCHEMA.FULL_NAME}
                  placeholder="Full Name"
                  component={TextInput}
                />
                <Field
                  label="Mobile Phone Number"
                  name={USER_INFORMATION_SCHEMA.MOBILE}
                  placeholder={"000000000"}
                  note="This number will be used for the SMS Authentication"
                  component={PhoneNumberInput}
                />
                {isMobile && (
                  <Field
                    name={USER_INFORMATION_SCHEMA.ACCEPTED}
                    component={CheckBoxInput}
                    label={
                      <Paragraph as="span">
                        As authorized user I hereby accept the{" "}
                        <InlineLink
                          external
                          target="_blank"
                          onClick={() => {
                            logEvent(EVENT_TYPE_ENUM.LINK, "TermsAndConditions-SignUp");
                          }}
                          to={Config.links.termsOfUse}
                        >
                          Terms & Conditions
                        </InlineLink>{" "}
                        and{" "}
                        <InlineLink
                          external
                          target="_blank"
                          onClick={() => {
                            logEvent(EVENT_TYPE_ENUM.LINK, "PrivacyPolicy-SignUp");
                          }}
                          to={Config.links.privacyPolicy}
                        >
                          Privacy Policy.
                        </InlineLink>
                      </Paragraph>
                    }
                  />
                )}
              </>
            </FormLayout>
          </Container>
        </StepTemplate>
      )}
    </Form>
  );
}
