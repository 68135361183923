import { Header } from "@tanstack/react-table";
import * as React from "react";
import { Icon, spacing } from "stablr";
import { ReactComponent as ArrowDownSVG } from "stablr/assets/icons/chevron-down.svg";
import { ReactComponent as ArrowUpSVG } from "stablr/assets/icons/chevron-up.svg";
import { ReactComponent as SortSVG } from "stablr/assets/icons/sort.svg";
import styled from "styled-components";

const IconStyled = styled(Icon)`
  padding-left: ${spacing.xxs};
`;
export function HeaderTableIcon<T>({ header }: { header: Header<T, unknown> }) {
  if (header.column.getCanSort() && header.column.getIsSorted()) {
    if (header.column.getIsSorted() === "asc") {
      return (
        <IconStyled size={10}>
          <ArrowUpSVG />
        </IconStyled>
      );
    } else if (header.column.getIsSorted() === "desc") {
      return (
        <IconStyled size={10}>
          <ArrowDownSVG />
        </IconStyled>
      );
    } else {
      return (
        <Icon size={10}>
          <SortSVG />
        </Icon>
      );
    }
  } else if (header.column.getCanSort() && !header.column.getIsSorted()) {
    return (
      <Icon size={15}>
        <SortSVG />
      </Icon>
    );
  } else {
    return null;
  }
}
