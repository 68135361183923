const colors = {
  gradient: 'linear-gradient(90.48deg, #849DFF 0.42%, #B898FF 99.6%)',
  theme: {
    primary: '#849DFF',
    primaryLight: '#F3F5FF',
    secondary: '#B898FF',
  },
  themeNew: {
    primary: '#784CF7',
    primaryHighlight: '#8863f8',
    primaryLight: '#E2D5FE',
    primaryLightHighlight: '#e7dcfe',
    primaryMidLight: '#B898FF',
    secondary: '#B898FF',
    pastelPurple: '#b59bf1',
    deepPurple: '#6923f7',
    lightBlack: '#212121',
    lightPink: '#f7f4fe',
  },
  greyscale: {
    white: '#fff',
    grey1: '#FAF8F9',
    grey2: '#F1F1F1',
    grey3: '#E4E4E4',
    grey4: '#CCCCCC',
    grey5: '#929292',
    grey6: '#00000066',
    grey7: 'rgba(255,255,255,0.7)',
    grey8: '#FFFFFF33',
    greyDark1: '#969696',
    black: '#000000',
  },
  palette: {
    red: '#FF5B42',
    lightRed: '#FFD6D0',
    yellow: '#EDE325',
    green: '#00CE59',
    lightGreen: 'rgba(0, 206, 89, 0.25)',
    purple: '#849DFF',
    lightPurple: '#F3F5FF',
    darkPurple: '#764AF6',
    orange: '#FF8E08',
    lightOrange: '#FBE3CC',
    brightOrange: '#ED9125',
    lightBlue: '#7233fe',
    mauve: '#F6F1FF',
    blue: '#2B8AFF',
  },
  other: {
    borderGradient: 'rgba(0, 0, 0, 0.1)',
    transparent: 'transparent',
  },
  text: {
    dark: '#392C43',
    light: '#929292',
  },
}

export default colors
