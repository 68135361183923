import * as React from 'react'
import { Heading } from 'stablr/components/atoms/Heading'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useIsMobile } from '../../../../hooks/use-is-mobile'

export interface CardTitleProps {
  title: string
  buttonElement?: React.ReactElement
}

const CardTitleStyled = styled.div`
  padding: ${spacing.m} ${spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export function CardTitle({ title, buttonElement }: CardTitleProps) {
  const isMobile = useIsMobile()
  return (
    <CardTitleStyled>
      <Heading as="h2" varient={isMobile ? 'h4' : 'h3'}>
        {title}
      </Heading>
      {buttonElement}
    </CardTitleStyled>
  )
}
