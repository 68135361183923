import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

const MARGIN = spacing.l

const UserDropDownDividerStyled = styled.div`
  width: calc(100% - ${MARGIN} * 2);
  border-top: 1px solid ${color.greyscale.grey2};
  margin: 0 ${MARGIN};
  box-sizing: border-box;
`

export function UserDropDownDivider() {
  return <UserDropDownDividerStyled />
}
