import React, { memo } from "react";
import { Button } from "stablr/components/atoms";

function SubmitButton({ loading, buttonText }: { loading: boolean; buttonText: string }) {
  return (
    <Button type="submit" loading={loading}>
      {buttonText}
    </Button>
  );
}

export default memo(SubmitButton);
