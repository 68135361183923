import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as StablRLogoSVG } from "assets/svg/logo-stablr.svg";
import { signOut } from "aws-amplify/auth";
import { Config } from "config";
import { PERMISSION_ENUM, PERMISSIONS } from "config/permissions";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import useUserData from "hooks/context/use-user-data";
import { TEST_ENVIRONMENTS } from "pages";
import ColoredDrawerLink from "pages/components/ColoredDrawerLink";
import NavigationDrawer from "pages/components/NavigationDrawer";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  DrawerLink,
  HeaderNavigation,
  MenuDropDown,
  MenuDropDownFeatureList,
  MenuDropDownList,
  UserDropDownDivider,
  UserDropDownInfo,
} from "stablr";
import { ReactComponent as BuyIconSVG } from "stablr/assets/icons/buy.svg";
import { ReactComponent as DashboardIconSVG } from "stablr/assets/icons/dashboard.svg";
import { ReactComponent as SellIconSVG } from "stablr/assets/icons/sell.svg";
import { ReactComponent as SettingsIconSVG } from "stablr/assets/icons/settings.svg";
import { ReactComponent as TransactionsIconSVG } from "stablr/assets/icons/transactions.svg";
import { Spacer } from "stablr/components/atoms";
import { DashboardTemplate } from "stablr/components/templates";
import { PermissionContext } from "stablr/context";
import { useIsMobile } from "stablr/hooks";
import { color, spacing } from "stablr/styles";

import PackageJson from "../../package.json";
import UserDropDown from "../refactor/UserDropDown";
import MenuDropDownFeatureLink from "./components/MenuDropDownFeatureLink";
import MenuDropDownLink from "./components/MenuDropDownLink";
import UserDropDownActions from "./components/UserDropDownActions";
import UserDropDownFootLinks from "./components/UserDropDownFootLinks";

interface DashboardMenuProps {
  children: React.ReactElement;
}

export const DashboardMenuItems = [
  {
    permission: PERMISSION_ENUM.VIEW_DASHBOARD,
    icon: <DashboardIconSVG />,
    children: "Dashboard",
    to: "/dashboard",
    testid: "dashboard",
  },
  {
    permission: PERMISSION_ENUM.VIEW_TRANSACTIONS,
    icon: <TransactionsIconSVG />,
    children: "Transactions",
    to: "/transactions",
    testid: "transactions",
  },
  {
    permission: PERMISSION_ENUM.VIEW_ACCOUNT,
    icon: <SettingsIconSVG />,
    children: "Accounts",
    to: "/accounts",
    testid: "accounts",
  },
];

export default function DashboardMenuComponent({ children }: DashboardMenuProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userData = useUserData();
  const queryClient = useQueryClient();

  const handleOnLogOut = async () => {
    await signOut();
    queryClient.removeQueries(); // Remove all queries
    navigate("../sign-in");
  };


  return (
    <PermissionContext.Provider value={{ permissions: PERMISSIONS, userRoles: userData?.["cognito:groups"] }}>
      <DashboardTemplate
        navigationBarElement={
          <NavigationDrawer>
            <ColoredDrawerLink
              color={color.theme.primary}
              icon={<BuyIconSVG />}
              to="/buy"
              testid="buy"
              permission={PERMISSION_ENUM.BUY_EURR}
            >
              Buy
            </ColoredDrawerLink>
            <ColoredDrawerLink
              color={color.theme.secondary}
              icon={<SellIconSVG />}
              to="/sell"
              testid="sell"
              permission={PERMISSION_ENUM.SELL_EURR}
            >
              Sell
            </ColoredDrawerLink>
            <Spacer height={spacing.s} />
            <>
              {DashboardMenuItems.map(menuItem => (
                <DrawerLink key={menuItem.to} {...menuItem} />
              ))}
            </>
          </NavigationDrawer>
        }
        headerNavigationElement={
          <HeaderNavigation
            title={
              isMobile ? (
                <StablRLogoSVG />
              ) : (
                <Routes>
                  <Route path="/dashboard/*" element={<>Dashboard</>} />
                  <Route path="/transactions/*" element={<>Transactions</>} />
                  <Route path="/accounts/*" element={<>Accounts</>} />
                  <Route path="/buy/*" element={<>Buy</>} />
                  <Route path="/sell/*" element={<>Sell</>} />
                  <Route path="/test/*" element={<>Test</>} />
                  <Route path="/settings/*" element={<>Settings</>} />
                </Routes>
              )
            }
          >
            <>
              <UserDropDown name={userData.name ?? "-"}>
                <UserDropDownInfo name={userData.name ?? "-"} email={userData.email ?? ""}></UserDropDownInfo>
                <UserDropDownDivider />
                <UserDropDownActions onLogout={handleOnLogOut} version={PackageJson.version} />
                <UserDropDownDivider />
                <UserDropDownFootLinks
                  links={[
                    // {
                    //   to: Config.links.termsOfUse,
                    //   label: "Terms of Use",
                    //   external: true,
                    //   onClick: () => {
                    //     logEvent(EVENT_TYPE_ENUM.LINK, "TermsAndConditions-DashboardMenu");
                    //   },
                    // },
                    {
                      to: "https://stablr.com",
                      label: "StablR.com",
                      external: true,
                      onClick: () => {
                        logEvent(EVENT_TYPE_ENUM.LINK, "StablRCom-DashboardMenu");
                      },
                    },
                    {
                      to: Config.links.privacyPolicy,
                      label: "Privacy Policy",
                      external: true,
                      onClick: () => {
                        logEvent(EVENT_TYPE_ENUM.LINK, "PrivacyPolicy-DashboardMenu");
                      },
                    },
                  ]}
                />
                {TEST_ENVIRONMENTS.includes(Config.environment ?? "") ? (
                  <>
                    <UserDropDownDivider />
                    <UserDropDownFootLinks
                      links={[
                        {
                          to: "/test",
                          label: "Test page",
                          external: false,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </UserDropDown>

              {isMobile ? (
                <MenuDropDown>
                  <>
                    <MenuDropDownFeatureList>
                      <MenuDropDownFeatureLink to="/buy" icon={<BuyIconSVG />} permission={PERMISSION_ENUM.BUY_EURR}>
                        Buy
                      </MenuDropDownFeatureLink>
                      <Spacer width={spacing.m} />
                      <MenuDropDownFeatureLink to="/sell" icon={<SellIconSVG />} permission={PERMISSION_ENUM.SELL_EURR}>
                        Sell
                      </MenuDropDownFeatureLink>
                    </MenuDropDownFeatureList>
                    <MenuDropDownList>
                      <MenuDropDownLink
                        icon={<DashboardIconSVG />}
                        to="/dashboard"
                        permission={PERMISSION_ENUM.VIEW_DASHBOARD}
                      >
                        Dashboard
                      </MenuDropDownLink>
                      <MenuDropDownLink
                        icon={<TransactionsIconSVG />}
                        to="/transactions"
                        permission={PERMISSION_ENUM.VIEW_TRANSACTIONS}
                      >
                        Transactions
                      </MenuDropDownLink>
                      <MenuDropDownLink
                        icon={<SettingsIconSVG />}
                        to="/accounts"
                        permission={PERMISSION_ENUM.VIEW_ACCOUNT}
                      >
                        Accounts
                      </MenuDropDownLink>
                    </MenuDropDownList>
                  </>
                </MenuDropDown>
              ) : (
                <></>
              )}
            </>
          </HeaderNavigation>
        }
      >
        {children}
      </DashboardTemplate>
    </PermissionContext.Provider>
  );
}
