import { track } from "@amplitude/analytics-browser";
import { Config } from "config";

export enum EVENT_TYPE_ENUM {
  NAVIGATE_SCREEN = "Navigate Screen",
  AUTHENTICATION = "Authentication",
  SIGN_UP = "SignUp",
  CLICK = "Click",
  FORM_INVALID = "FormInvalid",
  FORM_SUBMIT = "FormSubmit",
  LINK = "Link",
}

export default function logEvent(eventType: EVENT_TYPE_ENUM, event: string, options?: object) {
  if (Config.logging.amplitudeApiKey) track(`${eventType}: ${event}`, options);
}
