import React from "react";
import { Field } from "react-final-form";
import { ReactComponent as EmailIcon } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as UserIcon } from "stablr/assets/icons/person.svg";
import { PhoneNumberInput, TextInput } from "stablr/components/molecules";

import { RoleMultiSelectInput } from "./RoleMultiSelectInput";
import { USER_SCHEMA } from "./schemaValidation";

export default function UserAddForm() {
  return (
    <>
      <Field
        name={USER_SCHEMA.NAME}
        label="Name"
        placeholder="Name"
        component={TextInput}
        icon={<UserIcon />}
        compact
      />
      <Field name={USER_SCHEMA.PHONE} label="Mobile Phone Number" component={PhoneNumberInput} compact />
      <Field
        name={USER_SCHEMA.COMPANY_EMAIL}
        label="Company Email Address"
        placeholder="email@stablr.com"
        component={TextInput}
        parse={(value: string) => value.toLowerCase()}
        icon={<EmailIcon />}
        compact
      />
      <RoleMultiSelectInput />
    </>
  );
}
