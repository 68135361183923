import isMobilePhone from "validator/lib/isMobilePhone";
import * as yup from "yup";

export enum USER_INFORMATION_SCHEMA {
  EMAIL = "email",
  FULL_NAME = "fullName",
  MOBILE = "mobile",
  ACCEPTED = "accepted",
}

/**
 * Validation 1.1: Sign Up
 */

export const UserInformationSchemaValidation = yup.object({
  [USER_INFORMATION_SCHEMA.EMAIL]: yup.string().required("Email is required").email("Must be a valid email address"),
  [USER_INFORMATION_SCHEMA.FULL_NAME]: yup
    .string()
    .required("Full name is required")
    .test("maxLength", "Full Name cannot be more than 50 characters", (value?: string) => (value?.length ?? 0) <= 50),
  [USER_INFORMATION_SCHEMA.MOBILE]: yup
    .string()
    .required("Mobile phone number is required")
    .test(
      "Is Valid Mobile",
      "Must be valid mobile phone number",
      value => typeof value === "string" && isMobilePhone(value, undefined, { strictMode: true })
    ),
  [USER_INFORMATION_SCHEMA.ACCEPTED]: yup.boolean().test("Is true", "Please accept", value => !!value),
});
