import * as React from 'react'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { TableData, TableDataProps } from '../TableData'

export interface TableRowProps extends TableDataProps {
  onRowClick?: () => void
  badgeElement: React.ReactElement
  colSpan: number
}

const TableRowStyled = styled.tr<{ isClickable: boolean }>``

const BadgeContainerStyled = styled.div`
  padding: ${spacing.xs} ${spacing.xs} 0 ${spacing.xs};
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;

  & span {
    flex: 1;
    display: block;
    width: 100%;
  }
`

const TableDataStyled = styled(TableData)``

export function TableRowBadge({ badgeElement, onRowClick, colSpan, ...props }: TableRowProps) {
  return (
    <TableRowStyled onClick={onRowClick} isClickable={!!onRowClick}>
      <TableDataStyled colSpan={colSpan} {...props}>
        <BadgeContainerStyled>{badgeElement}</BadgeContainerStyled>
      </TableDataStyled>
    </TableRowStyled>
  )
}
