import { APIBankAccountListItem } from "types/API/BankAccount";

export const MOCK_ACCOUNT_LIST: APIBankAccountListItem[] = [
  {
    Id: "12345-11111-2222",
    Name: "ABollen",
    Iban: "NL 00 RABO 0123 4567 89",
    Country: "Netherlands",
    City: "Amsterdam",
    BankCode: "AAAA",
    BIC: "BIC",
    BankName: "",
    BankAddress: "",
    BankPostalCode: "1234 AB",
    Types: ["SEPA"],
    Status: "APPROVED",
    BankStatementFileId: "12345-11111-44538",
    BankStatementFileName: "file1.pdf",
  },
  {
    Id: "12345-11111-3333",
    Name: "R NIJKAMP",
    Iban: "NL 00 RABO 0123 4537 89",
    BankCode: "AAAA",
    Country: "Netherlands",
    City: "Amsterdam",
    BIC: "BIC",
    BankName: "",
    BankAddress: "",
    BankPostalCode: "1234 AB",
    Types: ["SEPA"],
    Status: "REJECTED",
    BankStatementFileId: "12345-11111-2222",
    BankStatementFileName: "file2.pdf",
  },
  {
    Id: "12345-11111-4444",
    Name: "D VANN",
    Iban: "NL 00 RABO 0123 4467 89",
    BankCode: "AAAA",
    Country: "Netherlands",
    City: "Amsterdam",
    BIC: "BIC",
    BankName: "",
    BankAddress: "",
    BankPostalCode: "1234 AB",
    Types: ["SEPA"],
    Status: "PENDING",
    BankStatementFileId: "12345-11111-0000",
    BankStatementFileName: "file3.pdf",
  },
];
