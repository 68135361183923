import { APICompanyInfo } from "types/API/Company";

export const MOCK_COMPANY_INFO: APICompanyInfo = {
  Name: "Demo Company",
  RegistrationNumber: "123456789",
  Address: "1 Demo Court",
  City: "Demotropolis",
  Country: "United Demo of America",
  SessionId: "2433542-2134534532-2352342435",
  PostalCode: "1234 US1",
  Status: "APPROVED",
};
