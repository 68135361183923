import * as React from 'react'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface MenuDropDownListProps {
  children: React.ReactElement | React.ReactElement[]
}

const MenuDropDownListStyled = styled.div`
  margin: ${spacing.m} 0;
`

export function MenuDropDownList({ children }: MenuDropDownListProps) {
  return <MenuDropDownListStyled>{children}</MenuDropDownListStyled>
}
