'use-client'

import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import colors from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontWeight from 'stablr/styles/fontWeight'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { Loader } from '../Loader'

type IconLayoutType = 'icon-left' | 'icon-right' | 'icon-only'
type IconMobileLayoutType = 'icon-only'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode | string
  varient?: 'primary' | 'secondary' | 'line' | 'gradient'
  testid?: string
  disabled?: boolean
  icon?: React.ReactElement
  layout?: IconLayoutType
  mobileLayout?: IconMobileLayoutType
  size?: 'small' | 'medium' | 'large'
  marginHorizontalSize?: 'none' | 'small' | 'large'
  width?: string
  loading?: boolean
  rounded?: boolean
  as?: React.ElementType
  to?: string // For as={NavLink}
  backgroundColor?: string
  color?: string
  iconSize?: number
  borderColor?: string
  iconColor?: string
  underlined?: boolean
}

Button.testid = 'Button'

const LINE_HEIGHT = '30px'

const DISABLED_BACKGROUND_COLOR = 'rgba(0,0,0,0.2)'
const LARGE_PADDING_HORIZONTAL = '30px'
const LARGE_PADDING_VERTICAL = '10px'
const SMALL_PADDING_VERTICAL = '5px'
const LARGE_HEIGHT = `calc(${LINE_HEIGHT} + ${LARGE_PADDING_VERTICAL} * 2)`
const SMALL_HEIGHT = `calc(${LINE_HEIGHT} + ${SMALL_PADDING_VERTICAL} * 2)`

const ButtonStyled = styled.button(
  ({
    $varient,
    $color,
    $layout = 'icon-left',
    $mobileLayout,
    $size,
    $width,
    $rounded,
    $marginHorizontalSize,
    $backgroundColor,
    $borderColor,
    $underlined,
  }: { $color: string; children: any } & PickRenameMulti<
    ButtonProps,
    {
      varient: '$varient'
      layout: '$layout'
      mobileLayout: '$mobileLayout'
      size: '$size'
      width: '$width'
      rounded: '$rounded'
      marginHorizontalSize: '$marginHorizontalSize'
      backgroundColor: '$backgroundColor'
      borderColor: '$borderColor'
      underlined: '$underlined'
    }
  >) => `
text-decoration: ${$underlined === true ? 'underline' : 'none'}; // Required for as={NavLink}
font-weight: ${fontWeight.bold};
font-family: ${fontFamily.secondary};
font-size: ${$size === 'small' ? '14px' : $size === 'medium' ? '16px' : '18px'};
white-space: nowrap;
text-align: center;
border-radius: ${
    $rounded === true
      ? `calc(${LINE_HEIGHT} + ${
          $size === 'large' ? LARGE_PADDING_VERTICAL : SMALL_PADDING_VERTICAL
        } * 2)`
      : '5px'
  };
${
  $varient === 'gradient'
    ? `background: ${colors.gradient};`
    : `background-color: ${
        $backgroundColor !== undefined
          ? $backgroundColor
          : $varient === 'primary'
          ? colors.greyscale.black
          : colors.greyscale.white
      };`
}
color: ${$color};
line-height: 27px;
height: ${$size === 'large' ? LARGE_HEIGHT : SMALL_HEIGHT};
padding: ${
    $size === 'large'
      ? LARGE_PADDING_VERTICAL
      : $marginHorizontalSize === 'small'
      ? SMALL_PADDING_VERTICAL
      : 0
  } ${
    $marginHorizontalSize === 'large'
      ? LARGE_PADDING_HORIZONTAL
      : $marginHorizontalSize === 'small'
      ? SMALL_PADDING_VERTICAL
      : 0
  };
${$width ? `width: ${$width};` : ''}
border: ${$varient === 'line' ? `2px ${$borderColor ?? colors.greyscale.black} solid` : 'none'};
cursor: pointer;
display: flex;
align-items: center;
${composeResponsiveButtonLayoutStyle($layout, $mobileLayout)}
&:disabled{
background-color: ${$varient === 'primary' ? DISABLED_BACKGROUND_COLOR : 'transparent'};
color: ${$varient === 'primary' ? colors.greyscale.white : DISABLED_BACKGROUND_COLOR};
}
span{
  width: 100%;
}
`,
)

export function Button({
  children,
  icon,
  varient = 'primary',
  layout = 'icon-left',
  mobileLayout,
  size = 'large',
  width,
  loading = false,
  rounded,
  marginHorizontalSize = 'large',
  backgroundColor,
  testid,
  color,
  iconSize,
  borderColor,
  iconColor,
  underlined = false,
  ...props
}: ButtonProps) {
  const color_ = color
    ? color
    : varient === 'primary'
    ? colors.greyscale.white
    : varient === 'secondary'
    ? colors.theme.primary
    : varient === 'gradient'
    ? colors.greyscale.white
    : colors.greyscale.black

  return (
    <ButtonStyled
      disabled={props.disabled ?? false}
      data-testid={composeTestID(Button.testid, testid)}
      $varient={varient}
      $mobileLayout={mobileLayout}
      $layout={layout}
      $color={color_}
      $size={size}
      $width={width}
      $rounded={rounded}
      $borderColor={borderColor}
      $marginHorizontalSize={marginHorizontalSize}
      $backgroundColor={backgroundColor}
      onClick={loading ? undefined : props.onClick}
      $underlined={underlined}
      {...props}
    >
      {icon && (layout === 'icon-left' || layout === 'icon-only') && !loading && (
        <Icon id="icon" color={iconColor ?? color_} size={iconSize}>
          {icon}
        </Icon>
      )}
      {loading === true ? (
        <Loader size={'27px'} varient={varient === 'line' ? 'black' : 'white'} />
      ) : (
        <></>
      )}
      {children && <span>{children}</span>}
      {icon && layout === 'icon-right' && (
        <Icon id="icon" color={color_}>
          {icon}
        </Icon>
      )}
    </ButtonStyled>
  )
}

function composeButtonLayoutStyle(layout: IconLayoutType | IconMobileLayoutType) {
  switch (layout) {
    case 'icon-left': {
      return `
      & #icon { margin-right: ${spacing.s}}
      `
    }
    case 'icon-right': {
      return `
      & #icon { margin-left: ${spacing.s}}
      `
    }
    case 'icon-only': {
      return `
      padding: 10px 15px;
      & span {
        display:none;
      }`
    }
    default: {
      return ``
    }
  }
}

function composeResponsiveButtonLayoutStyle(
  layout: IconLayoutType,
  mobileLayout?: IconMobileLayoutType,
) {
  return `
    @media ${media.mobile} {
      ${composeButtonLayoutStyle(mobileLayout ?? layout)}
    }
    @media ${media.desktop} {
      ${composeButtonLayoutStyle(layout)}
    }
  `
}
