import { PERMISSION_ENUM } from "config/permissions";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "stablr/components/atoms";
import { composeTestID } from "stablr/functions";
import { usePermission } from "stablr/hooks";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface AppDesktopBuySellNavigationLinkProps {
  testid?: string;
  children?: string;
  to: string;
  icon: React.ReactElement;
  color: string;
  permission?: PERMISSION_ENUM;
}

const LINK_PADDING = spacing.xs;
const ICON_CIRCLE_SIZE = "40px";

const AppDesktopBuySellNavigationLinkContainerStyled = styled.li`
  width: 100%;
  margin: ${spacing.s} 0;
`;

const AppDesktopBuySellNavigationLinkStyled = styled.div<{ disable?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${LINK_PADDING} 0;
  text-decoration: inherit;
  ${({ disable }) =>
    !disable &&
    `
    &:visited,
    &:active {
      text-decoration: inherit; /* no underline */
    }

    &.active {
      & span {
        color: ${color.greyscale.black};
      }

      & .iconContainer {
        box-shadow: 0 0 0 5px rgba(132, 157, 255, 0.25);
      }
    }

    &:hover {
      & span {
        color: ${color.greyscale.black};
      }
    }
  `}
`;

const AppDesktopBuySellNavigationLinkIconStyled = styled.div`
  width: ${ICON_CIRCLE_SIZE};
  min-height: ${ICON_CIRCLE_SIZE};
  border-radius: 100%;
  box-shadow: 0 0 0 4px rgba(132, 157, 255, 0.13);
  background-color: ${color.greyscale.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppDesktopBuySellNavigationLinkTextStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.semiBold};
  color: ${color.greyscale.grey5};
  margin: ${spacing.s} 0 0 0;
`;

AppDesktopBuySellNavigationLinkComponent.testid = "appDesktopBuySellNavigationLink";

export default function AppDesktopBuySellNavigationLinkComponent({
  icon,
  to,
  children,
  color,
  permission,
  ...props
}: AppDesktopBuySellNavigationLinkProps) {
  const hasPermission = usePermission();

  if (permission && !hasPermission(permission)) return <></>;

  return (
    <AppDesktopBuySellNavigationLinkContainerStyled>
      <AppDesktopBuySellNavigationLinkStyled
        as={NavLink}
        to={to}
        {...props}
        data-testid={composeTestID(AppDesktopBuySellNavigationLinkComponent.testid, props.testid)}
      >
        <AppDesktopBuySellNavigationLinkIconStyled className="iconContainer" style={{ backgroundColor: color }}>
          <Icon size={18}>{icon}</Icon>
        </AppDesktopBuySellNavigationLinkIconStyled>
        <AppDesktopBuySellNavigationLinkTextStyled>{children}</AppDesktopBuySellNavigationLinkTextStyled>
      </AppDesktopBuySellNavigationLinkStyled>
    </AppDesktopBuySellNavigationLinkContainerStyled>
  );
}
