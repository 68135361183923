import { APIAPIKey } from "types/API/APIManagement";

export const MOCK_API_MANAGEMENT_KEY_LIST: APIAPIKey[] = [
  {
    Id: "9c929431-9e3f-4adb-8c03-4cbc5d3e39dc",
    Name: "Demo System Integration",
    Role: "Treasury Manager",
    Status: "ACTIVE",
    WhitelistedIPs: ["192.168.1.1"],
    ApplicationId: "JHHDuud7ujDkdj38483DHd",
    CreatedOn: "2023-10-04T10:00:00.000Z",
    Secret: "JHud7ujDkdj38483DHd",
  },
  {
    Id: "aa929431-9e3f-d55b-8c03-4cbc5d3e39dc",
    Name: "Demo Cron Server",
    Role: "Trader",
    Status: "REVOKED",
    WhitelistedIPs: ["192.168.1.1"],
    ApplicationId: "ALjujDkdj38483DHd",
    CreatedOn: "2023-10-04T10:00:00.000Z",
    Secret: "HDuud7ujDkdj38483DHd",
  },
];

export const MOCK_API_MANAGEMENT_KEY: APIAPIKey = {
  Id: "9c929431-9e3f-4adb-8c03-4cbc5d3e39dc",
  Name: "Demo System Integration",
  Role: "Treasury Manager",
  WhitelistedIPs: ["192.168.0.0", "192.0.0.0"],
  Status: "ACTIVE",
  ApplicationId: "JHHDuud7ujDkdj38483DHd",
  CreatedOn: "2023-10-04T10:00:00.000Z",
  Secret: "JHHDuuDkdj38483DHd",
};
