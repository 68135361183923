import { usePermission } from 'stablr/hooks/use-permission'

export function RenderWithPermissions({
  permission,
  children,
}: {
  permission?: string
  children: any
}) {
  const hasPermission = usePermission()

  return !permission?.trim() || hasPermission(permission) ? children : null
}
