import { Config } from "config";
import { PERMISSION_ENUM } from "config/permissions";
import withPermission from "hocs/permission";
import React, { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { ListBox } from "stablr/components/atoms";
import { DashboardContainer, Tabs } from "stablr/components/molecules";
import { useIsMobile, usePermission } from "stablr/hooks";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";

import APIManagement from "./screens/APIManagement";
import BankAccounts from "./screens/BankAccounts";
import CompanyInformation from "./screens/CompanyInformation";
import PaymentInstructions from "./screens/PaymentInstructions";
import UserManagement from "./screens/UserManagement";
import Wallet from "./screens/Wallet";
import Webhooks from "./screens/Webhooks";

const disableAPIManagement = Config.features.disableAPIManagement;
const disableWebhooks = Config.features.disableWebhooks;

const DocumentTabItems = ["/accounts/payment-instructions"];
const ManagementTabItems = ["/accounts/user-management", "/accounts/webhooks", "/accounts/api-management"];

const TAB_LIST = [
  {
    label: "Company Information",
    value: "/accounts/company-information",
    permission: PERMISSION_ENUM.VIEW_ACCOUNT,
    testid: "company-information",
  },
  ...(Config.features.disableWalletBank
    ? []
    : [
        {
          label: "Wallets",
          value: "/accounts/wallet",
          permission: PERMISSION_ENUM.VIEW_WALLETS,
          testid: "wallets",
        },
        {
          label: "Bank Accounts",
          value: "/accounts/bank-accounts",
          permission: PERMISSION_ENUM.VIEW_BANK_ACCOUNTS,
          testid: "bank-accounts",
        },
      ]),
  {
    label: "Payment Instructions",
    value: "/accounts/payment-instructions",
    permission: PERMISSION_ENUM.VIEW_PAYMENT_INSTRUCTIONS,
    testid: "payment-instructions",
  },
  {
    label: "User Management",
    value: "/accounts/user-management",
    permission: PERMISSION_ENUM.VIEW_USERS,
    testid: "user-management",
  },
  ...(disableWebhooks
    ? []
    : [
        {
          label: "Webhooks",
          value: "/accounts/webhooks",
          permission: PERMISSION_ENUM.VIEW_WEBHOOKS,
          testid: "webhooks",
        },
      ]),
  ...(disableAPIManagement
    ? []
    : [
        {
          label: "API Management",
          value: "/accounts/api-management",
          permission: PERMISSION_ENUM.VIEW_API_KEY,
          testid: "api-management",
        },
      ]),
];

const TabPanelsStyled = styled.div`
  @media (${media.small}) {
    padding: ${spacing.s} ${spacing.l};
  }
`;

function Accounts() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const hasPermission = usePermission();

  const handleOnTabChange = React.useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const permittedTabArray = useMemo(
    () => TAB_LIST.filter(tab => hasPermission(tab.permission as PERMISSION_ENUM)),
    [hasPermission]
  );

  const { formattedTabOptions, routes } = useMemo(() => {
    const documentTabs = permittedTabArray.filter(tab => DocumentTabItems.includes(tab.value));
    const managementTabs = permittedTabArray.filter(tab => ManagementTabItems.includes(tab.value));
    return {
      formattedTabOptions: [
        ...permittedTabArray.filter(
          tab => !ManagementTabItems.includes(tab.value) && !DocumentTabItems.includes(tab.value)
        ),
        ...(documentTabs.length > 0
          ? [
              {
                value: "",
                label: "Documents",
                options: documentTabs,
              },
            ]
          : []),
        ...(managementTabs.length > 0
          ? [
              {
                value: "",
                label: "Management",
                options: managementTabs,
              },
            ]
          : []),
      ],
      routes: (
        <TabPanelsStyled>
          <Routes>
            <Route path="*" element={<CompanyInformation />} />
            <Route path="/company-information/*" element={<CompanyInformation />} />
            <Route path="/bank-accounts/*" element={<BankAccounts />} />
            <Route path="/payment-instructions/*" element={<PaymentInstructions />} />
            <Route path="/user-management/*" element={<UserManagement />} />
            <Route path="/wallet/*" element={<Wallet />} />
            <Route path="/api-management/*" element={<APIManagement />} />
            <Route path="/webhooks/*" element={<Webhooks />} />
          </Routes>
        </TabPanelsStyled>
      ),
    };
  }, [permittedTabArray]);

  const currentLocation = useCurrentLocationFromPaths(permittedTabArray.map(tabItem => tabItem.value));

  return (
    <DashboardContainer>
      {isMobile ? (
        <>
          <ListBox<string>
            defaultValue={permittedTabArray[0].value}
            options={permittedTabArray}
            value={permittedTabArray[currentLocation]?.value as string}
            onChange={handleOnTabChange}
            varient="tab"
          />
          {routes}
        </>
      ) : (
        <Tabs
          value={permittedTabArray[currentLocation]?.value as string}
          tabList={formattedTabOptions}
          onChange={handleOnTabChange}
        >
          {routes}
        </Tabs>
      )}
    </DashboardContainer>
  );
}

export default withPermission(PERMISSION_ENUM.VIEW_ACCOUNT)(Accounts);
