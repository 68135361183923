import { Config } from "config";
import React from "react";
import { Field } from "react-final-form";
import { useIsMobile } from "stablr";
import { Spacer } from "stablr/components/atoms";
import { CurrencyInput, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import { parseCurrency } from "stablr/functions";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APIBankAccount } from "types/API/BankAccount";
import { APIBuyTransactionTable } from "types/API/Transaction";

import BankWalletBoxes from "../../components/BankWalletBoxes";
import { BUY_ORDER_SCHEMA } from "./schemaValidation";

interface BuyOrderFormProps {
  transaction: APIBuyTransactionTable;
  bank?: APIBankAccount;
  isLoadingBank: boolean;
  selectedNetwork: string;
}

const FormWrapperStyled = styled.div`
  padding: 0 ${spacing.xl};
  box-shadow: 0 1px 4px 0 #0000001a;
  margin-top: ${spacing.m};
  border-radius: 20px;

  & > * {
    max-width: none;
  }

  @media ${media.mobile} {
    padding: ${spacing.s};
    margin-top: ${spacing.xxs};
  }
`;

const parseBalanceInput = (value: string) => {
  return parseCurrency(value, Config.fallbackDenominator);
};

export default function BuyOrderForm({ transaction, bank, isLoadingBank, selectedNetwork }: BuyOrderFormProps) {
  const isMobile = useIsMobile();

  return (
    <>
      <BankWalletBoxes
        transaction={transaction}
        bank={bank}
        isLoadingBank={isLoadingBank}
        selectedNetwork={selectedNetwork}
      />
      <Spacer height={spacing.l} />
      <FormWrapperStyled>
        <FormLayout columns={isMobile ? 1 : 2}>
          <Field
            name={BUY_ORDER_SCHEMA.BUY}
            compact
            label="You Buy"
            inputType="EURR"
            component={CurrencyInput}
            parse={parseBalanceInput}
            maxWidth
          />
          <Field
            name={BUY_ORDER_SCHEMA.REFERENCE}
            compact
            tooltip={"Here you can add a note to the transaction for example a reference number 123AB"}
            label="Notes (Optional)"
            placeholder="Notes"
            component={TextInput}
            maxWidth
          />
        </FormLayout>
      </FormWrapperStyled>
    </>
  );
}
