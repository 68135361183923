import * as React from 'react'
import { Icon } from 'stablr/components/atoms/Icon'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface FeatureIconProps {
  icon: React.ReactElement
  varient?: 'large' | 'small'
}

const CIRCLE_SIZE = '72px'
const WAVE_SIZE = '300px'
const WAVE_INNER_SIZE = '75%'
const WAVE_ICON_CIRCLE_SIZE = '40%'

const FeatureIconStyled = styled.div`
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background-color: rgba(184, 152, 255, 0.15);
  border-radius: calc(${CIRCLE_SIZE} / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const WaveStyled = styled.div`
  position: relative;
  width: ${WAVE_SIZE};
  height: ${WAVE_SIZE};
  background-color: rgba(184, 152, 255, 0.05);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    width: ${WAVE_INNER_SIZE};
    height: ${WAVE_INNER_SIZE};
    background-color: rgba(184, 152, 255, 0.05);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
`

const IconWaveStyled = styled.div`
  width: ${WAVE_ICON_CIRCLE_SIZE};
  height: ${WAVE_ICON_CIRCLE_SIZE};
  background: ${color.gradient};
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export function FeatureIcon({ icon, varient }: FeatureIconProps) {
  return varient === 'large' ? (
    <WaveStyled>
      <Icon as={IconWaveStyled} size={80} color={color.greyscale.white}>
        {icon}
      </Icon>
    </WaveStyled>
  ) : (
    <Icon as={FeatureIconStyled} size={30} color={color.theme.primary}>
      {icon}
    </Icon>
  )
}
