import * as yup from "yup";

export enum SELL_ORDER_SCHEMA {
  WALLET = "wallet",
  CURRENT_BALANCE = "currentBalance",
  NEW_BALANCE = "newBalance",
  SELL = "sell",
  RECEIVE = "receive",
  REFERENCE = "reference",
}

/**
 * Validation 4.1: Create Sell Order
 */

export const SellOrderSchemaValidation = yup.object({
  [SELL_ORDER_SCHEMA.WALLET]: yup.object().required("Wallet address is required"),
  [SELL_ORDER_SCHEMA.SELL]: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Incorrect number format")
    .min(10, "Must be more than 10")
    .test(
      "Max 1000000000",
      "Can not be more than 1000000000",
      value => value !== undefined && value !== null && value <= 1000000000
    )
    .max(yup.ref("currentBalance"), "You cannot sell more than the available balance in your wallet.")

    .required("Sell amount is required"),
  [SELL_ORDER_SCHEMA.REFERENCE]: yup.string().max(12, "Reference cannot exceed 12 characters"),
});
