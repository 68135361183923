import api from "api";
import { APIPaymentFile, APIPaymentListItem } from "types/API/Payment";

import Connection from "./Connection";

export const getPaymentList = () => Connection.get<APIPaymentListItem[]>("/organizations/payments");

export const getPaymentFileId = async (paymentReference: string) =>
  Connection.get<APIPaymentFile>(`/files/payment-instructions/${paymentReference}`);

export const downloadPaymentFile = (paymentReference: string) =>
  getPaymentFileId(paymentReference).then(data => api.Files.downloadFile(data.FileId, "payment-instructions"));
