import { Tab } from '@headlessui/react'
import React, { ReactElement, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { TabButton } from '../../atoms/Tab/TabButton'
import { TabPannels } from '../../atoms/Tab/TabPannels'

export interface TabListItem {
  label: string
  value: string
  testid?: string
  options?: Omit<TabListItem, 'options'>[]
}

const TAB_HEIGHT = '61px'
const TAB_MENU_SPACE = '400px'

const TabGroupStyled = styled.div`
  border-radius: ${spacing.s};
  font-family: ${fontFamily.primary};
  padding: 0;
`
const TabListWrapperStyled = styled.div`
  position: relative;
  float: top;
  overflow-x: scroll;
  overflow-y: none;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: calc(${TAB_MENU_SPACE} + ${TAB_HEIGHT});
  &::-webkit-scrollbar {
    display: none;
  }
  pointer-events: none;
  margin-bottom: -${TAB_MENU_SPACE};
`

const TabListStyled = styled.div`
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.greyscale.white};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: ${fontFamily.secondary};
  pointer-events: fill; // Override the parent pointer events - this wrapper is a workaround for css overflow-x: scroll and overflow-y: visible limitations
  padding-right: ${spacing.xxl};
  display: block;
  min-width: 100%;
  box-shadow: inset 0 -1px ${color.greyscale.grey3};

  &::-webkit-scrollbar {
    display: none;
  }
`

export function Tabs({
  tabList,
  value,
  onChange,
  tabPanels,
  children,
}: {
  tabList: TabListItem[]
  value: string
  tabPanels?: ReactElement[]
  onChange: (value: string) => void
  children?: React.ReactElement
}) {
  const flatTabList = React.useMemo(
    () => tabList.map((tab) => (tab.options === undefined ? [tab] : tab.options)).flat(),
    [tabList],
  )

  const handleOnTabChange = (index: number) => {
    onChange(flatTabList[index].value)
  }

  const selectedIndex = React.useMemo(
    () => flatTabList.findIndex((tabItem) => tabItem.value === value),
    [value],
  )

  const tabs = useMemo(() => {
    return tabList.map((tab, idx) => (
      <TabButton
        key={`${tab.value}-${idx}`}
        testid={tab.testid}
        title={tab.label}
        tabKey={tab.value}
        options={tab.options}
        selected={value}
      />
    ))
  }, [tabList, value])

  return (
    <Tab.Group
      as={TabGroupStyled}
      selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
      onChange={handleOnTabChange}
    >
      <TabListWrapperStyled>
        <Tab.List as={TabListStyled}>{tabs}</Tab.List>
      </TabListWrapperStyled>
      {tabPanels ? <TabPannels panels={tabPanels} /> : children}
    </Tab.Group>
  )
}
