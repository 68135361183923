import * as React from 'react'
import { formatCurrency } from 'stablr/functions/format-currency'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { AppCurrencyObject } from '../../../../types/currency'
import { Spacer } from '../../Spacer'

const TableDataCurrencyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

const TableDataCurrencyStyled = styled.span`
  display: block;
`

export interface TableDataProps extends React.HTMLAttributes<HTMLTableCellElement> {
  digitalAmount: AppCurrencyObject
  fiatAmount: AppCurrencyObject
}

TableDataCurrency.testid = 'tableData'

export function TableDataCurrency({ digitalAmount, fiatAmount }: TableDataProps) {
  return (
    <TableDataCurrencyContainerStyled>
      <TableDataCurrencyStyled>{formatCurrency(digitalAmount, 2)} EURR</TableDataCurrencyStyled>
      <Spacer height={spacing.xxs} />
      <TableDataCurrencyStyled style={{ color: color.greyscale.grey4 }}>
        € {formatCurrency(fiatAmount, 2)}
      </TableDataCurrencyStyled>
    </TableDataCurrencyContainerStyled>
  )
}
