import * as React from 'react'
import { ReactComponent as ChevronDownSVG } from 'stablr/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUpSVG } from 'stablr/assets/icons/chevron-up.svg'
import { Icon } from 'stablr/components/atoms/Icon'
import { color, fontSize, fontWeight, spacing } from 'stablr/styles'
import styled from 'styled-components'

type ExpandableListItemProps = {
  title?: string
  varient?: 'shadow' | 'basic'
  children: React.ReactElement | React.ReactElement[]
}

const ExpandableListItemStyled = styled.li<{ $varient: 'shadow' | 'basic' }>`
  width: 100%;
  display: block;
  margin: ${spacing.m} 0;
  ${({ $varient }) =>
    $varient === 'basic'
      ? `
      & > button {box-shadow: none;} 
      border-bottom: 1px solid ${color.greyscale.grey4};
      `
      : 'border: none;'}
`

const ExpandableListItemHeader = styled.button<{ $varient: 'shadow' | 'basic' }>`
  width: 100%;
  background-color: ${color.greyscale.white};
  margin: ${spacing.m};
  border-radius: ${spacing.xs};
  box-shadow: 0 4px 4px 0 ${color.other.borderGradient};
  min-height: ${({ $varient }) => ($varient === 'basic' ? '80px' : '65px')};
  margin: 0;
  font-size: ${fontSize.h5};
  font-weight: ${fontWeight.semiBold};
  border: none;
  text-align: left;
  padding-left: ${spacing.l};
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ExpandableListItemContentStyled = styled.div`
  margin: ${spacing.l} 0;
  width: 100%;
`

const ExpandableListItemIconStyled = styled(Icon)`
  margin-right: ${spacing.m};
  margin-left: auto;
`

export function ExpandableListItem({
  children,
  title,
  varient = 'shadow',
}: ExpandableListItemProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <ExpandableListItemStyled $varient={varient}>
      <ExpandableListItemHeader $varient={varient} onClick={() => setIsOpen(!isOpen)}>
        {title}
        <ExpandableListItemIconStyled>
          {isOpen ? <ChevronUpSVG /> : <ChevronDownSVG />}
        </ExpandableListItemIconStyled>
      </ExpandableListItemHeader>
      {isOpen && <ExpandableListItemContentStyled>{children}</ExpandableListItemContentStyled>}
    </ExpandableListItemStyled>
  )
}
