import { Config } from "config";
import { transformWalletAddress } from "pages/BuySell/functions/transform-wallet-address";
import * as React from "react";
import { useIsMobile } from "stablr";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { Icon, Spacer, Table, Tooltip } from "stablr/components/atoms";
import { formatCurrency } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";
import { APIBuyTransactionTable } from "types/API/Transaction";

const { TableBody, TableHeader, TableRow, TableData, Table: TableStyled, TableDataCopyable } = Table;
interface BuyTransactionTableProps {
  transaction: APIBuyTransactionTable;
}

const BuyTransactionTableStyled = styled(TableStyled)`
  width: 100%;
  padding: ${spacing.m};
  max-width: 1600px;

  & > tbody > tr:not(:last-child) > td,
  & > tbody > tr:not(:last-child) > th {
    border-bottom: 1px solid ${color.greyscale.grey3};
  }

  & > tbody > tr > td {
    width: 40%;
  }

  & > tbody > tr > td > div {
    justify-content: flex-end;
  }

  & > tbody > tr:nth-last-child(2) > th,
  & > tbody > tr:nth-last-child(2) > td {
    border-bottom: 1px dotted ${color.greyscale.black};
  }

  & > tbody > tr:nth-last-child(1) > th,
  & > tbody > tr:nth-last-child(1) > td {
    border-bottom: none;
  }

  & > tbody > tr > th {
    padding: ${spacing.m} 0;
    color: ${color.greyscale.black};
  }

  & > tbody > tr:nth-child(1) > td {
    padding: 0;
  }
`;

export default function BuyTransactionTable({ transaction }: BuyTransactionTableProps) {
  const isMobile = useIsMobile();
  const [isTooltipVisible, setTooltipVisible] = React.useState(false);

  const formatBalance = (value: string) =>
    value ? formatCurrency({ Amount: value, Denominator: Config.fallbackDenominator }, 2) : "";

  return (
    <BuyTransactionTableStyled cellSpacing="0">
      <TableBody>
        <TableRow>
          <TableHeader paddingSize="small">To wallet address</TableHeader>
          <TableData>
            <TableDataCopyable
              displayValue={
                isMobile ? transformWalletAddress(transaction?.wallet?.Address) : transaction?.wallet?.Address
              }
              value={transaction?.wallet?.Address}
            />
          </TableData>
        </TableRow>
        <TableRow>
          <TableHeader paddingSize="small">You will receive</TableHeader>
          <TableData paddingSize="small">{`${formatCurrency(
            {
              Amount: transaction.buy,
              Denominator: Config.fallbackDenominator,
            },
            2
          )} EURR`}</TableData>
        </TableRow>
        <TableRow>
          <TableHeader paddingSize="small">
            Transaction fee
            <Spacer width={spacing.xs} />
            <Icon
              color={color.greyscale.grey4}
              size={20}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <InfoIcon />
            </Icon>
            {isTooltipVisible && (
              <Tooltip text="The transaction fee of 0,00% that StablR charges for minting your StablR assets." />
            )}
          </TableHeader>
          <TableData paddingSize="small">0.00 EUR</TableData>
        </TableRow>
        <TableRow>
          <TableHeader paddingSize="small">Rate</TableHeader>
          <TableData paddingSize="small">1 EURO = 1 EURR</TableData>
        </TableRow>
        <TableRow>
          <TableHeader paddingSize="small"></TableHeader>
          <TableData paddingSize="small"></TableData>
        </TableRow>
        <TableRow>
          <TableHeader paddingSize="small">You pay</TableHeader>
          <TableData bold paddingSize="small">
            {transaction?.pay && formatBalance(transaction.pay)} EUR
          </TableData>
        </TableRow>
      </TableBody>
    </BuyTransactionTableStyled>
  );
}
