import { APIIssuer } from "types/API/Issuer";

export const MOCK_ISSUERS: APIIssuer[] = [
  {
    Id: "e286dbd7-0208-4ddd-878b-67ec55665132",
    Name: "StablR Ltd.",
    Address: "0x9589427373D6D84E98730D7795D8f6f8731FDA16",
    IsBurnAllowed: true,
  },
  {
    Id: "e256dbd7-0208-4cdd-878b-67ec55665132",
    Name: "StablR Ltd.",
    Address: "0x8589427373D6D84E98730D7795D8f6f8731FDA16",
    IsBurnAllowed: false,
  },
];
