import { Menu } from '@headlessui/react'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from 'stablr/components/atoms/Icon'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface MenuDropDownLinkProps {
  children: string
  icon: React.ReactElement
  to: string
}

const LINK_HEIGHT = '80px'

const MenuDropDownLinkStyled = styled.div`
  text-decoration: none;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily.secondary};
  font-weight: ${fontWeight.semiBold};
  color: ${color.greyscale.black};
  height: ${LINK_HEIGHT};
  display: flex;
  align-items: center;

  &:visited,
  &:active {
    text-decoration: inherit; /* no underline */
  }

  &.active {
    color: ${color.greyscale.black};

    & .iconContainer {
      box-shadow: 0 0 0 5px rgba(132, 157, 255, 0.25);
    }

    & svg > path {
      fill: url(#gradient) !important;
    }
  }
`

const SpanStyled = styled.span`
  padding-top: 6px; /* Offset text descender for caps only */
  padding-left: ${spacing.l};
`

export function MenuDropDownLink({ children, icon, to }: MenuDropDownLinkProps) {
  return (
    <li>
      <Menu.Item as={React.Fragment}>
        <MenuDropDownLinkStyled as={NavLink} to={to}>
          <Icon size={20}>{icon}</Icon>
          <SpanStyled>{children}</SpanStyled>
        </MenuDropDownLinkStyled>
      </Menu.Item>
    </li>
  )
}
