import * as yup from "yup";

export enum APIKEY_SCHEMA {
  ID = "Id",
  NAME = "Name",
  ROLE = "Role",
  STATUS = "Status",
  APPLICATION_ID = "ApplicationId",
  DATE = "CreatedDateTime",
  WHITELISTED_IPS = "WhitelistedIPs",
  SECRET = "Secret",
}

const ipv4Regex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2]?\d))?$/;
const ipv6Regex = /^([0-9a-f]){1,4}(:([0-9a-f]){1,4}){7}$/i;

export const APIKeySchemaValidation = yup.object({
  [APIKEY_SCHEMA.NAME]: yup.string().max(60, "Name must not exceed 60 characters").required("Name is required"),
  [APIKEY_SCHEMA.WHITELISTED_IPS]: yup.array().of(
    yup
      .string()
      .test("Whitelisted IPs Test", "Invalid IP format", function (value: string | undefined) {
        if (value === "" || value === undefined) {
          return true;
        }
        return ipv4Regex.test(value);
      })
      .test("Not IPv6 Address", "IPv6 addresses are not currently supported", function (value: string | undefined) {
        if (value === "" || value === undefined) {
          return true;
        }
        return !ipv6Regex.test(value);
      })
  ),
});
