import * as yup from "yup";

export enum WEBHOOK_SCHEMA {
  NAME = "Name",
  URL = "Url",
  SECRET = "Secret",
  STATUS = "Status",
}

export const WebhookSchemaValidation = yup.object({
  [WEBHOOK_SCHEMA.NAME]: yup
    .string()
    .required("Name is required")
    .test(
      "maxLength",
      "Display name cannot be more than 25 characters",
      (value?: string) => (value?.length ?? 0) <= 25
    ),
  [WEBHOOK_SCHEMA.URL]: yup
    .string()
    .required("Url is required")
    .url("URL must be valid")
    .test("isHttps", "URL must start with 'https://'", (value?: string) => {
      if (value) {
        return value.startsWith("https://");
      }
      return false;
    }),
  [WEBHOOK_SCHEMA.SECRET]: yup
    .string()
    .required("Secret is required")
    .test(
      "maxLength",
      "Secret cannot be more than 150 characters",
      (value?: string) => (value?.length ?? 0) <= 150
    ),
});
