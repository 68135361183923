export const SCREEN_SIZES = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '968px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

const mediaQueries = {
  smallMobile: `(min-width: ${SCREEN_SIZES.mobileS}) and (max-width: ${SCREEN_SIZES.mobileM})`,
  small: `(min-width: ${SCREEN_SIZES.tablet})`,
  medium: `(min-width: ${SCREEN_SIZES.laptopL})`,
  large: `(min-width: ${SCREEN_SIZES.desktop})`,
  mobile: `(max-width: ${SCREEN_SIZES.tablet})`,
  tablet: `(min-width: ${SCREEN_SIZES.tablet}) and (max-width: ${SCREEN_SIZES.laptopL})`,
  desktop: `(min-width: ${SCREEN_SIZES.tablet})`,
  laptop: `(min-width: ${SCREEN_SIZES.laptopL})`,
}

export default mediaQueries
