const fontSize = {
  h1: '54px',
  h2: '32px',
  h3: '24px',
  h4: '21px',
  h5: '18px',
  button: '18px',
  input: '16px',
  p: '14px', // TODO discuss with Aaron
  data: '16px', // TODO discuss with Aaron
  small: '12px',
  xs: '10px',
  web: {
    desktop: {
      h1: '72px',
      h2: '48px',
      h3: '34px',
      h4: '21px',
    },
    mobile: {
      h1: '34px',
      h2: '32px',
      h3: '24px',
      h4: '21px',
    },
  },
}

export default fontSize
