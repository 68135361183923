import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import styled from 'styled-components'

export type HeadingElementTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  testid?: string
  as: HeadingElementTypes
  varient?: HeadingElementTypes
  color?: string
}

const HeadingStyled = styled.h1(
  ({ varient, as = 'h1', color }: Pick<HeadingProps, 'varient' | 'as' | 'color'>) => {
    const styleVarient = varient ?? as

    return `
  font-family: ${fontFamily.secondary};
  ${color ? `color: ${color};` : ''}
  font-size: ${fontSize[styleVarient]};
`
  },
)

Heading.testid = 'heading'

export function Heading({ ...props }: HeadingProps) {
  return <HeadingStyled data-testid={composeTestID(Heading.testid, props.testid)} {...props} />
}
