import * as React from 'react'
import colorStyle from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import styled from 'styled-components'

export interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: string
}

export const Paragraph = styled.p(
  ({ color }: ParagraphProps) => `
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.p};
  color: ${color ?? colorStyle.greyscale.greyDark1};
  line-height: 21px;
  font-weight: ${fontWeight.regular};
  & > ul {
    padding-inline-start: 14px;
  }
  & > b {
    color: ${colorStyle.greyscale.black};
  }
`,
)
