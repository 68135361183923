import isMobilePhone from "validator/lib/isMobilePhone";
import * as yup from "yup";

export enum USER_SCHEMA {
  NAME = "Name",
  COMPANY_EMAIL = "Email",
  PHONE = "Phone",
  ROLE = "UserGroups",
}

/**
 * Validation 2.3: Create User
 */

export const UserSchemaValidation = yup.object({
  [USER_SCHEMA.NAME]: yup
    .string()
    .required("Name is required")
    .test("maxLength", "Name cannot be more than 50 characters", (value?: string) => (value?.length ?? 0) <= 50),
  [USER_SCHEMA.COMPANY_EMAIL]: yup.string().email().required("Email is required"),
  [USER_SCHEMA.PHONE]: yup
    .string()
    .required("Mobile Phone Number is required")
    .test(
      "Is Valid Mobile",
      "Must be valid mobile phone number",
      value => typeof value === "string" && isMobilePhone(value, undefined, { strictMode: true })
    ),
  [USER_SCHEMA.ROLE]: yup.array().of(yup.string()).required("Role is required").min(1, "At least one role is required"),
});
