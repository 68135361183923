import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "stablr/components/atoms";
import { DashboardContainer } from "stablr/components/molecules";
import { useIsMobile } from "stablr/hooks";

import BalanceCard from "./components/BalanceCard";
import RecentActivityCard from "./components/RecentActivityCard";

export default function DashboardComponent() {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const handleOnBuyClick = () => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "BuyCTA", { location: "Dashboard" });
    navigate("/buy");
  };

  const handleOnTransactionsClick = () => {
    logEvent(EVENT_TYPE_ENUM.CLICK, "TransactionsCTA", { location: "Dashboard" });
    navigate("/transactions");
  };

  return (
    <DashboardContainer>
      <BalanceCard onBuyClick={handleOnBuyClick} />
      <RecentActivityCard
        title="Recent Activity"
        buttonElement={
          isMobile ? undefined : (
            <Button varient="secondary" onClick={handleOnTransactionsClick} testid="view-transactions">
              View All
            </Button>
          )
        }
      />
    </DashboardContainer>
  );
}
