import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input } from 'stablr/components/atoms/Form/Input'
import { InputAppendType } from 'stablr/components/atoms/Form/InputAppendType'
import { InputNote } from 'stablr/components/atoms/Form/InputNote'
import { Label } from 'stablr/components/atoms/Form/Label'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface BalanceInputProps extends FieldRenderProps<string, HTMLInputElement> {
  testid?: string
  icon?: React.ReactElement
  note?: string
  compact?: boolean
  inputType?: string
}
const MAX_INPUT_WIDTH = '500px'

const BalanceInputStyled = styled.div`
  width: 100%;
  max-width: min(${MAX_INPUT_WIDTH}, 100%);
`

const InputGroupStyled = styled.div`
  & > div {
    background: ${color.gradient};
    border: none;
  }

  & > div > input,
  & > div > input::placeholder,
  & > div > input:disabled {
    background: none;
    color: ${color.greyscale.white};
  }
`

BalanceInput.testid = 'BalanceInput'

export function BalanceInput({
  input: { ...inputProps },
  label,
  note,
  inputType,
  compact = false,
  ...props
}: BalanceInputProps) {
  const error = props.meta.touched && props.meta.error

  return (
    <BalanceInputStyled data-testid={composeTestID(BalanceInput.testid, props.testid)}>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <InputGroupStyled>
        <Input
          {...inputProps}
          {...props}
          textAlign="right"
          type={'text'}
          placeholder="-"
          appendElement={
            <InputAppendType color={color.greyscale.white}>{inputType ?? ''}</InputAppendType>
          }
        ></Input>
      </InputGroupStyled>
      {(!compact || note?.trim() || error) && (
        <InputNote htmlFor={inputProps.name} color={error ? color.palette.red : undefined}>
          {error ? error : note}
        </InputNote>
      )}
    </BalanceInputStyled>
  )
}
