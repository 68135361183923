import React, { ReactElement } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { ReactComponent as CheckIcon } from 'stablr/assets/icons/check-circle.svg'
import { ReactComponent as CloseIcon } from 'stablr/assets/icons/close.svg'
import { ReactComponent as InfoIcon } from 'stablr/assets/icons/info.svg'
import { ReactComponent as WarningIcon } from 'stablr/assets/icons/warning.svg'
import { composeTestID } from 'stablr/functions'
import color from 'stablr/styles/color'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { Paragraph } from '../Paragraph'

type ToastType = 'error' | 'success' | 'info'

const ToastStyled = styled.div<{ visible: boolean }>`
  animation: ${({ visible }) => (visible ? 'custom-enter 1s ease' : 'custom-exit 1s ease')};
  padding: ${spacing.xs} ${spacing.s};
  background-color: ${color.greyscale.white};
  border-radius: 10px;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 5px 2px 10px ${color.greyscale.grey3};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderStyled = styled.div(
  ({ type }: { type: ToastType }) => `
  display: flex;

  & > span {
    margin-left: ${spacing.s};

    & > h5 {
      margin: 0;
      font-weight: ${fontWeight.semiBold};
      color: ${color.greyscale.grey5};
    }

    & > p {
      color: ${
        type === 'error'
          ? color.palette.red
          : type === 'success'
          ? color.palette.green
          : color.palette.blue
      };
      font-weight: ${fontWeight.semiBold};
      margin: 0;
    }
  }
`,
)

Toast.testid = 'Toast'

export function Toast() {
  const NOTIFOCATION_TYPE: { [key: string]: { color: string; icon: ReactElement } } = {
    success: {
      color: color.palette.green,
      icon: <CheckIcon />,
    },
    error: {
      color: color.palette.red,
      icon: <WarningIcon />,
    },
    info: {
      color: color.palette.blue,
      icon: <InfoIcon />,
    },
  }

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 3000,
      }}
    >
      {({ id, visible, type, ariaProps, message, icon }: any) => {
        const notification = NOTIFOCATION_TYPE[type] || NOTIFOCATION_TYPE.info
        return (
          <ToastStyled visible={visible}>
            <HeaderStyled type={type as ToastType}>
              <Icon size={28} color={notification.color}>
                {icon ?? notification.icon}
              </Icon>
              <span>
                <h5 data-testid={composeTestID(Toast.testid, 'Title')}>{ariaProps?.title}</h5>
                <Paragraph data-testid={composeTestID(Toast.testid, 'Body')}>
                  {message?.toString()}
                </Paragraph>
              </span>
            </HeaderStyled>
            <Button
              onClick={() => toast.remove(id)}
              icon={<CloseIcon />}
              iconSize={20}
              varient="secondary"
              layout="icon-only"
            />
          </ToastStyled>
        )
      }}
    </Toaster>
  )
}
