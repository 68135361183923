import * as React from 'react'
import { Alert } from 'stablr/components/atoms/Alert'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import styled from 'styled-components'

export interface ValidationAlertProps {
  testid?: string
  message: string
}

export const AlertStyled = styled.div`
  border: 1px solid ${color.palette.red};
  border-radius: 7px;
  background-color: ${color.greyscale.grey1};
  color: ${color.greyscale.black};

  & > :first-child {
    box-shadow: none;
  }
`

ValidationAlert.testid = 'ValidationAlert'

export function ValidationAlert({ testid, message }: ValidationAlertProps) {
  return (
    <AlertStyled data-testid={composeTestID(ValidationAlert.testid, testid)}>
      <Alert iconColor={color.palette.red} message={message} />
    </AlertStyled>
  )
}
