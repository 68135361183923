import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import { useAPIKey } from "hooks/query/api-management/use-api-key";
import { useUpdateAPIKey } from "hooks/query/api-management/use-update-api-key";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Spacer } from "stablr/components/atoms";
import { ButtonContainer, CardLoader, ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { color } from "stablr/styles";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";
import { APIAPIKey } from "types/API/APIManagement";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import ApplicationInfoForm from "../common/APIKeyInfoForm";
import RevokeAPIKeyButton from "../common/RevokeAPIKeyButton";
import { APIKeySchemaValidation } from "../common/schemaValidation";
import { getAPIBadgeVarient } from "../functions/get-api-badge-varient";
import { parseAPIBadgeText } from "../functions/parse-api-badge-text";

const HeaderContainerStyled = styled.div`
  align-items: center;
  display: flex;
`;

function EditAPIKey() {
  const navigate = useNavigate();
  const { apiKeyId = "" } = useParams();
  const [openExitModal, setOpenExitModal] = useState(false);

  const { data: application, isLoading, isError } = useAPIKey(apiKeyId);
  const { mutateAsync: mutateAsyncUpdateAPIKey, isLoading: updatingAPIKey } = useUpdateAPIKey();

  const handleOnAPIUpdate = async (apiKey: APIAPIKey) => {
    if (updatingAPIKey) return;
    try {
      const filteredWhitelistedIPs = apiKey.WhitelistedIPs && apiKey.WhitelistedIPs.filter((ip: string) => ip !== "");
      const apiKeyUpdated = { ...apiKey, WhitelistedIPs: filteredWhitelistedIPs };
      await mutateAsyncUpdateAPIKey({ apiKeyId, apiKeyUpdated }, { onSuccess: () => navigate(-1) });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  const handleOnBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate(-1);
    }
  };

  if (isLoading) return <CardLoader />;

  return (
    <>
      <Form
        onSubmit={handleOnAPIUpdate}
        validate={validateFormValues(APIKeySchemaValidation)}
        initialValues={{ ...application }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, submitError, values, dirty }) => {
          const isDisabled = submitting;
          return (
            <>
              <HeaderContainerStyled>
                <HeaderBackNav
                  title={application?.Name || ""}
                  onBack={event => {
                    event.preventDefault();
                    handleOnBack(dirty);
                  }}
                />
                <Spacer width={spacing.s} />
                {application && (
                  <Badge
                    noMargin
                    varient={getAPIBadgeVarient(application.Status)}
                    text={parseAPIBadgeText(application?.Status)}
                  />
                )}
              </HeaderContainerStyled>
              <form onSubmit={handleSubmit}>
                <ApplicationInfoForm isEditForm disabled={isDisabled || isError} status={values.Status} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                {application?.Status !== "REVOKED" && (
                  <ButtonContainer justifyContent="flex-start">
                    <Button
                      type="submit"
                      varient="primary"
                      color={color.greyscale.white}
                      iconSize={20}
                      loading={isDisabled}
                      width="120px"
                    >
                      Save
                    </Button>
                    <Spacer width={spacing.l} />
                    <RevokeAPIKeyButton id={apiKeyId} apikeyName={application?.Name ?? ""} />
                  </ButtonContainer>
                )}
              </form>
              <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.UPDATE_API_KEY)(EditAPIKey);
