import React, { ReactElement } from 'react'
import { Spacer } from 'stablr/components/atoms'
import { spacing } from 'stablr/styles'
import color from 'stablr/styles/color'

import { Button } from '../../atoms/Button'
import { Paragraph } from '../../atoms/Paragraph'
import { ErrorMessage } from '../../molecules/Form/ErrorMessage'
import { Modal } from '../../molecules/Modal'

export function ActionModal({
  title,
  open,
  varient,
  onClose,
  onConfirm,
  btnText,
  description,
  btnColor,
  btnBackgroundColor,
  iconElement,
  iconSize,
  loading,
  marginHorizontalSize,
  marginSize,
  errorMessage,
}: {
  btnText: string
  title: string
  open: boolean
  onConfirm: () => void
  onClose: () => void
  varient?: 'primary' | 'secondary'
  description: string | ReactElement
  btnColor?: string
  btnBackgroundColor?: string
  iconElement?: ReactElement
  marginSize?: 'large' | 'small'
  marginHorizontalSize?: 'large' | 'small'
  iconSize?: number
  loading?: boolean
  errorMessage?: string
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      modalBody={
        <>
          <Paragraph>{description}</Paragraph>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </>
      }
      modalFooter={
        <>
          <Button
            varient="secondary"
            color={color.greyscale.black}
            marginHorizontalSize="small"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Spacer width={spacing.m} />
          <Button
            color={btnColor}
            varient={varient}
            icon={iconElement}
            iconSize={iconSize ?? 20}
            backgroundColor={btnBackgroundColor}
            onClick={onConfirm}
            disabled={loading}
            marginHorizontalSize={marginHorizontalSize}
            size={marginSize}
            loading={loading}
          >
            {btnText}
          </Button>
        </>
      }
    />
  )
}
