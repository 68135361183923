import { APICurrencyObject, APIDate } from "./Shared";

export enum WALLET_NETWORK_ENUM {
  Ethereum = "Ethereum",
}

export const NETWORK_MAP = {
  Ethereum: "Ethereum",
  Solana: "Solana",
};
export const WALLET_TYPE_ENUM = {
  1: "MetaMask Institutional",
  2: "MetaMask",
};

export enum WALLET_STATUS_ENUM {
  Verified = "Verified",
  Pending_Approval = "Pending Approval",
  Requesting_Approval = "Requesting Approval",
  Pending_Delete = "Pending Delete",
  Requesting_Delete = "Requesting Delete",
  Rejected_Approval = "Rejected Approval",
  Rejected_Delete = "Rejected Delete",
}

type APIWalletStatus = "PENDING" | "APPROVED" | "REJECTED" | "DELETED";

type APIWalletNetwork = "Ethereum" | "Solana"

export type APIWalletType =
  | "MetaMaskInstitutional"
  | "MetaMask"
  | "FireblocksVault"
  | "Ledger"
  | "GnosisSafe"
  | "CoinbaseInstitutional"
  | "Trezor"
  | "Copper"
  | "Phantom"
  | "Solflare"
  | "Bit Go"
  | "Other";

  export const WALLET_TYPE_MAP = {
    Ethereum: {
      MetaMaskInstitutional: "MetaMask Institutional",
      MetaMask: "MetaMask",
      QredoVault: "Qredo Vault",
      FireblocksVault: "Fireblocks Vault",
      Ledger: "Ledger",
      GnosisSafe: "Gnosis Safe",
      CoinbaseInstitutional: "Coinbase Institutional",
      Trezor: "Trezor",
      Copper: "Copper",
      Other: "Other",
    },
    Solana: {
      Phantom: "Phantom",
      TrustWallet: "Trust Wallet",
      Solflare: "Solflare",
      FireblocksVault: "Fireblocks Vault",
      BitGo: "Bit Go",
      Ledger: "Ledger",
      CoinbaseInstitutional: "Coinbase Institutional",
    },
  } as { [network: string]: { [k in APIWalletType]?: string } };
  

export interface APIWallet {
  Id: string;
  OrganizationId: string;
  Name: string;
  Address: string;
  Network: APIWalletNetwork;
  Status: APIWalletStatus;
  Balance: APICurrencyObject;
  FireblocksWalletId: string;
  Created: APIDate;
  Type: APIWalletType;

  // Old Wallet
  // type: 1 | 2 | 3;
  // network: 1 | 2;
  // address: string;
  // walletId: string;
  // name: string;
  // status: "1" | "2" | "3" | "4"; // See Wallet Status comment:
  // Status: APIWalletStatus;
  // Balance: APICurrencyObject;
}

// Wallet Status:
// 1: "Pending Approve"
// 2: "Pending Delete"
// 3: "Verified"
// 4:  "Deleted"
// 5: "Rejected"

export type APICreateWallet = Pick<APIWallet, "Name" | "Network" | "Type" | "Address">;

export type APIWalletListItem = APIWallet;
