import { capitalize } from "stablr/functions";

export default function parseTransactionBadgeText(text?: string): string {
  if (text === undefined) return "";
  if (text === "REJECTED") return "FAILED";
  switch (text.toUpperCase()) {
    case "INITIALIZED": {
      return "INITIALIZED";
    }
    case "SUBMITTED": {
      return "Pending";
    }
    case "FAILED": {
      return "Failed";
    }
    case "VALIDATED": {
      return "Accepted";
    }
    default: {
      return capitalize(text ?? "");
    }
  }
}
