import { track } from "@amplitude/analytics-browser";
import { ReactComponent as StablRLogoMark } from "assets/svg/logo-mark-white.svg";
import { ReactComponent as StablRLogo } from "assets/svg/logo-stablr.svg";
import { confirmSignIn, fetchUserAttributes } from "aws-amplify/auth";
import logEvent, { EVENT_TYPE_ENUM } from "functions/log/log-event";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as TextIconSVG } from "stablr/assets/icons/text-sms.svg";
import { Heading, Paragraph, Spacer } from "stablr/components/atoms";
import { FeatureIcon, MFAInput } from "stablr/components/molecules";
import { HalfHalfResponsive } from "stablr/components/templates";
import { composeTestID, getErrorMessage } from "stablr/functions";
import { spacing } from "stablr/styles";
import styled from "styled-components";

interface TwoFactorAuthenticationProps {
  testid?: string;
}

interface TwoFactorAuthenticationFormValues {
  code: string;
}

const TwoFactorAuthenticationStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

const ALERT_DELAY_SECONDS = 15;

SMSAuthentication.testid = "TwoFactorAuthentication";

export default function SMSAuthentication({ ...props }: TwoFactorAuthenticationProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const destination = searchParams.get("destination");

  const [hasBeenAWhile, setHasBeenAWhile] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setHasBeenAWhile(true);
    }, ALERT_DELAY_SECONDS * 1000);
  }, []);

  const handleOnSubmit = async (values: TwoFactorAuthenticationFormValues) => {
    try {
      const newUser = await confirmSignIn({ challengeResponse: values.code });
      const attributes = await fetchUserAttributes();

      if (newUser.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        navigate("/sign-in/update-password");
        return;
      } else if (
        attributes?.["custom:assignedOrganization"] === "" ||
        attributes?.["custom:assignedOrganization"] === undefined
      ) {
        navigate("/sign-up/company-verification");
      } else if (attributes?.["custom:kybStatus"] !== "VALIDATED") {
        navigate("/sign-up/summary-and-sign-up");
      } else {
        logEvent(EVENT_TYPE_ENUM.AUTHENTICATION, "LoginSuccess");
        navigate("/dashboard");
        track("Authentication: Login Success");
        return;
      }
    } catch (error) {
      if (
        ["CodeMismatchException", "Code mismatch", "Invalid code or auth state for the user."].includes(
          getErrorMessage(error)
        )
      ) {
        return { code: "Code does not match" };
      }
      return { code: getErrorMessage(error) };
    }
  };

  return (
    <TwoFactorAuthenticationStyled data-testid={composeTestID(SMSAuthentication.testid, props.testid)}>
      <HalfHalfResponsive logo={StablRLogo} logoMark={<StablRLogoMark />}>
        <Form onSubmit={handleOnSubmit}>
          {({ handleSubmit, submitError, submitting }) => (
            <>
              <FeatureIcon icon={<TextIconSVG />} />
              <Heading as="h1" varient="h2">
                2 Factor Authentication
              </Heading>
              <Paragraph>
                Please confirm your sign-in by entering the authentication code sent to{" "}
                <b>{destination ?? "**********"}</b>
              </Paragraph>
              <Field
                name="code"
                invalid={submitError !== undefined}
                onComplete={handleSubmit}
                component={MFAInput}
                loading={submitting}
                autoFocus
              ></Field>
              {hasBeenAWhile && (
                <Paragraph>
                  If you are having trouble receiving your SMS code after 5 minutes. Sign in with the credentials you
                  created to try again.
                  <Spacer height={spacing.m} />
                  <Link to="/sign-in">Sign In Again</Link>
                </Paragraph>
              )}
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </TwoFactorAuthenticationStyled>
  );
}
