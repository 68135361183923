import * as React from "react";
import { composeTestID } from "stablr/functions/compose-test-id";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface HorizontalOptionProps {
  testid?: string;
  firstElement?: React.ReactElement | React.ReactElement[];
  secondElement?: React.ReactElement | React.ReactElement[];
}

const OR_TEXT_WIDTH = "30px";

const HorizontalOptionStyled = styled.div`
  display: flex;
  position: relative;
  min-height: 400px;
`;

const FirstOptionStyled = styled.div`
  width: 50%;
  padding-right: ${spacing.l};
`;

const SecondOptionStyled = styled.div`
  width: 50%;
  padding-left: ${spacing.l};
`;

const OrSeperatorStyled = styled.div`
  width: 0;
  text-align: center;
  border-left: 1px solid ${color.greyscale.grey3};
  position: relative;
`;

const OrTextStyled = styled.div`
  position: absolute;
  top: 50%;
  width: ${OR_TEXT_WIDTH};
  text-align: center;
  left: calc(-${OR_TEXT_WIDTH} / 2);
  background-color: ${color.greyscale.white};
  padding: ${spacing.m} 0;
  font-size: ${fontSize.button};
  font-weight: ${fontWeight.semiBold};
`;

HorizontalOptionComponent.testid = "HorizontalOption";

export default function HorizontalOptionComponent({ firstElement, secondElement, ...props }: HorizontalOptionProps) {
  return (
    <HorizontalOptionStyled data-testid={composeTestID(HorizontalOptionComponent.testid, props.testid)}>
      <FirstOptionStyled>{firstElement}</FirstOptionStyled>
      <OrSeperatorStyled>
        <OrTextStyled>or</OrTextStyled>
      </OrSeperatorStyled>
      <SecondOptionStyled>{secondElement}</SecondOptionStyled>
    </HorizontalOptionStyled>
  );
}
