import { capitalize } from "stablr/functions";
import { APITransactionStatus } from "types/API/Transaction";

export function parseTransactionBadgeText(text: APITransactionStatus): string {
  switch (text) {
    case "PENDING":
      return "Pending";
    case "CONFIRMED":
      return "Confirmed";
    case "REJECTED":
      return "Rejected";
    case "AWAITING_PAYOUT":
      return "Awaiting Payout";
    default:
      return capitalize(text); // Refurn blank style
  }
}
