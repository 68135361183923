import { Config } from "config/index";
import { PERMISSION_ENUM } from "config/permissions";
import { useWallets } from "hooks/query/wallet/use-wallets";
import * as React from "react";
import { ReactComponent as BuyIconSVG } from "stablr/assets/icons/buy.svg";
import { Button, Permission } from "stablr/components/atoms";
import { CardData, CardWrapper } from "stablr/components/molecules";
import { formatCurrency } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

interface BalanceCardProps {
  onBuyClick: () => void;
}

const BalanceCardStyled = styled(CardWrapper)`
  background: ${color.gradient};
  padding: ${spacing.m} ${spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function BalanceCardComponent({ onBuyClick }: BalanceCardProps) {
  const isMobile = useIsMobile();

  const { data, error } = useWallets();

  const filteredWalletsLength = data !== undefined ? data.filter(item => item.Status === "APPROVED").length : 0;

  const currentBalance = React.useMemo(() => {
    return data !== undefined && Array.isArray(data)
      ? formatCurrency(
          {
            Amount: data
              .filter(item => item.Status === "APPROVED")
              .map(item => parseFloat(item.Balance.Amount.toString()))
              .reduce((a, b) => {
                return a + b;
              }, 0),
            Denominator: Config.fallbackDenominator,
          },
          2
        )
      : undefined;
  }, [data]);
  return (
    <BalanceCardStyled>
      <CardData
        color={color.greyscale.white}
        label={`Total Balance${
          error
            ? ` (Error loading)`
            : data === undefined
            ? ""
            : ` (${filteredWalletsLength} Wallet${filteredWalletsLength === 1 ? "" : "s"})`
        }`}
        data={`${currentBalance !== undefined ? currentBalance : "-"} EURR`}
      />
      <Permission permission={PERMISSION_ENUM.BUY_EURR}>
        {!isMobile && (
          <Button
            rounded
            size="small"
            color={color.theme.secondary}
            icon={<BuyIconSVG />}
            onClick={onBuyClick}
            varient="secondary"
          >
            Buy
          </Button>
        )}
      </Permission>
    </BalanceCardStyled>
  );
}
