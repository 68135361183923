import * as React from 'react'
import { ReactComponent as ClearSVG } from 'stablr/assets/icons/clear.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../atoms/Icon'
import { Picture, SupportedImageType } from '../../atoms/Picture'

export interface LogoImageType {
  label: string
  src: string
}

export interface CollaborationElementProps {
  testid?: string
  logoList: LogoImageType[]
  supportedTypes: SupportedImageType[]
}

const CollaborationElementStyled = styled.div`
  display: flex;
  align-items: center;
`

const PictureStyled = styled(Picture)`
  height: 50px;

  & > img {
    height: 50px;
  }
`

const IconStyled = styled(Icon)`
  margin: 0 ${spacing.s};
`

CollaborationElement.testid = 'collaborationElement'

export function CollaborationElement({
  testid,
  logoList,
  supportedTypes,
}: CollaborationElementProps) {
  return (
    <CollaborationElementStyled data-testid={composeTestID(CollaborationElement.testid, testid)}>
      {logoList.map((logo, index) => (
        <React.Fragment key={logo.label}>
          {index !== 0 && (
            <IconStyled>
              <ClearSVG title="times" />
            </IconStyled>
          )}
          <PictureStyled
            alt={logo.label}
            src={logo.src}
            supportedTypes={supportedTypes}
          ></PictureStyled>
        </React.Fragment>
      ))}
    </CollaborationElementStyled>
  )
}
