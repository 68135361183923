import { PERMISSION_ENUM } from "config/permissions";
import useAPIKeys from "hooks/query/api-management/use-api-keys";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyIcon } from "stablr/assets/icons/vpn-key.svg";
import { ActionHeader } from "stablr/components/molecules";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import { APIManagementTable } from "./APIKeyTable";

export function APIKeyManagementOverview() {
  const navigation = useNavigate();

  const { data: apiKeys, isLoading, error, refetch } = useAPIKeys();

  return (
    <>
      <ActionHeader
        title="API Management"
        btnText="Create API Key"
        testid="Add-Key"
        onBtnClick={() => navigation("/accounts/api-management/add")}
        permission={PERMISSION_ENUM.ADD_API_KEY}
      />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching APIs" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !apiKeys?.length ? (
        <CardEmpty
          iconElement={<KeyIcon />}
          title="No APIs found"
          body="There are no APIs associated to your organization at the moment"
        />
      ) : (
        <APIManagementTable data={apiKeys} />
      )}
    </>
  );
}

export default APIKeyManagementOverview;
