import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import styled from 'styled-components'

export interface SummaryListProps {
  testid?: string
  children: React.ReactElement | React.ReactElement[]
}

const SummaryListStyled = styled.div``

SummaryList.testid = 'summaryList'

export function SummaryList({ children, ...props }: SummaryListProps) {
  return (
    <SummaryListStyled data-testid={composeTestID(SummaryList.testid, props.testid)}>
      {children}
    </SummaryListStyled>
  )
}
