import { APITransaction, APITransactionListItem } from "types/API/Transaction";

import * as Mock from "./Connection.mock";
import { APICreateAnyTransactionResponse } from "./Transaction";
import {
  MOCK_CREATE_BUY_SELL_TRANSACTION_RESPONSE,
  MOCK_TRANSACTION_DETAIL_LIST,
  MOCK_TRANSACTIONS,
} from "./Transaction.mock.data";

export const getTransactions = () =>
  Mock.get<APITransactionListItem[]>(`/organizations/transactions`, MOCK_TRANSACTIONS);

export const getTransaction = (transactionId: string): Promise<APITransaction> => {
  const mockTransaction = MOCK_TRANSACTION_DETAIL_LIST.find(transaction => transaction.Id === transactionId);
  if (mockTransaction !== undefined) {
    return Mock.get<APITransaction>(`/organizations/transactions/${transactionId}`, mockTransaction);
  } else {
    return Mock.throwError<APITransaction>(404);
  }
};

interface APICreateTransactionBasicPayload {
  WalletAddress: string;
  ClientReference: string;
}
interface APICreateSellTransactionPayload extends APICreateTransactionBasicPayload {
  DigitalAmount: string;
}

export const createSellTransaction = (_: APICreateSellTransactionPayload) =>
  Mock.postSocket<APICreateAnyTransactionResponse>(
    `/organizations/sellstablr`,
    MOCK_CREATE_BUY_SELL_TRANSACTION_RESPONSE
  );

export interface APICreateBuyTransactionPayload extends APICreateTransactionBasicPayload {
  FiatAmount: string;
}

export const createBuyTransaction = (_: APICreateBuyTransactionPayload) =>
  Mock.postSocket<APICreateAnyTransactionResponse>(
    "/organizations/buystablr",
    MOCK_CREATE_BUY_SELL_TRANSACTION_RESPONSE
  );

export const confirmTransaction = async (_transactionId: string, _transactionHash: string) =>
  Mock.post<{
    success: true;
  }>(`/ops/confirmwallettransfer`, { success: true });
