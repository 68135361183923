import Lottie from 'lottie-react'
import * as React from 'react'
import loaderData from 'stablr/assets/lottie/full-screen-loader.json'
import styled from 'styled-components'

const FullScreenLoaderContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function FullScreenLoader() {
  return (
    <FullScreenLoaderContainerStyled>
      <Lottie
        loop={false}
        autoplay={true}
        animationData={loaderData}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid meet',
        }}
        style={{
          width: '100px',
          height: '150px',
        }}
      ></Lottie>
    </FullScreenLoaderContainerStyled>
  )
}
