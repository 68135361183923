import * as React from 'react'
import { fontSize, fontWeight, media, spacing } from 'stablr/styles'
import styled from 'styled-components'

export interface FormInformationListProps {
  title: string
  bulletPoints: string[]
}

const FormInformationListStyled = styled.div`
  @media ${media.mobile} {
  }
  @media ${media.desktop} {
    padding: ${spacing.xl} 0;
  }
`

const FormInformationListTitleStyled = styled.span`
  font-size: ${fontSize.data};
  font-weight: ${fontWeight.semiBold};
  margin-bottom: ${spacing.m};
  display: block;
`

const FormInformationListListStyled = styled.ul`
  display: block;
  font-size: ${fontSize.data};
  font-weight: ${fontWeight.regular};
  padding: 0 20px;
  line-height: 28px;
`

export function FormInformationList({ title, bulletPoints }: FormInformationListProps) {
  return (
    <FormInformationListStyled>
      <FormInformationListTitleStyled>{title}</FormInformationListTitleStyled>
      <FormInformationListListStyled>
        {bulletPoints.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </FormInformationListListStyled>
    </FormInformationListStyled>
  )
}
