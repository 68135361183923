import { PERMISSION_ENUM } from "config/permissions";
import { FORM_ERROR } from "final-form";
import withPermission from "hocs/permission";
import { useCreateAccount } from "hooks/query/bank-account/use-create-bank-account";
import { useUploadFile } from "hooks/query/file/use-upload-file";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, HeaderBackNav } from "stablr/components/molecules";
import { BottomBar } from "stablr/components/organisms";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { useIsMobile, useToast } from "stablr/hooks";
import { spacing } from "stablr/styles";
import { APICreateBankAccount } from "types/API/BankAccount";

import ExitFormModal from "../../Webhooks/common/ExitFormModal";
import AccountFields from "../common/AccountFields";
import { BANK_ACCOUNT_SCHEMA, BankAccountSchemaValidation } from "../common/schemaValidation";
import SubmitButton from "../common/SubmitButton";
import { getBase64 } from "../functions/get-base-64";

interface APICreateBankAccountFormType extends Omit<APICreateBankAccount, "BankStatementFileName" | "Types"> {
  BankStatement: File;
  Type: "SWIFT" | "BLINC" | "SEPA";
}

function AddBankAccount() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { successToast } = useToast();

  const [openExitModal, setOpenExitModal] = useState(false);
  const { mutateAsync: mutateAsyncCreateAccount } = useCreateAccount();
  const { mutateAsync: mutateAsyncUploadFile } = useUploadFile();

  const handleOnSubmit = async (data: APICreateBankAccountFormType) => {
    try {
      const { BankStatement, Type, ...restData } = data; // Remove BankStatement from form data
      const base64 = `${await getBase64(BankStatement)}`;
      const fileName = `${BankStatement.name}`;
      const fileExtension = fileName.split(".").length > 0 ? fileName.split(".")?.[1] : undefined;
      const newFileName = `Bank_Statement_${Math.floor(Math.random() * 5000)}.${fileExtension}`;

      const response = await mutateAsyncUploadFile({
        file: {
          Title: newFileName,
          FileName: newFileName,
          Data: base64,
        },
        fileType: "bank-statements",
      });

      // Create Bank Account data
      const CreateBankAccount: APICreateBankAccount = {
        ...restData,
        BankStatementFileName: newFileName,
        BankStatementFileId: response.FileId,
        Types: [Type],
      };

      await mutateAsyncCreateAccount(CreateBankAccount, { onSuccess: () => navigate("/accounts/bank-accounts") });
      successToast({ message: "Bank account has been created successfully" });
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  const handleBack = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setOpenExitModal(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Form<APICreateBankAccountFormType>
        onSubmit={handleOnSubmit}
        validate={validateFormValues(BankAccountSchemaValidation)}
        keepDirtyOnReinitialize
        initialValues={{ Type: "SEPA" }}
      >
        {({ submitting, submitError, handleSubmit, values, dirty }) => {
          return (
            <>
              <HeaderBackNav
                title="New Bank Account"
                onBack={event => {
                  event.preventDefault();
                  handleBack(dirty);
                }}
              />
              <form onSubmit={handleSubmit}>
                <AccountFields accountType={values[BANK_ACCOUNT_SCHEMA.TYPE]} />
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                <BottomBar
                  paddingHorizontal={isMobile ? "0" : spacing.l}
                  rightElement={
                    !isMobile ? <SubmitButton buttonText="Add Bank Account" loading={submitting} /> : undefined
                  }
                  leftElement={
                    isMobile ? <SubmitButton buttonText="Add Bank Account" loading={submitting} /> : undefined
                  }
                />
                <ExitFormModal openModal={openExitModal} setOpenModal={setOpenExitModal} />
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default withPermission(PERMISSION_ENUM.ADD_BANK_ACCOUNTS)(AddBankAccount);
