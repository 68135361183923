import Lottie from 'lottie-react'
import React from 'react'
import blackLoaderData from 'stablr/assets/lottie/loader-simple-black.json'
import defaultLoaderData from 'stablr/assets/lottie/loader-simple-primary.json'
import whiteLoaderData from 'stablr/assets/lottie/loader-simple-white.json'

export interface LoaderProps {
  varient?: 'white' | 'default' | 'black'
  size?: string
}

const DEFAULT_SIZE = '40px'

export function Loader({ varient = 'default', size = DEFAULT_SIZE }: LoaderProps) {
  return (
    <Lottie
      loop={true}
      autoplay={true}
      animationData={
        varient === 'default'
          ? defaultLoaderData
          : varient === 'white'
          ? whiteLoaderData
          : blackLoaderData
      }
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      style={{
        width: size,
        height: size,
      }}
    ></Lottie>
  )
}
