import { PERMISSION_ENUM } from "config/permissions";
import useWebhooks from "hooks/query/webhooks/use-webhooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VectorIcon } from "stablr/assets/icons/vector.svg";
import { Icon } from "stablr/components/atoms";
import { ActionHeader } from "stablr/components/molecules";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

import { WebhooksTable } from "./WebhooksTable";

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing.s};

  > *:not(:last-child) {
    margin-right: ${spacing.s};
  }
`;
export function WebhooksOverview() {
  const navigation = useNavigate();

  const { data: webhooks, isLoading, error, refetch } = useWebhooks();

  return (
    <>
      <HeaderStyled>
        <Icon color={color.palette.purple} size={30}>
          <VectorIcon />
        </Icon>
        <ActionHeader
          title="Webhooks"
          btnText="Add Hook"
          testid="Add-Key"
          onBtnClick={() => navigation("/accounts/webhooks/add")}
          permission={PERMISSION_ENUM.ADD_WEBHOOKS}
        />
      </HeaderStyled>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching webhooks" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !webhooks?.length ? (
        <CardEmpty iconElement={<VectorIcon />} title="No Webhooks found" body="There are no webhooks at the moment" />
      ) : (
        <WebhooksTable data={webhooks} />
      )}
    </>
  );
}

export default WebhooksOverview;
