import useUserData from "hooks/context/use-user-data";
import { USER_INFORMATION_SCHEMA } from "pages/SignUp/common/userInformationSchemaValidation";
import React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as EmailIcon } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as PersonSVG } from "stablr/assets/icons/person.svg";
import { HeaderBackNav, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";
import { useIsMobile } from "stablr/hooks";

export function UserDetails() {
  const isMobile = useIsMobile();
  const userData = useUserData();

  const handleOnSubmit = () => {
    //do
  };

  const formattedPhoneNumber = userData?.phone_number?.replace(/(\+\d{2})(\d+)/, "$1 $2");

  return (
    <>
      {isMobile && <HeaderBackNav title="Settings" />}
      <Form onSubmit={handleOnSubmit}>
        {() => {
          return (
            <FormLayout>
              <>
                <Field
                  name="email"
                  label="Email Address"
                  icon={<EmailIcon />}
                  placeholder={userData.email}
                  component={TextInput}
                  readOnly
                  compact
                />
                <Field
                  label="Full Name"
                  icon={<PersonSVG />}
                  name={USER_INFORMATION_SCHEMA.FULL_NAME}
                  placeholder={userData.name}
                  component={TextInput}
                  readOnly
                  compact
                />
                <Field
                  label="Mobile Number"
                  name={USER_INFORMATION_SCHEMA.MOBILE}
                  placeholder={formattedPhoneNumber}
                  component={TextInput}
                  readOnly
                  compact
                />
              </>
            </FormLayout>
          );
        }}
      </Form>
    </>
  );
}
