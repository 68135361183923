import { del, get, patch, post, put } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { Config } from "config";
import logError from "functions/log/log-error";

export interface SocketResponse<T = void> {
  Result: "Fail" | "Success";
  Message: string;
  Data: T;
}

export type SocketErrorResponse = SocketResponse<{ Cause: string }>;

export type APIAmplifyError = {
  response: {
    status: number;
  };
};

const getErrorMessage = (error: SocketErrorResponse): string => {
  const errorObject = error?.Data?.Cause && JSON.parse(error?.Data?.Cause);
  return errorObject ? errorObject?.errorMessage : error.Message;
};

const connectSocket = async <T>(eventId: string): Promise<T> => {
  const baseUrl = process.env.REACT_APP_BASE_WEBSOCKET;
  const socketURL = `${baseUrl}?eventId=${eventId}`;
  const socket = new WebSocket(socketURL);
  const message = (await new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject("Socket connection timeout");
      socket.close();
    }, 30 * 1000);

    socket.onerror = () => {
      reject("Socket connection failure");
      clearTimeout(timeout);
    };
    socket.onmessage = ({ data }: MessageEvent<string>) => {
      try {
        const result = JSON.parse(data) as SocketResponse<T>;
        if (result.Result === "Fail") {
          reject(getErrorMessage(result as SocketErrorResponse));
        } else if (result.Result === "Success") {
          resolve(result.Data);
        } else {
          reject("Unhandled reponse rejected!");
        }
      } catch (er) {
        reject("Invalid JSON response from Socket Message");
      }
      clearTimeout(timeout);
      socket.close();
    };
  })) as T;

  return message;
};

const Connection = {
  get: async <T>(path: string, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = await get({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response;

      const response = await restOperation.body.json();
      return response as T;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  post: async <T>(path: string, body?: object, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = await post({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response;
      const response = await restOperation.body.json();
      return response as T;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postFile: async (
    path: string,
    body?: { [key: string]: File | string },
    options?: object,
    errorEventName?: string
  ): Promise<Response> => {
    const formData = new FormData();

    for (const name in body) {
      formData.append(name, body[name]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1${path}`, {
        method: "POST",
        body: formData,
        ...(options ? options : {}),
        headers: { ...(await getHeaders()) },
      });
      if (!response.ok) {
        const message = (await response.json())?.message ?? "Failed to submit file";
        throw new Error(`${message}`);
      }
      return response;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postSocket: async <T>(path: string, body?: object, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await post({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  // Temporary adjustment to resolve an event id issue for BE, this returns event ID and/or takes event ID to subscribe with instead of the returned event ID
  postSocketWithEvent: async <T>(
    path: string,
    body?: object,
    options?: object,
    errorEventName?: string,
    alternativeEventId?: string
  ): Promise<{ data: T; eventId: string }> => {
    try {
      const restOperation = (await post({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = alternativeEventId ?? response["Entries"][0]["EventId"];
      const socketResponse = await connectSocket(eventId);
      return {
        data: socketResponse as T,
        eventId,
      };
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  delete: async <T>(path: string, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await del({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response) as any;
      const response = await restOperation.body;
      return response as T;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  deleteSocket: async <T>(path: string, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await del({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response) as any; // eslint-disable-line

      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  // Temporary adjustment to resolve an event id issue for BE, this returns event ID and/or takes event ID to subscribe with instead of the returned event ID
  deleteSocketWithEvent: async <T>(
    path: string,
    body?: object,
    options?: object,
    errorEventName?: string,
    alternativeEventId?: string
  ): Promise<{ data: T; eventId: string }> => {
    try {
      const restOperation = (await del({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();

      const eventId = alternativeEventId ?? response["Entries"][0]["EventId"];
      const socketResponse = await connectSocket(eventId);
      return {
        data: socketResponse as T,
        eventId,
      };
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  patch: async <T>(path: string, body?: object, options?: object): Promise<T> => {
    const restOperation = await patch({
      apiName: Config.apiName,
      path: `api/v1${path}`,
      options: {
        ...(options ? options : {}),
        headers: await getHeaders(),
        body: body as any, // eslint-disable-line
      },
    }).response;
    const response = await restOperation.body.json();
    return response as T;
  },
  patchSocket: async <T>(path: string, body?: object, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await patch({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  put: async <T>(path: string, body?: object, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await put({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any;
      return restOperation;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  putSocket: async <T>(path: string, body?: object, options?: object, errorEventName?: string): Promise<T> => {
    try {
      const restOperation = (await put({
        apiName: Config.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postNoAuth: async (path: string, body?: object, options?: object) => {
    const restOperation = await post({
      apiName: Config.apiName,
      path: `api/v1${path}`,
      options: {
        ...(options ? options : {}),
        body: body as any, // eslint-disable-line
      },
    }).response;
    const response = await restOperation.body.json();
    return typeof response === "string" ? JSON.parse(response) : response;
  },
};

export default Connection;

const getHeaders = async () => {
  if (process.env.REACT_APP_E2E_MOCK?.toString() === "1") {
    try {
      return {
        Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken}`,
      };
    } catch (er) {
      return { Authorization: "No User Data" };
    }
  } else {
    return {
      Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken}`,
    };
  }
};
