import { Config } from "config";
import Web3 from "web3";

const ALCHEMY_MAP = {
  sepolia: {
    alchemyNetwork: "eth-sepolia",
    apiKey: "VZtMSeNm6DdAHM82CNawEDhdOmHiuDL8",
  },
  mainnet: {
    alchemyNetwork: "eth-mainnet",
    apiKey:
      Config.environment === "production" ? "rpxqJ9NNwbQjDH7EPJiaWRJSL-49eucm" : "WliS5RbqezkGZUMU3pqsT2GULc0aBY5Z",
  },
};

const provider = `https://${ALCHEMY_MAP[Config.web3.network].alchemyNetwork}.g.alchemy.com/v2/${
  ALCHEMY_MAP[Config.web3.network].apiKey
}`;

export const Web3ApiKey = ALCHEMY_MAP[Config.web3.network].apiKey;

export const Web3Service = new Web3(provider);
