import { Config } from "config";
import format from "date-fns/format";
import { transformWalletAddress } from "pages/BuySell/functions/transform-wallet-address";
import * as React from "react";
import { useIsMobile } from "stablr";
import { InlineLink, Table } from "stablr/components/atoms";
import { formatCurrency } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

const { TableBody, TableHeader, TableRow, TableData, Table: TableStyled, TableDataCopyable } = Table;
interface SuccessfulTransactionTableProps {
  transaction: APITransaction;
}

const SuccessfulTransactionTableStyled = styled(TableStyled)`
  width: 100%;
  max-width: 1250px;

  & > tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid ${color.greyscale.grey3};
  }

  & > tbody > tr > td {
    width: 40%;
    padding-right: 0;
  }

  & > tbody > tr > td > div {
    justify-content: flex-end;
  }

  & > tbody > tr > th {
    padding: ${spacing.m} 0;
    color: ${color.greyscale.black};
  }

  & > tbody > tr:nth-last-child(2) > th,
  & > tbody > tr:nth-last-child(2) > td {
    border-bottom: 1px dotted ${color.greyscale.black};
    padding-bottom: ${spacing.xl};
  }

  & > tbody > tr:nth-last-child(1) {
    border-bottom: none;
  }

  & > tbody > tr:nth-last-child(1) > th,
  & > tbody > tr:nth-last-child(1) > td {
    border-bottom: none;
  }
`;

export default function SuccessfulTransactionTable({ transaction }: SuccessfulTransactionTableProps) {
  const isMobile = useIsMobile();
  const etherscanLink =
    Config.web3.network === "mainnet"
      ? `https://etherscan.io/tx/${transaction.TransactionHash}`
      : `https://seploia.etherscan.io/tx/${transaction.TransactionHash}`;

  return (
    <SuccessfulTransactionTableStyled cellSpacing="0">
      <TableBody>
        <>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableData>{format(new Date(transaction.CreatedAt), "dd/MM/yyyy")}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>To wallet address</TableHeader>
            <TableData>
              <TableDataCopyable
                displayValue={
                  isMobile
                    ? transformWalletAddress(transaction.WalletInfo.WalletAddress)
                    : transaction.WalletInfo.WalletAddress
                }
                value={transaction.WalletInfo.WalletAddress}
              />
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Wallet Name</TableHeader>
            <TableData>{transaction.WalletInfo.WalletName}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>You Received</TableHeader>
            <TableData>
              {formatCurrency(
                {
                  Amount: transaction.FiatAmount?.Amount,
                  Denominator: Config.fallbackDenominator,
                },
                2
              )}{" "}
              EURR
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>Transaction fee</TableHeader>
            <TableData>
              {formatCurrency(
                {
                  Amount: `${
                    parseInt(transaction.FiatAmount.Amount.toString()) -
                    parseInt(transaction.DigitalAmount.Amount.toString())
                  }`,
                  Denominator: Config.fallbackDenominator,
                },
                2
              )}{" "}
              EUR
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rate</TableHeader>
            <TableData>1 EURR = 1 EUR</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>View Transaction</TableHeader>
            <TableData>
              {transaction.TransactionHash ? (
                <InlineLink target="_blank" external to={etherscanLink}>
                  Etherscan
                </InlineLink>
              ) : (
                "-"
              )}
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>You Paid</TableHeader>
            <TableData bold>
              {formatCurrency(
                {
                  Amount: transaction.DigitalAmount?.Amount,
                  Denominator: Config.fallbackDenominator,
                },
                2
              )}{" "}
              EUR
            </TableData>
          </TableRow>
        </>
      </TableBody>
    </SuccessfulTransactionTableStyled>
  );
}
