import { Menu } from "@headlessui/react";
import { PERMISSION_ENUM } from "config/permissions";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "stablr/components/atoms";
import { usePermission } from "stablr/hooks";
import { color, fontFamily, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";

interface MenuDropDownLinkProps {
  children: string;
  icon: React.ReactElement;
  to: string;
  permission?: PERMISSION_ENUM;
}

const LINK_HEIGHT = "80px";

const MenuDropDownLinkStyled = styled.div`
  text-decoration: none;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily.secondary};
  font-weight: ${fontWeight.semiBold};
  color: ${color.greyscale.black};
  height: ${LINK_HEIGHT};
  display: flex;
  align-items: center;

  &:visited,
  &:active {
    text-decoration: inherit; /* no underline */
  }

  &.active {
    color: ${color.greyscale.black};

    & .iconContainer {
      box-shadow: 0 0 0 5px rgba(132, 157, 255, 0.25);
    }

    & svg > path {
      fill: url(#gradient) !important;
    }
  }
`;

const SpanStyled = styled.span`
  padding-top: 6px; /* Offset text descender for caps only */
  padding-left: ${spacing.l};
`;

export default function MenuDropDownLinkComponent({ children, icon, to, permission }: MenuDropDownLinkProps) {
  const hasPermission = usePermission();

  if (permission && !hasPermission(permission)) return <></>;
  return (
    <li>
      <Menu.Item as={React.Fragment}>
        <MenuDropDownLinkStyled as={NavLink} to={to}>
          <Icon size={20}>{icon}</Icon>
          <SpanStyled>{children}</SpanStyled>
        </MenuDropDownLinkStyled>
      </Menu.Item>
    </li>
  );
}
