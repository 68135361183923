import { APIFileResponse, APIFileType, APIUploadFile, APIUploadFileResponse } from "types/API/File";

import Connection from "./Connection";

export const uploadFile = ({ Title, FileName, Data }: APIUploadFile, type: APIFileType) =>
  Connection.postFile(`/files/${type}`, { Title, FileName, Data }, undefined, "UPLOAD_FILE").then(
    response => response.json() as unknown as APIUploadFileResponse // Infer File Response from Response.json
  );

export const downloadFile = (fileId: string, type: APIFileType) =>
  Connection.post<APIFileResponse>(`/files/${type}/${fileId}/requests`);
