import * as React from "react";
import { ReactComponent as LogoIconSVG } from "stablr/assets/icons/logo.svg";
import { Icon } from "stablr/components/atoms";
import { composeTestID } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import { spacing } from "stablr/styles";
import styled from "styled-components";

interface NavigationDrawerProps {
  testid?: string;
  children?: React.ReactElement | React.ReactElement[];
}

const NavigationDrawerStyled = styled.nav`
  width: 125px;
  height: 100%;
  background-color: #f6f3f8;
  padding: ${spacing.m} 0;
`;

const NavigationDrawerLogoStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.s};
  margin-bottom: ${spacing.xl};
`;

const NavigationDrawerListStyled = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
`;

NavigationDrawerComponent.testid = "navigationDrawer";

export default function NavigationDrawerComponent({ children, ...props }: NavigationDrawerProps) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <></>
  ) : (
    <NavigationDrawerStyled data-testid={composeTestID(NavigationDrawerComponent.testid, props.testid)}>
      <NavigationDrawerLogoStyled as={Icon} size={"20px"} gradient>
        <LogoIconSVG />
      </NavigationDrawerLogoStyled>
      <NavigationDrawerListStyled>{children}</NavigationDrawerListStyled>
    </NavigationDrawerStyled>
);
}
