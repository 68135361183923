import React from 'react'
import { color } from 'stablr/styles'
import styled from 'styled-components'

export interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactElement | string
  id: string
  checked?: boolean
}

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
`

const SwitchInputStyled = styled.input<{ $checked: boolean }>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${color.palette.purple};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + .slider:before {
    transform: ${({ checked }) => (checked ? 'translateX(0px)' : 'translateX(16px)')};
  }
`

const Slider = styled.span<{ $checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color.greyscale.grey1};
  transition: 0.4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: ${({ $checked }) => ($checked ? '20px' : '4px')};
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ $checked }) => ($checked ? 'translateX(16px)' : 'translateX(0)')};
  }
`

export function Switch({ checked, onChange, ...props }: any) {
  return (
    <SwitchContainer>
      <SwitchInputStyled
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange && onChange(e.target.checked ? true : false)}
        {...props}
      />
      <Slider className="slider" $checked={checked} />
    </SwitchContainer>
  )
}
