import { fetchUserAttributes } from "aws-amplify/auth";
import useUserData from "hooks/context/use-user-data";
import useCompanyInfo from "hooks/query/company-information/use-company";
import * as React from "react";
import { ReactComponent as EmailSVG } from "stablr/assets/icons/alternate-email.svg";
import { ReactComponent as BusinessIconSVG } from "stablr/assets/icons/business.svg";
// import { ReactComponent as IconBackIOS } from "stablr/assets/icons/icon-back-ios.svg"; // Disabled until adjusting company information supported on BE
import { ReactComponent as PersonSVG } from "stablr/assets/icons/person.svg";
import { ReactComponent as PhoneSVG } from "stablr/assets/icons/phone.svg";
import { Badge, Button, Heading, Paragraph } from "stablr/components/atoms";
import { CardError, CardLoader, Container, FormSummaryTable } from "stablr/components/molecules";
import { BottomBar, SummaryList, SummaryListItem } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";

import parseTransactionBadgeText from "../functions/parse-transaction-badge-text";
import parseBadgeVarient from "../functions/parse-transaction-badge-varient";

interface SummaryAndSignUpProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SummaryAndSignUpComponent({ onContinue }: SummaryAndSignUpProps) {
  const user = useUserData();
  const { data: companyDetails, isLoading, error, refetch } = useCompanyInfo();
  const [currentKYBStatus, setCurrentKYBStatus] = React.useState<undefined | string>(user?.["custom:kybStatus"] ?? "");

  React.useEffect(() => {
    const getCurrentUserAttributes = async () => {
      try {
        const attributes = await fetchUserAttributes();
        setCurrentKYBStatus(attributes?.["custom:kybStatus"] ?? "");
      } catch (er) {
        setCurrentKYBStatus("");
      }
    };
    getCurrentUserAttributes();
  }, []);

  return (
    <StepTemplate
      bottomBarElement={
        <BottomBar
          // leftElement={
          //   <Button
          //     varient="secondary"
          //     onClick={onBack}
          //     layout="icon-left"
          //     mobileLayout="icon-only"
          //     icon={<IconBackIOS />}
          //   >
          //     Back
          //   </Button>
          // }
          rightElement={
            currentKYBStatus === "VALIDATED" ? (
              <Button varient="primary" onClick={onContinue}>
                Finish
              </Button>
            ) : undefined
          }
        />
      }
    >
      <>
        <Container as="section" maxWidth={822} id="title-container">
          <header>
            <Heading as="h1" varient="h2">
              6. Sign Up Summary
            </Heading>
          </header>
          <Paragraph>Summary of the previous steps</Paragraph>
          <SummaryList>
            <SummaryListItem defaultOpen={true} title="1. User Information">
              <FormSummaryTable
                data={[
                  {
                    label: "Email Address",
                    icon: <EmailSVG />,
                    value: user?.email ?? "-",
                  },
                  {
                    label: "Full Name",
                    icon: <PersonSVG />,
                    value: user?.name ?? "-",
                  },
                  {
                    label: "Mobile Phone Number",
                    icon: <PhoneSVG />,
                    value: user?.phone_number ?? "-",
                  },
                ]}
              />
            </SummaryListItem>
            <SummaryListItem title="2. Create Password" />
            <SummaryListItem title="3. Email Verification" />
            <SummaryListItem title="4. SMS Authentication" />
            <SummaryListItem
              title={
                <>
                  <span>5. Company Information</span>
                  <Badge
                    varient={parseBadgeVarient(currentKYBStatus)}
                    text={currentKYBStatus ? parseTransactionBadgeText(currentKYBStatus) : "No Status"}
                  />
                </>
              }
            >
              {isLoading ? (
                <CardLoader />
              ) : error ? (
                <CardError title="Error loading company information" body={error.toString() ?? ""} onRetry={refetch} />
              ) : (
                <FormSummaryTable
                  data={[
                    {
                      label: "Entity Name",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.Name ?? "-",
                    },
                    {
                      label: "Registration Number",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.RegistrationNumber ?? "-",
                    },
                    {
                      label: "Address",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.Address ?? "-",
                    },
                    {
                      label: "Postal code",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.PostalCode ?? "-",
                    },
                    {
                      label: "City",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.City ?? "-",
                    },
                    {
                      label: "Country",
                      icon: <BusinessIconSVG />,
                      value: companyDetails?.Country ?? "-",
                    },
                    {
                      label: "Onboarding Status",
                      icon: <BusinessIconSVG />,
                      value: (
                        <Badge
                          noMargin
                          varient={parseBadgeVarient(currentKYBStatus)}
                          text={currentKYBStatus ? parseTransactionBadgeText(currentKYBStatus) : "No Status"}
                        ></Badge>
                      ),
                    },
                  ]}
                />
              )}
            </SummaryListItem>
          </SummaryList>
        </Container>
      </>
    </StepTemplate>
  );
}
