import { Menu } from '@headlessui/react'
import * as React from 'react'
import { Icon } from 'stablr/components/atoms/Icon'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface MenuListItemProps {
  testid?: string
  children: string
  icon?: React.ReactElement
  onClick: () => void
}

const MENU_LIST_ITEM_PADDING_HORIZONTAL = '20px'
const MENU_LIST_ITEM_PADDING_VERTICAL = '5px'

const MenuListItemStyled = styled.button`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.button};
  border-radius: 5px;
  background-color: transparent;
  color: ${color.greyscale.white};
  line-height: 27px;
  padding: ${MENU_LIST_ITEM_PADDING_VERTICAL} ${MENU_LIST_ITEM_PADDING_HORIZONTAL};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  width: 100%;
  text-align: right;

  :hover {
    background-color: ${color.greyscale.white};
    color: ${color.greyscale.black};

    & path {
      fill: ${color.greyscale.black};
    }
  }
`

const IconStyled = styled.div`
  padding-left: ${spacing.m};
  padding-right: 0;

  & path {
    fill: ${color.greyscale.white};
  }
`

MenuListItem.testid = 'menuListItem'

export function MenuListItem({ onClick, children, icon, ...props }: MenuListItemProps) {
  return (
    <Menu.Item
      onClick={onClick}
      as={MenuListItemStyled}
      data-testid={composeTestID(MenuListItem.testid, props.testid)}
    >
      {children}
      {icon && <IconStyled as={Icon}>{icon}</IconStyled>}
    </Menu.Item>
  )
}
