import { APIFileResponse, APIFileType, APIUploadFile, APIUploadFileResponse } from "types/API/File";

import * as Mock from "./Connection.mock";
import { MOCK_FILE_UPLOAD_RESPONSE } from "./Files.mock.data";

export const uploadFile = ({ Title, FileName }: APIUploadFile, type: APIFileType) =>
  Mock.post<APIUploadFileResponse>(`/files/${type}`, { ...MOCK_FILE_UPLOAD_RESPONSE, Title, FileName });

export const downloadFile = (fileId: string, type: APIFileType) =>
  Mock.post<APIFileResponse>(`/files/${type}/${fileId}/requests`);
