import { Constants } from "config";
import { useWallets } from "hooks/query/wallet/use-wallets";
import * as React from "react";
import { ReactComponent as Logomark } from "stablr/assets/icons/logo-mark.svg";
import { Icon, Spacer } from "stablr/components/atoms";
import { TableDataCopyable } from "stablr/components/atoms/Table";
import { formatCurrency } from "stablr/functions";
import { color, fontSize, fontWeight, media, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

interface BankWalletInfoProps {
  transaction: APITransaction;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 50px;
  margin-bottom: ${spacing.l};
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${media.mobile}, ${media.tablet} {
    flex-direction: column;
  }

  @media ${media.desktop} {
    flex-direction: row;
  }
`;

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

const BoxStyled = styled.div`
  flex: 1;
  padding: ${spacing.xl};
  box-shadow: 0 1px 4px 0 #0000001a;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  font-size: ${fontSize.h4};
  background-color: ${color.greyscale.white};
  align-items: center;
  word-wrap: break-word;
  width: 100%;

  @media ${media.mobile} {
    padding: ${spacing.m};
  }
`;

const IbanStyled = styled.div`
  text-align: center;
  font-size: ${fontSize.data};
  padding-top: ${spacing.l};
`;

const BalanceStyled = styled.div`
  & span {
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.bold};
  }

  padding: ${spacing.m} 0 ${spacing.l} 0;
`;

const WalletAddressStyled = styled.div`
  text-align: center;
  font-size: ${fontSize.p};
  padding-top: ${spacing.l};
`;

export default function SellBankWalletInfo({ transaction }: BankWalletInfoProps) {
  const { data: wallets } = useWallets();

  const isBlincType = transaction?.BankAccountInfo?.Types?.includes("BLINC");

  const walletBalance = React.useMemo(() => {
    if (!wallets || !transaction?.WalletInfo?.WalletName) {
      return 0;
    }
    const matchingWallet = wallets.find(wallet => wallet?.Name === transaction.WalletInfo.WalletName);
    return matchingWallet ? parseFloat(matchingWallet.Balance.Amount.toString()) : 0;
  }, [wallets, transaction]);

  const formattedWalletBalance = React.useMemo(() => {
    return formatCurrency({ Amount: walletBalance.toString(), Denominator: Constants.fallbackDenominator }, 2);
  }, [walletBalance]);

  const newWalletBalance = React.useMemo(() => {
    if (!wallets || !transaction?.WalletInfo?.WalletName) {
      return "-";
    }
    const transactionAmount = transaction?.DigitalAmount?.Amount ? transaction.DigitalAmount.Amount : 0;
    const newBalanceAmount = walletBalance + transactionAmount;
    return formatCurrency({ Amount: newBalanceAmount.toString(), Denominator: Constants.fallbackDenominator }, 2);
  }, [transaction.DigitalAmount.Amount, transaction?.WalletInfo?.WalletName, walletBalance, wallets]);

  return (
    <Container>
      <BoxStyled>
        <TitleContainerStyled>
          <Spacer width={spacing.xs} />
          <div>Bank Account:</div>
          <Spacer width={spacing.xs} />
          <div>
            {transaction && transaction?.BankAccountInfo.AccountName !== undefined ? (
              <span>
                <b>{transaction?.BankAccountInfo.AccountName}</b>
              </span>
            ) : (
              "-"
            )}
          </div>
        </TitleContainerStyled>
        {transaction && (
          <IbanStyled>
            <TableDataCopyable
              value={!isBlincType ? transaction?.BankAccountInfo.IBAN : transaction?.BankAccountInfo.BlincId}
            />
          </IbanStyled>
        )}
      </BoxStyled>
      <BoxStyled>
        <TitleContainerStyled>
          <Spacer width={spacing.xs} />
          <div> Wallet Balance:</div> <Spacer width={spacing.xs} />
          <div>
            <b>
              {transaction && transaction?.WalletInfo?.WalletName !== undefined ? (
                <span>{transaction?.WalletInfo?.WalletName}</span>
              ) : (
                "-"
              )}
            </b>
          </div>
        </TitleContainerStyled>
        {transaction?.WalletInfo && (
          <WalletAddressStyled>
            <TableDataCopyable value={transaction?.WalletInfo?.WalletAddress} />
            <BalanceStyled>
              <Icon size={28}>
                <Logomark />
              </Icon>
              <span>{formattedWalletBalance}</span>
            </BalanceStyled>
            {transaction && (
              <span>
                New Balance: <b>{newWalletBalance} EURR</b>
              </span>
            )}
          </WalletAddressStyled>
        )}
      </BoxStyled>
    </Container>
  );
}
