const fontWeight = {
  default: 400,
  black: 900,
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 300,
  light: 200,
  thin: 100,
}

export default fontWeight
