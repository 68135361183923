import React from "react";
import { Field } from "react-final-form";
import { FormLayout } from "stablr";
import { CopyInputText, ListBoxInput } from "stablr/components/molecules";
import { APIWallet, APIWalletType, NETWORK_MAP, WALLET_TYPE_MAP } from "types/API/Wallet";

import { WALLET_SCHEMA } from "./schemaValidation";

const WALLET_OPTIONS = Object.entries(NETWORK_MAP).map(type => ({
  value: type[0],
  label: type[1],
}));

function WalletFields({
  isEditForm,
  disabled,
  values,
}: {
  isEditForm?: boolean;
  disabled?: boolean;
  walletType?: APIWalletType;
  values?: APIWallet;
}) {
  const filteredWalletOptions = React.useMemo(() => {
    if (values?.Network && WALLET_TYPE_MAP[values.Network]) {
      return Object.entries(WALLET_TYPE_MAP[values.Network]).map(type => ({
        value: type[0],
        label: type[1],
      }));
    }
    return [];
  }, [values?.Network]);

  return (
    <>
      <FormLayout columns={2}>
        <Field
          name={WALLET_SCHEMA.WALLET_NETWORK}
          options={WALLET_OPTIONS}
          label="Network"
          placeholder="Network"
          component={ListBoxInput}
          disabled={disabled}
        />
        <Field
          name={WALLET_SCHEMA.WALLET_TYPE}
          options={filteredWalletOptions}
          label="Type"
          placeholder="Wallet Type"
          component={ListBoxInput}
          disabled={disabled || !values?.Network || isEditForm}
        />
      </FormLayout>
      <FormLayout>
        <Field
          name={WALLET_SCHEMA.WALLET_NAME}
          label="Wallet Name"
          placeholder="Wallet Name"
          component={CopyInputText}
          compact
          maxWidth={true}
          disabled={disabled || !values?.Network}
        />
        <Field
          name={WALLET_SCHEMA.WALLET_ADDRESS}
          label="Wallet Address"
          placeholder="Wallet Address"
          component={CopyInputText}
          maxWidth
          compact
          disabled={disabled || !values?.Network || isEditForm}
        />
      </FormLayout>
    </>
  );
}

export default WalletFields;
