import { APIBankAccount, APIBankAccountListItem, APICreateBankAccount } from "types/API/BankAccount";

import Connection from "./Connection";

export const getAccountList = () =>
  Connection.get<APIBankAccountListItem[]>(`/customers/bankaccounts`, undefined, "GetBankAccounts");

export const getAccountById = (id: string) => Connection.get<APIBankAccountListItem>(`/customers/bankaccounts/${id}`);

export const createAccount = (data: APICreateBankAccount) =>
  Connection.post("/customers/bankaccounts", data, undefined, "CreateBankAccount");

export const updateAccount = (Iban: string, data: Partial<APIBankAccount>) =>
  Connection.put(`/organizations/bankaccounts/${Iban}`, data, undefined, "UpdateBankAccount");

export const deleteAccount = (accountId: string) =>
  Connection.delete(`/customers/bankaccounts/${accountId}`, undefined, "DeleteBankAccount");
