import * as React from 'react'
import { ReactComponent as KeyboardArrowRight } from 'stablr/assets/icons/keyboard-arrow-right.svg'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { useIsMobile } from '../../../../hooks/use-is-mobile'

export interface TableRowProps {
  children?: React.ReactElement[] | React.ReactElement
  onRowClick?: () => void
  noBorder?: boolean
  tooltip?: React.ReactElement
}

const TableRowStyled = styled.tr<{ isClickable: boolean; $noBorder: boolean }>`
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  ${({ $noBorder }) =>
    $noBorder === true
      ? `
  & > td,& > th{
    border-bottom: none !important;
  }
  `
      : ''}
`

const ActionIconStyled = styled.td`
  padding: 0 ${spacing.xs};
`

export function TableRow({ children, onRowClick, noBorder = false, tooltip }: TableRowProps) {
  const isMobile = useIsMobile()

  return (
    <TableRowStyled onClick={onRowClick} isClickable={!!onRowClick} $noBorder={noBorder}>
      {children}
      {onRowClick && !isMobile && (
        <ActionIconStyled>
          <KeyboardArrowRight />
        </ActionIconStyled>
      )}
      {tooltip}
    </TableRowStyled>
  )
}
