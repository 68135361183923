import { APIFileResponse } from "types/API/File";
import { APIPaymentFile, APIPaymentListItem } from "types/API/Payment";

import * as Mock from "./Connection.mock";
import { MOCK_PAYMENT_LIST } from "./Payment.mock.data";

export const getPaymentList = (): Promise<APIPaymentListItem[]> =>
  Mock.get("/organizations/payments", MOCK_PAYMENT_LIST);

export const getPaymentFileId = async (paymentReference: string) =>
  Mock.get<APIPaymentFile>(`/files/payment-instructions/${paymentReference}`, { FileId: "PAYMENT_FILE_1234" });

export const downloadPaymentFile = (paymentReference: string): Promise<APIFileResponse> =>
  Mock.get(`/files/payment-instructions/${paymentReference}`);
