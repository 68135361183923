export function transformWalletAddress(walletAddress?: string): string {
  if (!walletAddress) {
    return "";
  }
  if (walletAddress === "" || walletAddress.length < 5) {
    return walletAddress;
  }
  const first = walletAddress?.slice(0, 4);
  const last = walletAddress?.slice(-4);
  return `${first}...${last}`;
}
