import * as React from "react";
import styled from "styled-components";

export interface StepTemplateProps {
  children: React.ReactElement;
  bottomBarElement?: React.ReactElement;
  backgroundElement?: React.ReactElement;
}

const ArticleStyled = styled.article`
  flex-grow: 1;
  z-index: 1;
`;

const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export function StepTemplate({ children, bottomBarElement, backgroundElement }: StepTemplateProps) {
  return (
    <>
      {backgroundElement && <BackgroundStyled>{backgroundElement}</BackgroundStyled>}
      <ArticleStyled>{children}</ArticleStyled>
      {bottomBarElement}
    </>
  );
}
