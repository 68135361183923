import color from 'stablr/styles/color'
import styled from 'styled-components'

export const HR = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${color.greyscale.grey3};
  margin: 1em 0;
  padding: 0;
`
