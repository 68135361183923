import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTableIcon } from "refactor/HeaderTableIcon";
import { Badge, Table } from "stablr/components/atoms";
import { useIsMobile } from "stablr/hooks";
import fontWeight from "stablr/styles/fontWeight";
import styled from "styled-components";
import { APIWebhook } from "types/API/Webhooks";

import { getAPIWebhookVarient } from "../functions/get-webhook-badge-varient";

const { TableBody, TableData, TableHead, TableHeader, TableRow, Table: TableStyled } = Table;

interface WebhooksTableProps {
  data: APIWebhook[];
}

const WebhookTableStyled = styled(TableStyled)`
  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const WebhookTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    & b {
      font-weight: ${fontWeight.semiBold};
    }

    & > td:last-child {
      text-align: right;
    }
  }
`;

const NameStyled = styled.div`
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const columnHelper = createColumnHelper<APIWebhook>();

export const tableColumns = [
  columnHelper.accessor("Name", {
    header: "Title",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Url", {
    header: "URL",
    cell: info => <NameStyled>{info.getValue()}</NameStyled>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Status", {
    header: "Status",
    cell: info => <Badge noMargin varient={getAPIWebhookVarient(info.getValue())} text={info.getValue()} />,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("CreatedAt", {
    header: "Created At",
    cell: info => format(new Date(info.getValue()), "dd/MM/yyyy HH:mm"),
    footer: info => info.column.id,
  }),
];

export function WebhooksTable({ data }: WebhooksTableProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo(() => {
    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const onRowClick = (row: Row<APIWebhook>) => {
    navigate(`/accounts/webhooks/${row.original.Id}/edit`);
  };

  return (
    <WebhookTableWrapperStyled>
      <WebhookTableStyled>
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    icon={<HeaderTableIcon header={header} />}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} onRowClick={() => onRowClick(row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </WebhookTableStyled>
    </WebhookTableWrapperStyled>
  );
}
