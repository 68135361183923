import * as React from 'react'
import { ReactComponent as KeyboardArrowRight } from 'stablr/assets/icons/keyboard-arrow-right.svg'
import { ReactComponent as WarningIcon } from 'stablr/assets/icons/warning.svg'
import { Icon, Tooltip } from 'stablr/components/atoms'
import { color } from 'stablr/styles'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface TableRowTooltipProps {
  varient?: 'warning' | 'info'
  text: string
}

const TableRowTooltipStyled = styled.td`
  position: relative;
  padding: 0 ${spacing.xs} 0 0;
  width: 50px;
  svg {
    cursor: pointer;
    margin-top: 2px;
  }
  #tooltip {
    min-width: 300px;
    position: absolute;
    bottom: 80%;
    left: 80%;
    margin-left: 0;
    opacity: 0;
  }
  &:hover {
    #tooltip {
      opacity: 1;
    }
  }
`

export function TableRowTooltip({ text, varient }: TableRowTooltipProps) {
  return (
    <TableRowTooltipStyled>
      <Icon color={color.palette.blue} size={22}>
        <WarningIcon />
      </Icon>

      <Tooltip text={text}></Tooltip>
    </TableRowTooltipStyled>
  )
}
