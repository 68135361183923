import React from 'react'
import { ReactComponent as AddIcon } from 'stablr/assets/icons/add-circle-outline.svg'
import { Spacer } from 'stablr/components/atoms/Spacer'
import { composeTestID } from 'stablr/functions/compose-test-id'
import color from 'stablr/styles/color'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Button } from '../../atoms/Button'
import { RenderWithPermissions } from '../../atoms/Permission'
import { HeaderBackNav } from '../HeaderBackNav'

export interface ActionHeaderProps {
  title: string
  actionLeft?: React.ReactElement
  actionRight?: React.ReactElement
  btnText: string
  onBtnClick: () => void
  testid?: string
  permission?: string
}

const ActionHeaderStyled = styled.div`
  display: flex;
  align-items: center;
`

const ActionHeaderRightStyled = styled.div`
  margin-left: auto;
`

ActionHeader.testid = 'ActionHeader'

export function ActionHeader({
  title,
  btnText,
  onBtnClick,
  testid,
  permission,
  actionRight,
}: ActionHeaderProps) {
  return (
    <ActionHeaderStyled data-testid={composeTestID(ActionHeader.testid, testid)}>
      <HeaderBackNav title={title} />
      <Spacer width={spacing.m} />
      <RenderWithPermissions permission={permission}>
        <Button
          varient="line"
          size="medium"
          marginHorizontalSize="small"
          icon={<AddIcon />}
          iconSize={20}
          borderColor={color.palette.purple}
          color={color.palette.purple}
          onClick={onBtnClick}
        >
          {btnText}
        </Button>
      </RenderWithPermissions>
      {actionRight && <ActionHeaderRightStyled>{actionRight}</ActionHeaderRightStyled>}
    </ActionHeaderStyled>
  )
}
