import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { APIFileType, APIUploadFile } from "types/API/File";
export interface UseUploadFileMutationProps {
  file: APIUploadFile;
  fileType: APIFileType;
}

export function useUploadFile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (fileUpload: UseUploadFileMutationProps) => api.Files.uploadFile(fileUpload.file, fileUpload.fileType),

    onSuccess: async file => {
      // OnSuccess
      queryClient.setQueryData(["uploadFileData"], file); // Use an array as the key
    },
  });
}
