import * as React from 'react'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

export interface BottomBarProps {
  leftElement?: React.ReactElement
  rightElement?: React.ReactElement
  paddingHorizontal?: string
  rowReversed?: boolean
}

const BottomBarStyled = styled.div<{ $paddingHorizontal?: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${spacing.l};
  ${({ $paddingHorizontal }) =>
    $paddingHorizontal !== undefined
      ? `padding-left: ${$paddingHorizontal}; padding-right: ${$paddingHorizontal}`
      : ''}
`

const LeftNavigationStyled = styled.div``

const RightNavigationStyled = styled.div``

export function BottomBar({
  leftElement,
  rightElement,
  paddingHorizontal,
  rowReversed = false,
}: BottomBarProps) {
  return (
    <BottomBarStyled
      $paddingHorizontal={paddingHorizontal}
      style={{ flexDirection: rowReversed ? 'row-reverse' : 'row' }}
    >
      <LeftNavigationStyled>{leftElement}</LeftNavigationStyled>
      <RightNavigationStyled>{rightElement}</RightNavigationStyled>
    </BottomBarStyled>
  )
}
