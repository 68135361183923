import * as React from 'react'
import { Heading } from 'stablr/components/atoms/Heading'
import { Paragraph } from 'stablr/components/atoms/Paragraph'
import { Spacer } from 'stablr/components/atoms/Spacer'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { FeatureIcon } from '../../FeatureIcon'

export interface CardInfoProps {
  title: string
  body: string
  icon: React.ReactElement
  size?: 'large' | 'small'
  button?: React.ReactElement
}

const CardInfoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${spacing.l} ${spacing.l};
  flex: 1;
  text-align: center;

  & h2 {
    margin-bottom: 0;
    font-weight: 600;
  }

  & p {
    margin-top: ${spacing.s};
    text-align: center;
  }
`

export function CardInfo({ title, body, icon, button, size = 'large' }: CardInfoProps) {
  return (
    <CardInfoStyled>
      <FeatureIcon icon={icon} varient="large"></FeatureIcon>
      <Heading as="h2" varient="h3" testid="CardInfo">
        {title}
      </Heading>
      <Paragraph>{body}</Paragraph>
      {button && (
        <>
          <Spacer height={spacing.m} />
          {button}
        </>
      )}
    </CardInfoStyled>
  )
}
