import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useWallets } from "./use-wallets";

export function useDeleteWallet() {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToast();

  return useMutation(
    ({ walletId }: { walletId: string; title: string }) => {
      return api.Wallet.deleteWallet(walletId);
    },
    {
      onSuccess: async (_, { title }) => {
        await queryClient.invalidateQueries({ queryKey: [useWallets.queryKey] });
        successToast({ message: `${title} wallet deleted successfully` });
      },
      onError: error => {
        errorToast({ message: getErrorMessage(error) });
      },
    }
  );
}
