import { AppCurrencyObject } from '../types/currency'

export function formatCurrency(
  { Amount, Denominator = ',' }: AppCurrencyObject,
  decimalLength?: number,
): string {
  if (Amount === undefined || Amount === '') {
    return '-'
  }

  const restrictedValue = Amount.toString()
    .replace(/[^0-9.,-]/g, '')
    .replace(Denominator === ',' ? '.' : ',', Denominator)

  let resultValue = restrictedValue

  // If there is a dot, restrict the value of the decimals based on options
  const dotIndex = resultValue.indexOf(Denominator)
  const decimals =
    decimalLength !== undefined
      ? decimalLength
      : dotIndex !== -1
      ? resultValue.length - (dotIndex + 1)
      : 0

  if (dotIndex !== -1) {
    const integerPart = resultValue.slice(0, dotIndex)
    const decimalPart = resultValue.slice(dotIndex + 1, dotIndex + 1 + decimals)
    resultValue = `${integerPart}.${decimalPart}`
  }

  const significantDigits =
    Math.floor(parseFloat(resultValue)).toString().replace('-', '').length + decimals // Set to the integer length + 2 (include two decimals)

  return new Intl.NumberFormat(Denominator === '.' ? 'en-US' : 'en-DE', {
    maximumSignificantDigits: significantDigits,
    minimumSignificantDigits: significantDigits,
  }).format(parseFloat(resultValue))
}
