import * as React from "react";
import { ReactComponent as IconBackIOS } from "stablr/assets/icons/icon-back-ios.svg";
import { Button, Heading, InlineLink, Paragraph, Spacer } from "stablr/components/atoms";
import { Container } from "stablr/components/molecules";
import { BottomBar, InstructionSteps } from "stablr/components/organisms";
import { StepTemplate } from "stablr/components/templates";
import spacing from "stablr/styles/spacing";

interface ExplainedProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function ExplainedComponent({ onBack, onContinue }: ExplainedProps) {
  return (
    <StepTemplate
      bottomBarElement={
        <BottomBar
          leftElement={
            <Button
              onClick={onBack}
              varient="secondary"
              layout="icon-left"
              mobileLayout="icon-only"
              icon={<IconBackIOS />}
            >
              Back To Sign In
            </Button>
          }
          rightElement={
            <Button testid="Continue" varient="primary" onClick={onContinue}>
              Start Sign up
            </Button>
          }
        />
      }
    >
      <>
        <Container as="section" maxWidth={822}>
          <header>
            <Heading as="h1" varient="h2">
              Thank you for your interest in StablR
            </Heading>
          </header>
          <Spacer height={spacing.s} />
          <Paragraph>
            Already have an account? <InlineLink to="/sign-in">Sign in</InlineLink>
          </Paragraph>
          <Spacer height={spacing.s} />
          <Paragraph>
            Welcome to the StablR community! As a Corporate Officer, you play an important role in setting up the
            account for your organization by providing us with the necessary onboarding information. As you successfully
            sign-up, you will be able to finalize the configuration of your organization.
          </Paragraph>
        </Container>
        <Container as="section" maxWidth={1200}>
          <InstructionSteps
            steps={[
              {
                title: "Setup your Organization Admin",
                titleAs: "h2",
                titleVarient: "h3",
                body: "Fill in your user information, password and two factor authentication to complete the sign up.",
              },
              {
                title: "Setup your Organization",
                titleAs: "h2",
                titleVarient: "h3",
                body: "Fill in your organization details: entity name and registered information ",
              },
            ]}
          />
        </Container>
      </>
    </StepTemplate>
  );
}
