import { Combobox as HeadlessComboBox } from '@headlessui/react'
import * as React from 'react'
import { ReactComponent as CheckSVG } from 'stablr/assets/icons/check.svg'
import { ReactComponent as KeyboardArrowDownSVG } from 'stablr/assets/icons/keyboard-arrow-down.svg'
import color from 'stablr/styles/color'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../Icon'
import { Loader } from '../../Loader'

export interface ComboBoxComplexOption {
  id: string // Needed for wallet input
  value: string
  label: string
}

export interface ComboBoxProps
  extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  value: string
  options: (string | ComboBoxComplexOption)[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelectionChange?: (value: string) => void
  placeholder?: string
  icon?: React.ReactElement
  name?: string
  loading?: boolean
  minimumMatchedResults?: number
}

const INPUT_ICON_SIZE = 20

const HeadlessComboBoxStyled = styled.div`
  border: solid ${color.greyscale.grey5} 1px;
  border-radius: 3px;
  width: 100%;
  display: flex;
  position: relative;

  &.disabled {
    background-color: ${color.greyscale.grey1};

    & button {
      background-color: ${color.greyscale.grey1};
      cursor: default;
    }
  }
`

const HeadlessComboBoxInputStyled = styled.input`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.greyscale.black};
  padding: ${spacing.m} 0 ${spacing.m} ${spacing.s};
  width: 100%;
  border: none;

  ::placeholder {
    color: ${color.greyscale.grey5};
  }
`

const HeadlessComboBoxButtonStyled = styled.button`
  border: none;
  background-color: ${color.greyscale.white};
  padding: 0 ${spacing.m} 0 ${spacing.xs};
  cursor: pointer;
`

const HeadlessComboBoxOptionsStyled = styled.ul`
  width: 100%;
  top: calc(100% + ${spacing.xs});
  left: 0;
  position: absolute;
  min-height: 10px;
  border-radius: 3px;
  background-color: ${color.greyscale.white};
  border: solid ${color.greyscale.grey5} 1px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
  z-index: 100;
`

const HeadlessComboBoxOptionStyled = styled.li(
  ({ $active }: { $active: boolean }) => `
  width: 100%;
  background-color: ${color.greyscale.white};
  padding: ${spacing.s} ${spacing.m} ${spacing.s} ${spacing.l};
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.input};
  color: ${color.greyscale.black};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${
    $active === true
      ? `background-color: ${color.theme.primary}; color:${color.greyscale.white};`
      : ''
  }
  & > *:first-child {
    position: absolute;
    left: ${spacing.s};
  }
`,
)

const IconStyled = styled.div`
  padding: 0 0 0 ${spacing.m};
  display: flex;
  align-items: center;
`

ComboBox.testid = 'ComboBox'

export function ComboBox({
  icon,
  value,
  options,
  onChange,
  onSelectionChange,
  name,
  placeholder,
  disabled,
  loading = false,
  minimumMatchedResults = 1,
}: ComboBoxProps) {
  const [query, setQuery] = React.useState('')

  const filteredOptions = React.useMemo(
    () =>
      query === ''
        ? options
        : options.filter((option: string | ComboBoxComplexOption) => {
            return typeof option === 'string'
              ? option.toLowerCase().includes(query.toLowerCase())
              : option.label.toLowerCase().includes(query.toLowerCase())
          }),
    [query, options],
  )

  const handleOnChange = (
    value: string | ComboBoxComplexOption,
    identifier: 'id' | 'label' | 'value' = 'id',
  ) => {
    onSelectionChange && onSelectionChange(typeof value === 'string' ? value : value[identifier])
  }

  React.useEffect(() => {
    if (!query?.trim()) handleOnChange('')
    else {
      const selectedOption = options.find((option: string | ComboBoxComplexOption) => {
        return typeof option === 'string'
          ? option.toLowerCase() === query.toLowerCase()
          : option.label.toLowerCase() === query.toLowerCase()
      })
      if (selectedOption) handleOnChange(selectedOption)
    }
  }, [query])

  React.useEffect(() => {
    if (value) handleOnChange(value)
  }, [value])

  const displayValue = React.useMemo(() => {
    const newVal =
      typeof options[0] !== 'string'
        ? (options.find((opt: any) => opt?.id === value) as any)?.label
        : value
    return newVal
  }, [value])

  return (
    <HeadlessComboBoxStyled className={disabled === true ? 'disabled' : ''}>
      <HeadlessComboBox disabled={disabled} value={displayValue} onChange={handleOnChange}>
        {icon && (
          <IconStyled size={INPUT_ICON_SIZE} as={Icon}>
            {icon}
          </IconStyled>
        )}
        <HeadlessComboBox.Input
          as={HeadlessComboBoxInputStyled}
          autoComplete="off"
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={(event) => {
            onChange?.(event)
            setQuery(event.target.value)
          }}
        />

        {!disabled && !loading ? (
          <HeadlessComboBox.Button as={HeadlessComboBoxButtonStyled}>
            <Icon>
              <KeyboardArrowDownSVG />
            </Icon>
          </HeadlessComboBox.Button>
        ) : loading === true ? (
          <HeadlessComboBoxButtonStyled disabled>
            <Loader size={'27px'} varient="default" />
          </HeadlessComboBoxButtonStyled>
        ) : (
          <></>
        )}

        {filteredOptions.length >= minimumMatchedResults && (
          <HeadlessComboBox.Options as={HeadlessComboBoxOptionsStyled}>
            {filteredOptions.map((option: string | ComboBoxComplexOption) => {
              const key = typeof option === 'string' ? option : option.id
              const label = typeof option === 'string' ? option : option.label
              return (
                <HeadlessComboBox.Option as={React.Fragment} key={key} value={option}>
                  {({ selected, active }) => (
                    <HeadlessComboBoxOptionStyled $active={active}>
                      <Icon color={active ? color.greyscale.white : color.greyscale.black}>
                        {(selected || value === label || value === key) && <CheckSVG />}
                      </Icon>

                      {label}
                    </HeadlessComboBoxOptionStyled>
                  )}
                </HeadlessComboBox.Option>
              )
            })}
          </HeadlessComboBox.Options>
        )}
      </HeadlessComboBox>
    </HeadlessComboBoxStyled>
  )
}
