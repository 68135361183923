import * as React from 'react'
import { composeTestID } from 'stablr/functions/compose-test-id'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon, IconProps } from '../../Icon'

export interface InputButtonIconProps extends IconProps {
  testid?: string
  children: React.ReactElement
  onClick: () => void
  color?: string // Parsed to Icon
}

const InputButtonIconStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 50px;
  @media (${media.desktop}) {
    margin: 0 ${spacing.s};
  }
`

InputButtonIcon.testid = 'InputButtonIcon'

export function InputButtonIcon({ testid, children, onClick, ...restProps }: InputButtonIconProps) {
  return (
    <InputButtonIconStyled
      type="button"
      onClick={onClick}
      data-testid={composeTestID(InputButtonIcon.testid, testid)}
    >
      <Icon size={22} {...restProps}>
        {children}
      </Icon>
    </InputButtonIconStyled>
  )
}
