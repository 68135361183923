import { createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable } from "@tanstack/react-table";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Table } from "stablr/components/atoms";
import { capitalize } from "stablr/functions";
import { useIsMobile } from "stablr/hooks";
import fontWeight from "stablr/styles/fontWeight";
import styled from "styled-components";
import { APIUserListItem } from "types/API/User";

const { TableBody, TableData, TableHead, TableHeader, TableRow } = Table;

interface UserTableProps {
  data: APIUserListItem[];
}

const UserTableStyled = styled.table`
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const UserTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    & b {
      font-weight: ${fontWeight.semiBold};
    }

    & > td:last-child {
      text-align: right;
    }
  }
`;

const columnHelper = createColumnHelper<APIUserListItem>();

const columns = [
  columnHelper.accessor("Name", {
    header: "Name",
    cell: info => capitalize(info.getValue()),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Roles", {
    header: "Role",
    cell: info => <b>{info.getValue().join(", ")}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Email", {
    header: "Email",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Disabled", {
    header: "Status",
    cell: info =>
      info.getValue() ? (
        <Badge noMargin text="Disabled" varient="error" />
      ) : (
        <Badge noMargin text="Enabled" varient="success" />
      ),
    footer: info => info.column.id,
  }),
];

const mobileColumns = [columns[0], columns[1], columns[2], columns[3]];

export default function UserTableComponent({ data }: UserTableProps) {
  const isMobile = useIsMobile();
  const navigation = useNavigate();

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = (row: Row<APIUserListItem>) => {
    navigation(`./${row.original?.Username}/edit`);
  };

  return (
    <UserTableWrapperStyled>
      <UserTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} onRowClick={() => onRowClick(row)}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </UserTableStyled>
    </UserTableWrapperStyled>
  );
}
