import { ReactComponent as StablRLogoMark } from "assets/svg/logo-mark-white.svg";
import { ReactComponent as StablRLogo } from "assets/svg/logo-stablr.svg";
import { confirmSignIn } from "aws-amplify/auth";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LockSVG } from "stablr/assets/icons/lock.svg";
import { ReactComponent as LockIconSVG } from "stablr/assets/icons/lock-solid.svg";
import { Button, Heading, Paragraph } from "stablr/components/atoms";
import { ErrorMessage, FeatureIcon, PasswordInput } from "stablr/components/molecules";
import { HalfHalfResponsive } from "stablr/components/templates";
import { composeTestID, getErrorMessage, validateFormValues } from "stablr/functions";
import styled from "styled-components";

import { NEW_PASSWORD_SCHEMA, NewPasswordSchemaValidation } from "../common/newPasswordSchemaValidation";

interface NewPasswordProps {
  testid?: string;
}

interface NewPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const NewPasswordStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

const ParagraphStyled = styled.p`
  text-align: left;
`;

UpdatePassword.testid = "UpdatePassword";

export default function UpdatePassword({ ...props }: NewPasswordProps) {
  const navigate = useNavigate();

  const handleOnNewPasswordFromSignedIn = async (values: NewPasswordFormValues) => {
    try {
      await confirmSignIn({
        challengeResponse: values.newPassword,
      });
      navigate("/dashboard");
    } catch (err) {
      return { [FORM_ERROR]: getErrorMessage(err) };
    }
  };

  return (
    <NewPasswordStyled data-testid={composeTestID(UpdatePassword.testid, props.testid)}>
      <HalfHalfResponsive logo={StablRLogo} logoMark={<StablRLogoMark />}>
        <Form onSubmit={handleOnNewPasswordFromSignedIn} validate={validateFormValues(NewPasswordSchemaValidation)}>
          {({ handleSubmit, submitError }) => {
            return (
              <>
                <FeatureIcon icon={<LockIconSVG />} />
                <Heading as="h1" varient="h2">
                  Create New Password
                </Heading>
                <ParagraphStyled as={Paragraph}>
                  Your password must be classified as at least strong. A good password consist of:
                  <br />
                  <br />
                  <li>8 or more characters</li>
                  <li>Mixture of letters and numbers</li>
                  <li>Mixture of upper and lowercase</li>
                  <li>Special characters</li>
                </ParagraphStyled>
                <Field
                  label="Password"
                  name={NEW_PASSWORD_SCHEMA.NEW_PASSWORD}
                  placeholder="Create password"
                  component={PasswordInput}
                  hasIndicator
                  icon={<LockSVG />}
                />
                <Field
                  label="Confirm Password"
                  name={NEW_PASSWORD_SCHEMA.CONFIRM_NEW_PASSWORD}
                  icon={<LockSVG />}
                  placeholder="Repeat Password"
                  component={PasswordInput}
                />

                <Button width="100%" onClick={handleSubmit}>
                  Reset Password
                </Button>
                <ErrorMessage>{submitError}</ErrorMessage>
              </>
            );
          }}
        </Form>
      </HalfHalfResponsive>
    </NewPasswordStyled>
  );
}
