import color from 'stablr/styles/color'
import media from 'stablr/styles/media'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

const LARGE_MARGIN_VERTICAL = spacing.m
const LARGE_MARGIN_HORIZONTAL = spacing.m

const SMALL_MARGIN_VERTICAL = spacing.m
const SMALL_MARGIN_HORIZONTAL = '0px'

export const CardWrapper = styled.div`
  min-height: 50px;
  border-radius: 10px;
  background-color: ${color.greyscale.grey2};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @media ${media.mobile} {
    margin: ${SMALL_MARGIN_VERTICAL} ${SMALL_MARGIN_HORIZONTAL};
    width: calc(100% - ${SMALL_MARGIN_HORIZONTAL} * 2);
  }
  @media ${media.desktop} {
    margin: ${LARGE_MARGIN_VERTICAL} ${LARGE_MARGIN_HORIZONTAL};
    width: calc(100% - ${LARGE_MARGIN_HORIZONTAL} * 2);
  }
`
