import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import { InputNote } from "stablr/components/atoms/Form/InputNote";
import { ListBox, ListBoxOptionType } from "stablr/components/atoms/Form/ListBox";
import { spacing } from "stablr/styles";
import color from "stablr/styles/color";
import input from "stablr/styles/input";
import styled from "styled-components";

export interface ListBoxInputProps extends FieldRenderProps<string, any> {
  options: ListBoxOptionType[];
  label?: string;
  compact?: boolean;
}
const HeadlessComboBoxContainer = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
  padding-top: ${spacing.m};

  & ul {
    border: 1px solid #0000001a !important;
    box-shadow: 0 4px 4px 0 #0000001a !important;
  }

  & button {
    color: ${color.theme.primary};
    border: none !important;
    justify-content: center;

    & span {
      padding-right: ${spacing.m};
    }

    & > * {
      color: ${color.theme.primary};
    }
  }
`;
ListBoxInputNoLabel.testid = "ListBoxInputNoLabel";

export function ListBoxInputNoLabel({
  input: { ...inputProps },
  options = [],
  name,
  label,
  meta: { touched, error },
  compact = false,
  ...props
}: ListBoxInputProps) {
  const touchedError = touched && error ? error : undefined;
  return (
    <HeadlessComboBoxContainer data-testid={ListBoxInputNoLabel.testid}>
      <ListBox defaultValue="" {...inputProps} {...props} options={options} name={name} />
      {(!compact || touchedError) && (
        <InputNote htmlFor={name} color={touchedError ? color.palette.red : undefined}>
          {touchedError}
        </InputNote>
      )}
    </HeadlessComboBoxContainer>
  );
}
