import * as React from 'react'
import { ReactComponent as LogoIconSVG } from 'stablr/assets/icons/logo.svg'
import { composeTestID } from 'stablr/functions/compose-test-id'
import { useIsMobile } from 'stablr/hooks/use-is-mobile'
import fontFamily from 'stablr/styles/fontFamily'
import fontSize from 'stablr/styles/fontSize'
import fontWeight from 'stablr/styles/fontWeight'
import spacing from 'stablr/styles/spacing'
import styled from 'styled-components'

import { Icon } from '../../atoms/Icon'

export interface NavigationDrawerProps {
  testid?: string
  logoText?: string
  children?: React.ReactElement | React.ReactElement[]
}

const NavigationDrawerStyled = styled.nav`
  width: 125px;
  height: 100%;
  background-color: #f6f3f8;
  padding: ${spacing.m} 0;
`

const NavigationDrawerLogoStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.s};
  margin-bottom: ${spacing.xl};
`

const NavigationDrawerListStyled = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
`

const LogoContainerStyled = styled.div`
  position: relative;
`

const LogoTextStyled = styled.span`
  position: absolute;
  left: 100%;
  top: 0;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.p};
  font-family: ${fontFamily.secondary};
`

NavigationDrawer.testid = 'navigationDrawer'

export function NavigationDrawer({ children, logoText, ...props }: NavigationDrawerProps) {
  const isMobile = useIsMobile()

  return isMobile ? (
    <></>
  ) : (
    <NavigationDrawerStyled data-testid={composeTestID(NavigationDrawer.testid, props.testid)}>
      <NavigationDrawerLogoStyled>
        <LogoContainerStyled as={Icon} size={20} gradient>
          <LogoIconSVG />
          {logoText && <LogoTextStyled>{logoText}</LogoTextStyled>}
        </LogoContainerStyled>
      </NavigationDrawerLogoStyled>
      <NavigationDrawerListStyled>{children}</NavigationDrawerListStyled>
    </NavigationDrawerStyled>
  )
}
