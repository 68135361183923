import { APICreateWallet, APIWallet, APIWalletListItem } from "types/API/Wallet";

import * as Mock from "./Connection.mock";
import { MOCK_WALLET, MOCK_WALLET_LIST } from "./Wallet.mock.data";

export const getWalletList = (): Promise<Array<APIWalletListItem>> => Mock.get("/wallets", MOCK_WALLET_LIST);

export const getWalletById = (walletId: string): Promise<APIWalletListItem> =>
  Mock.get(`/wallets/${walletId}`, MOCK_WALLET);

export const createWallet = async (_: APICreateWallet) =>
  await Mock.postSocket<APIWallet>("/wallets", MOCK_WALLET, "Wallet created");

export const updateWallet = (walletId: string, _: Partial<APIWallet>) =>
  Mock.putSocket(`/wallets/${walletId}`, true, "Wallet Updated");

export const deleteWallet = async (walletId: string) => {
  await Mock.deleteSocket(`/wallets/${walletId}`, true, "Wallet Deleted");
};

export const approveWallet = (_: string) => Mock.postSocket("/ops/confirmwallet", true, "Wallet Approved");

export const rejectWallet = (_: string) => Mock.post(`/ops/rejectwallet`, true);

export const confirmDeleteWallet = (_: string) => Mock.post("/ops/confirmdeletewallet", true);
