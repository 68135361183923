import { useQueryClient } from "@tanstack/react-query";
import { updatePassword } from "aws-amplify/auth";
import { FORM_ERROR } from "final-form";
import useUserData from "hooks/context/use-user-data";
import { useMFAPreference } from "hooks/query/amplify/use-mfa-preference";
import { useSetMFAPreference } from "hooks/query/amplify/use-set-mfa-preference";
import { useTOTPSecret } from "hooks/query/amplify/use-totp-secret";
import HorizontalOption from "pages/Settings/components/HorizontalOption";
import React from "react";
import { Field, Form } from "react-final-form";
import { ReactComponent as AddIcon } from "stablr/assets/icons/add.svg";
import { ReactComponent as InfoIcon } from "stablr/assets/icons/info.svg";
import { ReactComponent as LockSVG } from "stablr/assets/icons/lock.svg";
import { Alert, Badge, Button, HR, Paragraph, Spacer } from "stablr/components/atoms";
import { CardLoader, ErrorMessage, HeaderBackNav, PasswordInput } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { useIsMobile, useToast } from "stablr/hooks";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

import SwitchOption from "../../components/SwitchOption";
import { PASSWORD_SCHEMA, PasswordSchemaValidation } from "./schemaValidation";
import TOTPSetup from "./TOTPSetup";

type SecuritySettingsValues = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

const Container = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;

export function SecuritySettings() {
  const isMobile = useIsMobile();
  const { successToast } = useToast();
  const [isSwitchingMFAType, setIsSwitchingMFAType] = React.useState(false); // Added to prevent jumping of switch between requests

  const queryClient = useQueryClient();

  const { isLoading: isLoadingMFAPreference, data: mfaPreference, refetch: refetchMFAPreference } = useMFAPreference();
  const { data: totpSecret, refetch: refetchTotpSecret } = useTOTPSecret();
  const { mutateAsync: mutateAsyncSetMFAPreference, isLoading: isLoadingSetMFAPreference } = useSetMFAPreference();
  const user = useUserData();

  const handleOnSubmit = async (values: SecuritySettingsValues) => {
    try {
      await updatePassword({
        oldPassword: values.currentPassword,
        newPassword: values.confirmPassword,
      });
      successToast({ message: "Password has been changed successfully" });
    } catch (err) {
      return { [FORM_ERROR]: getErrorMessage(err) };
    }
  };

  const setupTOTP = async () => {
    setIsSwitchingMFAType(true);
    try {
      await mutateAsyncSetMFAPreference("TOTP");
      await refetchMFAPreference();
    } catch (er) {
      await refetchTotpSecret();
    }
    setIsSwitchingMFAType(false);
  };

  const setupSMS = async () => {
    setIsSwitchingMFAType(true);
    await mutateAsyncSetMFAPreference("SMS");
    await refetchMFAPreference();
    queryClient.removeQueries([useTOTPSecret.queryKey]);
    setIsSwitchingMFAType(false);
  };

  const handleOnTotpSetupComplete = async () => {
    setIsSwitchingMFAType(true);
    await mutateAsyncSetMFAPreference("TOTP");
    await refetchMFAPreference();
    queryClient.removeQueries([useTOTPSecret.queryKey]);
    setIsSwitchingMFAType(false);
  };

  const message = (
    <div>
      Your password must be classified as at least strong. A good password consists of:
      <br />
      <ul>
        <li>8 or more characters</li>
        <li>Mixture of letters and numbers</li>
        <li>Mixture of upper and lowercase</li>
        <li>Special characters</li>
      </ul>
    </div>
  );

  const TOTPEnabled =
    (mfaPreference?.enabled?.includes("TOTP") || (mfaPreference?.preferred !== "SMS" && totpSecret !== undefined)) &&
    !isLoadingMFAPreference &&
    !isLoadingSetMFAPreference &&
    !isSwitchingMFAType;

  const SMSEnabled = !!(
    mfaPreference?.enabled?.includes("SMS") &&
    !isLoadingMFAPreference &&
    !isLoadingSetMFAPreference &&
    !isSwitchingMFAType
  );

  return (
    <>
      <Form onSubmit={handleOnSubmit} validate={validateFormValues(PasswordSchemaValidation)}>
        {({ handleSubmit, submitting, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            {!isMobile ? (
              <>
                <Container>
                  <Column>
                    <HeaderBackNav title="Password Settings" />
                    <Field
                      label="Current Password"
                      autoComplete="current-password"
                      type="email"
                      name={PASSWORD_SCHEMA.CURRENT_PASSWORD}
                      component={PasswordInput}
                      icon={<LockSVG />}
                    />
                    <Field
                      label="New Password"
                      type="email"
                      autoComplete="new-password"
                      name={PASSWORD_SCHEMA.PASSWORD}
                      note="Use a strong password"
                      component={PasswordInput}
                      icon={<LockSVG />}
                    />
                    <Field
                      label="Confirm Password"
                      autoComplete="new-password"
                      note={"Both passwords must match"}
                      name={PASSWORD_SCHEMA.CONFIRM_PASSWORD}
                      component={PasswordInput}
                      icon={<LockSVG />}
                    />
                    {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                    <Button varient="primary" loading={submitting} type="submit">
                      Save Password
                    </Button>
                  </Column>

                  <Column>
                    <Spacer height="290px" />
                    <Alert message={message} iconElement={<InfoIcon />} iconColor={color.palette.blue} iconSize={20} />
                  </Column>
                </Container>
              </>
            ) : (
              <>
                <HeaderBackNav title="Password Settings" />
                <Alert message={message} iconElement={<InfoIcon />} iconColor={color.palette.blue} iconSize={20} />
                <Spacer height={spacing.m} />
                <Field
                  label="Current Password"
                  type="email"
                  name="currentPassword"
                  placeholder="Current password"
                  note="Create a strong password"
                  component={PasswordInput}
                  icon={<LockSVG />}
                />
                <Field
                  label="New Password"
                  type="email"
                  name="password"
                  placeholder="Create password"
                  note="Create a strong password"
                  component={PasswordInput}
                  icon={<LockSVG />}
                />
                <Field
                  label="Confirm Password"
                  note={
                    values.password !== values.confirmPassword || values.password === ""
                      ? "Both passwords must match"
                      : undefined
                  }
                  name="confirmPassword"
                  placeholder="Repeat Password"
                  component={PasswordInput}
                  icon={<LockSVG />}
                />
                <ErrorMessage>{submitError}</ErrorMessage>
                <Button varient="primary" loading={submitting} type="submit">
                  Save Password
                </Button>
              </>
            )}
          </form>
        )}
      </Form>
      <HR />
      <HeaderBackNav title="Two Factor Authentication Settings" />
      <HorizontalOption
        firstElement={
          <>
            <SwitchOption
              value={TOTPEnabled}
              badgeElement={
                mfaPreference?.enabled?.includes("TOTP") ? <Badge text="Verified" varient="success" /> : undefined
              }
              onChange={async (newValue: boolean) => {
                if (newValue === true) {
                  setupTOTP();
                } else {
                  setupSMS();
                }
              }}
              label="Authentication App"
            />
            <Spacer height={spacing.s} />
            {isLoadingMFAPreference ? (
              <CardLoader />
            ) : totpSecret !== undefined ? (
              <>
                <TOTPSetup totpSecret={totpSecret.sharedSecret} onSuccess={handleOnTotpSetupComplete} />
              </>
            ) : !TOTPEnabled ? (
              <Alert
                iconColor={color.palette.blue}
                iconElement={<InfoIcon />}
                message={
                  "When enabling two factor authentication with the authenticator app, text message will be automatically disabled"
                }
              ></Alert>
            ) : (
              <>
                <Paragraph>
                  Two factor authentication is enabled. You will need to retrieve a code from the{" "}
                  <b>Authenticator App</b> when logging in to your account
                </Paragraph>
                <Spacer height={spacing.m} />
                <Button icon={<AddIcon />} varient="line" onClick={() => refetchTotpSecret()}>
                  New Authenticator
                </Button>
              </>
            )}
          </>
        }
        secondElement={
          <>
            <SwitchOption
              value={SMSEnabled}
              onChange={(newValue: boolean) => {
                if (newValue !== true) {
                  setupTOTP();
                } else {
                  setupSMS();
                }
              }}
              badgeElement={
                mfaPreference?.preferred === "SMS" ? <Badge text="Verified" varient="success" /> : undefined
              }
              label="Text Message"
            />
            <Spacer height={spacing.s} />
            {SMSEnabled ? (
              <Paragraph>
                Two factor authentication is enabled. A text message will be sent to <b>{user.phone_number}</b> when
                logging in to your account
              </Paragraph>
            ) : (
              <Alert
                iconColor={color.palette.blue}
                iconElement={<InfoIcon />}
                message={
                  "When enabling two factor authentication with text message, the authenticator app will be automatically disabled"
                }
              ></Alert>
            )}
          </>
        }
      />
    </>
  );
}
